import { useState, ChangeEvent, FormEvent } from 'react';
import { Form, Button, Card, Alert, FloatingLabel } from 'react-bootstrap';
import { CATEGORY_TYPE, GENDER, UserDetailsResponse } from '../../types/user-profille-types';
import { getMediaUrl, pdfUploadHelper, reciveDateTimeFormate } from '../../utils/helpers';
import toast from 'react-hot-toast';
import userService from '../../services/user-service';
import Select from 'react-select';

type Props = {
    details: UserDetailsResponse;
    userId: number;
    isDisabled?: boolean;
};

const MyFormComponent = ({ details, userId, isDisabled = false }: Props) => {
    const [formData, setFormData] = useState({
        name: details?.name,
        username: details?.username,
        gender: details?.gender,
        dateOfBirth: details?.dateOfBirth,
        isPwd: details?.userProfile?.isPwd ?? false,
        category: details?.userProfile?.category,
        profileUrl: details?.profileUrl,
        primaryEmail: details?.userProfile?.primaryEmail,
        remark: details?.userProfile?.remark ?? '',
    });

    const [error, setError] = useState<string | null>(null);

    const handleInputChange = (
        e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
    ) => {
        const { name, value, type } = e.target;
        if (type === 'checkbox') {
            const { checked } = e.target as HTMLInputElement;
            setFormData((prev) => ({
                ...prev,
                [name]: checked,
            }));
        } else {
            setFormData((prev) => ({
                ...prev,
                [name]: value,
            }));
        }
    };

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();

        // if (!formData.name || !formData.gender || !formData.dateOfBirth || !formData.category) {
        //     setError('Please fill in all required fields.');
        //     return;
        // }
        const { username, ...rest } = formData;

        userService.updateDetails(userId, rest).then((res) => {
            if (res.status === 'success') {
                toast.success(res.message);
                setError(null);
            } else {
                toast.error(res.message);
            }
        });
    };

    const handleFileChange = (files: FileList | null) => {
        pdfUploadHelper(files?.[0]).then((key) => {
            setFormData((prev) => ({
                ...prev,
                profileUrl: key,
            }));
        });
    };

    return (
        <Card className="mb-3">
            <Card.Body>
                <h4>Personal Info</h4>
                {error && <Alert variant="danger">{error}</Alert>}
                <Form onSubmit={handleSubmit}>
                    <div className="row g-3">
                        {/* Left Section */}
                        <div className="col-md-6">
                            <Form.Group controlId="formBasicName">
                                <FloatingLabel controlId="Name" label="Name" className="mb-3">
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter your name"
                                        name="name"
                                        value={formData.name || ''}
                                        onChange={handleInputChange}
                                        disabled={isDisabled}
                                    />
                                </FloatingLabel>
                            </Form.Group>

                            <Form.Group controlId="formBasicUsername">
                                <FloatingLabel
                                    controlId="UserName"
                                    label="User Name"
                                    className="mb-3"
                                >
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter your username"
                                        name="username"
                                        value={formData.username}
                                        onChange={handleInputChange}
                                        disabled
                                    />
                                </FloatingLabel>
                            </Form.Group>

                            <Form.Group controlId="formBasicEmail">
                                <FloatingLabel
                                    controlId="primaryEmail"
                                    label="User Email"
                                    className="mb-3"
                                >
                                    <Form.Control
                                        type="email"
                                        placeholder="Enter your Email"
                                        name="primaryEmail"
                                        value={formData.primaryEmail}
                                        onChange={handleInputChange}
                                        disabled={isDisabled}
                                    />
                                </FloatingLabel>
                            </Form.Group>

                            <Form.Group controlId="formBasicGender">
                                <Select
                                    isDisabled={isDisabled}
                                    placeholder="Gender"
                                    options={[
                                        { value: GENDER.MALE, label: GENDER.MALE },
                                        { value: GENDER.FEMALE, label: GENDER.FEMALE },
                                    ]}
                                    onChange={(e) =>
                                        handleInputChange({
                                            target: { name: 'gender', value: e?.value || '' },
                                        } as React.ChangeEvent<HTMLInputElement>)
                                    }
                                    value={
                                        !!!formData.gender
                                            ? null
                                            : { label: formData.gender, value: formData.gender }
                                    }
                                />
                            </Form.Group>

                            <Form.Group controlId="formBasicDOB">
                                <Form.Label>Date of Birth</Form.Label>
                                <Form.Control
                                    type="date"
                                    name="dateOfBirth"
                                    value={reciveDateTimeFormate(formData.dateOfBirth || '')?.[0]}
                                    onChange={handleInputChange}
                                    disabled={isDisabled}
                                />
                            </Form.Group>

                            <Form.Group controlId="formBasicIsPWD">
                                <Form.Check
                                    type="checkbox"
                                    label="Is PWD"
                                    name="isPwd"
                                    checked={formData.isPwd}
                                    onChange={handleInputChange}
                                    disabled={isDisabled}
                                />
                            </Form.Group>

                            <Form.Group controlId="formBasicCategory">
                                <Select
                                    isDisabled={isDisabled}
                                    placeholder="Category"
                                    options={[
                                        { value: CATEGORY_TYPE.OPEN, label: CATEGORY_TYPE.OPEN },
                                        {
                                            value: CATEGORY_TYPE.OBC_NCL,
                                            label: CATEGORY_TYPE.OBC_NCL,
                                        },
                                        { value: CATEGORY_TYPE.SC, label: CATEGORY_TYPE.SC },
                                        { value: CATEGORY_TYPE.ST, label: CATEGORY_TYPE.ST },
                                    ]}
                                    onChange={(e) =>
                                        handleInputChange({
                                            target: { name: 'category', value: e?.value || '' },
                                        } as React.ChangeEvent<HTMLInputElement>)
                                    }
                                    value={
                                        !!!formData.category
                                            ? null
                                            : { label: formData.category, value: formData.category }
                                    }
                                />
                            </Form.Group>

                            <FloatingLabel
                                controlId="floatingTextarea"
                                label="Add a Remark Here"
                                className="my-3"
                            >
                                <Form.Control
                                    as="textarea"
                                    placeholder="Add a Remark Here"
                                    onChange={handleInputChange}
                                    value={formData.remark}
                                    name="remark"
                                    disabled={isDisabled}
                                />
                            </FloatingLabel>
                        </div>

                        {/* Right Section for Image */}
                        <div className="col-md-6">
                            <Form.Group controlId="formBasicImage">
                                <div className="d-flex align-items-center justify-content-center">
                                    <div className="position-relative">
                                        <img
                                            src={getMediaUrl(formData.profileUrl!) || ''}
                                            className="student_img"
                                            style={{
                                                height: '300px',
                                                width: '300px',
                                                borderRadius: '50%',
                                            }}
                                            alt="Mentor"
                                        />
                                        <div className="edit-image-btn d-flex justify-content-center m-1">
                                            <input
                                                disabled={isDisabled}
                                                type="file"
                                                className="file-input"
                                                accept="image/*"
                                                onChange={(e) => handleFileChange(e.target.files)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Form.Group>
                        </div>
                    </div>
                    {!isDisabled && (
                        <div className="d-flex justify-content-end mt-3">
                            <Button type="submit">Submit</Button>
                        </div>
                    )}
                </Form>
            </Card.Body>
        </Card>
    );
};

export default MyFormComponent;
