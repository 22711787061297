import EnrolledStudents from './enrolled-students';
import StudentInfo from './student-info';
import UserDetails, { loader as UserDetailsLoader } from './user-details';
import AllUsersReadOnly from './all-users-read-only';
import UserAllInfoReadOnly from './user-all-info-read-only';

const Students = {
    EnrolledStudents,
    StudentInfo,
    UserDetails,
    UserDetailsLoader,
    AllUsersReadOnly,
    UserAllInfoReadOnly,
};

export default Students;
