// upload-tt-modal.tsx

import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import CustomUpload from '../ModulePDF/Upload';
import toast from 'react-hot-toast';

interface UploadTTModalProps {
    show: boolean;
    onHide: () => void;
    onConfirm: (url: string, name: string, enrollmentIds: number[]) => void;
    onFileChange: (url: string) => void;
    onNameChange: (name: string) => void;

    enrollmentIds: number[];
    title?: string;
    bodyText?: string;
    confirmText?: string;
    cancelText?: string;
}

const UploadTTModal: React.FC<UploadTTModalProps> = ({
    show,
    onHide,
    onConfirm,
    onFileChange,
    onNameChange,
    enrollmentIds,
    title = "Upload Time Table",
    confirmText = "Upload",
    cancelText = "Cancel",
}) => {
    const [selectedFile, setSelectedFile] = useState<string>();
    const [fileName, setFileName] = useState('');
    const handleNameChange = (name: string) => {
        setFileName(name);
        onNameChange(name);  // Pass the name back to the parent
    };

    const handleConfirm = () => {
        if (selectedFile && fileName) {
            onConfirm(`${selectedFile}`, fileName, enrollmentIds);
            resetFields();
        } else {
            toast.error("File and name are required");
        }
    };

    const handleCancel = () => {
        resetFields(); // Reset fields on cancel
        onHide(); // Close the modal
    };

    const resetFields = () => {
        setSelectedFile('');
        setFileName('');
        onFileChange(''); // Reset parent state
        onNameChange(''); // Reset parent state
    };

    return (
        <Modal show={show} onHide={onHide}  backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {/* File Input Section */}
                <text>Total Selected Student: {enrollmentIds.length}</text>
                <div className="edit-image-btn d-flex flex-column justify-content-right align-items-start m-2">

                    <CustomUpload
                        isPdf={true}
                        onUploaded={(key) => {
                            if (key) {
                                onFileChange(key); // Pass the key to the parent

                                setSelectedFile(key);
                            } else {
                                toast.error('Error: File key is undefined');
                            }
                        }}
                    />
                </div>

                {/* Text Input Section */}
                <div className="edit-image-btn d-flex flex-column justify-content-center align-items-center m-2">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Enter file name"
                        value={fileName}
                        onChange={(e) => handleNameChange(e.target.value)}
                    />
                </div>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={handleCancel}>
                    {cancelText}
                </Button>
                <Button variant="danger" onClick={handleConfirm}>
                    {confirmText}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default UploadTTModal;