import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { NavLink, useLocation } from 'react-router-dom';
import FormModal from '../../../components/formModal';
import LiveManagementContent from '../../../components/live-managment/live-managment';
import CreateSessionModel from '../../../components/modals/create-session-model';
import LiveAttendenceModal from '../../../components/modals/live-attendence-modal';
import { Sessions } from '../../../components/node';
import { useAppDispatch, useAppSelector } from '../../../Redux/hooks';
import { LiveManagementConstants } from '../../../Redux/live-mangment/live-mangment-constant';
import { ModalsConstants } from '../../../Redux/models/models-constants';
import {
    liveMangmentTabsType,
    nodeContentMappingInterface,
    SessionInterface,
} from '../../../types/LiveTypes';
import LiveManagmentDatatable from './live-managment-datatable';

const LiveManagement: React.FC = () => {
    const tabs = ['live', 'schdeuled', 'in-progress', 'completed', 'cancelled'];
    const location = useLocation();
    const dispatch = useAppDispatch();

    const selectedTab = location.pathname.split('/')[3] as liveMangmentTabsType;

    const [showModal, setShowModal] = useState(false);
    const [showSessionModel, setShowSessionModel] = useState(false);
    const [curentSession, setCurrentSession] = useState<SessionInterface>();

    const isModal = useAppSelector((state) => state?.ModalReducer?.isModal);
    const sessions = useAppSelector((state) => state.liveMangment?.sessions) as SessionInterface[];

    const [contentNodes, setContentNodes] = useState<nodeContentMappingInterface[]>([]);

    // Open modal and set selected row
    const handleViewClick = (row: any) => {
        setShowModal(true);
        setCurrentSession(row);
    };

    // Close modal
    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleLaunch = (url: string, id: number) => {
        const session = sessions.find((ses) => ses.id === id);
        dispatch({
            type: LiveManagementConstants.UPDATE_SESSION,
            payload: {
                ...session,
                status: 'LIVE',
            },
        });

        toast(`Launching session with ID: ${url}`);
    };

    const handleRecordings = (session: SessionInterface) => {
        setShowModal(true);
        setCurrentSession(session);
        setContentNodes(session.nodeContentMapping);
        // toast(`opeining session with ID: ${session.sessionId}`);
    };

    const handleUpcomingSessionClick = (row: SessionInterface) => {
        setCurrentSession(row);
        dispatch({ type: LiveManagementConstants.CURRENT_SESSION_ID, payload: row.id });
        dispatch({ type: ModalsConstants.IS_Modal, payload: true });
    };

    return (
        <div className="mx-3">
            <h1>Live Dashboard</h1>
            <div style={{ display: 'flex', gap: '10px', marginBottom: '10px' }}>
                {tabs.map((tab) => (
                    <NavLink
                        key={tab}
                        to={`/contents/live-mangment/${tab}`}
                        style={({ isActive }) => ({
                            textDecoration: 'none',
                            padding: '10px 20px',
                            backgroundColor: isActive ? 'var(--primary-color)' : '#e0e0e0',
                            color: isActive ? 'white' : 'black',
                            borderRadius: '5px',
                        })}
                    >
                        {tab.replace('-', ' ').toUpperCase()}
                    </NavLink>
                ))}
                <Button className="ms-auto" onClick={() => setShowSessionModel(true)}>
                    Add Session
                </Button>
            </div>

            <LiveManagmentDatatable
                handleLaunch={handleLaunch}
                handleRecordings={handleRecordings}
                handleUpcomingSessionClick={handleUpcomingSessionClick}
                handleViewClick={handleViewClick}
                // setCurentSessionClick={setCurentSessionClick}
                key={'live-managment-data-table'}
            />

            {selectedTab === 'schdeuled' && curentSession && (
                <FormModal
                    dialogClassName="modal-90w"
                    title="Update Session"
                    modalShow={isModal}
                    handleModalClose={() =>
                        dispatch({ type: ModalsConstants.IS_Modal, payload: false })
                    }
                    backdrop="static"
                    size="xl"
                >
                    <Sessions key={5} />
                </FormModal>
            )}

            {['completed', 'in-progress'].includes(selectedTab) && curentSession?.sessionId && (
                <FormModal
                    dialogClassName="modal-90w"
                    title="Add Pdf or Recodings"
                    modalShow={showModal}
                    handleModalClose={() => setShowModal(false)}
                    backdrop="static"
                    size="xl"
                >
                    <LiveManagementContent
                        contentNodes={contentNodes}
                        closeModal={() => setShowModal(false)}
                        sessionId={curentSession.sessionId}
                        zoomSessionId={curentSession.id}
                        pdfId={curentSession.pdfId}
                        videoId={curentSession.videoId ? String(curentSession.videoId) : null}
                        hasPDF={curentSession.hasPDF}
                        isRecorded={curentSession.isRecorded}
                        // handleUpdateStatusOfRecordingAndPdf={handleUpdateStatusOfRecordingAndPdf}
                        videoName={curentSession.videoName}
                        pdfName={curentSession.pdfName}
                        type={curentSession.sessionType}
                        actualStartTime={curentSession.actualStartTime || undefined}
                        actualEndTime={curentSession.actualEndTime || undefined}
                        studentCount={curentSession.studentCount}
                    />
                </FormModal>
            )}

            {selectedTab === 'live' && curentSession && (
                <LiveAttendenceModal
                    show={showModal}
                    onHide={handleCloseModal}
                    onConfirm={() => {
                        handleCloseModal();
                    }}
                    title={`Attendance Details`}
                    sessionId={curentSession.id}
                />
            )}

            <Modal show={showSessionModel} onHide={() => setShowSessionModel(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Create Session</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CreateSessionModel onSuccess={() => setShowSessionModel(false)} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowSessionModel(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default LiveManagement;
