import { PermissionPayload, PermissionResponse } from '../../types/permission.types';
import ApiService, { ApiResponse } from './api-service';

class PermissionService extends ApiService {
    constructor() {
        super('/permissions');
    }

    getAllPermission(permissionId?: number): ApiResponse<{ permissions: PermissionResponse[] }> {
        return this.getData(permissionId ? `?permissionId=${permissionId}` : '');
    }
    getPermissionById(id: number): ApiResponse<{ permission: PermissionResponse }> {
        return this.getData(`/${id}`);
    }
    createPermission(data: PermissionPayload): ApiResponse<{ id: number }> {
        return this.postData('', data);
    }
    updatePermission(id: number, data: Partial<PermissionPayload>): ApiResponse<{ id: number }> {
        return this.putData(`/${id}`, data);
    }
    deletePermission(id: number): ApiResponse<{ count: number }> {
        return this.deleteData(`/${id}`);
    }
}

export default new PermissionService();
