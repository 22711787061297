import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { ContentConstants } from '../../Redux/content/content-constants';
import { ModalsConstants } from '../../Redux/models/models-constants';
import { BACKEND_URLS, FRONTEND_URLS } from '../../config';
import { Formik } from 'formik';
import { Button, Form } from 'react-bootstrap';
import { axiosClient } from '../../services/axiosClient';
import toast from 'react-hot-toast';


const PdfLink = () => {
    const schema = yup.object().shape({
        encryptedUrl: yup.string().required('Encrypted file URL is required'),
        decryptedUrl: yup.string().required('Decrypted file URL is required'),
        password: yup.string().required('Password is required'),
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { nodeId } = useParams();

    const selectedChapter = useSelector<any>(
        (state) => state.ContentReducer.selectedChapter
    ) as { level2Id: number; level2Name: number };

    const isValidURL = (url: string) => {
        const urlRegex = new RegExp(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/);
        const isUrl = urlRegex.test(url);
        return isUrl
    };

    const handlePdfLink = async ({ encryptedUrl, decryptedUrl, password }: {
        encryptedUrl: string;
        decryptedUrl: string;
        password: string;
    }) => {
        try {

            const isEncrprtedFileIsUrl = !isValidURL(encryptedUrl)
            const isDecrptedFileIsUrl = !isValidURL(decryptedUrl)

            // Validate URLs
            if (isEncrprtedFileIsUrl) {
                toast.error('Please enter a valid encrypted URL.');
                return;
            }

            if (isDecrptedFileIsUrl) {
                toast.error('Please enter a valid decrypted URL.');
                return;
            }

            // Extract internal name from encrypted URL
            const internalName = encryptedUrl.split('/').pop() || '';

            // API call to create asset
            const response = await axiosClient.post(BACKEND_URLS.ASSET_CREATE_PDF, {
                internalName,
                fileName: internalName,
                decryptedFileUrl: decryptedUrl,
                encryptedFileUrl: encryptedUrl,
                password,
            });

            const assetId = response.data.data.id;

            // Create content object
            const content = {
                isNew: true,
                id: -1,
                name: internalName,
                assetType: "PDF",
                assetId,
                type: "",
                level2Id: selectedChapter.level2Id,
                level2Name: selectedChapter.level2Name,
                level3Id: null,
                parentAssetType: "null",
                parentAssetId: 0,
                isFree: false,
                createdOn: "",
                assetData: {
                    id: assetId,
                    internalName,
                    fileName: internalName,
                    decryptedFileUrl: decryptedUrl,
                    encryptedFileUrl: encryptedUrl,
                    password
                }
            };

            // Dispatch actions
            dispatch({ type: ContentConstants.ADD_CONTENT, payload: content });
            dispatch({ type: ModalsConstants.IS_Modal, payload: false });

            // Navigate
            navigate(`${FRONTEND_URLS.CONTENTS.EDIT_NODE}/${nodeId}/${FRONTEND_URLS.CONTENTS.ASSET}/${content.id}`,);

        } catch (error: any) {
            toast.error('Error creating PDF asset:', error);
            toast.error('An error occurred while creating the PDF asset. Please try again.');
        }
    };


    return (
        <Formik
            validationSchema={schema}
            onSubmit={(values) => handlePdfLink(values)}
            initialValues={{
                encryptedUrl: '',
                decryptedUrl: '',
                password: '',
            }}
        >
            {({ handleSubmit, handleChange, values, touched, errors }) => (
                <Form noValidate onSubmit={handleSubmit} className="p-2">
                    <Form.Group controlId="encryptedUrl" className="mb-3 row align-items-center">
                        <Form.Label className="col-sm-3 col-form-label">Encrypted URL</Form.Label>
                        <div className="col-sm-9">
                            <Form.Control
                                type="text"
                                placeholder="Encrypted file URL"
                                name="encryptedUrl"
                                value={values.encryptedUrl}
                                onChange={handleChange}
                                isInvalid={touched.encryptedUrl && !!errors.encryptedUrl}
                            />
                            <Form.Control.Feedback type="invalid" className="text-danger">
                                {errors.encryptedUrl}
                            </Form.Control.Feedback>
                        </div>
                    </Form.Group>

                    <Form.Group controlId="decryptedUrl" className="mb-3 row align-items-center">
                        <Form.Label className="col-sm-3 col-form-label">Decrypted URL</Form.Label>
                        <div className="col-sm-9">
                            <Form.Control
                                type="text"
                                placeholder="Decrypted file URL"
                                name="decryptedUrl"
                                value={values.decryptedUrl}
                                onChange={handleChange}
                                isInvalid={touched.decryptedUrl && !!errors.decryptedUrl}
                            />
                            <Form.Control.Feedback type="invalid" className="text-danger">
                                {errors.decryptedUrl}
                            </Form.Control.Feedback>
                        </div>
                    </Form.Group>

                    <Form.Group controlId="password" className="mb-3 row align-items-center">
                        <Form.Label className="col-sm-3 col-form-label">Password</Form.Label>
                        <div className="col-sm-9">
                            <Form.Control
                                type="password"
                                placeholder="Password"
                                name="password"
                                value={values.password}
                                onChange={handleChange}
                                isInvalid={touched.password && !!errors.password}
                            />
                            <Form.Control.Feedback type="invalid" className="text-danger">
                                {errors.password}
                            </Form.Control.Feedback>
                        </div>
                    </Form.Group>
                    <Button type="submit" variant="primary">
                        Add PDF
                    </Button>
                </Form>
            )
            }
        </Formik >
    );
};


export default PdfLink