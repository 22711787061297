import { useState } from 'react'
import DataTable from 'react-data-table-component';
import { ServicesInterface, ServicesApiInterface } from '../../types/ApiTypes';
import { BACKEND_URLS } from '../../config';
import { axiosClient } from '../../services/axiosClient';
import { useLoaderData } from 'react-router-dom';
import MainBtn from '../../components/btn/main-btn';
import ServiesModal from '../../components/modals/create-service-modal';
import axios from 'axios';
import toast from 'react-hot-toast';



export async function loader(): Promise<ServicesApiInterface> {
    const data = await axiosClient.get(BACKEND_URLS.SERVIECES);
    return data.data;
}


function Services() {
    const { data } = useLoaderData() as ServicesApiInterface;
    const [serviceData, setServiceData] = useState<ServicesInterface[]>(data.services)
    const [show, setShow] = useState<boolean>(false)
    const [id, setId] = useState<number | undefined>(undefined)
    const [name, setName] = useState<string | undefined>(undefined)


    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);



    const handleClick = (row: any) => {
        setId(row.id)
        setName(row.name)
        setShow(true);
    };

    const servicesColumns = [
        {
            name: "id",
            selector: (row: any) => row.id,
            cell: (row: any) => <div
                data-tag="allowRowEvents"
                className='text-breaker'
            > {row.id} </div>,
        },
        {
            name: "Service Name",
            selector: (row: any) => row.name,
            cell: (row: any) => <div
                data-tag="allowRowEvents"
                className='text-breaker'
            > {row.name} </div>,
        },
        {
            name: "Created By",
            selector: (row: any) => row.createdBy?.name,
            cell: (row: any) => <div
                data-tag="allowRowEvents"
                className='text-breaker'
            > {row.createdBy?.name} </div>,
        },
        {
            name: "Created On",
            selector: (row: any) => row.createdOn?.split('T')[0],
            cell: (row: any) => <div
                data-tag="allowRowEvents"
                className='text-breaker'
            > {row.createdOn?.split('T')[0]} </div>,
        },
        {
            name: "Updated On",
            selector: (row: any) => row.updatedOn?.split('T')[0],
            cell: (row: any) => <div
                data-tag="allowRowEvents"
                className='text-breaker'
            > {row.updatedOn?.split('T')[0]} </div>,
        },
        {
            name: 'Action',
            cell: (row: any) => (
                <button className="btn btn-primary" onClick={() => handleClick(row)}>
                    Edit
                </button>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    const handleSubmit = async ({ name }: { name: string }) => {
        try {

            if (id) {
                const res = await axiosClient.put(BACKEND_URLS.SERVIECES + `/` + id, {
                    name: name,
                });

                if (res.status === 200) {
                    toast.success("Service Item updated successfully!");
                    const res = await axiosClient.get(BACKEND_URLS.SERVIECES)
                    setServiceData(res.data.data.services)

                } else {
                    toast.error(`Failed to create service. Status: ${res.status}`);
                }
            }
            else {
                const res = await axiosClient.post(BACKEND_URLS.SERVIECES, {
                    name: name,
                });

                if (res.status === 201) {
                    toast.success("Service created successfully!");
                    const res = await axiosClient.get(BACKEND_URLS.SERVIECES)
                    setServiceData(res.data.data.services)

                } else {
                    toast.error(`Failed to create service. Status: ${res.status}`);
                }
            }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                toast.error(`Error: ${error.response?.data?.message || "Failed to create service."}`);
            } else {
                toast.error("An unexpected error occurred.");
            }
        }
        finally {
            setShow(false)
            setId(undefined)
            setName(undefined)
        }
    };


    return (
        <div className='mx-3'>
            <h2>
                Services
            </h2>

            <DataTable
                columns={servicesColumns}
                data={serviceData}  // Make sure to pass the correct data array here
                pagination
                paginationPerPage={100}
                paginationRowsPerPageOptions={[100, 500, 1000]}
                customStyles={{
                    table: {
                        style: {
                            width: '90%',
                            margin: 'auto'
                        }
                    }
                }}
            />

            <ServiesModal handleSubmit={handleSubmit} setShow={setShow} show={show} key='Services' id={id} serviceName={name} />

            <MainBtn
                message="create service"
                onClick={handleShow}
            />

        </div>
    )
}

export default Services