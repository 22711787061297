import { useEffect, useState } from 'react';
import { RoleResponse } from '../../types/role.types';
import ESaralLoader from '../../components/Spinner/ESaralLoader';
import roleService from '../../services/ApiServices/role-service';
import DataTable from 'react-data-table-component';
import { Button, Modal } from 'react-bootstrap';
import toast from 'react-hot-toast';
import CreateEditRole from '../../components/users/create-edit-role';

const RolesPage = () => {
    const [roles, setRoles] = useState<RoleResponse[]>([]);
    const [loading, setLoading] = useState(false);
    const [showRoleModal, setShowRoleModal] = useState(false);
    const [editId, setEditId] = useState<number | undefined>(undefined);

    const handleRoleModal = (show: boolean) => setShowRoleModal(show);

    const fetchRoles = () => {
        setLoading(true);
        roleService
            .getAllRoles()
            .then((res) => {
                if (res.status === 'success') {
                    setRoles(res.data.roles);
                } else {
                    toast.error(res.message);
                }
            })
            .catch(() => toast.error('Something went wrong'))
            .finally(() => setLoading(false));
    };

    const handleDelete = (id: number) => {
        if (window.confirm('Are you sure you want to delete this role?')) {
            roleService
                .deleteRole(id)
                .then((res) => {
                    if (res.status === 'success') {
                        toast.success('Role deleted successfully');
                        fetchRoles();
                    }
                })
                .catch(() => toast.error('Failed to delete role'));
        }
    };

    useEffect(() => {
        fetchRoles();
    }, []);

    const columns = [
        {
            name: 'ID',
            selector: (row: RoleResponse) => row.id,
            sortable: true,
        },
        {
            name: 'Name',
            selector: (row: RoleResponse) => row.name,
            sortable: true,
        },
        {
            name: 'Slug',
            selector: (row: RoleResponse) => row.slug,
            sortable: true,
        },
        {
            name: 'Actions',
            cell: (row: RoleResponse) => (
                <div className="d-flex gap-2">
                    <Button
                        variant="primary"
                        size="sm"
                        onClick={() => {
                            if (row.slug == 'admin') {
                                return toast.error('This role is not editable');
                            }
                            setEditId(row.id);
                            handleRoleModal(true);
                        }}
                    >
                        Edit
                    </Button>
                    <Button variant="danger" size="sm" onClick={() => handleDelete(row.id)}>
                        Delete
                    </Button>
                </div>
            ),
        },
    ];

    const handleHide = () => {
        handleRoleModal(false);
        setEditId(undefined);
    };
    const handleSuccess = () => {
        setEditId(undefined);
        handleRoleModal(false);
        fetchRoles();
    };

    if (loading) return <ESaralLoader />;

    return (
        <div className="container mt-4">
            <h3>Roles Management</h3>
            <div className="d-flex justify-content-between mx-4">
                <button className="btn btn-primary my-2" onClick={() => handleRoleModal(true)}>
                    Add Role
                </button>
                <a className="text-primary my-2" href="/permissions">
                    Permissions
                </a>
            </div>
            <DataTable
                columns={columns}
                data={roles}
                pagination
                highlightOnHover
                striped
                responsive
                progressPending={loading}
                noDataComponent={<div>No roles available...Trying adding some roles</div>}
            />
            <Modal show={showRoleModal} onHide={handleHide} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{editId ? 'Edit' : 'Create'} Role</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CreateEditRole id={editId} onSuccess={handleSuccess} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleHide}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default RolesPage;
