import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApiInterface, ChapterType } from "../../types/ApiTypes";
import { ContentConstants } from "../../Redux/content/content-constants";
import { axiosClient } from "../../services/axiosClient";
import { BACKEND_URLS } from "../../config";
import { SelectCreator } from "../selector/SelectCreator";
import { Button } from "react-bootstrap";
import toast from "react-hot-toast";

const AddNewChapter = ({ subject1Id }: { subject1Id: number }) => {
    const dispatch = useDispatch();
    const isAddChapter = useSelector<any>((state) => state.ContentReducer.isAddChapter);
    const [chapters, setChapters] = useState<ChapterType[]>([]);
    const [selectedChapter, setSelectedChapter] = useState<string>('');
    const [selectedChapterData, setSelectedChapterData] = useState<ChapterType[]>([]);

    const handleIsAddChapter = () =>
        dispatch({ type: ContentConstants.IS_ADD_CHAPTER, payload: false });

    const handleChapterData = async () => {
        try {
            const { data }: ApiInterface = await axiosClient.get(
                BACKEND_URLS.LEVEL + 2 + `?level1Id=${subject1Id}`
            );
            setChapters(data.data.level);
        } catch (error: any) {
            toast.error(error?.message);
        }
    };

    const handleOnSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const {
            target: { value },
        } = e;
        const selected = chapters.filter((chapter) => chapter.id == parseInt(value));
        setSelectedChapter(value);
        setSelectedChapterData(selected);
    };

    const handelSaveButton = () => {
        if (selectedChapterData.length == 0) {
            toast.error('please choose chapter');
            return;
        }
        dispatch({
            type: ContentConstants.ADD_CHAPTER,
            payload: {
                level2Id: selectedChapterData[0].id,
                level2Name: selectedChapterData[0].internalName,
            },
        });
        handleIsAddChapter();
    };

    useEffect(() => {
        if (isAddChapter) {
            handleChapterData();
        }
    }, [isAddChapter]);

    return isAddChapter ? (
        <div className="col-9 p-2 ps-3">
            <h2>Create Chapter</h2>
            <div className="w-25    ">
                {chapters && (
                    <SelectCreator
                        labelName="Chapter"
                        options={chapters.map((lev) => {
                            return {
                                optionName: lev.internalName,
                                optionValue: lev.id,
                            };
                        })}
                        onChange={handleOnSelect}
                        selectedId={selectedChapter}
                        // linkName='Add a new subject'
                        linkUrl={'/'}
                    />
                )}
            </div>
            <div className="w-25 d-flex justify-content-between m-3">
                <Button onClick={handelSaveButton}>Save</Button>
                <Button variant="danger" onClick={handleIsAddChapter}>
                    Cancel
                </Button>
            </div>
        </div>
    ) : (
        <></>
    );
};


export default AddNewChapter