import axios from "axios";
import { ENDPOINT_HOST, STUDENT_ENDPOINT_HOST } from "../config";
import toast from "react-hot-toast";
import base64 from 'base-64'

const pathname = window.location.href

const axiosClient = axios.create({
  baseURL: pathname.includes(`/user/progress-report`) ? STUDENT_ENDPOINT_HOST : ENDPOINT_HOST,
  // timeout: 10000,
  headers: {
    "Content-Type": "application/json",
  },
});


const setAuthorizationHeader = (config: any) => {
  // Retrieve token or set it to an empty string if null
  let token = localStorage.getItem('@user') || '';
  if (!token && window?.ReactNativeWebView) {
    const userData = base64.decode(localStorage.getItem('@user') ?? '');
    const decodedUser = userData ? JSON.parse(window.btoa(userData)) : ''
    localStorage.setItem('@user', decodedUser);
  }
  return config;
};

axiosClient.interceptors.request.use(
  // Add the Authorization header before making the request
  setAuthorizationHeader,
  (error) => {
    // Handle request errors
    return Promise.reject(error);
  }
);

axiosClient.interceptors.request.use(
  (config) => {
    try {
      const user = localStorage.getItem("@user");
      if (user) {
        const parsedUser = JSON.parse(window.atob(user)) as { token: string; isNewUser?: boolean };

        if (parsedUser && parsedUser.token) {
          config.headers.Authorization = `Bearer ${parsedUser.token}`;
        } else {
          toast.error("Token is missing or invalid. Proceeding without Authorization header.");
        }
      }
    } catch (error) {
      toast.error("contact to admin");
      localStorage.removeItem("@user");
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);


axiosClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Handle errors globally
    if (error.response && error.response.status === 401) {
      localStorage.clear()
      window.location.href = '/login'
    } else if (error.response && error.response.status === 500) {
      toast.error("Internal Server Error");
    } else if (!navigator.onLine) {
      // else if (!error.response) {
      toast.error("Network Error");
    }
    else {
      toast.error(error.response.data.message)
    }
    return Promise.reject(error);
  }
);

export { axiosClient };
