import ApiService, { ApiResponse } from "./ApiServices/api-service";

class SettingServices extends ApiService {
    constructor() {
        super('/settings')
    }

    getSettings() {
        const data: string[] = []
        return this.postData('', data)
    }

    addSettings(key: string, value: string | [] | {}): ApiResponse {
        return this.postData('/add', { key, value })
    }

    updateSettings(prevKey: string, key: string, value: string | [] | {}): ApiResponse {
        return this.putData(`/${prevKey}`, { key, value })
    }
}


export default new SettingServices();