// Defines application-wide constants, such as API endpoints or action types

const STATES: { value: string; label: string }[] = [
    { value: 'AP', label: 'Andhra Pradesh' },
    { value: 'AR', label: 'Arunachal Pradesh' },
    { value: 'AS', label: 'Assam' },
    { value:'AN', label:'Andaman and Nicobar Islands' },
    { value: 'BR', label: 'Bihar' },
    { value: 'CG', label: 'Chhattisgarh' },
    { value:'CH', label:'Chandigarh' },
    { value: 'DL', label: 'Delhi' },
    { value:'DD', label:'Dadra and Nagar Haveli and Daman and Diu'},
    { value: 'GA', label: 'Goa' },
    { value: 'GJ', label: 'Gujarat' },
    { value: 'HR', label: 'Haryana' },
    { value: 'HP', label: 'Himachal Pradesh' },
    { value: 'JH', label: 'Jharkhand' },
    { value: "JK", label: 'Jammu & Kashmir' },
    { value: 'KA', label: 'Karnataka' },
    { value: 'KL', label: 'Kerala' },
    { value: 'LD', label: 'Lakshadweep' },
    { value: 'LA', label: 'Ladakh' },
    { value: 'MP', label: 'Madhya Pradesh' },
    { value: 'MH', label: 'Maharashtra' },
    { value: 'MN', label: 'Manipur' },
    { value: 'ML', label: 'Meghalaya' },
    { value: 'MZ', label: 'Mizoram' },
    { value: 'NL', label: 'Nagaland' },
    { value: 'OD', label: 'Odisha' },
    { value: 'PB', label: 'Punjab' },
    { value:'PY', label: 'Pondicherry'},
    { value: 'RJ', label: 'Rajasthan' },
    { value: 'SK', label: 'Sikkim' },
    { value: 'TN', label: 'Tamil Nadu' },
    { value: 'TG', label: 'Telangana' },
    { value: 'TR', label: 'Tripura' },
    { value: 'UK', label: 'Uttarakhand' },
    { value: 'UP', label: 'Uttar Pradesh' },
    { value: 'WB', label: 'West Bengal' },
];

const EDUCATION_BOARD_OPTIONS: { value: string; label: string }[] = [
    { value: 'CBSE', label: 'CBSE - Central Board of Secondary Education' },
    { value: 'ISC', label: 'ISC - Indian School Certificate' },
    { value: 'ICSE', label: 'ICSE - Indian School Certificate Examinations' },
    { value: 'NIOS', label: 'NIOS - National Institute of Open Schooling' },
    {
        value: 'UP Board',
        label: 'UP Board - Board of High School and Intermediate Education Uttar Pradesh',
    },
    { value: 'JKBOSE', label: 'JKBOSE - Jammu and Kashmir State Board of School Education' },
    { value: 'RBSE', label: 'RBSE - Board of Secondary Education Rajasthan' },
    { value: 'HPBOSE', label: 'HPBOSE - Himachal Pradesh Board of School Education' },
    { value: 'MPBSE', label: 'MPBSE - Madhya Pradesh Board of Secondary Education' },
    { value: 'CGBSE', label: 'CGBSE - Chhattisgarh Board of Secondary Education' },
    { value: 'PSEB', label: 'PSEB - Punjab School Education Board' },
    { value: 'BSEH', label: 'BSEH - Haryana Board of School Education (HBSE)' },
    { value: 'BSEB', label: 'BSEB - Bihar School Examination Board' },
    { value: 'GSEB', label: 'GSEB - Gujarat Secondary and Higher Secondary Education Board' },
    {
        value: 'MSBSHSE',
        label: 'MSBSHSE - Maharashtra State Board Of Secondary and Higher Secondary Education',
    },
    { value: 'BIEAP', label: 'BIEAP - Andhra Pradesh Board of Intermediate Education' },
    { value: 'BSEAP', label: 'BSEAP - Andhra Pradesh Board of Secondary Education' },
    { value: 'WBBSE', label: 'WBBSE - West Bengal Board of Secondary Education' },
    { value: 'WBCHSE', label: 'WBCHSE - West Bengal Council of Higher Secondary Education' },
    { value: 'OTHER', label: 'Other' },
];

const CONTACT_MODE_TYPE = [
    {
        value: 'WHATSAPP',
        label: 'WHATSAPP',
    },
    {
        value: 'PHONE',
        label: 'PHONE',
    },
    {
        value: 'EMAIL',
        label: 'EMAIL',
    },
];

const REALATIONS = [
    {
        value: 'SELF',
        label: 'SELF',
    },
    {
        value: 'MOTHER',
        label: 'MOTHER',
    },
    {
        value: 'FATHER',
        label: 'FATHER',
    },
    {
        value: 'UNCLE',
        label: 'UNCLE',
    },
    {
        value: 'BROTHER',
        label: 'BROTHER',
    },
    {
        value: 'SISTER',
        label: 'SISTER',
    },
];

interface SelectType {
    value: string,
    label: string,
}

const CallToAction: SelectType[] = [
    {
        label: 'Homepage',
        value: 'HOMEPAGE'
    },
    {
        label: 'Course page',
        value: 'COURSE'
    },
    {
        label: 'Specific course',
        value: 'SINGLECOURSE'
    },
    {
        label: 'External',
        value: 'EXTERNAL'
    }
]

const CampaignType: SelectType[] = [
    {
        label: 'One Time',
        value: "ONE-TIME"
    },
    {
        label: 'Can be used Reused',
        value: 'REUSABLE'
    }
]


const NODE_PREFIXES = {
    "categories": [
        "JEE",
        "JM",
        "JA",
        "NEET",
        "Olympiad",
        "Bitsat"
    ],
    "grades": [
        "11",
        "12",
        "12+",
        "10",
        "9",
        "8",
        "7"
    ],
    "subjects": [
        "Physics",
        "Chemistry",
        "Mathematics",
        "Biology",
        "History",
        "Economics",
        "Geography",
        "Civics",
        "English",
        "Grammar Section",
        "Writing Section",
        "Footprints Without Feet",
        "First Flight",
        "Moments",
        "Beehive",
        "integrated",
        "Reading Section",
        "Test",
    ],
    "contentType": [
        "TWT",
        "Sheets",
        "Video Lectures",
        "Sessions",
        "PYQ",
        "Mindmaps",
        "Revision Videos",
        "Notes",
        "Boards",
        "NCERT",
    ]
};

export { STATES, EDUCATION_BOARD_OPTIONS, CONTACT_MODE_TYPE, REALATIONS, NODE_PREFIXES, CallToAction, CampaignType };
