import { Row, Col, Card, Form, Button, Image, FormGroup, FloatingLabel, InputGroup } from "react-bootstrap";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import images from "../../assets/images";
import OTPInput from "react-otp-input";
import './login-component.css'; // Custom CSS file for extra styling
import { axiosClient } from "../../services/axiosClient";
import { BACKEND_URLS, FRONTEND_URLS } from "../../config";
import toast from "react-hot-toast";
import { FaPhoneAlt } from "react-icons/fa";

const LoginComponent = () => {
  const [otp, setOtp] = useState<string>('');
  const [mobileNumber, setMobileNumber] = useState<string>("");
  const [isOtpSend, setIsOtpSend] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const [mobileNumberInvalid, setMobileNumberInvalid] = useState<boolean>(false)
  const navigate = useNavigate()

  const handleSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  const sendOTP = async (e: React.SyntheticEvent<HTMLFormElement | HTMLButtonElement>) => {
    e.preventDefault();
    if (mobileNumber.length !== 10) {
      setError('Phone Number is invalid.')
      setMobileNumberInvalid(true)
      return
    }
    setMobileNumberInvalid(false)

    try {
      const response = await axiosClient.post(
        BACKEND_URLS.SEND_OTP,
        {
          "mobileNumber": mobileNumber,
          "countryCode": "+91",
          "appVersion": "1.0.0",
          // "refId": ""
        }
      )
      if (response.data.status === "success") {
        setIsOtpSend(true)
      }
      else {
        toast.error('try after some time their is issue.')
      }
    } catch (error: any) {
      toast.error(error.message)
    }
  }

  const handleMobileNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value: string = e.target.value
    if (value.length <= 10) {
      setMobileNumber(e.target.value)
    }
  }

  const handleVerifieOTP = async (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault()
    const payload = {
      "mobileNumber": mobileNumber,
      "countryCode": "91",
      "otp": otp,
      "deviceInfo": {
        "uniqueId": "unique-device-id1",
        "os": "Android",
        "osVersion": "12.0",
        "deviceName": "Samsung Galaxy S21",
        "buildId": "",
        "brand": "",
        "deviceId": "unique-device-id1",
        "displayId": "",
        "hardwareId": "",
        "manufacturerName": "",
        "productName": ""
      }
    }

    try {
      const response = await axiosClient.post(BACKEND_URLS.VERIFY_OTP, payload)
      if (response.data.status === "success") {
        const userKey = response.data.data
        localStorage.setItem('@user', window.btoa(JSON.stringify(userKey)))
        navigate(FRONTEND_URLS.ROOT)
      }
    } catch (error: any) {
      toast.error(error.response.data.message)
    }


  }

  return (
    <Row className="align-items-center justify-content-center g-0 min-vh-100 login-body">
      <Col xxl={4} lg={6} md={8} xs={12} className="py-8 py-xl-0">
        <Card className="smooth-shadow-md login-card">
          <Card.Body className="p-6">
            <div className="mb-4 text-center">
              <Link to="/">
                <img src={images.logo as unknown as string} alt="" height={30} width={120} />
              </Link>
            </div>

            {error && (
              <div className="alert alert-danger">
                <strong>Error:</strong> {error}
              </div>
            )}
            {!isOtpSend && <Form onSubmit={sendOTP}>
              <Card className="p-3 shadow-sm border-0 rounded-3">
                <Form.Group controlId="mobileNumber" className="mb-3">
                  <Form.Label className="fw-bold mb-2">Mobile Number</Form.Label>
                  <InputGroup>
                    <InputGroup.Text className="bg-light text-secondary">
                      <FaPhoneAlt />
                    </InputGroup.Text>
                    <FloatingLabel controlId="MobileNumber" label="Enter mobile number">
                      <Form.Control
                        type="text"
                        value={mobileNumber}
                        onChange={handleMobileNumber}
                        name="mobile number"
                        placeholder="Enter mobile number"
                        required
                        isInvalid={mobileNumberInvalid}
                        maxLength={10}
                        disabled={isOtpSend}
                        className="border-start-0"
                      />
                    </FloatingLabel>
                    <Button
                      onClick={sendOTP}
                      disabled={isOtpSend}
                      className="btn-gradient ms-2"
                    >
                      Get OTP
                    </Button>
                  </InputGroup>
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid mobile number.
                  </Form.Control.Feedback>
                </Form.Group>
              </Card>
            </Form>
            }
            {isOtpSend && (

              <div className="otp-verifie d-flex justify-content-center w-100">
                {/* <button onClick={() => { setIsOtpSend(false) }}>back</button> */}
                <Form className="form" onSubmit={handleVerifieOTP}>

                  <div className="title">OTP</div>
                  <div className="title">Verification Code</div>
                  <p className="message">
                    We have sent a verification code to your mobile number
                  </p>
                  <div className="inputs">
                    <OTPInput
                      containerStyle={'justify-content-center'}
                      value={otp}
                      onChange={setOtp}
                      numInputs={4}
                      renderSeparator={<span>-</span>}
                      renderInput={(props) => <input {...props} />}
                      inputStyle={{
                        width: '40px',
                        height: '80px',
                        borderRadius: '8px',
                        borderColor: 'var(--primary-color)',
                        transition: 'all 0.3s ease-in-out'
                      }}
                      shouldAutoFocus
                    />
                  </div>
                  <button className="action">verify me</button>
                </Form>
              </div>
            )}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default LoginComponent;
