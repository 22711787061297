import { useEffect, useState } from 'react';
import userService from '../../services/user-service';
import toast from 'react-hot-toast';
import permissionService from '../../services/ApiServices/permission-service';
import { PermissionResponse } from '../../types/permission.types';

const AssignPermissions = ({ id }: { id: number }) => {
    const [permissions, setPermissions] = useState<PermissionResponse[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [userPermissionIds, setUserPermissionIds] = useState<number[]>([]);

    useEffect(() => {
        if (id) {
            setIsLoading(true);
            userService
                .getUserPermissions(id)
                .then((res) => {
                    if (res.status === 'success') {
                        setUserPermissionIds(res.data.permissions.map((p) => p.permissionId));
                    }
                })
                .finally(() => setIsLoading(false));
        }

        permissionService.getAllPermission().then((res) => {
            if (res.status === 'success') {
                setPermissions(res.data.permissions);
            }
        });
    }, [id]);

    const handleGivePermission = (roleId: number) => {
        if (roleId && id) {
            userService
                .assignRole(id, roleId)
                .then((res) => {
                    if (res.status === 'success') {
                        setUserPermissionIds((prev) => [...prev, roleId]);
                        toast.success(res.message);
                    }
                })
                .catch(() => toast.error('Failed to assign role'));
        }
    };

    const handleRemovePermission = (roleId: number) => {
        if (roleId && id) {
            userService
                .removeRole(id, roleId)
                .then((res) => {
                    if (res.status === 'success') {
                        setUserPermissionIds((prev) => prev.filter((role) => role !== roleId));
                        toast.success(res.message);
                    }
                })
                .catch(() => toast.error('Failed to remove role'));
        }
    };

    const handleCheckboxChange = (roleId: number, isChecked: boolean) => {
        if (isChecked) {
            handleGivePermission(roleId);
        } else {
            handleRemovePermission(roleId);
        }
    };

    return (
        <div>
            {isLoading ? (
                <p>Loading permissions...</p>
            ) : (
                <ul className="list-group">
                    {permissions.map((permission) => (
                        <li
                            key={permission.id}
                            className="list-group-item d-flex align-items-center"
                        >
                            <input
                                type="checkbox"
                                checked={userPermissionIds.includes(permission.id)}
                                onChange={(e) =>
                                    handleCheckboxChange(permission.id, e.target.checked)
                                }
                                className="form-check-input me-2"
                            />
                            <span>{permission.slug}</span>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default AssignPermissions;
