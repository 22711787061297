import { Button, Col, Container, FloatingLabel, Form, Row, Stack } from "react-bootstrap"
import { courseInterface, Props } from "../../types/order"
import Select from "react-select";
import { axiosClient } from "../../services/axiosClient";
import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import { BACKEND_URLS } from "../../config";


function OrderHeader({
  onChangeFilterText,
  onFilterApply,
}: Props) {

  const [sellers, setSellers] = useState<{ value: number; label: string }[]>([])
  const [allCourses, setAllCourses] = useState<{ label: string, value: courseInterface }[]>([])


  async function getSellers() {
    try {
      const response = await axiosClient.get(BACKEND_URLS.GET_USERS + `?isStaff=true`);
      const mentors = response.data.data.users.map(
        (user: any) => ({
          value: parseInt(user.id),
          label: `${user.username} (${user.name})`
        })
      )
      setSellers(mentors)
    } catch (error: any) {
      toast.error('Error fetching data:', error);
    }
  }

  function getCourses() {
    axiosClient.get(`/courses?availableToSales=true&status=PUBLISHED`).then(
      (res) => {
        const coursesData = res.data.data.courses.map(
          (course: any) => ({
            value: course,
            label: course.name
          })
        )
        setAllCourses(coursesData)
      }
    ).catch((error: any) => {
      toast.error(error.message)
    })
  }

  useEffect(
    () => {
      getSellers()
      getCourses()
    }, []
  )


  return (
    <Container fluid>
      <Row className="mb-3">
        <Col xs={12} className="text-center text-md-start">
          <Button variant="primary" href="/order/0">
            Create a New Order
          </Button>
        </Col>
      </Row>

      <Row className="align-items-center g-3">
        <Col xs={12} md={6} lg={2}>
          <Select
            options={sellers}
            onChange={(e) => onChangeFilterText(e?.value, 'staffId')}
            placeholder="Select Seller"
          />
        </Col>

        <Col xs={12} md={6} lg={2}>
          <Select
            options={allCourses}
            onChange={(e) => onChangeFilterText(e?.value?.id, 'courseId')}
            placeholder="Select Course"
          />
        </Col>

        <Col xs={12} md={6} lg={2}>
          <FloatingLabel controlId="Search" label="Search">
            <Form.Control
              type="text"
              onChange={(e) => onChangeFilterText(e.target.value, 'search')}
            />
          </FloatingLabel>
        </Col>


        <Col xs={12} md={6} lg={2}>
          <FloatingLabel controlId="From" label="From">
            <Form.Control
              type="date"
              onChange={(e) => onChangeFilterText(e.target.value, 'from')}
            />
          </FloatingLabel>
        </Col>

        <Col xs={12} md={6} lg={2}>
          <FloatingLabel controlId="To" label="To">
            <Form.Control
              type="date"
              onChange={(e) => onChangeFilterText(e.target.value, 'to')}
            />
          </FloatingLabel>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col xs={12} className="d-flex justify-content-center justify-content-md-start">
          <Stack direction="horizontal" gap={3}>
            <Button variant="primary" onClick={onFilterApply}>
              Filter
            </Button>
            <Button variant="outline-secondary" onClick={() => window.location.reload()}>
              Reset
            </Button>
          </Stack>
        </Col>
      </Row>
    </Container>
  )
}


export default OrderHeader