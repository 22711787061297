import { ChangeEvent, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import SearchInput from '../searchInput';

type Props = {
    data: any[];
    onFilterApply: (value: string) => void;
    onTypeChange: (e: any) => void;
};

const DoubtTicketHeader = ({ data, onFilterApply, onTypeChange }: Props) => {
    const [selectedButton, setSelectedButton] = useState(0);

    return (
        <div style={{ flex: 1 }}>
            <div>
                {data &&
                    data.map((item, index: number) => (
                        <Button
                            key={index}
                            size="sm"
                            style={{ marginRight: 5 }}
                            variant={index === selectedButton ? 'primary' : 'outline-primary'}
                            onClick={() => {
                                setSelectedButton(index);
                                onTypeChange(item.type);
                            }}
                        >
                            {item.type}({item.count})
                        </Button>
                    ))}
            </div>
            <Form.Group
                className="m-2"
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    float: 'right',
                    alignItems: 'center',
                }}
            >
                <Form.Label column="sm" style={{ marginRight: 15 }}>
                    Search:
                </Form.Label>
                <SearchInput
                    onValueChange={(e: ChangeEvent<HTMLInputElement>) =>
                        onFilterApply(e.target.value)
                    }
                />
            </Form.Group>
        </div>
    );
};

export default DoubtTicketHeader;
