import { useLoaderData, useNavigate } from 'react-router-dom';
import MainBtn from '../../../components/btn/main-btn';
import { FRONTEND_URLS } from '../../../config';
import collectionService from '../../../services/ApiServices/collection-service';
import { CollectionItem } from '../../../types/collection-types';
import DataTable from 'react-data-table-component';
import './styles.css';

export async function collectionLoader() {
    const response = await collectionService.getAllCollections();
    return response.data.collections;
}

const CollectionPage = () => {
    const collections = useLoaderData() as CollectionItem[];
    const navigate = useNavigate();

    const columns = [
        {
            name: 'ID',
            selector: (row: CollectionItem) => row.id,
            sortable: true,
            width: '15ch',
        },
        {
            name: 'Name',
            selector: (row: CollectionItem) => row.name,
            sortable: true,
            width: '50ch',
        },
        {
            name: 'Nodes',
            cell: (row: CollectionItem) => (
                <ul className="text-dark my-1">
                    {row.nodeCollections.map((nc) => (
                        <li className="text-dark">
                            <a href={`/edit-node/${nc.id}`} target="_blank">
                                {nc.nodeName}
                            </a>
                        </li>
                    ))}
                </ul>
            ),
            sortable: false,
        },
        {
            name: 'Actions',
            cell: (row: CollectionItem) => (
                <div className="d-flex gap-2">
                    <button
                        className="edit-btn"
                        onClick={() =>
                            navigate(`${FRONTEND_URLS.CONTENTS.COLLECTION}/edit/${row.id}`)
                        }
                    >
                        Edit
                    </button>

                    <button className="edit-btn bg-danger" onClick={() => handleDelete(row.id)}>
                        Delete
                    </button>
                </div>
            ),
            width: '20ch',
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    const handleDelete = (id: number) => {
        if (window.confirm('Are you Sure')) {
            collectionService.deleteData(`/${id}`).then((res) => {
                window.location.reload();
            });
        }
    };

    return (
        <div className="collection-page">
            <MainBtn onClick={() => navigate(FRONTEND_URLS.CONTENTS.COLLECTION + '/create')} />
            <DataTable
                title="Collections List"
                columns={columns}
                data={collections}
                pagination
                highlightOnHover
                striped
                responsive
                customStyles={{
                    rows: {
                        style: {
                            minHeight: '45px',
                        },
                    },
                    headCells: {
                        style: {
                            fontSize: '16px',
                            fontWeight: 'bold',
                            backgroundColor: '#f4f4f4',
                        },
                    },
                    cells: {
                        style: {
                            paddingLeft: '8px',
                            paddingRight: '8px',
                        },
                    },
                }}
            />
        </div>
    );
};

export default CollectionPage;
