import { Card } from 'react-bootstrap';
import { Enrollment, User } from '../../types/user-profille-types';

function EnrollmentCards({ enrollment, user }: { enrollment: Enrollment[]; user: User[] | null }) {
    return (
        <Card style={{ overflow: 'hidden' }}>
            <Card.Body>
                <h3>Enrollment Details</h3>
                {enrollment?.map((enr, index) => (
                    <EnrollmentCard data={enr} key={index} user={user} />
                ))}
            </Card.Body>
        </Card>
    );
}

export default EnrollmentCards;

const EnrollmentCard: React.FC<{ data: Enrollment; user: User[] | null }> = ({ data, user }) => {
    return (
        <Card className="my-1">
            <Card.Body>
                {/* General Information */}
                <div className="row mb-3">
                    <div className="col-md-6">
                        <strong>Course Name:</strong> {data.courseId}
                    </div>

                    <div className="col-md-6">
                        <strong>Order Date:</strong>
                        {new Date(data.orderDate).toLocaleDateString()}
                    </div>
                </div>

                {/* Staff and Location Information */}
                <div className="row mb-3">
                    <div className="col-md-6">
                        <strong>Valid Till:</strong>
                        {new Date(data.validTill).toLocaleDateString()}
                    </div>
                    <div className="col-md-6">
                        <strong>Sales Person Name:</strong> {data.staffId}
                    </div>
                </div>

                {/* Enrollment Type and Mentor Information */}
                <div className="row mb-3">
                    <div className="col-md-6">
                        <strong>Last Mentor Call:</strong>
                        {data.lastMentorCall
                            ? new Date(data.lastMentorCall).toLocaleDateString()
                            : 'N/A'}
                    </div>
                    <div className="col-md-6">
                        <strong>Enrollment Type:</strong> {data.type.replace('_', ' ')}
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <strong>
                            Assigned Mentors:
                            {
                                user?.find(
                                    (use: User, index) =>
                                        use?.id === data?.assignedMentor[0]?.mentorId
                                )?.name
                            }
                        </strong>
                    </div>
                </div>
            </Card.Body>
        </Card>
    );
};
