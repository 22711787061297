import { TestimonialPayload, TestimonialResp } from '../../types/testimonialTypes';
import ApiService, { ApiResponse } from './api-service';

class TestimonialService extends ApiService {
    constructor() {
        super('/testimonial');
    }

    getAllTestimonials(): ApiResponse<{ testimonials: TestimonialResp[] }> {
        return this.getData('');
    }

    getTestimonial(id: number): ApiResponse<{ testimonial: TestimonialResp }> {
        return this.getData(`/${id}`);
    }

    createTestimonial(data: TestimonialPayload): ApiResponse<{ id: number }> {
        return this.postData('', data);
    }
    updateTestimonial(id: number, data: Partial<TestimonialPayload>) {
        return this.putData(`/${id}`, data);
    }

    deleteTestimonial(id: number) {
        return this.deleteData(`/${id}`);
    }
}

export default new TestimonialService();
