import Modal from "react-bootstrap/Modal";

function FormModal(props: any) {
  return (
    <Modal
      dialogClassName={props.dialogClassName}
      size={props.size ?? "lg"}
      show={props.modalShow}
      onHide={props.handleModalClose}
      backdrop={props.backdrop}

    >
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.children}</Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
}

export default FormModal;
