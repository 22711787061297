"use client";
import { useEffect, useState } from "react";
import { Button, Col, FloatingLabel, Form, Row, Table } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import {
  QuizType,
  QuizPayload,
  QuizSectionItem,
} from "../../../types/quizTypes";
import SummerNote from "../../summer-note";
import QuizSection from "./QuizSections";
import QuizService from "../../../services/ApiServices/QuizService";
import { FRONTEND_URLS } from "../../../config";
import { useDispatch } from "react-redux";
import { quizConstants } from "../../../Redux/ReducerConstants";
import toast from "react-hot-toast";
import ConfirmModal from "../../modals/remove-item-model";
import TinyMceEditor from "../../../TinyMceEditor";

type Props = {
  quizId?: number;
};

type CreateQuiz = Omit<QuizPayload, "sections">;

const QuizDetails = ({ quizId }: Props) => {
  const navigate = useNavigate();
  const pathname = useLocation().pathname;
  const quizType = pathname
    ?.split("/")[2]
    ?.split("-")[1]
    ?.toUpperCase() as QuizType;

  const [sections, setSections] = useState<QuizSectionItem[]>([]);
  const dispatch = useDispatch();
  const [quizDetails, setQuizDetails] = useState({
    quizType: quizType,
    internalName: "",
    showInstructions: quizType == "QUIZ" ? true : false,
    instructions: "",
    timeLimit: quizType == "QUIZ" ? 60 : 0,
    minTimeSubmission: quizType == "QUIZ" ? 10 : 0,
    totalAttempts: 0,
    showSolutions: true,
    showRank: true,
    showReport: true,
    language: "",
    postSubmissionMessage: "",
  });
  const [loading, setLoading] = useState(false)
  const [isModal, setIsModal] = useState(false)

  const broadcast = new BroadcastChannel('contentUpdates');

  const handleDeleteContent = (quizId: number) => {
    broadcast.postMessage({ type: 'DELETE_CONTENT', quizId });
  };


  async function deleteQuiz() {
    if (quizId) {
      try {
        const quizReponse = await QuizService.deleteQuiz(quizId);
        setIsModal(false)
        toast.success("Quiz Deleted Successfully");
        handleDeleteContent(quizId)
        window.close()
      } catch (error: any) {
        toast.error("Something wrong Happend. " + JSON.stringify(error.message))
      }
    }
    else {
      toast.error("Quiz id is required");
    }
  }

  const handleChange = <T extends keyof CreateQuiz>(
    key: T,
    value: CreateQuiz[T]
  ) => {
    setQuizDetails((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  async function createQuiz() {
    setLoading(true)

    if (quizDetails.internalName.length < 3) {
      setLoading(false)
      toast.error("Please enter a correct name.");
      return
    }

    if (quizType === 'QUIZ') {
      if (quizDetails.showInstructions && quizDetails?.instructions.length === 0) {
        toast.error("Instructions can't be blank.");
        setLoading(false)
        return
      }

    }

    if (sections.length === 0) {
      toast.error("atleast 1 section is required.")
      setLoading(false)
      return
    }
    const hasInvalidSection = sections.some(({ sectionName, maxQuestions }) => {
      if (maxQuestions === 0) {
        toast.error(`Max questions can't be zero. Please update the "${sectionName}" section.`);
        return true;
      }
      return false;
    });

    if (hasInvalidSection) {
      setLoading(false)
      return
    }


    const data = {
      quizType: quizDetails.quizType,
      internalName: quizDetails.internalName,
      showInstructions: quizDetails.showInstructions,
      instructions: quizDetails.instructions,
      timeLimit: quizDetails.timeLimit * 60,
      minTimeSubmission: quizDetails.minTimeSubmission * 60,
      totalAttempts: quizDetails.totalAttempts,
      showSolutions: !!quizDetails.showSolutions,
      showRank: !!quizDetails.showRank,
      showReport: !!quizDetails.showReport,
      language: "ENGLISH",
      postSubmissionMessage: quizDetails.postSubmissionMessage,
    };
    try {
      setLoading(true);

      let promise: any;
      if (quizId) {
        promise = QuizService.updateQuiz(quizId, data);

      } else {
        promise = QuizService.createQuiz({
          ...data, sections: sections.map(
            section => ({
              sectionName: section.sectionName,
              maxQuestions: section.maxQuestions
            })
          )
        });
      }

      const quizRes = await promise;

      if (quizRes.status === "success") {
        toast.success("Quiz saved successfully!");
        if (quizType !== quizDetails.quizType) {
          if (quizDetails.quizType === QuizType.QUIZ) {
            navigate(FRONTEND_URLS.CONTENTS.EDIT_QUIZ + "/" + quizRes.data.id);
            // window.location.reload()
          }
          else {
            navigate(FRONTEND_URLS.CONTENTS.PRACTICE_QUIZ_EDIT + "/" + quizRes.data.id);
            // window.location.reload()
          }
        }
      }
      else {
        toast.error(quizRes.message);
      }
    } catch (error: any) {
      toast.error(error.message || "Failed to save the quiz. Please try again.");
    } finally {
      setLoading(false);
    }

    return
  }

  useEffect(() => {
    if (quizId && quizId !== 0) {
      QuizService.getById(quizId).then((res) => {
        if (res.status === "success") {
          const quiz = res.data;
          dispatch({ type: quizConstants.GET_QUIZ_SUCCESS, data: quiz });
          const timeLimit = quiz.quizType == "QUIZ" ? quiz.timeLimit / 60 : 0
          const minTimeSubmission = quiz.quizType == "QUIZ" ? quiz.minTimeSubmission / 60 : 0
          const { sections, instructions = "", ...rest } = quiz

          setQuizDetails({ ...rest, instructions, timeLimit, minTimeSubmission });

          if (
            quizType == "QUIZ" &&
            quiz.sections.length === 0 && quizId === undefined) {
            setSections([{ sectionName: "Section", maxQuestions: 10, isNew: true }]);
          } else {
            setSections(sections);
          }
        } else {
          toast.error(res.message);
          navigate(-1);
        }
      });
    }
    else if (quizType == "PRACTICE_QUIZ" && quizId === undefined) {
      setSections([{ sectionName: "Section", maxQuestions: 10, isNew: true }])
    }
  }, []);

  return (
    <div>
      <ConfirmModal
        show={isModal}
        onHide={() => setIsModal(false)}
        onConfirm={deleteQuiz}
      />
      <h3 style={{ marginTop: 5 }}>Create/Edit Quiz</h3>
      <br />
      <Form.Group as={Row} className="mb-3" controlId="quizName">
        <Form.Label column sm={2}>
          Internal Name
        </Form.Label>
        <Col sm={10}>
          <Form.Control
            type="text"
            placeholder="Name"
            value={quizDetails.internalName}
            onChange={(e) => handleChange("internalName", e.target.value)}
          />
        </Col>
      </Form.Group>


      <Form.Group as={Row} className="mb-3" controlId="quizName">
        <Form.Label column sm={2}>
          Quiz Type
        </Form.Label>
        <Col sm={10}>
          <FloatingLabel controlId="ChangeQuizType" label="Change Quiz Type">
            <Form.Select aria-label="Change Quiz Type" onChange={(e) => {
              setQuizDetails((prev) => ({
                ...prev,
                quizType: e.target.value as QuizType
              }))
            }}>
              <option value={QuizType.QUIZ} selected={QuizType.QUIZ === quizDetails.quizType}>{QuizType.QUIZ}</option>
              <option value={QuizType.PRACTICE_QUIZ} selected={QuizType.PRACTICE_QUIZ === quizDetails.quizType}>{QuizType.PRACTICE_QUIZ}</option>
            </Form.Select>
          </FloatingLabel>
        </Col>
      </Form.Group>

      {
        quizDetails.quizType == "QUIZ" && (
          <>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="quizShowInstructions"
            >
              <Col sm={{ span: 10, offset: 2 }}>
                <Form.Check
                  label="Show Instructions"
                  checked={quizDetails.showInstructions}
                  onChange={(e) =>
                    handleChange("showInstructions", e.target.checked)
                  }
                />
              </Col>
            </Form.Group>

            {
              quizDetails.showInstructions && <Form.Group as={Row} className="mb-3" controlId="quizInstructions">
                <Form.Label column sm={2}>
                  Instructions
                </Form.Label>
                <Col sm={10}>
                  <TinyMceEditor
                    height={250}
                    initialValue={quizDetails?.instructions || ''}
                    onBlur={(_evt: any, editor: any) => {
                      handleChange("instructions", editor.getContent())
                    }}
                  />
                  {/* 
                <SummerNote
                  initialValue={quizDetails?.instructions}
                  onBlur={(editor) =>
                    handleChange("instructions", editor.getContent())
                  }
                /> */}
                </Col>
              </Form.Group>
            }

            <Form.Group as={Row} className="mb-3" controlId="quizName">
              <Form.Label column sm={2}>
                Post Submission message
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  placeholder="Type message here..."
                  value={quizDetails.postSubmissionMessage}
                  onChange={(e) =>
                    handleChange("postSubmissionMessage", e.target.value)
                  }
                />
              </Col>
            </Form.Group>

            <Row className="mb-3">
              <Col sm={4}>
                <FloatingLabel controlId="quizTimeLimit" label="Time Limit (Minutes)">
                  <Form.Control
                    type="number"
                    placeholder="In Minutes"
                    value={quizDetails.timeLimit}
                    onChange={(e) => handleChange("timeLimit", +e.target.value)}
                  />
                </FloatingLabel>
              </Col>

              <Col sm={4}>
                <FloatingLabel
                  controlId="minTimeSubmission"
                  label="Min Time to Submission (Minutes)"
                >
                  <Form.Control
                    type="number"
                    placeholder="In Minutes"
                    value={quizDetails.minTimeSubmission}
                    onChange={(e) => handleChange("minTimeSubmission", +e.target.value)}
                  />
                </FloatingLabel>
              </Col>

              <Col sm={4}>
                <FloatingLabel controlId="maxAttempts" label="Max Attempt Limit">
                  <Form.Control
                    type="number"
                    placeholder="Max Attempts"
                    value={quizDetails.totalAttempts}
                    onChange={(e) => handleChange("totalAttempts", +e.target.value)}
                  />
                  <Form.Text muted>Enter 0 for no maximum attempt limit.</Form.Text>
                </FloatingLabel>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col sm={{ span: 3, offset: 2 }}>
                <Form.Check
                  type="checkbox"
                  id="showSolutions"
                  label="Show Solution After Test"
                  checked={quizDetails.showSolutions}
                  onChange={(e) => handleChange("showSolutions", e.target.checked)}
                />
              </Col>

              <Col sm={3}>
                <Form.Check
                  type="checkbox"
                  id="showRank"
                  label="Show Rank"
                  checked={quizDetails.showRank}
                  onChange={(e) => handleChange("showRank", e.target.checked)}
                />
              </Col>

              <Col sm={3}>
                <Form.Check
                  type="checkbox"
                  id="showReport"
                  label="Show Report"
                  checked={quizDetails.showReport}
                  onChange={(e) => handleChange("showReport", e.target.checked)}
                />
              </Col>
            </Row>
          </>
        )
      }
      <Form.Group as={Row} className="mb-3" controlId="quizSections">
        <Form.Label column sm={2}>
          Sections
        </Form.Label>
        <Col sm={10}>
          <Table striped hover>
            <thead>
              <tr>
                <th className="w-50">Section Name</th>
                <th className="w-25">Maximum Allowed Question</th>
              </tr>
            </thead>
            <tbody>
              {sections.map((item, index) => (
                <QuizSection
                  quizId={quizId}
                  index={index}
                  section={item}
                  key={index}
                  setSection={setSections}
                  isDisabled={quizId === undefined || item?.isNew == true ? false : true}
                />
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td></td>
                <td></td>
                <td colSpan={2}>
                  <Button
                    variant="success"
                    className="m-2"
                    onClick={() =>
                      setSections((s) => [
                        ...s,
                        {
                          sectionName: "Section",
                          maxQuestions: 10,
                          isNew: true
                        },
                      ])
                    }
                  >
                    Add Section
                  </Button>
                </td>
              </tr>
            </tfoot>
          </Table>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Col sm={{ span: 10 }}>
          <Button variant="danger" onClick={() => setIsModal(true)} disabled={loading}>Delete</Button>
        </Col>
        <Col sm={{ span: 1 }}>
          <Button onClick={createQuiz} disabled={loading} >Submit</Button>
        </Col>
      </Form.Group>
      <hr />
    </div >
  );
};

export default QuizDetails;