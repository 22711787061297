import { startTransition, Suspense, useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import ESaralLoader from '../../components/Spinner/ESaralLoader';
import Insights from '../../components/StudentStatistics/Insights';
import StudentDetails from '../../components/StudentStatistics/StudentDetails';
import { BACKEND_URLS, FRONTEND_URLS } from '../../config';
import { axiosClient } from '../../services/axiosClient';
import enrollments from '../../services/enrollments';
import mentorCallsServices from '../../services/mentor-calls-services';
import userService from '../../services/user-service';
import { CallRecord } from '../../types/mentor-calls-types';
import { Enrollment, enrollmentTimetableInterface, User } from '../../types/user-profille-types';
import CallLogs from './call-logs';
import EnrollmentCards from './enrollment-card';
import { Timetable } from '../../types/enrollments';

function UserAllInfoReadOnly() {
    const { userId } = useParams();
    const [user, setUser] = useState<User[] | null>([]);
    const [enrollment, setEnrollment] = useState<Enrollment[]>([]);
    const [loading, setLoading] = useState(true);
    const [activeKey, setActiveKey] = useState('studentDetails');
    const navigate = useNavigate();
    const [tableData, setTableData] = useState<CallRecord[]>([]);
    const [allTimeTables, setAllTimeTables] = useState<enrollmentTimetableInterface[]>([]);

    const handleTabSelect = (key: string | null) => {
        if (key) {
            startTransition(() => {
                setActiveKey(key);
            });
        }
    };

    const fetchSalesPerson = async () => {
        try {
            const response = await axiosClient.get(BACKEND_URLS.GET_USERS + `?isStaff=true`);
            setUser(response.data.data.users);
        } catch (error: any) {
            toast.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchSalesPerson();
        if (!userId) return;
        mentorCallsServices
            .getMentorCalls(+userId)
            .then((res) => {
                setTableData(res.data);
            })
            .catch((err) => {
                console.log('err ====>', err);
            });
        enrollments
            .getUserEnrollment(userId ?? '')
            .then((res) => {
                setEnrollment(res.data);
                setAllTimeTables(res.data[0].enrollmentTimetable);
            })
            .catch((err) => {
                console.error(err);
            });
        userService
            .getAllUsers(`id=${userId}`)
            .then((res) => {
                const user = res.data.users[0];
                navigate(FRONTEND_URLS.STUDENT.STUDENT_ALL_USERS + `/${userId}`, {
                    state: {
                        studentId: userId,
                        username: user?.username,
                    },
                });
                setLoading(false);
            })
            .catch((err) => {
                console.error(err);
                setLoading(false);
            });
    }, []);

    return loading ? (
        <ESaralLoader />
    ) : (
        <div className="container">
            <Tabs
                activeKey={activeKey}
                onSelect={handleTabSelect}
                id="student-statistics-tab"
                className="my-3"
            >
                <Tab eventKey="studentDetails" title="Student Details">
                    <Suspense fallback={<ESaralLoader />}>
                        <StudentDetails isDisabled={true}>
                            <EnrollmentCards enrollment={enrollment} user={user} />
                            <CallLogs
                                tableData={tableData}
                                allTimeTables={allTimeTables}
                                user={user}
                            />
                        </StudentDetails>
                    </Suspense>
                </Tab>
                <Tab eventKey="insights" title="Insights">
                    <Suspense fallback={<ESaralLoader />}>
                        <Insights />
                    </Suspense>
                </Tab>
            </Tabs>
        </div>
    );
}

export default UserAllInfoReadOnly;
