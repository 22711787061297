import { Modal, Button } from 'react-bootstrap';

interface ConfirmModalProps {
    show: boolean;
    onHide: () => void;
    onConfirm: () => void;
    title?: string;
    bodyText?: string;
    confirmText?: string;
    cancelText?: string;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({
    show,
    onHide,
    onConfirm,
    title = "Confirm Action",
    bodyText = "Are you sure you want to proceed?",
    confirmText = "Confirm",
    cancelText = "Cancel",
}) => (
    <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{bodyText}</Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={onHide}>
                {cancelText}
            </Button>
            <Button variant="danger" onClick={onConfirm}>
                {confirmText}
            </Button>
        </Modal.Footer>
    </Modal>
);

export default ConfirmModal;
