import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { SelectCreator } from '../selector/SelectCreator';
import { useDispatch, useSelector } from 'react-redux';
import { ModalsConstants } from '../../Redux/models/models-constants';
import {
    ImportPdf, ImportSession, ImportVideo, PdfLink, Sessions,
    UploadPdf, UploadVideo, VideoLink
} from '../node';
import ImportQuiz from '../node/import-quiz.node';
import { useSearchParams } from 'react-router-dom';
import { LiveManagementConstants } from '../../Redux/live-mangment/live-mangment-constant';

function ContentCreateModel(props: any) {
    const contentCreateOptions = [
        { optionValue: '0', optionName: 'Upload Video' },
        { optionValue: '1', optionName: 'Import Video' },
        { optionValue: '2', optionName: 'Video Link' },
        { optionValue: '3', optionName: 'Session' },
        { optionValue: '4', optionName: 'Upload Pdf' },
        { optionValue: '5', optionName: 'Import Pdf' },
        { optionValue: '6', optionName: 'Pdf Link' },
        { optionValue: '7', optionName: 'Import Quiz' },
        { optionValue: '8', optionName: 'Import Session' },
    ];

    const isModal = useSelector<any>(state => state.ModalReducer.isModal);
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const queryContentType = searchParams.get('content') || '';

    const [selectId, setSelectId] = useState<string | number>(queryContentType || '');

    const onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const newValue = event.target.value;
        setSelectId(newValue);
        setSearchParams({ content: newValue });
    };

    const handleClose = () => {
        setSelectId('');
        setSearchParams({});
        dispatch({ type: LiveManagementConstants.SESSIONS, payload: [] })
        dispatch({ type: LiveManagementConstants.CURRENT_SESSION_ID, payload: null })
        dispatch({ type: ModalsConstants.IS_Modal, payload: false });
    };

    useEffect(() => {
        setSelectId(queryContentType);
    }, [queryContentType]);

    return (
        <Modal
            {...props}
            size="xl"
            centered
            backdrop="static"
            keyboard={false}
            show={isModal}
            onHide={handleClose}
            dialogClassName="modal-90"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    Create/ Add content
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="w-50 p-2">
                    <SelectCreator
                        labelName="Content type"
                        options={contentCreateOptions}
                        onChange={onChange}
                        selectedId={selectId}
                    />
                </div>
                {selectId === '0' ? (
                    <UploadVideo key={0} />
                ) : selectId === '1' ? (
                    <ImportVideo key={1} />
                ) : selectId === '2' ? (
                    <VideoLink key={4} />
                ) : selectId === '3' ? (
                    <Sessions key={5} />
                ) : selectId === '4' ? (
                    <UploadPdf key={6} />
                ) : selectId === '5' ? (
                    <ImportPdf key={7} />
                ) : selectId === '6' ? (
                    <PdfLink key={8} />
                ) : selectId === '7' ? (
                    <ImportQuiz key={9} />
                ) : selectId === '8' ? (
                    <ImportSession key={10} />
                ) : (
                    <></>
                )}
            </Modal.Body>
        </Modal>
    );
}

export default ContentCreateModel;
