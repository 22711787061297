import './chat-box.css';
import { getMediaUrl } from '../../../utils/helpers';
import { MdDelete } from 'react-icons/md';

type Props = {
    message: string;
    mediaUrl?: string | null;
    mediaType?: 'AUDIO' | 'VIDEO' | 'IMAGE';
    createdOn: string | Date;
    isBySelf: boolean;
    onDelete: (id: number) => void;
    messageId: number;
};

const Chatbox = ({
    message,
    mediaUrl,
    mediaType,
    createdOn,
    isBySelf,
    onDelete,
    messageId,
}: Props) => {
    const handleMessageDelete = () => {
        // if (window.confirm('Are you sure you want to delete this message?')) {
        // Save it!
        onDelete(messageId);
        // } else {
        //     // Do nothing!
        //     console.log('Thing was not saved to the database.');
        // }
    };

    return (
        <div className={`chatbox-message ${isBySelf ? 'self ms-auto' : 'other me-auto'}`}>
            <div className="d-flex">
                <div className="d-flex flex-column">
                    <div
                        className="chatbox-content"
                        style={{
                            flexDirection: isBySelf ? 'row-reverse' : 'row',
                        }}
                    >
                        {!isBySelf && (
                            <div className="user-icon">
                                <img
                                    src={
                                        'https://img.icons8.com/?size=100&id=MWLDh5O573es&format=png&color=000000'
                                    }
                                    alt="User Icon"
                                />
                            </div>
                        )}
                        <div className="message-content">
                            {mediaUrl && mediaType === 'IMAGE' && (
                                <img
                                    className="media-content"
                                    src={getMediaUrl(mediaUrl)}
                                    alt="Media"
                                />
                            )}
                            {mediaUrl && mediaType === 'VIDEO' && (
                                <video className="media-content" controls src={mediaUrl} />
                            )}
                            {mediaUrl && mediaType === 'AUDIO' && (
                                <audio className="media-content" controls src={mediaUrl} />
                            )}
                            {message && <p className="text-message">{message}</p>}
                            <div
                                style={{
                                    height: '30px',
                                    width: '30px',
                                }}
                            ></div>
                        </div>
                    </div>
                    <div className="message-date">{new Date(createdOn).toLocaleDateString()}</div>
                </div>
                <div className="d-flex align-items-center">
                    <MdDelete
                        size={30}
                        color="red"
                        onClick={handleMessageDelete}
                        style={{
                            cursor: 'pointer',
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default Chatbox;
