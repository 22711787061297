"use client";
import { lazy, Suspense, useEffect, startTransition } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userPracticeConstants } from "../../Redux/ReducerConstants/userPracticeConstants";
import { QuestionItem, QuizItem } from "../../types/quizTypes";
import { State } from "../../types/Store";
import LoadingIndicator from "../LoadingIndicator";
import './SolveCast.css';
import { quizServices } from "../../services/quiz";

const DrawTool = lazy(() => import("../DrawTool"));
const QuizExpBody = lazy(() => import("./QuizExpBody"));

type Props = {
  quizId: number;
  quizDetails: QuizItem;
  questions: QuestionItem[];
};

const SolveCast = ({ quizId, quizDetails, questions }: Props) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(
    (state: State) => state.userPracticeReducer.loading
  );

  useEffect(() => {
    const root = document.getElementById('root');
    if (root) {
      root.style.width = 'auto'
    }

    startTransition(() => {
      dispatch({
        type: userPracticeConstants.GET_PRACTICE_DATA,
        data: questions,
        quiz: quizDetails,
        quizId,
      });
    });
    quizServices.initQuizSolutionUploader(questions, dispatch)
  }, [quizId, questions, quizDetails, dispatch]);

  if (isLoading) return <LoadingIndicator />;

  return (
    <div
      className="solve-cast"
      style={{ color: "#FFF", backgroundColor: "#000" }}
    >
      <Suspense fallback={<LoadingIndicator />}>
        <DrawTool />
      </Suspense>
      <div
        className="vertical-scroll p-3"
        style={{ zoom: 1.7, width: "80%", margin: "auto" }}
      >
        <Suspense fallback={<LoadingIndicator />}>
          <QuizExpBody />
        </Suspense>
      </div>
    </div>
  );
};

export default SolveCast;
