import { Outlet, useParams } from "react-router-dom";


export default function PracticeSheetPage() {

    const params = useParams<{ quizId: string }>()
    const quizId = params.quizId;



    //   const session = await getServerAuthSession();
    //   const user = session?.user as CurrentUser;

    return <> PracticeSheetPage  quizId {quizId} </>
    //   return <Practice quizId={+quizId} currentUser={user} />;
}
