import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet, useLoaderData, useLocation, useParams } from 'react-router-dom';
import { AddNewChapter, SideBar } from '../../../components/edit-node';
import { EditNodeHeader } from '../../../components/headers';
import ContentCreateModel from '../../../components/modals/content-create-model';
import { BACKEND_URLS } from '../../../config';
import { ContentConstants } from '../../../Redux/content/content-constants';
import { axiosClient } from '../../../services/axiosClient';
import {
    ApiInterface,
    LevelsApiInterface,
} from '../../../types/ApiTypes';
import toast from 'react-hot-toast';

type NodeType = {
    id: number;
    internalName: string;
    level0Id: number;
    level1Id: number;
    createdOn: string;
};

interface GroupResultInterface {
    subjectGroups: LevelsApiInterface;
    subject: LevelsApiInterface;
}

export async function loader({ params }: any): Promise<GroupResultInterface | {}> {
    if (params.type !== undefined) {
        return {};
    }
    const [subjectGroups, subject] = await Promise.all([
        axiosClient.get(BACKEND_URLS.LEVEL + 0),
        axiosClient.get(BACKEND_URLS.LEVEL + 1),
    ]);

    return {
        subjectGroups: subjectGroups.data,
        subject: subject.data,
    };
}

function EditNode() {
    const { nodeId } = useParams();
    const { state } = useLocation();
    const nodeFromState: NodeType | undefined = state?.node;

    const dispatch = useDispatch();
    const { subject, subjectGroups } = useLoaderData() as GroupResultInterface;

    const [subject1Id, setSubject1Id] = useState<number>(-1);

    const fetchData = async () => {
        let subjectId: number, subjectGroupId = null;

        try {
            dispatch({
                type: ContentConstants.SUBJECTS,
                payload: subject.data.level,
            });
            dispatch({
                type: ContentConstants.SUBJECTS_GROUPS,
                payload: subjectGroups.data.level,
            });

            if (!nodeFromState) {
                const res = await axiosClient.get(BACKEND_URLS.NODES + '/' + nodeId);
                subjectGroupId = res.data.data.node.level0Id;
                subjectId = res.data.data.node.level1Id;

                dispatch({
                    type: ContentConstants.SELECTED_SUBJECT_GROUP_ID,
                    payload: res.data.data.node.level0Id,
                });
                dispatch({
                    type: ContentConstants.NODE_NAME,
                    payload: res.data.data.node.internalName,
                });
            } else {
                dispatch({
                    type: ContentConstants.SELECTED_SUBJECT_GROUP_ID,
                    payload: nodeFromState.level0Id,
                });
                subjectId = nodeFromState.level1Id;
            }

            setSubject1Id(subjectId);
            dispatch({
                type: ContentConstants.SELECTED_SUBJECT_ID,
                payload: subjectId,
            });

            const { data }: ApiInterface = await axiosClient.get(
                BACKEND_URLS.LEVEL + 2 + `?limit=100&isChapter=true&level1Id=${subjectId}`
            );
            dispatch({
                type: ContentConstants.CHAPTER_DATA,
                payload: data.data.level,
            });

            const content = await axiosClient.get('/nodes/' + nodeId + '/content');

            dispatch({
                type: ContentConstants.CONTENT,
                payload: content.data.data.contents,
            });
        } catch (err: any) {
            toast.error(err);
        }
    };

    useEffect(() => {
        fetchData();
    }, [nodeFromState, nodeId]);

    return (
        <div className='overflow-hidden h-100 w-100'>
            <ContentCreateModel />

            <EditNodeHeader />

            <div className="w-100 h-100 d-flex overflow-y-auto">
                <SideBar />
                <AddNewChapter subject1Id={subject1Id} />
                <Outlet />
            </div>
        </div>
    );
}

export default EditNode;
