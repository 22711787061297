import React, { useState } from "react";
import { Form } from "react-bootstrap";

const SearchInput = ({ onValueChange, typeInterval = 500 }: any) => {
  const [searchTO, setSearchTO] = useState<any>(null);

  const onChange = (e: any) => {
    if (searchTO) {
      clearTimeout(searchTO);
    }
    const timeout = setTimeout(() => onValueChange(e), typeInterval);
    setSearchTO(timeout);
  };

  return <Form.Control onChange={onChange} />;
};

export default SearchInput;
