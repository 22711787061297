import React, { useEffect, useState } from 'react';
import SendNotification from '../../../components/Notification/Send';
import { StudentClassesApiInterface, TargetsApiInterface } from '../../../types/ApiTypes';
import { axiosClient } from '../../../services/axiosClient';
import { BACKEND_URLS } from '../../../config';
import { useLoaderData } from 'react-router-dom';
import courseService from '../../../services/ApiServices/course-service';
import { CourseType } from '../../../types/course.types';
// import SendNotification from '@/components/Notification/Send';
// import NotificationService from '@/services/ApiServices/NotificationService';


type loaderType = {
  studentClassesData: StudentClassesApiInterface
  targetsData: TargetsApiInterface,
  courses: CourseType[]
}


export async function loader(): Promise<loaderType> {
  const studentClass = await axiosClient.get(BACKEND_URLS.STUDENT_CLASSES);
  const targets = await axiosClient.get(BACKEND_URLS.TARGETS);
  const coursesData = await courseService.getAllCourses();
  return {
    studentClassesData: studentClass.data,
    targetsData: targets.data,
    courses: coursesData.data.courses
  }
}

const NotificationPage = () => {
  const { studentClassesData, targetsData, courses } = useLoaderData() as loaderType;

  return (
    <SendNotification
      courses={courses}
      target={targetsData.data.targets}
      studentClass={studentClassesData.data.classes}
    />
  );
};

export default NotificationPage;
