import React, { useRef, useEffect, useState } from 'react';
import { STUDENT_APP_HOST } from '../../config';
import { Loader } from 'rsuite';

interface QuizAttemptsProps {
    quizId: number;
    attemptId: number;
}

const QuizAttempts: React.FC<QuizAttemptsProps> = ({ quizId, attemptId }) => {
    const iframeRef = useRef<HTMLIFrameElement | null>(null);
    const [loaded, setLoaded] = useState(true);

    // Function to send data to iframe
    const handleIframeLoad = () => {
        const iframeWindow = iframeRef.current?.contentWindow;
        const token = localStorage.getItem('@user'); // Fetch the token

        if (iframeWindow) {
            iframeWindow.postMessage(
                { quizId, attemptId, token }, // No need for JSON.stringify
                '*' // Ensure this matches the iframe's origin
            );
        }
    };

    // Function to handle messages received from iframe
    const handleLoaded = (event: MessageEvent) => {
        // Ensure the message is from the correct iframe origin
        if (event.data === 'loaded') {
            setLoaded(false);
            handleIframeLoad(); // Trigger the message to iframe
        }
    };

    useEffect(() => {
        // Add event listener for messages
        window.addEventListener('message', handleLoaded);

        return () => {
            // Cleanup the event listener
            window.removeEventListener('message', handleLoaded);
        };
    }, []);

    return (
        <>
            {loaded && (
                <div className="d-flex justify-content-center align-items-center">
                    <Loader />
                </div>
            )}
            <iframe
                ref={iframeRef}
                src={`${STUDENT_APP_HOST}/redirect/quiz-attempt`}
                style={{
                    width: '100%',
                    height: '70vh',
                    maxBlockSize: '643px',
                }}
                title="Quiz Attempt"
            />
        </>
    );
};

export default QuizAttempts;
