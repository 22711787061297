import { BACKEND_URLS } from '../config';
import ApiService, { ApiResponse } from './ApiServices/api-service';

class Enrollments extends ApiService {
    constructor() {
        super(BACKEND_URLS.ENROLLMENT);
    }

    getUserEnrollment(userId: string): ApiResponse {
        return this.getData(BACKEND_URLS.USER_ENROLLMENTS + `/${userId}`);
    }
}

export default new Enrollments();
