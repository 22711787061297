import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

function Practice() {
    const quizId = useParams().quizId;
    const navigate = useNavigate()
    
    useEffect(
        () => {
            navigate("/user/practice-sheet/" + quizId + "?phone=esaral");
        }, []
    )

    return <></>
    
}

export default Practice