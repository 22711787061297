import React, { useEffect, useState } from 'react'
import { useLoaderData, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import userService from '../../services/user-service';
import { User } from '../../types/user-profille-types';
import { Alert, Button, Form, Modal } from 'react-bootstrap';
import toast, { Toast } from 'react-hot-toast';
import AssignRoles from '../../components/users/assign-roles';
import AssignPermissions from '../../components/users/assign-permission';
import { convertISTtoUTC, convertUTCtoIST, getMediaUrl, pdfUploadHelper } from '../../utils/helpers';

export async function loader({ request }: { request: Request }): Promise<{ users: User[] }> {
    const url = new URL(request.url);
    const searchParams = new URLSearchParams(url.search);
    const userId = searchParams.get("id");
    const search = searchParams.get("search");

    
    const queryParams: string[] = [];
    if (userId) {
        if (+userId === 0) {
            return { users: [] }
        }
        queryParams.push(`id=${userId}`);
    }
    if (search) {
        queryParams.push(`search=${search}`);
    }
    const query = queryParams.join('&');
    const usersData = await userService.getAllUsers(query);
    
    if ((usersData.data.users.length === 0)) {
        return { users: [] }
    }
    
    return { users: usersData.data.users };
}

const channel = new BroadcastChannel('user_crud');


function UserDetails() {
    const { users } = useLoaderData() as { users: User[] }

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const userId = queryParams.get("id");
    const search = queryParams.get("search");

    const navigate = useNavigate()
    const [modalTitle, setModalTitle] = useState<'Role' | 'Permission' | null>(null)
    const [user, setUser] = useState<User | null>(null);
    const [loading, setLoading]= useState(true)
    
    useEffect(() => {
        setLoading(true)
        if (!userId) {
            toast.error("user not find.")
        } else {            
            if (+userId !== 0) {
                if (users.length !== 0) {
                setUser(users[0]);
            } else {
                toast.error('User not found');
            }
        } else {
            setUser({
                id: 0,
                name: '',
                username: '',
                password: '',
                isStaff: false,
                utmSource: '',
                profileUrl: '',
                gender: '',
                dateOfBirth: null,
                createdById: null,
                createdOn: new Date(''),
                updatedOn: new Date(''),
            });
        }
        setLoading(false)
    }
    }, [userId, users]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        if (!user) {
            return toast.error("user is not find.")
        }
        // if (!user.id) {
            // return toast.error(`user id is not find.`)
        // }
        const { name, value, type } = e.target;
        setUser((prev) => {
            if (!prev) return prev;

            if (type === 'checkbox') {
                const { checked } = e.target as HTMLInputElement
                return {
                    ...prev,
                    [name]: checked,
                };

            }
            if (type === 'dateOfBirth') {
                return {
                    ...prev,
                    [name]: convertISTtoUTC(value),
                };
            }
            return {
                ...prev,
                [name]: value,
            };
        })
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!user) return toast.error('user details not found.');

        const userData = {
            name: user.name ?? undefined,
            dateOfBirth: user.dateOfBirth ? user.dateOfBirth : undefined,
            gender: user.gender ?? undefined,
            isStaff: user.isStaff ?? undefined,
            profileUrl: user.profileUrl ?? undefined,
            utmSource: user.utmSource ?? undefined,
        }

        if (userId || search) {
            if (user.id !==0) {
                try {
                    const updateUser = await userService.updateUser(user.id, userData);
                    toast.success(updateUser.message)
                    channel.postMessage({id:user.id, data: user});
                } catch (err: any) {
                    toast.error(err.message || 'An error occurred');
                }
            }
            else {
                try {
                    // const createUser = await userService.createUser(userData);
                    toast.success("user created successfully")
                    channel.postMessage({id:0, data: user});
                } 
                catch (err: any) {
                    toast.error(err.message || 'An error occurred');
                }
            }
        }
        else {
            toast.error("something went wrong. error [789654]")
        }
    };


    const handleDelete = (t: Toast, id: number) => {
        toast.dismiss(t.id);
        userService.deleteUser(id).then((res) => {
            if (res.status === 'success') {
                toast.success(res.message);
                navigate('/users');
            } else {
                toast.error(res.message);
            }
        });
    };


    const deleteUser = (id: number) => {
        toast((t) => (
            <span className="confirmation-box">
                <p style={{ margin: 0, fontSize: '1rem', fontWeight: '500' }}>
                    Do you want to delete this user?
                </p>
                <div className="confirmation-buttons">
                    <button className="confirmation-yes" onClick={() => handleDelete(t, id)}>
                        Yes
                    </button>
                    <button className="confirmation-no" onClick={() => toast.dismiss(t.id)}>
                        No
                    </button>
                </div>
            </span>
        ));
    };

    const onSuccess = () => {
        setModalTitle(null);
    };

    const handleFileChange = (files: FileList | null) => {
        if (!user) {
            return toast.error("user not found.")
        }
        pdfUploadHelper(files?.[0]).then((key) => {
            setUser((prev) => {
                if (!prev) return prev
                return {
                    ...prev,
                    profileUrl: key ?? ''
                }
            });
        });
    };

    if (loading) return <div>Loading...</div>;

    if (!user) return <div>User not Found.</div>;

    return (
        <div className="container mt-4">
            <h2>{(userId  && (+userId !== 0))? 'Update User' : 'Create User'}</h2>
            <Form onSubmit={handleSubmit}>
                <div className="d-flex justify-content-between" >
                    <div className="w-50">
                        <Form.Group className="mb-3">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                value={user.name || ''}
                                onChange={handleChange}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Username</Form.Label>
                            <Form.Control
                                type="text"
                                name="username"
                                value={user.username || ''}
                                onChange={handleChange}
                                required
                                disabled
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Utm Source</Form.Label>
                            <Form.Control
                                type="text"
                                name="utmSource"
                                value={user.utmSource || ''}
                                onChange={handleChange}
                                required
                                disabled
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Gender</Form.Label>
                            <Form.Select aria-label="OTHER" onChange={handleChange} name='gender'>
                                {
                                    ["MALE", "FEMALE", "OTHER"].map(
                                        (option, index) =>
                                            <option key={index} value={option} selected={user.gender === option}>{option}</option>
                                    )
                                }
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Date of Birth</Form.Label>
                            <Form.Control
                                type="date"
                                name="dateOfBirth"
                                placeholder="dd-mm-yyyy"
                                value={user.dateOfBirth ? convertUTCtoIST(user.dateOfBirth).split('T')[0] : ''}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        {/* <Form.Group className="mb-3">
                            <Form.Check
                                type="checkbox"
                                name="isStaff"
                                label="Is Staff"
                                id={`is-staff-checkbox`}
                                checked={user.isStaff || false}
                                onChange={handleChange}
                            />
                        </Form.Group> */}
                        <Form.Group className='m-2'>
                            <div className=" gap-2">
                                <Button
                                    variant="primary"
                                    size="sm"
                                    onClick={() => {
                                        // setUserId(row.id);
                                        setModalTitle('Role');
                                    }}
                                >
                                    Role
                                </Button>
                                <Button
                                    className="ms-1"
                                    variant="success"
                                    size="sm"
                                    onClick={() => {
                                        // setUserId(row.id);
                                        setModalTitle('Permission');
                                    }}
                                >
                                    Permission
                                </Button>
                            </div>
                        </Form.Group>
                    </div>
                    <div className="w-25">
                        <Form.Group controlId="formBasicImage">
                            <div className="d-flex align-items-center justify-content-center">
                                <div className="position-relative">
                                    <img
                                        src={user?.profileUrl ? getMediaUrl(user?.profileUrl) : ''}
                                        className="student_img"
                                        style={{
                                            height: '300px',
                                            width: '300px',
                                            borderRadius: '50%',
                                        }}
                                        alt="Mentor"
                                    />
                                    <div className="edit-image-btn d-flex justify-content-center m-1">
                                        <input
                                            type="file"
                                            className="file-input"
                                            accept="image/*"
                                            onChange={(e) => handleFileChange(e.target.files)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Form.Group>
                    </div>
                </div>
                <Button variant="primary" type="submit" className="me-2">
                    {(userId  && (+userId !== 0)) ? 'Update' : 'Create'}
                </Button>
                {userId && (
                    <Button variant="danger" onClick={() => deleteUser(users[0].id)}>
                        Delete
                    </Button>
                )}
            </Form>

            <Modal show={!!modalTitle} onHide={onSuccess} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Assign {modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {userId && modalTitle == 'Role' && <AssignRoles id={user.id} />}
                    {userId && modalTitle == 'Permission' && <AssignPermissions id={user.id} />}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onSuccess}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>

    )
}

export default UserDetails