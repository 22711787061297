import { useEffect, useState } from 'react';
import { BiTrash } from 'react-icons/bi';
import { IoIosAddCircle } from 'react-icons/io';
import {
    CourseServiceItem,
    CourseClassTargetitem,
    CourseProcessItem,
    CourseHardwareItem,
    ProcessItem,
    TargetItem,
    ServiceItem,
    HardwareItem,
    ClassItem,
    CourseClassSessionItem,
} from '../../../types/course.types';
import CustomCheckbox from '../../common/custom-checkbox';
import hardwareService from '../../../services/ApiServices/hardware-service';
import processService from '../../../services/ApiServices/process-service';
import studentClassService from '../../../services/ApiServices/student-class-service';
import targetService from '../../../services/ApiServices/target-service';
import servicesService from '../../../services/ApiServices/services-service';
import courseService from '../../../services/ApiServices/course-service';
import toast from 'react-hot-toast';
import SpecialButton from '../../btn/special-btn';
import { Form } from 'react-bootstrap';
import { IoAdd, IoAddCircleSharp } from 'react-icons/io5';

type Props = {
    courseId: number;
    courseServices: CourseServiceItem[];
    courseTargets: CourseClassTargetitem[];
    courseProcesses: CourseProcessItem[];
    courseHardwares: CourseHardwareItem[];
    courseClassSessions: CourseClassSessionItem[];
};

const AdditionalDetailsTab = (props: Props) => {
    const [processes, setProcesses] = useState<ProcessItem[]>([]);
    const [courseProcesses, setCourseProcesses] = useState(props.courseProcesses);
    const [courseServices, setCourseServices] = useState(props.courseServices);
    const [courseHardwares, setCourseHardwares] = useState(props.courseHardwares);
    const [courseTargets, setCourseTargets] = useState(props.courseTargets);
    const [courseClassSessions, setCourseClassSessions] = useState<CourseClassSessionItem[]>(
        props.courseClassSessions
    );
    const [targets, setTargets] = useState<TargetItem[]>([]);
    const [classes, setClasses] = useState<ClassItem[]>([]);
    const [services, setServices] = useState<ServiceItem[]>([]);
    const [hardwares, setHardwares] = useState<HardwareItem[]>([]);
    const [classId, setClassId] = useState(0);
    const [targetId, setTargetId] = useState(0);

    const { courseId } = props;
    useEffect(() => {
        // Fetch initial data
        setCourseClassSessions(props.courseClassSessions);
        fetchInitialData();
    }, []);

    const fetchInitialData = async () => {
        try {
            const [hardwaresRes, processesRes, classesRes, targetsRes, servicesRes] =
                await Promise.all([
                    hardwareService.getAll(),
                    processService.getAll(),
                    studentClassService.getAll(),
                    targetService.getAll(),
                    servicesService.getAll(),
                ]);

            setHardwares(hardwaresRes.data.hardwares);
            setProcesses(processesRes.data.processes);
            setClasses(classesRes.data.classes);
            setTargets(targetsRes.data.targets);
            setServices(servicesRes.data.services);
        } catch (error: any) {
            toast.error('Error fetching initial data', error.message);
        }
    };

    const updateProcess = (processId: number, checked: boolean) => {
        if (checked) {
            courseService.addProcess(courseId, { processId }).then((res) => {
                refreshCourseData(); // Refresh after the update
            });
        } else {
            courseService.removeProcess(courseId, { processId }).then((res) => {
                refreshCourseData(); // Refresh after the update
            });
        }
    };

    const updateService = (serviceId: number, checked: boolean) => {
        if (checked) {
            courseService.addServices(courseId, { serviceId, isDefault: false }).then((res) => {
                refreshCourseData(); // Refresh after the update
            });
        } else {
            courseService.removeServices(courseId, { serviceId }).then((res) => {
                refreshCourseData(); // Refresh after the update
            });
        }
    };

    const updateHardware = (hardwareId: number, checked: boolean) => {
        if (checked) {
            courseService.addHardware(courseId, { hardwareId }).then((res) => {
                refreshCourseData(); // Refresh after the update
            });
        } else {
            courseService.removeHardware(courseId, { hardwareId }).then((res) => {
                refreshCourseData(); // Refresh after the update
            });
        }
    };

    const addClassTarget = () => {
        if (!classId || classId < 1) {
            return toast.error('Please select a class ');
        }
        if (!targetId || targetId < 1) {
            return toast.error('Please select a Target ');
        }
        let isDuplicate = courseTargets?.find(
            (itm) => itm.classId === classId && itm.targetId === targetId
        );

        if (isDuplicate) {
            return toast.error('This target is already added to the course!');
        }
        courseService.addClassTarget(courseId, { targetId, classId }).then((res) => {
            refreshCourseData(); // Refresh after the update
        });
    };

    const removeTarget = (courseClassTargetId: number) => {
        courseService.removeClassTarget(courseId, { courseClassTargetId }).then((res) => {
            refreshCourseData(); // Refresh after the update
        });
    };

    // This function refetches the course data after any updates to keep UI in sync
    const refreshCourseData = async () => {
        try {
            const updatedData = await courseService
                .getCourseById(courseId)
                .then((res) => res.data.course); // Assuming there's an API method to get course details
            setCourseProcesses(updatedData.processes);
            setCourseHardwares(updatedData.hardwares);
            setCourseTargets(updatedData.courseClassTargets);
            setCourseServices(updatedData.services);
            setCourseClassSessions(updatedData.classSessions);

            // Update the specific course-related state
            // Similarly, update other state variables like courseServices, courseTargets, etc.
        } catch (error: any) {
            toast.error('Error refreshing course data', error.message);
        }
    };

    const addClassSessions = () => {
        if (
            courseClassSessions.some(
                (d) => !d.sessionStart || !d.classId || !d.targetExamId || !d.sessionEnd
            )
        ) {
            return toast.error('Please fill all the data for course class session');
        }
        courseService.addClassSession(props.courseId, courseClassSessions).then((res) => {
            if (res.status === 'success') {
                refreshCourseData();
            }
        });
    };

    const handleUpdateClassSession = (data: CourseClassSessionItem) => {
        if (!data.id) return;
        if (
            (!data.classId && !data.sessionStart?.trim()) ||
            !data.targetExamId ||
            !data.sessionEnd
        ) {
            return toast.error(`Can't set to null values`);
        }
        if (data.classId && data.classId < 1) return toast.error('class Id is required');
        courseService
            .updateClassSession(props.courseId, data.id, {
                classId: data.classId,
                sessionStart: data.sessionStart,
                sessionEnd: data.sessionEnd,
                targetExamId: data.targetExamId,
            })
            .then((res) => {
                if (res.status === 'success') {
                    toast.success(res.message);
                    refreshCourseData();
                }
            });
    };

    return (
        <div className="row align-items-stretch row-gap-3">
            <div className="col-md-4">
                <fieldset>
                    <legend>Services</legend>
                    {services?.map((itm) => (
                        <div className="d-flex gap-2 my-2" key={itm.id}>
                            <CustomCheckbox
                                label={itm.name}
                                checked={!!courseServices?.find((el) => el.serviceId == itm.id)}
                                onChecked={(e, v) => updateService(+e, v)}
                                name={itm.id.toString()}
                            />
                        </div>
                    ))}
                </fieldset>
            </div>
            <div className="col-md-4">
                <fieldset>
                    <legend>Processes</legend>
                    {processes?.map((itm) => (
                        <div className="d-flex gap-2 my-2" key={itm.id}>
                            <CustomCheckbox
                                label={itm.processName}
                                checked={!!courseProcesses?.find((el) => el.processId == itm.id)}
                                onChecked={(e, v) => updateProcess(+e, v)}
                                name={itm.id.toString()}
                            />
                        </div>
                    ))}
                </fieldset>
            </div>
            <div className="col-md-4">
                <fieldset>
                    <legend>Targets</legend>
                    <div className="row align-items-baseline">
                        {courseTargets?.map((trgt) => (
                            <div className="row gap-2 my-2" key={trgt.courseClassTargetId}>
                                <span className="col-4">{trgt.className}</span>
                                <span className="col-4">{trgt.targetName}</span>
                                <div className="col-2 text-danger" style={{ cursor: 'pointer' }}>
                                    <BiTrash
                                        size={24}
                                        onClick={() => removeTarget(trgt.courseClassTargetId)}
                                    />
                                </div>
                            </div>
                        ))}
                        <div className="col-4">
                            <select
                                className="form-select"
                                onChange={(e) => setClassId(+e.target.value)}
                                defaultValue={-1}
                            >
                                <option value={-1}></option>
                                {classes.map((itm) => (
                                    <option value={itm.id} key={itm.id}>
                                        {itm.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-4">
                            <select
                                className="form-select"
                                onChange={(e) => setTargetId(+e.target.value)}
                                defaultValue={-1}
                            >
                                <option value={-1}></option>
                                {targets.map((itm) => (
                                    <option value={itm.id} key={itm.id}>
                                        {itm.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-md-2 col-3 d-flex align-items-center">
                            <a
                                className="btn btn-primary btn-sm px-4 d-flex align-items-center justify-content-center gap-2"
                                onClick={() => addClassTarget()}
                            >
                                Add{' '}
                            </a>
                        </div>
                    </div>
                </fieldset>
            </div>
            <div className="col-md-8 col-12">
                <fieldset className="pb-1">
                    <legend>Class Sessions</legend>
                    {courseClassSessions?.map((itm, i) => {
                        return (
                            <div className="row mt-0" key={i}>
                                <div className="col-md-2 col-4">
                                    <Form.Floating className="mb-1">
                                        <Form.Control
                                            type="text"
                                            id={`sessionStart-${i}`}
                                            placeholder="Session Start"
                                            value={itm.sessionStart}
                                            onChange={(e) => {
                                                setCourseClassSessions((prev) =>
                                                    prev.map((data, j) => ({
                                                        ...data,
                                                        ...(j === i && {
                                                            sessionStart: e.target.value,
                                                        }),
                                                    }))
                                                );
                                            }}
                                        />
                                        <Form.Label htmlFor={`sessionStart-${i}`}>
                                            Session Start
                                        </Form.Label>
                                    </Form.Floating>
                                </div>
                                <div className="col-md-2 col-4">
                                    <Form.Floating className="mb-3">
                                        <Form.Control
                                            type="text"
                                            id={`session-end`}
                                            onChange={(e) => {
                                                setCourseClassSessions((prev) =>
                                                    prev.map((data, j) => ({
                                                        ...data,
                                                        ...(j == i && {
                                                            sessionEnd: e.target.value,
                                                        }),
                                                    }))
                                                );
                                            }}
                                            placeholder="Session End"
                                            value={itm.sessionEnd}
                                            className="form-control"
                                        />
                                        <Form.Label htmlFor={`session-end`}>Session End</Form.Label>
                                    </Form.Floating>
                                </div>
                                <div className="col-md-3 col-4">
                                    <Form.Floating>
                                        <Form.Select
                                            id={`targetSelect-${i}`}
                                            className="form-select"
                                            onChange={(e) => {
                                                setCourseClassSessions((prev) =>
                                                    prev.map((data, j) => ({
                                                        ...data,
                                                        ...(j == i && { classId: +e.target.value }),
                                                    }))
                                                );
                                            }}
                                            value={itm.classId}
                                        >
                                            <option value={0}>--Select Class--</option>
                                            {classes.map((itm) => (
                                                <option value={itm.id} key={itm.id}>
                                                    {itm.name}
                                                </option>
                                            ))}
                                        </Form.Select>
                                        <Form.Label htmlFor={`targetSelect-${i}`}>
                                            Select Class
                                        </Form.Label>
                                    </Form.Floating>
                                </div>
                                <div className="col-md-3 col-6">
                                    <Form.Floating>
                                        <Form.Select
                                            id={`targetSelect-${i}`}
                                            className="form-select"
                                            onChange={(e) => {
                                                setCourseClassSessions((prev) =>
                                                    prev.map((data, j) => ({
                                                        ...data,
                                                        ...(j === i && {
                                                            targetExamId: +e.target.value,
                                                        }),
                                                    }))
                                                );
                                            }}
                                            value={itm.targetExamId}
                                        >
                                            <option value={0}>--Select Target--</option>
                                            {targets.map((target) => (
                                                <option value={target.id} key={target.id}>
                                                    {target.name}
                                                </option>
                                            ))}
                                        </Form.Select>
                                        <Form.Label htmlFor={`targetSelect-${i}`}>
                                            Select Target
                                        </Form.Label>
                                    </Form.Floating>
                                </div>
                                {itm.id && (
                                    <div className="col-md-2 col-4 d-flex align-items-center">
                                        <button
                                            className="btn btn-primary btn-sm px-4 d-flex align-items-center justify-content-center gap-2"
                                            onClick={() => {
                                                handleUpdateClassSession(itm);
                                            }}
                                        >
                                            Save
                                        </button>
                                    </div>
                                )}
                            </div>
                        );
                    })}
                    <div className="mt-2">
                        {!courseClassSessions.some((d) => d.id) && (
                            <SpecialButton
                                text="Save"
                                variant="btn-15"
                                onClick={addClassSessions}
                            />
                        )}{' '}
                    </div>
                </fieldset>
            </div>

            <div className="col-md-4">
                <fieldset>
                    <legend>Hardwares</legend>
                    {hardwares?.map((itm) => (
                        <div className="d-flex gap-2 my-2" key={itm.id}>
                            <CustomCheckbox
                                label={itm.name}
                                checked={!!courseHardwares?.find((el) => el.hardwareId == itm.id)}
                                onChecked={(e, v) => updateHardware(+e, v)}
                                name={itm.id.toString()}
                            />
                        </div>
                    ))}
                </fieldset>
            </div>
        </div>
    );
};

export default AdditionalDetailsTab;
