const Title = ({ title, total }: { title: string; total?: number }) => (
  <div className="p-2">
    <h2>
      {title}
      {!!total && `(${total})`}
    </h2>
  </div>
);

export default Title;
