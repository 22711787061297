import React, { useRef, useState } from 'react';
import { Button, FloatingLabel, Form, Modal } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import toast from 'react-hot-toast';
import { ENDPOINT_HOST, MEDIA_BASE_URL } from '../../config';
import QuizService from '../../services/ApiServices/QuizService';
import { axiosClient } from '../../services/axiosClient';
import { QuizPayload, quizQuestionType, QuizType } from '../../types/quizTypes';
import { GroupedQuestionData, QuestionData, QuizImportInterface } from '../../types/types';
import { pdfUploadHelper } from '../../utils/helpers';
import { Link } from 'react-router-dom';

type FilteredQuestionData = { question: any; results: any };

function UploadQuizPage() {
    const typeRef = useRef<HTMLSelectElement>(null);
    const [loading, setLoading] = useState(false);
    const [jsondata, setJsondata] = useState<QuizImportInterface>();
    const [filteredQuestionsData, setFilteredQuestionsData] = useState<any>();
    const [selectedAnswers, setSelectedAnswers] = useState<Array<FilteredQuestionData>>([]);

    const [selectedQuestions, setSelectedQuestions] = useState<Array<FilteredQuestionData>>([]);
    const [quizId, setQuizId] = useState<number | null>(null);
    const [showModal, setShowModal] = useState(false);
    const [error, setError] = useState<string[]>([]);
    const [showModal2, setShowModal2] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);

    const [quizDetails, setQuizDetails] = useState<QuizPayload>({
        quizType: QuizType.QUIZ,
        internalName: '',
        showInstructions: typeRef.current?.value == 'QUIZ' ? true : false,
        instructions: '',
        timeLimit: typeRef.current?.value == 'QUIZ' ? 60 : 0,
        minTimeSubmission: typeRef.current?.value == 'QUIZ' ? 10 : 0,
        totalAttempts: 0,
        showSolutions: true,
        showRank: true,
        showReport: true,
        language: 'ENGLISH',
        postSubmissionMessage: '',
        sections: [],
    });

    const handleCreateQuiz = async () => {
        const quizServiceResponse = await QuizService.createQuiz({
            ...quizDetails,
            timeLimit: Number(quizDetails.timeLimit) * 60,
            minTimeSubmission: Number(quizDetails.minTimeSubmission) * 60,
            sections: quizDetails.sections.length
                ? quizDetails.sections
                : [
                    {
                        sectionName: 'section',
                        maxQuestions: 0,
                    },
                ],
        });
        const quizData = quizServiceResponse.data;
        setQuizId(quizServiceResponse.data.id);

        return quizData;
    };

    const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
        setLoading(true);
        // toast.loading('Reading files...');
        const files = Array.from(e.target.files || []);
        const uploadedFiles: { key: string; fileName: string }[] = [];
        let JsonData;

        // Wrap the entire file processing within toast.promise
        await toast.promise(
            (async () => {
                for (const file of files) {
                    if (file.name === 'assessmentData.json') {
                        try {
                            const jsonContent = await readFileContent(file);
                            const data: QuizImportInterface = JSON.parse(jsonContent);
                            const quizData = {
                                quizType: typeRef.current?.value as QuizType,
                                internalName: data['spayee:resource']['spayee:title'],
                                showInstructions:
                                    data['spayee:resource']['spayee:instruction'].length == 0
                                        ? false
                                        : true,
                                instructions: data['spayee:resource']['spayee:instruction'],
                                timeLimit:
                                    typeRef.current?.value == 'QUIZ' ? quizDetails.timeLimit : 0,
                                minTimeSubmission:
                                    typeRef.current?.value == 'QUIZ'
                                        ? quizDetails.minTimeSubmission
                                        : 0,
                                totalAttempts: typeRef.current?.value == 'QUIZ' ? 0 : 0,
                                showSolutions: true,
                                showRank: true,
                                showReport: true,
                                language: 'ENGLISH',
                                postSubmissionMessage: '',
                                sections: data['spayee:resource']['spayee:SectionGrouping'].map(
                                    (section, index) => ({
                                        id: index,
                                        sectionName: section.sectionName,
                                        maxQuestions: section.maxQuestions,
                                    })
                                ),
                            };

                            setQuizDetails(quizData);
                            JsonData = JSON.stringify(data);
                        } catch (error) {
                            toast.error('Failed to read JSON file');
                        }
                    } else {
                        try {
                            const key = await pdfUploadHelper(file);
                            uploadedFiles.push({ key, fileName: file.name });
                        } catch (err) {
                            toast.error('Failed to upload file: ' + file.name);
                        }
                    }
                }

                if (JsonData) {
                    for (let i = 0; i < uploadedFiles.length; i++) {
                        const uploadedFile = uploadedFiles[i];
                        JsonData = JsonData?.replace(
                            'images/' + uploadedFile.fileName,
                            MEDIA_BASE_URL + uploadedFile.key
                        );
                    }
                    setJsondata(JSON.parse(JsonData));
                } else {
                    toast.error('Data not found.');
                    return;
                }
            })(),
            {
                loading: 'Processing files...',
                success: 'Files processed successfully!',
                error: 'Error when processing files',
            }
        );

        setLoading(false);
    };

    const readFileContent = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = reject;

            reader.readAsText(file);
        });
    };

    function transformData(inputArray: (string | number)[] | undefined) {
        if (!inputArray) {
            alert('data not found.');
            return;
        }
        const resultMap = new Map();

        inputArray.forEach((item, optionIndex) => {
            // Use regex to split the first part as index and the rest as matches
            //@ts-ignore
            const [index, matchesPart] = item.match(/^(\d+|[A-Za-z]+)(.*)$/).slice(1, 3);
            const matches = matchesPart
                .split(',')
                .map((match: any) => match.trim())
                .filter(Boolean);

            // Update resultMap for each match option
            matches.forEach((matchOption: any) => {
                if (!resultMap.has(matchOption)) {
                    resultMap.set(matchOption, {
                        option: matchOption,
                        matchOptionIndexes: [],
                    });
                }
                resultMap.get(matchOption).matchOptionIndexes.push(optionIndex);
            });
        });

        // Convert the resultMap values to an array
        return Array.from(resultMap.values());
    }

    const createTest = async () => {
        setLoading(true);

        try {
            const questionData: any[] = [];
            const questions = jsondata?.['spayee:resource']['spayee:questionIds'] ?? {};
            const paragraphs: { id: number; paragraph: string }[] = [];
            const questionDataKeys: string[] = Object.keys(questions);

            for (let index = 0; index < questionDataKeys.length; index++) {
                const spyaeeQuestion = questions[questionDataKeys[index]];
                if ('questiongroup' in questions[questionDataKeys[index]].data) {
                    const question = spyaeeQuestion as GroupedQuestionData;
                    const paragraphQuestions = question.data.question;
                    const paragraph = question.data.grouptext;

                    let paragraphId: number = -1;

                    if (paragraphQuestions.length > 0) {
                        paragraphId =
                            paragraphs.find((para) => para.paragraph === paragraph)?.id || -1;
                    }
                    if (paragraphId === -1) {
                        const response = await QuizService.saveParagraphs(paragraph);
                        paragraphs.push(response.data);
                        paragraphId = response.data.id;
                    }

                    for (let index = 0; index < paragraphQuestions.length; index++) {
                        const paragraphQuestion = paragraphQuestions[index];
                        const questionSolution = question.data.answer.find(
                            (ans) => ans.questionId === paragraphQuestion.id
                        );

                        const questionSpayeeType = paragraphQuestion.type;
                        const qType: quizQuestionType =
                            questionSpayeeType === 'objective'
                                ? 'SINGLE_CORRECT'
                                : questionSpayeeType === 'multichoice'
                                    ? 'MULTIPLE_CORRECT'
                                    : questionSpayeeType === 'numerical'
                                        ? 'NUMERICAL'
                                        : questionSpayeeType === 'match'
                                            ? 'MATCH_THE_COLUMN'
                                            : 'SUBJECTIVE';

                        const qTypeLabel =
                            qType === 'SINGLE_CORRECT'
                                ? 'SINGLE CORRECT TYPE'
                                : qType === 'MULTIPLE_CORRECT'
                                    ? 'ONE OR MORE THAN ONE CORRECT TYPE'
                                    : qType === 'NUMERICAL'
                                        ? 'NUMERICAL TYPE'
                                        : qType === 'SUBJECTIVE'
                                            ? 'SUBJECTIVE TYPE'
                                            : qType === 'MATCH_THE_COLUMN'
                                                ? 'MATCH THE COLUMN TYPE'
                                                : '';

                        const questionFormatedData = {
                            paragraphId,
                            qOrder: question.sequence,
                            qType,
                            qTypeLabel,
                            language: 'English',
                            sectionId: question.section,
                            qInstructions: undefined,
                            qText: paragraphQuestion.text,
                            options: [
                                'SINGLE_CORRECT',
                                'MULTIPLE_CORRECT',
                                'MATCH_THE_COLUMN',
                            ].includes(qType)
                                ? paragraphQuestion.options.option.map((opt) => ({
                                    qOption: opt.content,
                                    optionOrder: opt.id,
                                    isSolution: questionSolution?.correctOptions.option.includes(
                                        opt.id
                                    ),
                                }))
                                : [],
                            qHint: '',
                            qSolution: questionSolution?.solution.text
                                ? questionSolution?.solution.text
                                : '<div></div>',
                            positiveMarks: +paragraphQuestion.mark,
                            negativeMarks: +paragraphQuestion.penalty,
                            partialMarks: 0,
                            correctAnswer:
                                qType === 'MATCH_THE_COLUMN'
                                    ? undefined
                                    : qType === 'NUMERICAL'
                                        ? questionSolution?.correctOptions.option
                                            ? +questionSolution?.correctOptions.option
                                            : undefined
                                        : undefined,
                            rangeFrom: 0,
                            rangeTo: 0,
                            level1Id: null,
                            level2Id: null,
                            matches:
                                qType === 'MATCH_THE_COLUMN'
                                    ? transformData(questionSolution?.correctOptions.option)
                                    : undefined,
                        };
                        questionData.push(questionFormatedData);
                    }
                } else {
                    const question = spyaeeQuestion as QuestionData;
                    const questionSpayeeType = question.data.type;
                    const qType: quizQuestionType =
                        questionSpayeeType === 'objective'
                            ? 'SINGLE_CORRECT'
                            : questionSpayeeType === 'multichoice'
                                ? 'MULTIPLE_CORRECT'
                                : questionSpayeeType === 'numerical'
                                    ? 'NUMERICAL'
                                    : questionSpayeeType === 'match'
                                        ? 'MATCH_THE_COLUMN'
                                        : 'SUBJECTIVE';

                    const qTypeLabel =
                        qType === 'SINGLE_CORRECT'
                            ? 'SINGLE CORRECT TYPE'
                            : qType === 'MULTIPLE_CORRECT'
                                ? 'ONE OR MORE THAN ONE CORRECT TYPE'
                                : qType === 'NUMERICAL'
                                    ? 'NUMERICAL TYPE'
                                    : qType === 'SUBJECTIVE'
                                        ? 'SUBJECTIVE TYPE'
                                        : qType === 'MATCH_THE_COLUMN'
                                            ? 'MATCH THE COLUMN TYPE'
                                            : '';

                    const questionFormatedData = {
                        paragraphId: undefined,
                        qOrder: question.sequence,
                        qType,
                        qTypeLabel,
                        language: 'English',
                        sectionId: question.section,
                        qInstructions: undefined,
                        qText: question.data?.text,
                        options: [
                            'SINGLE_CORRECT',
                            'MULTIPLE_CORRECT',
                            'MATCH_THE_COLUMN',
                        ].includes(qType)
                            ? question.data.options.option.map((opt) => ({
                                qOption: opt.content,
                                optionOrder: opt.id,
                                isSolution: question.data.answer.correctOptions.option.includes(
                                    opt.id
                                ),
                            }))
                            : [],
                        qHint: '',
                        qSolution: question.data.answer.solution?.text
                            ? question.data.answer.solution?.text
                            : '<div></div>',
                        positiveMarks: +question.data.mark,
                        negativeMarks: +question.data.penalty,
                        partialMarks: 0,
                        correctAnswer:
                            qType === 'MATCH_THE_COLUMN'
                                ? undefined
                                : qType === 'NUMERICAL'
                                    ? +question.data.answer.correctOptions.option
                                    : undefined,
                        rangeFrom: 0,
                        rangeTo: 0,
                        level1Id: null,
                        level2Id: null,
                        matches:
                            qType === 'MATCH_THE_COLUMN'
                                ? transformData(question.data.answer.correctOptions.option)
                                : undefined,
                    };
                    questionData.push(questionFormatedData);
                }
            }

            const filteredQuestions: Array<FilteredQuestionData> = [];

            // for (let index = 0; index < questionData.length; index++) {
            //     const question = questionData[index];
            //     const requestData = {
            //         text: question.qText,
            //     };
            //     const response = await axiosClient.post(
            //         `${ENDPOINT_HOST}/quiz/search-similer-questions`,
            //         {
            //             ...requestData,
            //         }
            //     );

            //     if (response && response.data.data.length > 0) {
            //         const questionResult = {
            //             question,
            //             results: response.data.data,
            //         };
            //         filteredQuestions.push(questionResult);
            //     } else {
            //         setSelectedQuestions((prev) => [
            //             ...prev,
            //             { question, results: [] }, // Empty results
            //         ]);
            //     }
            // }

            if (filteredQuestions.length === 0) {
                toast.loading('creadting quiz...');
                const quizData = await handleCreateQuiz();

                const questionsWithSections = questionData.map((question) => ({
                    ...question,
                    sectionId:
                        quizData.sections.length === 1
                            ? quizData.sections[0].id
                            : quizData.sections.find((section) => {
                                const sectionId = question.sectionId;
                                // Ensure sectionId is a string before splitting
                                if (typeof sectionId === 'string') {
                                    return section.sectionName === sectionId.split(',')[0];
                                }
                                return false;
                            })?.id,
                    matches: [],

                    // !!question.sectionId
                    //     ? quizData.sections.length === 1
                    //         ? quizData.sections[0].id
                    //         : quizData.sections.find(
                    //               (section) =>
                    //                   section.sectionName === question.section?.split(',')[0]
                    //           )?.id
                    //     : question.sectionId,
                }));
                await Promise.allSettled(
                    questionsWithSections.map((question: any) =>
                        QuizService.addQuestion(quizData.id, question)
                    )
                ).catch((error) => error.forEach((err: any) => console.error(err)));
                toast.success('Quiz Created Successfully. Quiz Id: ' + quizData.id);
                setLoading(false);
            } else {
                setFilteredQuestionsData(filteredQuestions);
                setShowModal(true);
                setLoading(false);
            }
        } catch (error: any) {
            toast.error(JSON.stringify(error.message));
            setLoading(false);
        }
    };

    // Hide the modal and reset state variables
    const handleHideModal = () => {
        setShowModal(false);
    };

    const addExtraData = async () => {
        const questionsToAdd = filteredQuestionsData.filter(
            (item: { question: { qText: any } }) =>
                !selectedQuestions.some(
                    (selected) => selected.question.qText === item.question.qText
                )
        );

        if (questionsToAdd.length > 0) {
            const updatedQuestions = [
                ...selectedQuestions,
                ...questionsToAdd.map((item: { question: { matches: any } }) => ({
                    question: {
                        ...item.question,
                        matches: item.question.matches ?? [], // Ensure matches is an array
                    },
                    results: [],
                })),
            ];

            setSelectedQuestions(updatedQuestions);
            return updatedQuestions;
        }

        const remainQuestions = selectedQuestions.map((item) => ({
            ...item,
            question: {
                ...item.question,
                matches: item.question.matches ?? [],
            },
        }));

        setSelectedQuestions(remainQuestions);
        return remainQuestions;
    };

    const handleConfirm = async () => {
        const updatedQuestions = await addExtraData();

        const quizData = await handleCreateQuiz();

        for (let index = 0; index < updatedQuestions.length; index++) {
            const element = updatedQuestions[index];

            const question = {
                ...element.question,
                matches: element.question.matches ?? [],
                sectionId:
                    quizData.sections.length === 1
                        ? quizData.sections[0].id
                        : quizData.sections.find((section) => {
                            const sectionId = element.question.sectionId;
                            // Ensure sectionId is a string before splitting
                            if (typeof sectionId === 'string') {
                                return section.sectionName === sectionId.split(',')[0];
                            }
                            return false;
                        })?.id,
            };

            if (element.results.id) {
                const requestData = {
                    questionId: element.results.id,
                    qOrder: question.qOrder,
                    sectionId: question.sectionId,
                    positiveMarks: question.positiveMarks,
                    negativeMarks: question.negativeMarks,
                    partialMarks: question.partialMarks,
                };

                try {
                    const response = await axiosClient.post(
                        `${ENDPOINT_HOST}/quiz/${quizData.id}/questions`,
                        requestData
                    );

                    if (response.status === 201) {
                        toast.success('Similar Question attached successfully');
                    }
                } catch (error) {
                    setError((prevErrors) => [
                        ...prevErrors,
                        `Error when attaching similar question: ${error}`,
                    ]);
                    toast.error(`Error when attaching similar question: ${error}`);
                }
            } else {
                const response = await QuizService.addQuestion(quizData.id ?? 0, question);
                if (response.errors.length > 0) {
                    toast.error(`Error while add question ${response.errors}`);
                    setError((prevErrors) => [
                        ...prevErrors,
                        `Error while add question ${response.errors}`,
                    ]);
                } else {
                    toast.success('question added successfully');
                }
            }
        }
        if (error.length > 0) {
            toast.error('Error while creating questions', { duration: error.length * 1000 });
        } else {
            toast.loading('creadting quiz...');
            toast.success('Quiz Created Successfully.');
            if (inputRef.current) {
                inputRef.current.value = ''; // Clear the input field
            }
        }

        setShowModal(false);
    };

    const handleAnswerChange = (question: any, selectedAnswer: any) => {
        setSelectedAnswers((prev) => {
            // Check if the question already exists in the selectedAnswers
            const index = prev.findIndex((item) => item.question === question);
            if (index >= 0) {
                // Update the existing question's answer
                const updated = [...prev];
                updated[index].results = selectedAnswer;
                return updated;
            }
            // Add new question-answer pair
            return [...prev, { question, results: selectedAnswer }];
        });
    };

    const columns = [
        {
            name: 'S.No.',
            cell: (row: any, rowIndex: number) => rowIndex + 1,
            sortable: false,
            width: '20px',
        },
        {
            name: 'Question',
            selector: (row: any) => row.question?.qText || 'N/A',
            sortable: false,
            cell: (row: any) => (
                <div
                    data-tag="allowRowEvents"
                    className='text-breaker'
                    dangerouslySetInnerHTML={{ __html: row.question?.qText ?? 'N/A' }}
                />
            ),
        },
        {
            name: 'Similar Question',
            cell: (row: any) => (
                <Link to={`/content/upload-quiz`} onClick={() => handleShowModal2(row)}>
                    {row.results.length} matches found
                </Link>
            ),
            sortable: false,
        },
    ];

    const handleShowModal2 = (rowData: any) => {
        setSelectedQuestionData(rowData);
        setShowModal2(true);
    };

    const handleHideModal2 = () => {
        setShowModal2(false);
    };

    const [selectedQuestionData, setSelectedQuestionData] = useState<any>();

    const handleConfirm2 = () => {
        setSelectedQuestions((prev) => {
            // Merge new questions from selectedAnswers into selectedQuestions
            const merged = [...prev];
            selectedAnswers.forEach((answer) => {
                const index = merged.findIndex((item) => item.question === answer.question);
                if (index >= 0) {
                    // Update the existing question's results
                    merged[index].results = answer.results;
                } else {
                    // Add the new question-answer pair
                    merged.push(answer);
                }
            });
            return merged;
        });
        setShowModal2(false);
    };

    return (
        <div className="dashboard-page">
            <div className="container">
                Download test from Spayee and upload image files here.
                <br />
                <FloatingLabel className="my-3" controlId="quizType" label="Quiz Type">
                    <Form.Select name="quizType" defaultValue="Quiz" ref={typeRef}>
                        <option value={QuizType.QUIZ}>Quiz</option>
                        <option value={QuizType.PRACTICE_QUIZ}>Practice Quiz</option>
                    </Form.Select>
                </FloatingLabel>
                <FloatingLabel controlId="quizTimeLimit" label="Time Limit (Minutes)">
                    <Form.Control
                        type="number"
                        placeholder="In Minutes"
                        value={quizDetails.timeLimit}
                        className="my-3"
                        onChange={(e) => {
                            setQuizDetails((prev) => ({
                                ...prev,
                                timeLimit: +e.target.value,
                            }));
                        }}
                    // setQuizDetails("timeLimit", +e.target.value)}
                    />
                </FloatingLabel>
                <FloatingLabel
                    controlId="minTimeSubmission"
                    label="Min Time to Submission (Minutes)"
                >
                    <Form.Control
                        className="my-3"
                        type="number"
                        placeholder="In Minutes"
                        value={quizDetails.minTimeSubmission}
                        onChange={(e) => {
                            setQuizDetails((prev) => ({
                                ...prev,
                                minTimeSubmission: +e.target.value,
                            }));
                        }}
                    // onChange={(e) => handleChange("minTimeSubmission", +e.target.value)}
                    />
                </FloatingLabel>
                <input
                    ref={inputRef}
                    type="file"
                    className="mb-3"
                    onChange={handleFileUpload}
                    multiple
                    accept=".json,image/*"
                />
                <br />
                <Button disabled={loading} onClick={createTest}>
                    Save Test
                </Button>
                {loading && <span className="wait"> Please Wait...</span>}
            </div>
            <div>
                {/* {filteredQuestionsData.map((que, ind) => JSON.stringify(que))} */}
                {showModal && (
                    <Modal show={showModal} onHide={handleHideModal} backdrop="static" size="lg">
                        <Modal.Header closeButton>
                            <Modal.Title>View Question</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <DataTable
                                columns={columns}
                                data={filteredQuestionsData}
                                progressPending={loading}
                                pagination
                                highlightOnHover
                                striped
                                noDataComponent="No data available"
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="danger" onClick={handleConfirm}>
                                Submit
                            </Button>
                            <Button
                                variant="secondary"
                                onClick={handleHideModal}
                                disabled={loading}
                            >
                                Cancel
                            </Button>
                        </Modal.Footer>
                    </Modal>
                )}

                {showModal2 && (
                    <Modal show={showModal2} onHide={handleHideModal2} backdrop="static" size="lg">
                        <Modal.Header closeButton>
                            <Modal.Title>Similar Question</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                {selectedQuestionData?.results?.map(
                                    (result: { id: any; q_text: any }, index: number) => (
                                        <div key={index} className="d-flex align-items-baseline">
                                            <input
                                                type="radio"
                                                name={`question-${selectedQuestionData.question?.qOrder}`}
                                                value={result.q_text}
                                                id={String(index)}
                                                onChange={() =>
                                                    handleAnswerChange(
                                                        selectedQuestionData.question,
                                                        result
                                                    )
                                                }
                                                className="me-2"
                                            />
                                            <label
                                                htmlFor={String(index)}
                                                dangerouslySetInnerHTML={{
                                                    __html: result.q_text || 'N/A',
                                                }}
                                            />
                                        </div>
                                    )
                                )}
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="danger" onClick={handleConfirm2}>
                                Submit
                            </Button>
                            <Button
                                variant="secondary"
                                onClick={handleHideModal2}
                                disabled={loading}
                            >
                                Cancel
                            </Button>
                        </Modal.Footer>
                    </Modal>
                )}
            </div>
        </div>
    );
}

export default UploadQuizPage;
