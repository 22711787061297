import React from 'react';
import { Tooltip } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

interface TriggerToolTipInterface {
    message: string;
    children: React.ReactElement;
}

function TriggerToolTip({ message = '', children }: TriggerToolTipInterface) {
    return (
        <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="button-tooltip-2">{message}</Tooltip>}
        >
            {children}
        </OverlayTrigger>
    );
}

export default TriggerToolTip;
