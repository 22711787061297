export const getCourseSessions = () => {
    const currentYear = new Date().getFullYear();
    const nextYear = currentYear + 1;

    const start = [currentYear, nextYear];

    const end = [];
    for (let i = 1; i <= 5; i++) {
        const year1 = nextYear + i - 1;
        end.push(year1);
    }

    return { start, end };
};

export const debounce = <T extends (args: any) => any>(func: T, interval: number) => {
    let timeout: ReturnType<typeof setTimeout> | null = null;

    return function (args: any) {
        if (timeout !== null) clearTimeout(timeout);
        // @ts-ignore
        timeout = setTimeout(() => func(args), interval);
    };
};