import { useEffect, useState } from 'react';
import { Button, Form, FloatingLabel } from 'react-bootstrap';
import testimonialService from '../../services/ApiServices/testimonial-service';
import Select, { ActionMeta, MultiValue } from 'react-select';
import targetService from '../../services/ApiServices/target-service';
import toast from 'react-hot-toast';
import { TestimonialResp } from '../../types/testimonialTypes';

type ServiesModalInterface = {
    handleSubmit: ({ name, testimonialIds }: { name: string; testimonialIds: number[] }) => void;
    serviceName?: string;
    id?: number;
};

const ExamTargetModal = ({ handleSubmit, serviceName, id }: ServiesModalInterface) => {
    const [name, setName] = useState('');
    const [testimonials, setTestimonials] = useState<{ label: string; value: number }[]>([]);
    const [ids, setIds] = useState<{ label: string; value: number }[]>([]);

    const handleForm = () => {
        handleSubmit({ name, testimonialIds: id ? [] : ids.map((i) => i.value) });
        setName('');
    };

    const handleChange = async (
        newValue: MultiValue<{ label: string; value: number }>,
        actionMeta: ActionMeta<{ label: string; value: number }>
    ) => {
        console.log('ActionMeta:', { actionMeta, newValue });

        setIds([...newValue.values()]);
        if (id)
            try {
                if (actionMeta.action === 'remove-value') {
                    if (!actionMeta.removedValue?.value) {
                        console.error('Item not found for removal.');
                        return;
                    }
                    const removedItemId = actionMeta.removedValue.value;
                    console.log('Removing item with ID:', removedItemId);
                    targetService.removeTestimonial(+id, removedItemId).then((res) => {
                        if (res.status == 'success') toast.success(res.message);
                    });
                }

                if (actionMeta.action === 'select-option') {
                    if (!actionMeta.option?.value) {
                        console.error('Item not found for addition.');
                        return;
                    }
                    const addedItemId = actionMeta.option.value;
                    console.log('Adding item with ID:', addedItemId);
                    targetService.attachTestimonial(+id, addedItemId).then((res) => {
                        if (res.status == 'success') {
                            toast.success(res.message);
                        }
                    });
                }
            } catch (error) {
                console.error('Error in handleChange:', error);

                // Optionally, handle different actions
                if (actionMeta.action === 'remove-value') {
                    console.error('Failed to remove item. Please try again.');
                } else if (actionMeta.action === 'select-option') {
                    console.error('Failed to add item. Please try again.');
                } else {
                    console.error('An unexpected error occurred.');
                }
            }
    };

    useEffect(() => {
        testimonialService.getAllTestimonials().then((res) => {
            if (res.status == 'success') {
                setTestimonials(res.data.testimonials.map((i) => ({ label: i.name, value: i.id })));
            }
        });
        if (id) {
            targetService.getTarget(id).then((res) => {
                if (res.status == 'success') {
                    const targetTestimonials: TestimonialResp[] = res.data.target.testimonials;

                    setIds(targetTestimonials.map((i) => ({ label: i.name, value: i.id })));
                }
            });
        }
        if (serviceName) {
            setName(serviceName);
        } else {
            setName('');
        }
    }, [serviceName]);

    return (
        <Form>
            <Form.Group controlId="formName">
                <FloatingLabel controlId="floatingInput" label="Exam Target Name" className="mb-3">
                    <Form.Control
                        type="text"
                        placeholder="Enter exam target name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </FloatingLabel>
            </Form.Group>
            <Form.Group>
                <Select
                    placeholder="Select Testimonials/Selections"
                    options={testimonials}
                    value={ids}
                    onChange={handleChange}
                    isMulti
                    isSearchable
                />
            </Form.Group>
            <div className="my-2">
                <Button variant="primary" onClick={handleForm}>
                    Submit
                </Button>
            </div>
        </Form>
    );
};

export default ExamTargetModal;
