import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { IoArrowBack } from 'react-icons/io5';
import DoubtService from '../../../services/ApiServices/doubt-service';
import { MessagePayload, MessageResponse, TicketResponse } from '../../../types/DoubtTypes';
import ChatboxFooter from './chat-input';
import { User } from '../../../types/user-profille-types';
import Chatbox from './chatbox';
import './singleDoubt.css';

type Props = {
    ticket: TicketResponse;
    userId: number;
    onHide: () => void;
    users: User[];
};

const SingleDoubt = ({ ticket, onHide, users, userId }: Props) => {
    const scrolldiv = useRef(null);
    const [messages, setMessages] = useState<MessageResponse[]>([]);
    const [status, setStatus] = useState(ticket.status);
    const [staff, setStaff] = useState(ticket.staffId);
    const [msgPostData, setMsgPostData] = useState<MessagePayload>({
        message: '',
        mediaUrl: '',
        isRead: false,
    });

    async function fetchAllMessages() {
        if (!ticket?.id) {
            toast.error('ticket id is missing.');
        }
        DoubtService.getTicket(ticket.id).then((res) => {
            setMessages(res.data.ticket.message);
        });
    }

    async function sendMessage() {
        if (msgPostData.mediaUrl || msgPostData.message.length) {
            const newMessage: MessagePayload = {
                message: msgPostData.message,
                ...(msgPostData.mediaType && { mediaType: msgPostData.mediaType }),
                ...(msgPostData.mediaUrl && { mediaUrl: msgPostData.mediaUrl }),
                isRead: false,
            };

            DoubtService.sendMessage(ticket.id, newMessage)
                .then((res) => {
                    fetchAllMessages();
                })
                .catch((err) => {
                    toast.error(err.message);
                });

            setMsgPostData({ message: '', mediaUrl: '', isRead: false });
            scrollToBottom(scrolldiv.current);
        }
    }

    function deleteMessage(id: number) {
        DoubtService.deleteMessage(ticket.id, id).then((res) => {
            const updatedMessages = messages.filter((msg) => msg.id !== id);
            setMessages(updatedMessages);
            toast.success(res.message);
        });
    }

    const scrollToBottom = (node: any) => {
        node.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        fetchAllMessages();

        const interval = setInterval(() => {
            fetchAllMessages();
        }, 10 * 1000);

        return () => clearInterval(interval);
    }, []);

    const onStatusChange = (newStatus: string) => {
        if (newStatus === 'NEW' && status === 'OPENED') {
            return toast.error("you can't change status from opened to new");
        }
        if (newStatus === 'NEW' || newStatus === 'CLOSED' || newStatus === 'SOLVED') {
            DoubtService.updateTicket(ticket.id, { status: newStatus }).then((res) => {
                if (res.status === 'success') setStatus(newStatus);
                toast.success(res.message);
            });
        }
    };

    const onUserChange = (id: number) => {
        if (id === staff) {
            toast.error('Ticket is already assigned to this user!');
            return;
        }
        DoubtService.assignUser(ticket.id, {
            staffId: id,
            assignmentId: ticket.assignmentId,
        }).then((res) => {
            if (res.status === 'success') {
                setStaff(id);
                DoubtService.updateTicket(ticket.id, { status: 'OPENED' }).then((res) => {
                    if (res.status === 'success') setStatus('OPENED');
                    toast.success(res.message);
                });
            }
            toast.success(res.message);
        });
    };

    return (
        <div id="singleDoubt">
            <div className="home-cont">
                <div className="chattingpage">
                    <div className="chat-header">
                        <div className="back-button">
                            <button onClick={onHide}>
                                <IoArrowBack />
                            </button>
                        </div>
                        <div className="user-info">
                            <img
                                src="https://img.icons8.com/?size=100&id=aZ0WjSq1u1YB&format=png&color=000000"
                                alt="User Avatar"
                                className="user-avatar"
                            />
                            <div className="user-details">
                                <span className="user-name">{ticket.user.name}</span>
                                <div className="status-container">
                                    <select
                                        className="doubt-status p-2 ps-0 pt-0"
                                        value={status}
                                        onChange={(e) => onStatusChange(e.target.value)}
                                    >
                                        <option value="NEW">New</option>
                                        <option value={'OPENED'}>Opened</option>
                                        <option value="SOLVED">Solved</option>
                                    </select>
                                </div>
                            </div>
                            <div className="ms-auto" style={{ cursor: 'pointer' }}>
                                <select
                                    className="doubt-status p-2 ps-0 pt-0"
                                    value={staff || ''}
                                    onChange={(e) => onUserChange(+e.target.value)}
                                    disabled={status === 'SOLVED'}
                                >
                                    {users.map((user) => (
                                        <option value={user.id} key={user.id}>
                                            {user.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="live-chat pb-5" style={{ backgroundColor: '#f3f3f3' }}>
                        {messages.map((el) => {
                            return (
                                <Chatbox
                                    messageId={el.id}
                                    key={el.id}
                                    createdOn={el.createdOn}
                                    message={el.message}
                                    mediaType={el.mediaType}
                                    mediaUrl={el.mediaUrl}
                                    isBySelf={el.user == null || el.user.isStaff}
                                    onDelete={deleteMessage}
                                />
                            );
                        })}
                        <div ref={scrolldiv} />
                    </div>
                    <div
                        style={{
                            bottom: 0,
                            position: 'absolute',
                            width: 'inherit',
                            backgroundColor: 'white',
                            boxShadow: '1px -7px 20px -21px black',
                        }}
                    >
                        {userId == staff && status !== 'SOLVED' && (
                            <ChatboxFooter
                                onSendMessage={sendMessage}
                                onFileUpload={(url) => {
                                    setMsgPostData({
                                        ...msgPostData,
                                        mediaType: 'IMAGE',
                                        mediaUrl: url,
                                    });
                                }}
                                value={msgPostData.message}
                                onChange={(e) => {
                                    setMsgPostData({ ...msgPostData, message: e.target.value });
                                }}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SingleDoubt;
