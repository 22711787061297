import { Button, Col, FloatingLabel, Form, Row, Stack } from 'react-bootstrap';
import { PaymentProps } from '../../types/payments';
import Select from 'react-select';

function PaymentsHeader({ onChangeFilterText, userPermissions, onFilterApply }: PaymentProps) {
    return (
        <div style={{ width: '90%' }}>
            <Row className="align-items-center mt-3 mb-3">
                <Col className="mb-1" xs={12} sm={6} md={4} lg={3}>
                    <FloatingLabel
                        controlId="floatingInput"
                        label="Search by Student name or number"
                        className="mb-3"
                    >
                        <Form.Control
                            type="text"
                            placeholder="Student name or number"
                            onChange={(e) => onChangeFilterText(e.target.value, 'search')}
                        />
                    </FloatingLabel>
                </Col>

                {/* Uncomment and implement this section if needed */}
                {/* 
        {userPermissions &&
          userPermissions.includes(PERMISSIONS.VIEW_ALL_ORDER_FORM) && (
            <Col xs={12} sm={6} md={4} lg={3}>
              <Form.Label>Collected by</Form.Label>
              <SearchSelect
                is_staff={true}
                handleChange={(e) =>
                  onChangeFilterText(e?.value, "collected_by")
                }
              />
            </Col>
          )}
        */}

                {/* Uncomment and implement this section if needed */}
                {/* <Col xs={12} sm={6} md={4} lg={3}>
          <Form.Label>Course</Form.Label>
          <Select
            options={options}
            onChange={(e: any) => onChangeFilterText(e.value, "phase")}
          />
        </Col> */}

                <Col xs={12} sm={6} md={4} lg={3}>
                    <FloatingLabel
                        controlId="From"
                        label="From"
                        className="mb-3"
                    >
                        <Form.Control
                            type="date"
                            onChange={(e) => onChangeFilterText(e.target.value, 'from')}
                        />
                    </FloatingLabel>
                </Col>

                <Col xs={12} sm={6} md={4} lg={3}>
                    <FloatingLabel
                        controlId="To"
                        label="To"
                        className="mb-3"
                    >
                        <Form.Control
                            type="date"
                            onChange={(e) => onChangeFilterText(e.target.value, 'to')}
                        />
                    </FloatingLabel>
                </Col>

                <Col xs={12} sm={6} md={4} lg={3}>
                    <Select
                        options={[
                            { label: "Completed", value: "Completed" },
                            { label: "Pending", value: "Pending" },
                            { label: "NPA", value: "NPA" }
                        ]}
                        onChange={(e) => onChangeFilterText(e?.value, 'status')}
                        placeholder='Status'
                    />
                </Col>

                <Col xs={12} sm={6} md={4} lg={3}>
                    <Select
                        options={[
                            { label: "NA", value: "NA" },
                            { label: "New", value: "New" },
                            { label: "Installment", value: "Installment" }
                        ]}
                        onChange={(e) => onChangeFilterText(e?.value, 'paymentType')}
                        placeholder='Payment Type'
                    />
                </Col>
            </Row>

            <Stack direction="horizontal" gap={3} className="mt-3">
                <Button variant="primary" onClick={onFilterApply}>
                    Filter
                </Button>
                <Button variant="outline-secondary" onClick={() => window.location.reload()}>
                    Reset
                </Button>
            </Stack>
        </div>
    );
}

export default PaymentsHeader;
