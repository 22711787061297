import { Editor } from '@tinymce/tinymce-react';

import { pdfUploadHelper } from './utils/helpers';
import { MEDIA_BASE_URL } from './config';
import toast from 'react-hot-toast';

export default function TinyMceEditor(props: any) {

    // Upload Images to S3-Bucket
    async function uploadImages(blobFiles: any, v: any) {
        try {
            const file = blobFiles.blob();
            const key = await pdfUploadHelper(file);
            const url = `${MEDIA_BASE_URL}${key}`;
            return url
        } catch (error: any) {
            console.error("Error uploading image:", error);
            toast.error(`Error uploading image: ${error.message || "Unknown error"}`);
            return error;
        }
    }


    return (
        <Editor
            tinymceScriptSrc='/tinymce/tinymce.min.js'
            licenseKey='gpl'
            init={{
                browser_spellcheck: true,
                branding: false,
                // inline:true,
                menubar: false,
                height: props.height ? props.height : 200,
                width: "100%",
                images_upload_handler: uploadImages,
                images_replace_blob_uris: true,
                plugins: [
                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                    'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount', 'code', 'searchreplace'
                ],
                toolbar: 'undo redo | blocks | ' +
                    'bold italic forecolor backcolor | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | ' +
                    'removeformat | help | code | ' + 'removeformat',
                color_default_background: 'yellow',
                font_formats: 'Arial=arial,helvetica,sans-serif; Courier New=courier new,courier,monospace; AkrutiKndPadmini=Akpdmi-n',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }',
                placeholder: props.placeholder,
                resize: true,
            }}

            {...props}
        />
    );
}