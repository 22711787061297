import { MEDIA_BASE_URL } from '../../config';
import './styles.css';

type Props = {
    data: { thumbnail: string | null; name: string; id: number };
    className?: string;
    status?: string;
    type: 'course' | 'set';
};

export default function CommonCard({ status, data, className = '', type }: Props) {
    const url = data.thumbnail ? MEDIA_BASE_URL + data.thumbnail : '';
    return (
        <div
            title={`/contents/edit-${type}/${data.id}`}
            className="content-card card border shadow-sm d-flex flex-column"
            onClick={() => window.open(`/contents/edit-${type}/${data.id}`, '_blank')}
        >
            <img src={url || ''} alt={data.name} className="card-img-top pb-0 content-card-img" />
            <div className="card-body d-flex flex-column">
                <h5 className="card-title">{data.name}</h5>
                {status && (
                    <p className={`description ${status == 'DRAFT' ? 'text-danger' : ''}`}>
                        {status}
                    </p>
                )}
            </div>
        </div>
    );
}
