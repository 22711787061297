import { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { axiosClient } from '../../../services/axiosClient';
import { BACKEND_URLS, FRONTEND_URLS } from '../../../config';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ModalsConstants } from '../../../Redux/models/models-constants';
import toast from 'react-hot-toast';
import generalService from '../../../services/ApiServices/general-service';
import targetService from '../../../services/ApiServices/target-service';
import studentClassService from '../../../services/ApiServices/student-class-service';
import { TargetItem, ClassItem } from '../../../types/course.types';
import courseService from '../../../services/ApiServices/course-service';
import { ContentConstants } from '../../../Redux/content/content-constants';
import nodeService from '../../../services/ApiServices/node-service';
import { NodeSettings, NodeType } from '../../../types/node-types';
import Select from 'react-select';

interface LevelInterface {
    id: number;
    imageUrl: string;
    internalName: string;
    name: string;
    createdOn: string;
}

function CreateNode({
    node,
    handleNodeCancel,
    isCopyNode,
    copingNodeId,
}: {
    node?: any;
    handleNodeCancel?: () => void;
    isCopyNode?: boolean;
    copingNodeId?: number;
}) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { nodeId = null } = useParams();
    const isNodeModal = useSelector<any>((state) => state.ModalReducer.isNodeModal) as boolean;

    const [subjectData, setSubjectData] = useState<LevelInterface[]>();
    const [subjectGroupData, setSubjectGroupData] = useState<LevelInterface[]>();
    const [targets, setTargets] = useState<TargetItem[]>([]);
    const [classes, setClasses] = useState<ClassItem[]>([]);
    const [settingsData, setSettingsData] = useState<NodeSettings | null>(null);
    const [nodeData, setNodeData] = useState<NodeType>(node || {});
    const [phases, setPhases] = useState<string[]>([]);
    const [groups, setGroups] = useState<string[]>([]);

    const handleClose = () => {
        dispatch({ type: ModalsConstants.IS_NODE_MODAL, payload: false });
        if (handleNodeCancel) {
            handleNodeCancel();
        }
    };

    const handleChange = <T extends keyof NodeType>(key: T, value: NodeType[T]) => {
        setNodeData((prev) => ({
            ...prev,
            [key]: value,
        }));
    };

    const handleNodeCreate = async () => {
        if (!nodeData.level1Id) return toast.error('Level1 Id is required');
        const isInvalid = [
            nodeData.branding,
            nodeData.category,
            nodeData.class,
            nodeData.group,
            nodeData.target,
            nodeData.itemType,
        ].every((e) => !e);
        if (isInvalid) return toast.error('Unsufficient data');
        const subject = subjectData?.find((itm) => itm.id == nodeData.level1Id)!.name;
        const internalName = `${nodeData.class || ' '} ${nodeData.target || ''} ${subject || ''} ${
            nodeData.category || ''
        } ${nodeData.itemType || ''} ${nodeData.branding || ''} ${nodeData.phase || ''} ${
            nodeData.group || ''
        }`.trim();

        nodeService
            .createNode({ ...nodeData, internalName })
            .then((res) => {
                if (res.status == 'success') {
                    handleClose();
                    toast.success(res.message);
                    navigate(FRONTEND_URLS.CONTENTS.EDIT_NODE + '/' + res.data.id);
                } else toast.error(res.message);
            })
            .catch((err) => toast.error(err));
    };

    const handleSelectedSubjectGroupId = (event: React.ChangeEvent<HTMLSelectElement>) => {
        if (node)
            dispatch({
                type: ContentConstants.SELECTED_SUBJECT_GROUP_ID,
                payload: event.target.value,
            });
    };

    const handleNodeEdit = async () => {
        if (node && nodeId) {
            if (!nodeData.level1Id) return toast.error('Subject is required');
            const subject = subjectData?.find((itm) => itm.id == nodeData.level1Id)!.name;
            const internalName = [
                nodeData.class,
                nodeData.target,
                subject,
                nodeData.category,
                nodeData.itemType,
                nodeData.branding,
                nodeData.phase,
                nodeData.group,
                nodeData.targetYear,
            ]
                .filter((value) => value && value.trim() !== '') // Exclude empty or undefined values
                .join(' '); // Join with a space

            const payload = {
                ...(node.level0Id != nodeData.level0Id && { level0Id: nodeData.level0Id }),
                ...(node.level1Id != nodeData.level1Id && { level1Id: nodeData.level1Id }),
                ...(node.class != nodeData.class && { class: nodeData.class }),
                ...(node.target != nodeData.target && { target: nodeData.target }),
                ...(node.targetYear != nodeData.targetYear && {
                    targetYear: nodeData.targetYear,
                }),
                ...(node.category != nodeData.category && { category: nodeData.category }),
                ...(node.itemType != nodeData.itemType && { itemType: nodeData.itemType }),
                ...(node.branding != nodeData.branding && { branding: nodeData.branding }),
                ...(node.phase != nodeData.phase && { phase: nodeData.phase }),
                ...(node?.group !== nodeData.group && { group: nodeData.group }),
            };

            dispatch({ type: ContentConstants.NODE_NAME, payload: internalName });
            nodeService
                .updateNode(+nodeId, { ...payload, internalName })
                .then((res) => {
                    if (res.status == 'success') {
                        handleClose();
                        toast.success(res.message);
                    } else toast.error(res.message);
                })
                .catch(toast.error);
        }
    };
    const handleSelectedSubjectId = (id: number) => {
        if (node)
            dispatch({
                type: ContentConstants.SELECTED_SUBJECT_ID,
                payload: id,
            });
    };

    const handleCopyNode = async () => {
        try {
            // Fetch node content
            const fetchContentPromise = axiosClient.get(`/nodes/${copingNodeId}/content`);
            const content = await toast.promise(fetchContentPromise, {
                loading: 'Fetching node content...',
                success: 'Node content fetched successfully.',
                error: 'Failed to fetch node content. Please try again.',
            });
            const contentResponse = content.data;

            if (contentResponse.status === 'success') {
                const nodeContents = contentResponse.data.contents;

                // Filter and prepare node data for creation
                const filteredNodeData = Object.fromEntries(
                    Object.entries(nodeData).filter(([key, value]) => value !== null)
                );
                const subject = subjectData?.find((itm) => itm.id == nodeData.level1Id)!.name;

                const internalName = [
                    nodeData.class,
                    nodeData.target,
                    subject,
                    nodeData.category,
                    nodeData.itemType,
                    nodeData.branding,
                    nodeData.phase,
                    nodeData.group,
                    nodeData.targetYear,
                ]
                    .filter((value) => value && value.trim() !== '') // Exclude empty or undefined values
                    .join(' '); // Join with a space
                // Create a new node
                const createNodePromise = nodeService.createNode({
                    ...filteredNodeData,
                    createdOn: undefined,
                    level1Name: undefined,
                    level0Name: undefined,
                    id: undefined,
                    internalName,
                });

                const createNodeResponse = await toast.promise(createNodePromise, {
                    loading: 'Creating a new node...',
                    success: (res) =>
                        `${res.message}: Node ID ${res.data.id} created successfully.`,
                    error: 'Failed to create a new node. Please try again.',
                });

                if (createNodeResponse.status === 'success') {
                    const newNodeId = createNodeResponse.data.id;

                    try {
                        // Show a single loading toast
                        const copyPromise = toast.promise(
                            (async () => {
                                // Execute all copy requests in parallel
                                await Promise.all(
                                    nodeContents.map((element: any) =>
                                        axiosClient.post(`/nodes/${newNodeId}/content`, {
                                            contentId: element.id,
                                            order: element.order,
                                        })
                                    )
                                );
                            })(),
                            {
                                loading: 'Copying contents...',
                                success: 'All contents copied successfully.',
                                error: 'Failed to copy some or all contents.',
                            }
                        );

                        await copyPromise;

                        navigate(FRONTEND_URLS.CONTENTS.EDIT_NODE + '/' + newNodeId);
                    } catch (error) {
                        console.error('Error copying content:', error);
                        // Optionally, you can handle individual errors here or add additional logs.
                    }
                } else {
                    toast.error(createNodeResponse.message);
                }
            }
        } catch (error) {
            console.error(error);
            toast.error('An unexpected error occurred. Please try again.');
        }
    };

    const handleSubmit = async () => {
        if (isCopyNode) {
            if (!copingNodeId) {
                return toast.error('copying node id is not find.');
            }
            handleCopyNode();
        } else {
            if (node && nodeId) handleNodeEdit();
            else handleNodeCreate();
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [
                    subjectGroupsResponse,
                    subjectResponse,
                    settingsResponse,
                    targetResponse,
                    classResponse,
                    courseResponse,
                ] = await Promise.all([
                    axiosClient.get(BACKEND_URLS.LEVEL + 0),
                    axiosClient.get(BACKEND_URLS.LEVEL + 1),
                    generalService.getSettings(['categories', 'item_types', 'course_brandings']),
                    targetService.getAll(),
                    studentClassService.getAll(),
                    courseService.getAllCourses(),
                ]);
                if (targetResponse.status == 'success') setTargets(targetResponse.data.targets);
                if (classResponse.status == 'success') setClasses(classResponse.data.classes);
                if (settingsResponse.status == 'success') setSettingsData(settingsResponse.data);
                if (courseResponse.status === 'success') {
                    const p = new Set<string>();
                    const g = new Set<string>();
                    courseResponse.data.courses.forEach((course) => {
                        const phase = course.phase;
                        const group = course.group;
                        if (phase) p.add(phase);
                        if (group) g.add(group);
                    });
                    setPhases([...p]);
                    setGroups([...g]);
                }
                setSubjectGroupData(subjectGroupsResponse.data.data.level);
                setSubjectData(subjectResponse.data.data.level);
            } catch (error: any) {
                toast.error(error.message);
            }
        };

        if (node) {
            setNodeData(node);
        } else {
            setNodeData({});
        }

        fetchData();
    }, [isNodeModal]);

    const targetYears = Array.from({ length: 5 }, (_, i) => new Date().getFullYear() + i + 0);

    return (
        <Modal
            show={isNodeModal}
            onHide={handleClose}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            keyboard={false}
            size="xl"
        >
            <Modal.Body>
                <Form.Group className="m-2" controlId="exampleForm.ControlTextarea1">
                    <Row className="mb-2">
                        {subjectGroupData && (
                            <>
                                <Col xs={12} md={2} className="d-flex align-items-center">
                                    <label>Subject Group</label>
                                </Col>
                                <Col xs={12} md={4}>
                                    <Form.Select
                                        value={nodeData?.level0Id}
                                        onChange={(e) => {
                                            handleChange('level0Id', +e.target.value || undefined);
                                            handleSelectedSubjectGroupId(e);
                                        }}
                                    >
                                        <option value={undefined}>------</option>
                                        {subjectGroupData.map((sub, index) => (
                                            <option key={index} value={sub.id}>
                                                {sub.internalName}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Col>
                            </>
                        )}

                        {subjectData && (
                            <>
                                <Col xs={12} md={2} className="d-flex align-items-center">
                                    <label>Subject</label>
                                </Col>
                                <Col xs={12} md={4}>
                                    <Select
                                        options={subjectData.map((itm) => ({
                                            label: itm.internalName,
                                            value: itm.id,
                                        }))}
                                        isSearchable
                                        isClearable
                                        onChange={(e) => {
                                            if (e) {
                                                handleChange('level1Id', e?.value);
                                                handleSelectedSubjectId(+e?.value);
                                            }
                                        }}
                                    />
                                </Col>
                            </>
                        )}
                    </Row>

                    <Row className="mb-2 ">
                        <Col xs={12} md={2} className="d-flex align-items-center">
                            <label>Class</label>
                        </Col>
                        <Col xs={12} md={4}>
                            <Form.Select
                                value={nodeData?.class || ''}
                                onChange={(e) => handleChange('class', e.target.value)}
                            >
                                <option value="">-----</option>
                                {classes.map((cls, index) => (
                                    <option key={index} value={cls.name}>
                                        {cls.name}
                                    </option>
                                ))}
                            </Form.Select>
                        </Col>
                        <Col xs={12} md={2} className="d-flex align-items-center">
                            <label>Exam</label>
                        </Col>
                        <Col xs={12} md={4}>
                            <Form.Select
                                value={nodeData?.target}
                                onChange={(e) => handleChange('target', e.target.value)}
                            >
                                <option value="">-----</option>
                                {targets.map((target, index) => (
                                    <option key={index} value={target.name}>
                                        {target.name}
                                    </option>
                                ))}
                            </Form.Select>
                        </Col>
                    </Row>

                    <Row className="mb-2 ">
                        <Col xs={12} md={2} className="d-flex align-items-center">
                            <label>Category</label>
                        </Col>
                        <Col xs={12} md={4}>
                            <Form.Select
                                value={nodeData?.category}
                                onChange={(e) => handleChange('category', e.target.value)}
                            >
                                <option value="">-----</option>
                                {settingsData?.categories?.map((category, index) => (
                                    <option key={index} value={category}>
                                        {category}
                                    </option>
                                ))}
                            </Form.Select>
                        </Col>
                        <Col xs={12} md={2} className="d-flex align-items-center">
                            <label>Item Type</label>
                        </Col>
                        <Col xs={12} md={4}>
                            <Form.Select
                                value={nodeData?.itemType}
                                onChange={(e) => handleChange('itemType', e.target.value)}
                            >
                                <option value="">-----</option>
                                {settingsData?.item_types?.map((type, index) => (
                                    <option key={index} value={type}>
                                        {type}
                                    </option>
                                ))}
                            </Form.Select>
                        </Col>
                    </Row>

                    <Row className="mb-2 ">
                        <Col xs={12} md={2} className="d-flex align-items-center">
                            <label>Branding</label>
                        </Col>
                        <Col xs={12} md={4}>
                            <Form.Select
                                value={nodeData?.branding}
                                onChange={(e) => handleChange('branding', e.target.value)}
                            >
                                <option value="">-----</option>
                                {settingsData?.course_brandings?.map((branding, index) => (
                                    <option key={index} value={branding}>
                                        {branding}
                                    </option>
                                ))}
                            </Form.Select>
                        </Col>
                        <Col xs={12} md={2} className="d-flex align-items-center">
                            <label>Target Year</label>
                        </Col>
                        <Col xs={12} md={4}>
                            <Form.Select
                                value={nodeData?.targetYear}
                                onChange={(e) => handleChange('targetYear', e.target.value)}
                            >
                                <option value="">-----</option>
                                {targetYears?.map((year, index) => (
                                    <option key={index} value={year.toString()}>
                                        {year}
                                    </option>
                                ))}
                            </Form.Select>
                        </Col>
                    </Row>

                    <Row className="mb-2 ">
                        <Col xs={12} md={2} className="d-flex align-items-center">
                            <label>Phase</label>
                        </Col>
                        <Col xs={12} md={4}>
                            <Form.Select
                                value={nodeData?.phase}
                                onChange={(e) => handleChange('phase', e.target.value)}
                            >
                                <option value="">-----</option>
                                {phases.map((phase, index) => (
                                    <option key={index} value={phase}>
                                        {phase}
                                    </option>
                                ))}
                            </Form.Select>
                        </Col>
                        <Col xs={12} md={2} className="d-flex align-items-center">
                            <label>Group</label>
                        </Col>
                        <Col xs={12} md={4}>
                            <Form.Select
                                value={nodeData?.group}
                                onChange={(e) => handleChange('group', e.target.value)}
                            >
                                <option value="">-----</option>
                                {groups.map((branding, index) => (
                                    <option key={index} value={branding}>
                                        {branding}
                                    </option>
                                ))}
                            </Form.Select>
                        </Col>
                    </Row>
                </Form.Group>

                <div className="d-flex justify-content-between m-3">
                    <Button variant="danger" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit}>
                        {isCopyNode ? 'Copy Node' : node ? 'Edit' : 'Save'}
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default CreateNode;
