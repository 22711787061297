import React from 'react'
import { Outlet } from 'react-router-dom';

function User() {
    // const session = (await getServerAuthSession()) as Session;
    // const user = session.user;
    return (
      <>
        <script src="https://player.vimeo.com/api/player.js" />
        <script src="https://player.vdocipher.com/v2/api.js" />
        <Outlet />
        {/* <RootProvider user={user}>{children}</RootProvider>; */}
      </>
    );
}

export default User