import Insights from '../../components/StudentStatistics/Insights'

function StudentParentReport() {

    return (
        <div className='container pt-3'>
            <h2 className="mb-1">Time Spent on eSaral App </h2>
            <Insights />
        </div>
    )
}

export default StudentParentReport