import { useEffect, useState } from 'react';
import { RoleResponse } from '../../types/role.types';
import roleService from '../../services/ApiServices/role-service';
import userService from '../../services/user-service';
import toast from 'react-hot-toast';

const AssignRoles = ({ id }: { id: number }) => {
    const [roles, setRoles] = useState<RoleResponse[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [userRoleIds, setUserRoleIds] = useState<number[]>([]);

    useEffect(() => {
        if (id) {
            setIsLoading(true);
            userService
                .getUserRoles(id)
                .then((res) => {
                    if (res.status === 'success') {
                        setUserRoleIds(res.data.roles.map((role) => role.roleId));
                    }
                })
                .finally(() => setIsLoading(false));
        }

        roleService.getAllRoles().then((res) => {
            if (res.status === 'success') {
                setRoles(res.data.roles);
            }
        });
    }, [id]);

    const handleAssignRole = (roleId: number) => {
        if (roleId && id) {
            userService
                .assignRole(id, roleId)
                .then((res) => {
                    if (res.status === 'success') {
                        setUserRoleIds((prev) => [...prev, roleId]);
                        toast.success(res.message);
                    } else toast.error(res.message);
                })
                .catch(() => toast.error('Failed to assign role'));
        }
    };

    const handleRemoveRole = (roleId: number) => {
        if (roleId && id) {
            userService
                .removeRole(id, roleId)
                .then((res) => {
                    if (res.status === 'success') {
                        setUserRoleIds((prev) => prev.filter((role) => role !== roleId));
                        toast.success(res.message);
                    } else toast.error(res.message);
                })
                .catch(() => toast.error('Failed to remove role'));
        }
    };

    const handleCheckboxChange = (roleId: number, isChecked: boolean) => {
        if (isChecked) {
            handleAssignRole(roleId);
        } else {
            handleRemoveRole(roleId);
        }
    };

    return (
        <div>
            {isLoading ? (
                <p>Loading roles...</p>
            ) : (
                <ul className="list-group">
                    {roles.map((role) => (
                        <li key={role.id} className="list-group-item d-flex align-items-center">
                            <input
                                type="checkbox"
                                checked={userRoleIds.includes(role.id)}
                                onChange={(e) => handleCheckboxChange(role.id, e.target.checked)}
                                className="form-check-input me-2"
                            />
                            <span>{role.name}</span>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default AssignRoles;
