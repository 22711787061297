import { NavBarConstants } from "./nav-bar-constants"


export interface InitialStateType {
    isNavbar: boolean
}


const initialState: InitialStateType = {
    isNavbar: false
}



const NavBarReducer = (state:InitialStateType =initialState, action: any) => {

    switch (action.type) {
        case NavBarConstants.IS_NAVBAR:
            return {
                ...state, 
                isNavbar: action.payload
            }
    
        default:
            return state
    }

}



export default NavBarReducer