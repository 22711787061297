import ApiService, { ApiResponse } from "./ApiServices/api-service";

class ContentServices extends ApiService {
    constructor() {
        super('/content')
    }

    sheduleContent(data: {
        setId: number,
        contentId: number,
        availableFrom: Date | null,
        availableTill: Date | null
    }): ApiResponse {
        return this.postData('/schedule', data)
    }

    updateSheduleContent(data: {
        setId: number,
        contentId: number,
        availableFrom: Date | null,
        availableTill: Date | null
    }, scheduleId: number): ApiResponse {
        return this.putData(`/schedule/${scheduleId}`, data)
    }

    delteSheduleContent(scheduleId: number): ApiResponse {
        return this.deleteData(`/schedule/${scheduleId}`)
    }
}

export default new ContentServices()