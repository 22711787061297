import { BACKEND_URLS } from '../config';
import ApiService, { ApiResponse } from './ApiServices/api-service';

class EnrollmentMentorProcessesServices extends ApiService {
    constructor() {
        super(BACKEND_URLS.ENROLLMENT);
    }

    getEnrollmentInformation(enrollmentId: string): ApiResponse {
        return this.getData('/' + enrollmentId);
    }

    createTimeTables({
        enrollmentId,
        timetableData,
    }: {
        enrollmentId: string;
        timetableData: {
            timeTableUrl: string;
            timeTableName: string;
            fileName: string;
        };
    }): ApiResponse {
        return this.postData(`/${enrollmentId}/timetable`, timetableData);
    }

    getTimeTables({ enrollmentId }: { enrollmentId: string }): ApiResponse {
        return this.getData(`/${enrollmentId}/timetable`);
    }

    deleteTimeTable({
        enrollmentId,
        timetableId,
    }: {
        enrollmentId: string;
        timetableId: number;
    }): ApiResponse {
        return this.deleteData(`/${enrollmentId}/timetable/${timetableId}`);
    }

    updateProcssess({
        enrollProcessId,
        IsCompleted,
    }: {
        enrollProcessId: number;
        IsCompleted: boolean;
    }) {
        return this.putData(`/update-process/${enrollProcessId}`, {
            completed: IsCompleted,
        });
    }

    assignMentor({
        enrollmentId,
        mentorId,
        isPrimary,
    }: {
        enrollmentId: number;
        mentorId: string;
        isPrimary: boolean;
    }) {
        return this.postData(`/${enrollmentId}/assign-mentor`, {
            mentorId,
            isPrimary,
        });
    }

    removeMentor(enrollmentId: number, mentorId: number) {
        return this.deleteData(`/${enrollmentId}/assign-mentor/${mentorId}`);
    }

    getAssignMentor({ enrollmentId }: { enrollmentId: number }) {
        return this.getData(`/${enrollmentId}/assign-mentor`);
    }
}

export default new EnrollmentMentorProcessesServices();
