import { BACKEND_URLS } from '../config';
import ApiService, { ApiResponse } from './ApiServices/api-service';

class DemoCoursesServcies extends ApiService {
    constructor() {
        super(BACKEND_URLS.COURSES.path);
    }

    getCoursesData(): ApiResponse {
        return this.getData(BACKEND_URLS.COURSES.children.CLASS_TARGET);
    }

    updateIsDemoCourse({ courseId, isDemo }: { courseId: number; isDemo: boolean }): ApiResponse {
        return this.putData(BACKEND_URLS.COURSES.children.CLASS_TARGET + `/${courseId}`, {
            isDemo,
        });
    }
}

export default new DemoCoursesServcies();
