import { CollectionItem } from '../../types/collection-types';
import ApiService, { ApiResponse } from './api-service';

class CollectionService extends ApiService {
    constructor() {
        super('/collections');
    }

    getAllCollections(): ApiResponse<{ collections: CollectionItem[] }> {
        return this.getData('/');
    }
    getCollection(id: number): ApiResponse<{ collection: CollectionItem }> {
        return this.getData(`/${id}`);
    }
    createNewCollection(data: {
        name: string;
        nodeIds?: number[];
    }): ApiResponse<{ collection: Omit<CollectionItem, 'nodeCollections'> }> {
        return this.postData('/', data);
    }
    updateCollection(id: number, data: { name: string }) {
        return this.putData(`/${id}`, data);
    }
    removeCollection(id: number) {
        return this.deleteData(`/${id}`);
    }
    addNodesToCollection(id: number, nodeIds: number[]) {
        return this.postData(`/${id}/nodes`, { nodeIds });
    }
    removeNodesFromCollection(id: number, nodeIds: number[]) {
        return this.deleteData(`/${id}/nodes`, { nodeIds });
    }
}

export default new CollectionService();
