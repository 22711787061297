import React from "react";
import loader from "../../assets/images/lottie/esaral_loader.json";
import Lottie from "lottie-react";


function ESaralLoader() {
  return (
    <div
      className="Background "
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        zIndex: "10000",
        padding: "15%",
        bottom: 0,
        top: 0,
        overflow: "hidden",
      }}
    >
      <Lottie
        style={{
          height: "100%",
          overflow: "hidden",
        }}
        animationData={loader}
        loop={true}
      />
    </div>
  );
}

export default ESaralLoader;
