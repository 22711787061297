import { ModalsConstants } from "./models-constants"


export interface InitialStateType {
    isModal: boolean,
    isNodeModal: boolean,
    isScheduleModal: Boolean,
    isSettingModal: boolean,
}


const initialState: InitialStateType = {
    isModal: false,
    isNodeModal: false,
    isScheduleModal: false,
    isSettingModal: false
}



const ModalReducer = (state: InitialStateType = initialState, action: any) => {

    switch (action.type) {
        case ModalsConstants.IS_Modal:
            return {
                ...state,
                isModal: action.payload
            }

        case ModalsConstants.IS_NODE_MODAL:
            return {
                ...state,
                isNodeModal: action.payload
            }

        case ModalsConstants.IS_SCHEDULE_MODAL:
            return {
                ...state,
                isScheduleModal: action.payload
            }

        case ModalsConstants.IS_SETTING_MODAL:
            return {
                ...state,
                isSettingModal: action.payload
            }

        default:
            return state
    }

}



export default ModalReducer