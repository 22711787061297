import { Menu, Permission, UserApiData } from "../../../types/types";

export type UserReducerState = {
  common_permissions: Permission[];
  menu: Menu;
  user?: UserApiData;
};

const initialState: UserReducerState = {
  common_permissions: [],
  menu: {},
};

export default function userReducer(state = initialState, action: any) {
  switch (action.type) {
    case "SET_USER_PERMISSION":
      return action.user;
    case "CLEAR_USER_PERMISSION":
      return initialState;
    default:
      return state;
  }
}
