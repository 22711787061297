import { TargetItem } from '../../types/course.types';
import ApiService, { ApiResponse } from './api-service';

class TargetService extends ApiService {
    constructor() {
        super('/targets');
    }

    getAll(): ApiResponse<{ targets: TargetItem[] }> {
        return this.getData('');
    }
    getTarget(id: number): ApiResponse {
        return this.getData(`/${id}`);
    }

    attachTestimonial(targetId: number, testimonialId: number) {
        return this.postData(`/${targetId}/testimonial`, { testimonialId });
    }

    removeTestimonial(targetId: number, testimonialId: number) {
        return this.deleteData(`/${targetId}/testimonial/${testimonialId}`);
    }
}

export default new TargetService();
