import { Dispatch } from "redux";
import { URLS } from "../config";
import { mediaUploadConstants } from "../Redux/ReducerConstants/mediaUploadConstants";
import { QuestionItem } from "../types/quizTypes";
import { axiosClient } from "./axiosClient";
import toast from "react-hot-toast";

export const quizServices = {
    attachQueVideoSol,
    getRelatedQuizes,
    getQuizDetails,
    getQuizQuestions,
    initQuizSolutionUploader,
    getQuestion,
    fatchQuizAttemptDetails,
    download_quiz_report,
};

async function getRelatedQuizes(quizId: any) {
    try {
        const res = await axiosClient(
            `/v2/general/get-related-quizes?id=${quizId}`
        );
        const data = res.data;
        if (data.success) {
            return data.data;
        }
    } catch (error) {
        throw error;
    }
}
async function attachQueVideoSol(
    questionId: any,
    url: any,
    faculty_id: any,
    edit_required: any
) {
    const data = {
        video_url: url,
        faculty_id: faculty_id,
        question_id: questionId,
        edit_required: edit_required,
    };
    return axiosClient
        .post(URLS.ATTACH_QUE_VIDEO_SOL, data)
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        });
}

async function getQuizDetails(quizId: any) {
    return axiosClient(`/v2/quizes/crud-quiz?quiz_id=${quizId}`)
        .then((res) => {
            if (res.data.success) {
                return res.data.data;
            }
        })
        .catch((err) => {
            throw err;
        });
}

async function getQuizQuestions(quizId: any) {
    return axiosClient(`/v2/quizes/get-quiz-question-details?quiz_id=${quizId}`)
        .then((res) => {
            if (res.data.success) {
                return res.data.data;
            }
        })
        .catch((err) => {
            throw err;
        });
}

function initQuizSolutionUploader(data: QuestionItem[], dispatch: Dispatch) {
    for (let i = 0; i < data.length; i++) {
        const que = data[i];

        if (que.videoSolutions && que.videoSolutions.length) {
            for (let arr of que.videoSolutions) {
                dispatch({
                    type: mediaUploadConstants.MEDIA_UPLOAD_START,
                    data: {
                        questionId: que.questionId,
                        qOrder: que.qOrder,
                        status: "COMPLETED",
                        percent: 100,
                        assetId: arr.content.assetId,
                        editRequired: arr.editRequired,
                    },
                });
            }
        } else {
            dispatch({
                type: mediaUploadConstants.MEDIA_UPLOAD_START,
                data: {
                    questionId: que.questionId,
                    qOrder: que.qOrder,
                    status: "NOT_STARTED",
                    percent: 0,
                    assetId: "",
                },
            });
        }
    }
}

async function getQuestion(questionId: any) {
    const data = {
        question_id: questionId,
    };
    return axiosClient
        .post(URLS.GET_QUESTION, data)
        .then((res) => {
            if (res.data.success) return res.data.data;
            else throw "api errors: " + res.data.errors.join(" - ");
        })
        .catch((error) => {
            throw error;
        });
}

async function fatchQuizAttemptDetails(
    attempted_from: any,
    attempted_to: any,
    search: any,
    page = 1,
    limit = 20,
    courses = [],
    quizes = ""
) {
    return axiosClient
        .get(
            `${URLS.GET_QUIZ_ATTEMPTS}?attempted_from=${attempted_from}&attempted_to=${attempted_to}&search=${search}&page=${page}&limit=${limit}&courses=${courses}&quizes=${quizes}`
        )
        .then((res) => {
            if (res.data.success) return res.data;
            else throw "api errors: " + res.data.errors.join(" - ");
        })
        .catch((error) => {
            throw error;
        });
}

async function download_quiz_report(
    attempted_from: any,
    attempted_to: any,
    search = "",
    courses = [],
    quizes = ""
) {
    try {
        // Send a GET request to the Django backend to generate the CSV file
        const response = await fetch(
            `${URLS.QUIZ_REPORT_CSV}?attempted_from=${attempted_from}&attempted_to=${attempted_to}&search=${search}&courses=${courses}&quizes=${quizes}`
        );

        // Extract filename from Content-Disposition header
        // const filename = response.headers.get('Content-Disposition').split('filename=')[1];
        const filename = "quiz_report.csv";

        // Convert the response to Blob object
        const blob = await response.blob();

        // Create a temporary link element to trigger the download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);

        // Trigger the download
        document.body.appendChild(link);
        link.click();

        // Clean up
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        return;
    } catch (error: any) {
        toast.error("Error downloading CSV:", error.message);
    }
}