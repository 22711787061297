import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SolveCast from "../../components/SolveCast/SolveCast";
import QuizService from "../../services/ApiServices/QuizService";
import { QuestionItem, QuizItem } from "../../types/quizTypes";


export default function SolveCastContainer() {
  const { quizId } = useParams<{ quizId: string }>();
  const [quizDetails, setQuizDetails] = useState<QuizItem>();
  const [questions, setQuestions] = useState<QuestionItem[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!quizId) return;

    const fetchQuizData = async () => {
      try {
        const [quizDetailsRes, questionsRes] = await Promise.all([
          QuizService.getById(+quizId),
          QuizService.getQuestions(+quizId),
        ]);

        setQuizDetails(quizDetailsRes.data);
        setQuestions(questionsRes.data);
      } catch (error) {
        console.error("Failed to fetch quiz data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchQuizData();
  }, [quizId]);

  if (loading) return <div>Loading...</div>;

  if (!quizId) return <div>Quiz not found.</div>

  if (!quizDetails) return <div>Quiz not found.</div>

  return (
    <SolveCast
      quizId={+quizId}
      quizDetails={quizDetails}
      questions={questions}
    />
  );
}
