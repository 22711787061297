export type Permission = {
  id: number;
  slug: string;
  name: string;
  role: string | null;
  role_name: string | null;
};

export type MenuItem = {
  id: number;
  item_name: string;
  url: string;
  children?: {
    [key: string]: MenuItem;
  };
  permissions?: Permission[];
};

export type Menu = {
  [key: string]: MenuItem;
};

export type NotificationMetaData = {
  studentClass: StudentClass[];
  target: Target[];
  courses: Course[];
};

type StudentClass = {
  id: number;
  class_name: string;
};
type Target = {
  id: number;
  name: string;
};

type Course = {
  course_id: number;
  course__name: string;
  target__name: string;
};

export type CoursePhase = {
  id: number;
  course_name: string;
  course: string;
  phase_name: string;
  phase: string;
  current_subcourse_id: number;
  group_name: string;
};

export type SelectItem = {
  label: string;
  value: string | number;
};

export type EnrolledStud = {
  enrollment_id: number;
  student_name: string;
  student_number: string;
  email: string;
  course_name: string;
  course_id: number;
  phase_id: number;
  phase_name: string;
  enrollment_date: string;
  total_usage: number;
  current_end_data: string;
  last_call: null | string;
  next_call: null | string;
  username: string;
  mentor: string | null;
  mentor_id: number | null;
  mentorship_start_date: string | null;
  mentorship_end_date: string | null;
  academic_end_date: string;
  student_id: number;
  total_processes: number;
  completed_processes: number;
  incompleted_processes: number;
};

export type CurrentUser = {
  first_name: string;
  username: string;
  id: number;
  is_active: boolean;
  is_staff: boolean;
  is_superuser: boolean;
  tokens: string;
} | null;

export type ClassItem = {
  id: number;
  name: string;
  thumbnail: string;
};

export type ContactForm = {
  contact_person_name: string;
  contact_type: "EMAIL" | "WHATSAPP" | "PHONE";
  contact_person: string;
  contact_value: string;
};
export type Address = {
  address1: string;
  address2: string | null;
  city: string;
  country: null | string;
  id: number;
  is_primary: boolean;
  pincode: string;
  state: string;
};

export type ContactDetails = {
  addresses: Address[];
  contacts: ContactForm[];
};

export type UserApiData = {
  name: string;
  username: string;
  email: string;
  country_code: string;
  phone_number: string;
  user_type: string;
  gender: "MALE" | "FEMALE" | "OTHER";
  utm_source: null | string;
  primary_subcourse: number;
  primary_phase_id: number;
  active_target_course: number;
  firebase_notification_token: string | null;
  student_class: {
    id: number | string;
    name: string;
    thumbnail: string | null;
  };
  app_version: string;
  enrollments: any;
};

export type SearchUser = {
  id: number;
  name: string;
  username: string;
  role: null | string;
  date_joined: number;
};



declare global {
  interface Window {
    ReactNativeWebView?: {
      postMessage: (message: string) => void;
      DateTime: any;
    };
    VdoPlayer: {
      getInstance: (ref: any) => any; // Adjust the types according to the actual API
    };
  }
}


export enum contenType {
  LEARN = "LEARN",
  PRACTICE = "PRACTICE",
  TEST = 'TEST',
  REVISE = "REVISE",
  OTHER = "OTHER"
}

export enum VideoSource {
  VIMEO = 'VIMEO',
  VDOCIPHER = 'VDOCIPHER',
  VDOCIPHER_SECURE = 'VDOCIPHER_SECURE',
  // YOUTUBE='YOUTUBE'
}


export enum AssetType {
  VIDEO = "VIDEO",
  PDF = "PDF",
  QUIZ = "QUIZ",
  SESSION = 'SESSION'
}

export interface ZoomSession {
  id: number;
  internalName: string;
  sessionId: string;
  password: string;
  zoomInstanceId: number;
  startTime: string;
  endTime: string;
  actualStartTime: string | null;
  actualEndTime: string | null;
  isRecorded: boolean;
  status: string | null;
  studentCount: number;
  isDeleted: boolean;
  createdById: number;
  createdOn: string;
  updatedOn: string;
}



export interface QuizImportInterface {
  "spayee:resource": {
    "spayee:SectionGrouping": {
      sectionName: string;
      maxMarks: number;
      maxQuestions: number;
      actualMaxQuestions: number;
      markPerQuestion: number;
      penalty: number;
    }[];
    "spayee:allowedAttempts": string;
    "spayee:anotherLang": string;
    "spayee:arrangeQuestionsByTopic": boolean;
    "spayee:basicCalc": boolean;
    "spayee:courseAssetType": string;
    "spayee:forceAttemptInOneGo": boolean;
    "spayee:instruction": string;
    "spayee:scientificCalc": boolean;
    "spayee:sectionalTiming": boolean;
    "spayee:showCustomMessages": boolean;
    "spayee:showDefaultInstructions": boolean;
    "spayee:showInstructions": boolean;
    "spayee:showRank": boolean;
    "spayee:showSectionGrouping": boolean;
    "spayee:showSolutionsAfter": string;
    "spayee:showSolutionsToLearner": boolean;
    "spayee:tags": string[];
    "spayee:title": string;
    "spayee:totalQuestions": number;
    "spayee:type": string;
    "spayee:questionIds": {
      [key: string]: QuestionData | GroupedQuestionData
    };
  };
}

// Base question data (single question)
export interface QuestionData {
  count: number;
  sequence: number;
  section?: string;
  sectionId?: string;
  data: {
    id: string;
    mark: number;
    penalty: number;
    searchtext: string;
    "spayee:objective": string;
    subject: string;
    text: string;
    type: "objective" | "multichoice" | "numerical" | "match";
    _id: string;
    tag: string[];
    options: {
      option: {
        id: string | number;
        content: string;
      }[];
    };
    answer: {
      correctOptions: {
        option: (string | number)[];
      };
      solution: {
        text: string;
      };
      questionId: string;
    };
  };
}

// Grouped question data (questions grouped together with additional group information)
export interface GroupedQuestionData {
  count: number;
  sequence: number;
  section?: string;
  sectionId?: string;
  data: {
    questiongroup: true;
    groupinstruction: string;
    grouptext: string;
    question: {
      id: string;
      "spayee:objective": string;
      type: "objective" | "multichoice" | "numerical" | "match";
      tag: string[];
      text: string;
      searchtext: string;
      mark: string;
      penalty: string;
      options: {
        option: {
          id: string | number;
          content: string;
        }[];
      };
    }[];
    answer: {
      correctOptions: {
        option: (string | number)[];
      };
      solution: {
        text: string;
      };
      questionId: string;
    }[];
    lastEditedBy: string;
  };
}



export interface quizResponseInterface {
  id: number,
  createdById: number,
  quizType: string,
  internalName: string,
  showInstructions: boolean,
  instructions: string,
  timeLimit: number,
  minTimeSubmission: number,
  totalAttempts: number,
  showSolutions: boolean,
  showRank: boolean,
  showReport: boolean,
  postSubmissionMessage: string,
  language: string,
  isDeleted: false,
  level1Id: number | null,
  level2Id: number | null,
  createdOn: string,
  updatedOn: string,
  sections: {
    id: number,
    quizId: number,
    sectionName: string,
    maxQuestions: number,
    isDeleted: boolean
  }[]
}



export type settingInterface = {
  [key: string]: string | (number | string)[] | settingInterface,
}