import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { ENDPOINT_HOST as BASE_URL, FRONTEND_URLS } from '../../config';
import toast from 'react-hot-toast';
import base64 from 'base-64';

export interface ApiResp<T = any> {
    data: T;
    status: 'success' | 'error';
    message: string;
    meta: {
        total?: number;
        limit?: number;
        page?: number;
        [key: string]: any;
    };
    errors: [];
}

export type ApiResponse<T = any> = Promise<ApiResp<T>>;

const apiClient = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

const setAuthorizationHeader = (config: any) => {
    let token = localStorage.getItem('@user') || '';
    if (window?.ReactNativeWebView) {
        token = JSON.parse(base64.decode(localStorage.getItem('@user') || ''))?.access || '';
        localStorage.setItem('@user', token);
    }

    return config;
};

apiClient.interceptors.request.use(
    // Add the Authorization header before making the request
    setAuthorizationHeader,
    (error) => {
        // Handle request errors
        return Promise.reject(error);
    }
);

apiClient.interceptors.request.use(
    (config) => {
        try {
            const user = localStorage.getItem('@user');
            if (user) {
                const parsedUser = JSON.parse(window.atob(user)) as {
                    token: string;
                    isNewUser: boolean;
                };
                if (parsedUser && parsedUser.token) {
                    config.headers.Authorization = `Bearer ${parsedUser.token}`;
                } else {
                    console.error(
                        'Token is missing or invalid. Proceeding without Authorization header.'
                    );
                }
            }
        } catch (error) {
            console.error('contact to admin');
            localStorage.removeItem('@user');
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

apiClient.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
    return config;
});
apiClient.interceptors.response.use(
    (response: AxiosResponse) => {
        const data = response?.data;
        if (data.status === 'error') {
            return data;
        }
        return data;
    },
    (error: AxiosError) => {
        if (error.response) {
            const { data, status } = error.response as any;
            if (status === 401) {
                toast.error('Not authorized');
                localStorage.clear();
                window.location.href = FRONTEND_URLS.LOGIN;
            } else if (status === 404) {
                toast.error('Resource Not found. ' + error.message);
            } else if (status === 500) {
                if (data?.message) {
                    toast.error(data.message);
                } else toast.error('Some server error...don’t know');
            } else {
                toast.error(data.message);
            }
            return Promise.resolve(error.response.data);
        } else if (error.request) {
            toast.error('Network error!');
            console.error('Network error!', error);
        } else {
            toast.error('Error: ' + error.message);
        }
        toast.error('Error config: ' + error.message);

        return Promise.resolve(error);
    }
);

class ApiService {
    private baseUrl = '';
    constructor(baseUrl: string) {
        if (!baseUrl.startsWith('http')) {
            baseUrl = `${BASE_URL}${baseUrl}`;
        }
        this.baseUrl = baseUrl;
    }

    getUrl(path: string | undefined) {
        if (!path?.startsWith('http')) {
            return `${this.baseUrl}${path}`;
        }
        return path;
    }

    getHeaders() {
        return {
            'Content-type': 'application/json; charset=UTF-8',
        };
    }

    getData(path: string, options?: any): ApiResponse {
        const headers = options?.headers || this.getHeaders();
        return apiClient.get(this.getUrl(path), { ...options, headers });
    }

    postData(path: string, data: any, options?: any): ApiResponse {
        const headers = options?.headers || this.getHeaders();

        return apiClient.post(this.getUrl(path), data, { ...options, headers });
    }

    putData(path: string, data: any, options?: any): ApiResponse {
        const headers = options?.headers || this.getHeaders();

        return apiClient.put(this.getUrl(path), data, { ...options, headers });
    }

    patchData(path: string, data: any, options?: any): ApiResponse {
        const headers = options?.headers || this.getHeaders();

        return apiClient.patch(this.getUrl(path), data, { ...options, headers });
    }

    deleteData(path: string, data?: any, options?: any): ApiResponse {
        const headers = options?.headers || this.getHeaders();

        // if (data) {
        return apiClient.delete(this.getUrl(path), {
            data,
            ...options,
            headers,
        });
        // }
    }
}

export default ApiService;
