import { useEffect, useRef } from 'react'
import { useLoaderData, useNavigate, useNavigation } from 'react-router-dom'
import { NavbarVertical } from '../components'
import { Outlet } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { useDispatch } from 'react-redux';
import { NavBarConstants } from '../Redux/nav-bar/nav-bar-constants';
import Lottie from 'lottie-react';
import Loader from '../components/loader/loader';
import TinyMceEditor from '../TinyMceEditor';
import { Editor as TinyMCEEditor } from 'tinymce';


interface dataTypes {
  name: string
}

export async function loader(): Promise<dataTypes> {
  const data = {
    name: "Ram lal"
  }
  return data
}


function Root() {

  const data = useLoaderData() as dataTypes
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(
    () => {
      const tkn = localStorage.getItem('@user')
      if (!tkn) {
        navigate('/login')
      }
    }, []
  )

  const handleShow = () => dispatch({ type: NavBarConstants.IS_NAVBAR, payload: true });


  return (
    <>
      <Loader />
      <div className='d-flex' style={{
        color: 'black'
      }}>
        <NavbarVertical />
        <div className='w-100 d-flex flex-column' >
          <div className='navbar-horizontal d-block d-md-none'>
            <GiHamburgerMenu onClick={handleShow} color='#fff' style={{
              width: '30px',
              height: '30px',
            }} />
          </div>
          <div className='p-2' style={{
            height: '100vh',
            overflow: 'auto'
          }}>
            <Outlet />
          </div>
        </div>
      </div>
    </>
  )
}

export default Root