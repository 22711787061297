import { useLoaderData, useNavigate } from 'react-router-dom';
import SetService from '../../../services/ApiServices/set-service';
import Title from '../../../components/common/title';
import MainBtn from '../../../components/btn/main-btn';
import CommonCard from '../../../components/cards/common.card';
import { SET_TYPE, SetItem } from '../../../types/setTypes';
import { FloatingLabel, Form } from 'react-bootstrap';
import { useState } from 'react';

export const setLoader = async () => {
    const resp = await SetService.getAll();
    return {
        total: resp.meta.total,
        sets: resp.data.sets,
    };
};

const Sets = () => {
    const { total, sets } = useLoaderData() as { total: number; sets: SetItem[] };
    const [setname, setSetname] = useState('');

    const navigate = useNavigate();
    const [typeFilter, setTypeFilter] = useState('');

    const filteredSets = sets
        ?.filter((c) => {
            const matchesName = c.name.toLowerCase().includes(setname.toLowerCase());
            const matchesStatus = typeFilter ? c.type === typeFilter : true;
            return matchesName && matchesStatus;
        })
        ?.sort((a, b) => {
            return new Date(b?.updatedOn ?? '').getTime() - new Date(a?.updatedOn ?? '').getTime(); // Convert to timestamp
        });

    return (
        <div className="pt-2">
            <div className="mx-4 mt-1 d-flex justify-content-between">
                <Title title="Sets" total={total} />
                <div className="d-flex align-items-center w-50 col-7 gap-3">
                    <Form.Group className="form-floating w-50">
                        <Form.Select
                            className="form-select"
                            id="statusSelect"
                            onChange={(e) => setTypeFilter(e.target.value)}
                        >
                            <option value="">All</option>
                            <option value="ACADEMIC">Academic</option>
                            <option value="NON_ACADEMIC">Mentorship</option>
                        </Form.Select>
                        <Form.Label htmlFor="statusSelect">Filter by Type</Form.Label>
                    </Form.Group>
                    <FloatingLabel
                        controlId="floatingInput"
                        label="Search for Set"
                        className="w-50"
                    >
                        <Form.Control
                            type="text"
                            placeholder="Class 10 Champ Live Session"
                            onChange={(e) => setSetname(e.target.value)}
                        />
                    </FloatingLabel>
                </div>
            </div>
            <div className="mx-4 mt-md-4">
                <div style={{ display: 'flex', flexWrap: 'wrap' }} className="gap-md-2">
                    {filteredSets.map((set) => (
                        <CommonCard
                            data={set}
                            type="set"
                            status={set.type == SET_TYPE.ACADEMIC ? set.type : 'Mentorship'}
                        />
                    ))}
                </div>
            </div>
            <MainBtn onClick={() => navigate('/contents/create-set')} message="Add new Set" />
        </div>
    );
};

export default Sets;
