import { HardwareItem } from '../../types/course.types';
import ApiService, { ApiResponse } from './api-service';

class Hardware extends ApiService {
    constructor() {
        super('/hardwares');
    }

    getAll(): ApiResponse<{ hardwares: HardwareItem[] }> {
        return this.getData('');
    }
}

export default new Hardware();
