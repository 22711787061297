import { AxiosError, AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { Button, Container, Form } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import AmountDetails from '../../../components/order/amount-details';
import CourseDetatils from '../../../components/order/course-details';
import PersonalDetails from '../../../components/order/personal-details';
import { BACKEND_URLS } from '../../../config';
import { axiosClient } from '../../../services/axiosClient';
import { reciveDateTimeFormate } from '../../../utils/helpers';
import toast from 'react-hot-toast';
import { EnrollmentType } from '../../../types/order';
import TriggerToolTip from '../../../components/tooltip/tooltip-trigger';

export enum paymentMode {
    'KOTAK' = 'KOTAK',
    'HDFC' = 'HDFC',
    'CASH' = 'CASH',
    'SBI' = 'SBI',
    'EXTERNAL_LINK' = 'EXTERNAL_LINK',
    'APP' = 'APP',
}

export enum paymentStatus {
    'PENDING' = 'PENDING',
    'COMPLETE' = 'COMPLETE',
}

export enum Action {
    Delete = 'DELETE',
    Edit = 'EDIT',
    New = 'NEW',
    Empty = '',
    NA = 'NA',
}

type offeredType = {
    serviceId: number;
    serviceName?: string;
    endDate: string;
    isOffered: boolean;
    action: Action | false;
    slug: string;
};

type orderAmountType = {
    id?: number;
    paymentAmount: string;
    mode: paymentMode | null;
    paymentDate: string;
    status: paymentStatus;
    comment: string;
    action: Action;
};

export type personalDetailsType = {
    mobileNumber: string;
    salesPerson: string;
    studentName: string;
    orderDate: string;
    state: string;
    comment: string;
};

export type shipmentHardwareType = {
    hardwareId: number;
    // qty: number
    action: Action;
};

export type courseDetailsType = {
    targetExam: string;
    // courseEndDate: string,
    course: {
        name: string;
        id: null | number;
    };
    currentValidity: string;
    services: offeredType[];
    hardwares: shipmentHardwareType[];
};

export type amountDetailsType = {
    totalAmount: string;
    paymentDetails: orderAmountType[];
};

const CreateOrderForm = () => {
    const { orderId } = useParams();
    const navigete = useNavigate();
    const [student, setStudent] = useState<{ value: any; label: string }>();
    const [salesPerson, setSalesPerson] = useState<{ value: any; label: string }>();

    const [personalDetails, setPersonalDetails] = useState<personalDetailsType>({
        mobileNumber: '',
        salesPerson: '',
        studentName: '',
        orderDate: `${reciveDateTimeFormate(new Date().toISOString())[0]}`,
        state: '',
        comment: '',
    });

    const [userId, setUserId] = useState<number>();
    const [staffId, setStaffId] = useState<number>();
    const [courseId, setCourseId] = useState<number>();

    const [enrollemntType, setEnrollmentType] = useState<EnrollmentType>();

    const [courseDetails, setCourseDetails] = useState<courseDetailsType>({
        targetExam: '',
        // courseEndDate: '',
        course: {
            name: '',
            id: null,
        },
        currentValidity: '',
        services: [],
        hardwares: [],
    });

    const [amountDetails, setAmountDetails] = useState<amountDetailsType>({
        totalAmount: '',
        paymentDetails: [],
    });

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        if (!(personalDetails.mobileNumber || userId)) {
            toast.error('Please Select Student.');
            return;
        }
        if (!staffId) {
            toast.error('Please select Sales Person.');
            return;
        }

        if (!personalDetails.orderDate) {
            toast.error("Order date cann't be empty.");
            return;
        }
        if (!personalDetails.state) {
            toast.error('Please select State.');
            return;
        }

        if (!(courseDetails.course.id || courseId)) {
            toast.error('Please Select course first.');
            return;
        }

        if (!courseDetails.currentValidity) {
            toast.error("You didn't select Course Validity date.");
            return;
        }

        const totalAmount = amountDetails.paymentDetails.reduce((total, transaction) => {
            if (transaction.action !== Action.Delete) {
                return total + parseInt(transaction.paymentAmount);
            }
            return total;
        }, 0);

        // totalAmount check that it is not less then.
        if (parseInt(amountDetails.totalAmount) !== totalAmount) {
            toast.error('you entered wrong amount. please check further.');
            return;
        }

        const submitData = {
            userId: orderId !== '0' ? userId : personalDetails.mobileNumber,
            courseId:
                orderId !== '0' && courseId === courseDetails.course.id
                    ? courseId
                    : courseDetails.course.id,
            validTill: courseDetails.currentValidity,
            staffId: staffId,
            type: 'FULL_ENROLLMENT',
            totalAmount: amountDetails.totalAmount,
            orderDate: personalDetails.orderDate,
            state: personalDetails.state,
            comment: personalDetails.comment,
            payment: amountDetails.paymentDetails
                .map((details) => {
                    if (details.action === 'DELETE' && !details.id) return;
                    return {
                        receivedOn: details.paymentDate,
                        amount: details.paymentAmount,
                        mode: details.mode,
                        status: details.status,
                        isVerified: false,
                        comment: details.comment,
                        action: details.action,
                        id: details.id,
                    };
                })
                .filter((service) => {
                    if (service !== undefined) {
                        if (service && service?.action && service.action !== 'NA') return service;
                    }
                }),
            service: courseDetails.services
                .map((service) => {
                    if (orderId === '0' && service.action !== 'DELETE') {
                        return {
                            serviceId: service.serviceId,
                            slug: service.slug,
                            // customValidTill: service.endDate,
                            customValidTill: courseDetails.currentValidity,
                            action: service.action,
                        };
                    } else if (orderId !== '0') {
                        return {
                            slug: service.slug,
                            serviceId: service.serviceId,
                            customValidTill: service.endDate,
                            action: service.action,
                        };
                    }
                })
                .filter((service) => {
                    if (service !== undefined) {
                        if (service && service?.action && service.action !== 'NA') return service;
                    }
                }),
            hardwares: courseDetails.hardwares.map((hardware) => {
                if (orderId === '0' && hardware.action !== 'DELETE') {
                    return {
                        hardwareId: hardware.hardwareId,
                        action: hardware.action,
                    };
                } else if (orderId !== '0') {
                    return {
                        hardwareId: hardware.hardwareId,
                        action: hardware.action,
                    };
                }
            }),
        };

        try {
            const url =
                orderId !== '0' ? BACKEND_URLS.ENROLLMENT + `/${orderId}` : BACKEND_URLS.ENROLLMENT;
            const response = await axiosClient.post(url, submitData);

            toast.success(response.data.message);
            navigete('/bd/order');
        } catch (error: any) {
            toast.error(error.response.data.message);
        }
    };

    useEffect(() => {
        if (orderId !== '0') {
            axiosClient
                .get(BACKEND_URLS.ENROLLMENT + `/${orderId}`)
                .then((res: AxiosResponse) => {
                    const data = res.data.data;
                    setEnrollmentType(data.type);

                    setUserId(data.userId);
                    setStaffId(data.staffId);
                    setCourseId(data.courseId);

                    setSalesPerson({
                        label: data?.staff ? `${data?.staff?.username} (${data?.staff?.name})` : '',
                        value: data?.staffId,
                    });
                    setStudent({
                        label: `${data.user.username} (${data.user.name})`,
                        value: data.userId,
                    });

                    const personalInfo = {
                        mobileNumber: `${data.user.username} (${data.user.name})`,
                        salesPerson: data.staff
                            ? `${data.staff.username} (${data.staff.name})`
                            : '',
                        comment: data.comment,
                        orderDate: reciveDateTimeFormate(data.orderDate)[0],
                        state: data.state,
                        studentName: data.name,
                    };

                    setPersonalDetails(personalInfo);

                    const enrolledCourses = data.user.userEnrollments.find(
                        (enrollment: any) => enrollment.course.id === data.courseId
                    );

                    setCourseDetails({
                        course: enrolledCourses.course,
                        currentValidity: reciveDateTimeFormate(data.validTill)[0],
                        targetExam: '',
                        services: data.enrollmentService.map((enrollmentService: any) => ({
                            endDate: reciveDateTimeFormate(enrollmentService.customValidTill)[0],
                            isOffered: true,
                            serviceId: enrollmentService.serviceId,
                            action: Action.Empty,
                            slug: enrollmentService.service.slug,
                        })),
                        hardwares: data.hardware.map((hard: any) => ({
                            ...hard,
                            action: Action.Empty,
                        })),
                    });

                    setAmountDetails({
                        totalAmount: data.totalAmount,
                        paymentDetails: data.enrollmentPayment.map((payment: any) => ({
                            paymentAmount: payment.amount,
                            id: payment.id,
                            mode: payment.mode,
                            paymentDate: reciveDateTimeFormate(payment.receivedOn)[0],
                            status: payment.status,
                            comment: payment.comment,
                            action: Action.Edit,
                        })),
                    });
                })
                .catch((err: AxiosError) => {
                    toast.error(err.message);
                });
        } else {
            const user = localStorage.getItem('@user');
            if (user) {
                const userData = JSON.parse(window.atob(user))['user'];
                if (userData) {
                    setStaffId(userData.id);
                    setSalesPerson({
                        label: `${userData.username} (${userData.name})`,
                        value: userData.id,
                    });
                    setPersonalDetails((prev) => ({
                        ...prev,
                        salesPerson: `${userData.username} (${userData.name})`,
                    }));
                }
            }
        }
    }, [orderId]);

    return (
        <div
            style={{
                height: '98vh',
                overflow: 'auto',
            }}
        >
            <h1>{orderId != '0' ? 'Edit' : 'Create'} Order</h1>
            <Form onSubmit={handleSubmit}>
                {/* User Details */}
                {personalDetails.mobileNumber.length > 0 || orderId == '0' ? (
                    <PersonalDetails
                        personalDetails={personalDetails}
                        setPersonalDetails={setPersonalDetails}
                        setStaffId={setStaffId}
                        student={student}
                        setStudent={setStudent}
                        salesPerson={salesPerson}
                        setSalesPerson={setSalesPerson}
                    />
                ) : (
                    <div>Loading...</div>
                )}

                {/* Course Details */}
                {courseDetails || orderId == '0' ? (
                    <CourseDetatils
                        courseDetails={courseDetails}
                        setCourseDetails={setCourseDetails}
                        setAmountDetails={setAmountDetails}
                        courseId={courseId}
                    />
                ) : (
                    <div>Loading...</div>
                )}

                {/* Amount Details */}
                <AmountDetails amountDetails={amountDetails} setAmountDetails={setAmountDetails} />

                <div className="d-flex justify-content-end mx-3">
                    {enrollemntType === 'DEMO' ? (
                        <TriggerToolTip message={'You are not allowed to edit this form.'}>
                            <Button
                                variant="primary"
                                style={{
                                    cursor: 'no-drop',
                                }}
                            >
                                {orderId != '0' ? 'Edit' : 'Create'} Order
                            </Button>
                        </TriggerToolTip>
                    ) : (
                        <Button variant="primary" type="submit">
                            {orderId != '0' ? 'Edit' : 'Create'} Order
                        </Button>
                    )}
                </div>
            </Form>
        </div>
    );
};

export default CreateOrderForm;
