import { NodeContentType } from "../types/node.types";
import ApiService, { ApiResponse } from "./ApiServices/api-service";

class NodeServices extends ApiService {
    constructor() {
    super('/asset/content')
    }

    getContentType({
        assetType,
        name,
        level1Id,
        level2Id,
        level3Id,
        page,
        limit,
        quizType,
        contentId
    }: {
        assetType: 'video' | 'quiz' | 'pdf' | 'session',
        name?: string
        level1Id?: number | string,
        level2Id?: number | string,
        level3Id?: number | string,
        limit?: number | string,
        page?: number | string,
        quizType?:'PRACTICE_QUIZ' | 'QUIZ' | '',
        contentId?: string
    }): ApiResponse<NodeContentType[]> {
        const params = [
            name && `name=${encodeURIComponent(name.trim())}`,
            level1Id && `level1Id=${level1Id}`,
            level2Id && `level2Id=${level2Id}`,
            level3Id && `level3Id=${level3Id}`,
            page && `page=${page}`,
            limit && `limit=${limit}`,
            quizType && `quizType=${quizType.trim()}`,
            contentId && `contentId=${contentId.trim()}`
          ]
            .filter(Boolean)
            .join('&');
          
          return this.getData(`/${assetType}?${params}`);          
    }
}

export default new NodeServices()