
const formatDuration = (seconds: number) => {
    // If duration is less than 60 seconds, just return seconds
    if (seconds < 30) {
        return `00:00`;
    } else if (seconds > 30 && seconds < 60) {
        return '00:01';
    }

    const hours = Math.floor(seconds / 3600); // Calculate total hours
    const minutes = Math.floor((seconds % 3600) / 60); // Calculate remaining minutes
    const remainingSeconds = seconds % 60; // Calculate remaining seconds

    // Round up the minutes if there are remaining seconds greater than or equal to 30
    const roundedMinutes = remainingSeconds >= 30 ? minutes + 1 : minutes;

    const formattedTime = [
        hours > 0 ? `${hours}:` : "00:", // Show hours only if greater than 0
        roundedMinutes < 10 ? `0${roundedMinutes}` : `${roundedMinutes}`, // Show minutes only if greater than 0 or if hours exist
    ].filter(Boolean).join(" "); // Filter out empty values and join the time parts

    
    return formattedTime || "00:00"; // Return formatted time or "0m" if no time
};

export default formatDuration;