import { NodeItem } from './node.types';

export type SetItem = {
    id: number;
    name: string;
    endDate?: string;
    isStatic: boolean;
    type: SET_TYPE;
    thumbnail: string;
    nodes: SetNodeItem[];
    createdById?: number | null;
    createdOn?: string;
    updatedOn?: string;
};

export type SetNodeItem = NodeItem & {
    nodeId: number;
};

export type CourseSetItem = {
    id: number;
    setId: number;
    name: string;
    order: number;
    type: SET_TYPE;
    thumbnail: string;
    isPrimary: boolean;
};

export enum SET_TYPE {
    ACADEMIC = 'ACADEMIC',
    NON_ACADEMIC = 'NON_ACADEMIC',
}

export type CreateSetItem = Omit<SetItem, 'id' | 'nodes'>;
