import { RiAddCircleFill } from 'react-icons/ri';
import TriggerToolTip from '../tooltip/tooltip-trigger';

interface MainBtnInterface {
    onClick: () => void;
    message?: string;
}

function MainBtn({ onClick, message }: MainBtnInterface) {
    return (
        <TriggerToolTip message={message ?? ''}>
            <div
                className="subject-group-creation-btn d-flex justify-content-center align-items-center"
                onClick={onClick}
                style={{
                    zIndex: '1000',
                }}
            >
                <div className="icon"></div>
                <RiAddCircleFill size={50} color="#5F46E3" />
            </div>
        </TriggerToolTip>
    );
}

export default MainBtn;
