import { QuestionItem, QuestionOptionItem } from "../../../types/quizTypes";
import { userPracticeConstants } from "../../ReducerConstants/userPracticeConstants";

export type UserPracticeState = {
  quiz?: any;
  quizId?: number;
  currentIndex: number;
  isbottomoffcanvas: boolean;
  loading: boolean;
  practiceData: QuestionItem[];
  practiceModData: QuestionItem[];
};

const initState: UserPracticeState = {
  loading: true,
  currentIndex: 0,
  practiceData: [],
  practiceModData: [],
  isbottomoffcanvas: false,
};
export default function userPracticeReducer(state = initState, action: any) {
  switch (action.type) {
    case userPracticeConstants.PRACTICE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userPracticeConstants.GET_PRACTICE_DATA:
      return {
        ...state,
        loading: false,
        quiz: action.quiz,
        quizId: action.quizId,
        practiceData: action.data,
        practiceModData: action.data.map((q: QuestionItem, i: number) => {
          return {
            ...q,
            attempt_count: 0,
            is_ans: "",
            questionNo: i + 1,
            attempt_state: "SKIPPED",
            answer_numerical: "",
            options: q.options.map((op) => ({ ...op, is_selected: false })),
            is_submitted: false,
            show_solution: false,
            // matches: q.matches.map((mt) => ({
            //   ...mt,
            //   selected_option_indexes: [],
            // })),
          };
        }),
      };

    case userPracticeConstants.UPDATE_PRACTICE_DATA:
      return {
        ...state,
        practiceModData: state.practiceModData.map((que) => {
          if (que.id === action.question_id) {
            if (["NUMERICAL", "SUBJECTIVE"].includes(que.qType)) {
              return {
                ...que,
                attempt_state: action.answer_numerical ? "ANSWERED" : "SKIPPED",
                answer_numerical: action.answer_numerical,
              };
            }
            // else if (que.qType === "match-the-column") {
            //   var mt_selected_ops =
            //     que.matches[action.mtIndex].selected_option_indexes;
            //   que.matches[action.mtIndex].selected_option_indexes =
            //     mt_selected_ops.includes(action.opIndex)
            //       ? mt_selected_ops.filter(
            //           (op: QuestionOptionItem) => op !== action.opIndex
            //         )
            //       : [...mt_selected_ops, action.opIndex];
            //   return {
            //     ...que,
            //     attempt_state: que.matches.find(
            //       ({ selected_option_indexes }) =>
            //         selected_option_indexes.length > 0
            //     )
            //       ? "ANSWERED"
            //       : "SKIPPED",
            //   };
            // }
            else {
              const selected_op = que.options.filter((op) => op.isSolution);
              return {
                ...que,
                // "attempt_count": 0,
                attempt_state:
                  selected_op.length === 1 &&
                    selected_op[0].id === action.option_id
                    ? "SKIPPED"
                    : "ANSWERED",
                options: que.options.map((op) => {
                  if (op.id === action.option_id)
                    return { ...op, is_selected: !op.isSolution };
                  else {
                    if (que.qType === "SINGLE_CORRECT") {
                      return {
                        ...op,
                        is_selected: false,
                      };
                    } else {
                      return op;
                    }
                  }
                }),
              };
            }
          } else return que;
        }),
      };

    case userPracticeConstants.UPDATE_CURRENT_INDEX:
      const ind = action.index;
      return {
        ...state,
        currentIndex: ind,
        // practiceModData: state.practiceModData.map((que, i) => {
        //   if (i === ind)
        //     return {
        //       ...que,
        //       show_solution: false,
        //     };
        //   else return que;
        // }),
      };

    // case userPracticeConstants.UPDATE_PRACTICE_SHOW_SOLUTION:
    //   return {
    //     ...state,
    //     practiceModData: state.practiceModData.map((que) => {
    //       if (que.id === action.question_id) {
    //         return {
    //           ...que,
    //           show_solution: !que.show_solution,
    //         };
    //       }
    //       return que;
    //     }),
    //   };

    // case userPracticeConstants.SUBMIT_PRACTICE_ANSWER:
    //   return {
    //     ...state,
    //     practiceModData: state.practiceModData.map((que, i) => {
    //       if (i === state.currentIndex) {
    //         const selectedOption = que.options.find((e) => e.is_selected);
    //         const isCorrect =
    //           selectedOption?.is_selected == selectedOption?.is_solution;

    //         return {
    //           ...que,
    //           is_ans: action.is_ans,
    //           attempt_count: isCorrect ? que?.attempt_count || 0 + 1 : 0,
    //           attempt_state: que.is_submitted ? "SKIPPED" : que.attempt_state,
    //           is_submitted: !que.is_submitted,
    //           show_solution: que.is_submitted ? false : que.show_solution,
    //           options: que.is_submitted
    //             ? que.options.map((op) => {
    //                 return { ...op, is_selected: false };
    //               })
    //             : que.options,
    //           matches: que.is_submitted
    //             ? que.matches.map((mt) => ({
    //                 ...mt,
    //                 selected_option_indexes: [],
    //               }))
    //             : que.matches,
    //         };
    //       }
    //       return que;
    //     }),
    //   };

    case userPracticeConstants.PRACTICE_REQUEST_FAILED:
      return {
        ...state,
        loading: false,
        errors: action.errors,
      };

    case userPracticeConstants.BOTTOM_OFF_CANVAS:
      return {
        ...state,
        isbottomoffcanvas: !state.isbottomoffcanvas,
      };

    default:
      return state;
  }
}
