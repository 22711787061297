import React, { useState } from 'react';
import { Form, Button, Card, FloatingLabel } from 'react-bootstrap';
import { EDUCATION_BOARD_OPTIONS, STATES } from '../../utils/constants';
import { getAcadmicesSessions } from '../../utils/helpers';
import { UserAcademicInfoPayload, UserAcademicInfoResponse } from '../../types/user-profille-types';
import toast from 'react-hot-toast';
import userService from '../../services/user-service';
import Select from 'react-select';

type Props = {
    userId: number;
    academics: UserAcademicInfoResponse;
    isDisabled?: boolean;
};

const AcademicDetailsInfo = ({ userId, academics, isDisabled = false }: Props) => {
    const [showInstituteInfo, setShowInstituteInfo] = useState<boolean>(!!academics?.instituteName);
    const [formData, setFormData] = useState<UserAcademicInfoPayload>({
        instituteName: academics?.instituteName ?? '',
        instituteCity: academics?.instituteCity ?? '',
        schoolName: academics?.schoolName ?? '',
        schoolCity: academics?.schoolCity ?? '',
        board: academics?.board ?? '',
        session: academics?.session,
    });

    const [errors, setErrors] = useState<Partial<any>>({});

    const handleInputChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: '' }); // Clear error on change
    };

    // const validateForm = () => {
    // const newErrors: Partial<any> = {};
    // if (showInstituteInfo) {
    //     if (!formData.instituteName) newErrors.instituteName = 'Institute name is required.';
    //     if (!formData.instituteCity) newErrors.instituteCity = 'Institute city is required.';
    // }
    // if (!formData.schoolName) newErrors.schoolName = 'School name is required.';
    // if (!formData.schoolCity) newErrors.schoolCity = 'School city is required.';
    // if (!formData.board) newErrors.board = 'School board is required.';

    // if (!formData.session) newErrors.session = 'Session is required.';

    // return newErrors;
    // };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        // const validationErrors = validateForm();

        // if (Object.keys(validationErrors).length > 0) {
        //     setErrors(validationErrors);
        //     return;
        // } else {
        const data = {
            ...formData,
            instituteName: showInstituteInfo ? formData.instituteName : undefined,
            instituteCity: showInstituteInfo ? formData.instituteCity : undefined,
        };
        userService.updateAcademics(userId, formData).then((res) => {
            if (res.status === 'success') {
                toast.success(res.message);
            }
        });
        // }
    };

    return (
        <Card className="mb-3">
            <Card.Body>
                <h4>Acadmic Details</h4>
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="formInstituteName">
                        {/* Custom Select (react-select) */}
                        <Select
                            isDisabled={isDisabled}
                            placeholder="Select Session"
                            options={getAcadmicesSessions()}
                            onChange={(e) =>
                                handleInputChange({
                                    target: { name: 'session', value: e?.value || '' },
                                } as React.ChangeEvent<HTMLInputElement>)
                            }
                            classNamePrefix={errors.sessions ? 'is-invalid' : ''}
                            name="session"
                            value={
                                !!!formData?.session
                                    ? null
                                    : { label: formData?.session, value: formData?.session }
                            }
                        />
                        {errors.sessions && (
                            <div className="invalid-feedback d-block">{errors.sessions}</div>
                        )}
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Check
                            type="checkbox"
                            id="enrolled-institute-checkbox"
                            label="Enrolled in any institute other than eSaral."
                            name="enrolledInOtherInstitute"
                            checked={showInstituteInfo}
                            onChange={(e) => setShowInstituteInfo(e.target.checked)}
                            disabled={isDisabled}
                        />
                    </Form.Group>

                    {showInstituteInfo && (
                        <>
                            <Form.Group className="mb-3" controlId="formInstituteName">
                                <FloatingLabel
                                    controlId="InstituteName"
                                    label="Institute Name"
                                    className="mb-3"
                                >
                                    <Form.Control
                                        type="text"
                                        placeholder="Institute Name"
                                        name="instituteName"
                                        value={formData.instituteName}
                                        onChange={handleInputChange}
                                        isInvalid={!!errors.instituteName}
                                        disabled={isDisabled}
                                    />
                                </FloatingLabel>
                                <Form.Control.Feedback type="invalid">
                                    {errors.instituteName}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formInstituteCity">
                                <FloatingLabel
                                    controlId="InstituteCity"
                                    label="Institute City"
                                    className="mb-3"
                                >
                                    <Form.Control
                                        type="text"
                                        placeholder="Institute City"
                                        name="instituteCity"
                                        value={formData.instituteCity}
                                        onChange={handleInputChange}
                                        isInvalid={!!errors.instituteCity}
                                        disabled={isDisabled}
                                    />
                                </FloatingLabel>
                                <Form.Control.Feedback type="invalid">
                                    {errors.instituteCity}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </>
                    )}

                    <Form.Group className="mb-3" controlId="formSchoolName">
                        <FloatingLabel controlId="SchoolName" label="School Name" className="mb-3">
                            <Form.Control
                                type="text"
                                placeholder="School Name"
                                name="schoolName"
                                value={formData.schoolName}
                                onChange={handleInputChange}
                                isInvalid={!!errors.schoolName}
                                disabled={isDisabled}
                            />
                        </FloatingLabel>
                        <Form.Control.Feedback type="invalid">
                            {errors.schoolName}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formSchoolCity">
                        <FloatingLabel controlId="SchoolCity" label="School City" className="mb-3">
                            <Form.Control
                                type="text"
                                placeholder="School City"
                                name="schoolCity"
                                value={formData.schoolCity}
                                onChange={handleInputChange}
                                isInvalid={!!errors.schoolCity}
                                disabled={isDisabled}
                            />
                        </FloatingLabel>
                        <Form.Control.Feedback type="invalid">
                            {errors.schoolCity}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formSchoolBoard">
                        <Select
                            isDisabled={isDisabled}
                            placeholder="School Board"
                            options={EDUCATION_BOARD_OPTIONS}
                            onChange={(e) =>
                                handleInputChange({
                                    target: { name: 'board', value: e?.value || '' },
                                } as React.ChangeEvent<HTMLInputElement>)
                            }
                            value={EDUCATION_BOARD_OPTIONS.find(
                                (board) => board.value === formData.board
                            )}
                        />
                        <Form.Control.Feedback type="invalid">{errors.board}</Form.Control.Feedback>
                    </Form.Group>

                    {!isDisabled && (
                        <div className="d-flex justify-content-end mt-3">
                            <Button variant="primary" type="submit">
                                Submit
                            </Button>
                        </div>
                    )}
                </Form>
            </Card.Body>
        </Card>
    );
};

export default AcademicDetailsInfo;
