import {
    QuizPayload,
    QuestionPayload,
    QuizItem,
    QuizSectionItem,
    QuestionItem,
} from '../../types/quizTypes';
import ApiService, { ApiResponse } from './api-service';
import { quizResponseInterface } from '../../types/types';

class QuizService extends ApiService {
    constructor() {
        super('/quiz');
    }

    deleteQuiz(quizId: number) {
        return this.deleteData(`/${quizId}`);
    }

    getAll(query?: string): ApiResponse<QuizItem[]> {
        const q = !!query ? query : '';
        return this.getData('' + q);
    }

    getById(id: number): ApiResponse<QuizItem> {
        return this.getData(`/${id}`);
    }

    createQuiz(data: QuizPayload): ApiResponse<quizResponseInterface> {
        return this.postData('', data);
    }

    updateQuiz(id: number, data: Partial<QuizPayload>): ApiResponse<{ id: number }> {
        return this.putData(`/${id}`, data);
    }

    // Questions
    getQuestions(quizId: number): ApiResponse<QuestionItem[]> {
        return this.getData(`/${quizId}/questions`);
    }

    addQuestion(quizId: any, data: QuestionPayload) {
        return this.postData(`/${quizId}/questions`, data);
    }

    updateQuestion(quizId: number, questionId: number, data: Partial<QuestionPayload>) {
        return this.putData(`/${quizId}/questions/${questionId}`, data);
    }

    addVideoSolution({
        questionId,
        contentId,
        userId,
        editRequired,
    }: {
        questionId: number;
        contentId: number;
        userId: number;
        editRequired: boolean;
    }) {
        return this.postData(`/video-solutions`, {
            questionId,
            contentId,
            userId,
            editRequired,
        });
    }

    updateQuizOrder(quizId: number, questionId: number, order: number) {
        return this.postData(`/${quizId}/questions/${questionId}/order`, { order });
    }

    deleteQuestion(quizId: number, questionId: number) {
        return this.deleteData(`/${quizId}/questions/${questionId}`);
    }

    // Sections
    addSection(quizId: number, data: QuizSectionItem) {
        return this.postData(`/${quizId}/section`, data);
    }
    updateSection(quizId: number, sectionId: number, data: Partial<QuizSectionItem>) {
        return this.putData(`/${quizId}/section/${sectionId}`, data);
    }
    removeSection(quizId: number, data: { sectionId: number }) {
        return this.deleteData(`/${quizId}/section/${data.sectionId}`);
    }

    // paragraphs
    getParagraphs(search: string): ApiResponse {
        if (search.length) {
            return this.getData(`/paragraphs?search=${search}`);
        }
        return this.getData(`/paragraphs?limit=1000`);
    }

    saveParagraphs(paragraph: string): ApiResponse {
        return this.postData(`/paragraphs`, { text: paragraph });
    }

    updateParagraph(id: number, paragraph: string): ApiResponse {
        return this.putData(`/paragraphs/${id}`, { text: paragraph });
    }

    quizParticipantsReport(quizId: number): ApiResponse {
        return this.getData(`/${quizId}/quiz-participants-report/`);
    }
}

export default new QuizService();
