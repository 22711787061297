import Lottie from 'lottie-react'
import React from 'react'
import { useNavigation } from 'react-router-dom'

function Loader() {

    const { state } = useNavigation()

    return (
        state === 'loading'
            ?
            <div
                className='d-flex flex-column justify-content-center align-items-center'
                style={{
                    width: '100vw',
                    height: "100vh",
                    position: 'fixed',
                    backgroundColor: 'hsl(0deg 0% 0% / 59%)',
                    zIndex: '1000'
                }}
            >
                {/* <Lottie animationData={require('../../assets/images/lottie/esaral_loader.json')} style={{
                width: '600px'
            }} /> */}
                <div style={{ color: 'white' }} >Loading...</div>
            </div>
            :
            <></>
    )
}

export default Loader