import React, { useEffect, useRef, useState } from "react";
import { Ratio } from "react-bootstrap";
import content from "../../services/content";
import { VideoSource } from "../../types/types";
import { boolean } from "yup";
import toast from "react-hot-toast";

const VideoPlayer = ({
  videoUrl,
  style,
  autoPlay = true,
  onPlay = (e) => console.log('onPlay ===>', e),
  onPause = (e) => console.log('onPause ===>', e),
  phone = '',
  source = VideoSource.VDOCIPHER,
  videoOtp,
  videoPlayBackInfo
}: {
  videoUrl: string,
  style?: any,
  autoPlay?: boolean,
  onPlay?: (e: any) => void,
  onPause?: (e: any) => void,
  phone?: string,
  source?: VideoSource,

  videoOtp?: string,
  videoPlayBackInfo?: string

}) => {
  const playerRef = useRef<HTMLIFrameElement>(null);
  const [URL, setURL] = useState("");

  async function streamURL(url: string) {
    let src = "";
    if (url && /^[0-9]+$/.test(url)) {
      src = `https://player.vimeo.com/video/${url}?badge=0&amp;autopause=1&amp;player_id=0&amp;app_id=58479&autoplay=${autoPlay}`;
    }
    else if (url) {
      await content
        .getVideoOTP({ videoId: url, source })
        .then((res) => {
          src = `https://player.vdocipher.com/v2/?otp=${res.otp}&playbackInfo=${res.playbackInfo}&autoplay=${autoPlay}&primaryColor=5f46e3`;
        })
        .catch((err) => {
          toast.error(err.message)
        });
    }
    setURL(src);
  }

  useEffect(() => {
    if (videoOtp && videoPlayBackInfo) {
      setURL(`https://player.vdocipher.com/v2/?otp=${videoOtp}&playbackInfo=${videoPlayBackInfo}&autoplay=${autoPlay}&primaryColor=5f46e3`);
    }
    else {
      streamURL(videoUrl);
    }
  }, [videoUrl, videoOtp, videoPlayBackInfo]);

  useEffect(() => {
    if (playerRef.current && URL.startsWith("https://player.vdocipher.com")) {
      if (window.VdoPlayer && window.VdoPlayer.getInstance) {
        const player = window.VdoPlayer.getInstance(playerRef.current);
        player.video.addEventListener("play", onPlay);
        player.video.addEventListener("pause", onPause);
        return () => {
          player.video.removeEventListener("pause", onPause);
          player.video.removeEventListener("play", onPlay);
        };
      }
      // else {
      //   toast.error("VdoPlayer or getInstance is not available");
      // }
    }
  }, [URL]);

  if (!URL) return null;

  return (
    <Ratio aspectRatio="16x9" key={videoUrl} style={{ ...style }}>
      <iframe
        ref={playerRef}
        src={URL + ""}
        allow="encrypted-media"
        allowFullScreen
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
      ></iframe>
    </Ratio>
  );
};

export default VideoPlayer;
