import { RolePayload, RolePermission, RoleResponse } from '../../types/role.types';
import ApiService, { ApiResponse } from './api-service';

class RoleService extends ApiService {
    constructor() {
        super('/roles');
    }

    getAllRoles(userId?: number): ApiResponse<{ roles: RoleResponse[] }> {
        return this.getData(userId ? `?userId=${userId}` : '');
    }
    getRoleById(id: number): ApiResponse<{ role: RoleResponse }> {
        return this.getData(`/${id}`);
    }
    createRole(data: RolePayload): ApiResponse<{ id: number }> {
        return this.postData('', data);
    }
    updateRole(id: number, data: Partial<RolePayload>): ApiResponse<{ id: number }> {
        return this.putData(`/${id}`, data);
    }
    deleteRole(id: number): ApiResponse<{ count: number }> {
        return this.deleteData(`/${id}`);
    }
    addPermission(
        id: number,
        permissionId: number
    ): ApiResponse<{ rolePermission: RolePermission }> {
        return this.postData(`/${id}/permissions`, { permissionId });
    }
    removePermission(
        id: number,
        permissionId: number
    ): ApiResponse<{ rolePermission: RolePermission }> {
        return this.deleteData(`/${id}/permissions/${permissionId}`);
    }
}

export default new RoleService();
