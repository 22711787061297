import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";

interface SelectCreatorInterface {
  labelName?: string;
  options: { optionName: string; optionValue?: any }[];
  linkName?: string;
  linkUrl?: string;
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  disabled?: boolean;
  selectedId?: any;
}

export function SelectCreator({
  labelName,
  options,
  linkName,
  linkUrl = "#",
  onChange,
  disabled = false,
  selectedId,
}: SelectCreatorInterface) {
  return (
    <div className="d-flex align-items-center">
      {labelName && (
        <label htmlFor="subject-groups " className="me-2 col-4">
          {labelName}
        </label>
      )}
      <Form.Select
        name="subject-groups"
        className="col-4"
        aria-label={labelName}
        onChange={onChange}
        disabled={disabled}
        value={selectedId ?? ""}
      >
        <option key={"sample_" + labelName ? labelName : ''}>
          -------------------------
        </option>
        {options.map((option, index) => (
          <option key={index} value={option.optionValue ?? option.optionName}>
            {option.optionName}
          </option>
        ))}
      </Form.Select>
      {linkName && (
        <Link to={linkUrl} className="col-4">
          {linkName}
        </Link>
      )}
    </div>
  );
}
