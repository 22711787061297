import { AssetItem, ChapterType, GroupedAsset, LevelInterface, LevelsApiInterface } from "../../types/ApiTypes"
import { contenType } from "../../types/types";
import { ContentConstants } from "./content-constants"


export interface AssetData {
    id: number;
    source: string;
    videoId: string;
    fileName: string;
    thumbnailHr: string;
    thumbnailLr: string;
    durInSec: number;
    internalName: string;
    createdById: number | null;
    createdAt: string;
    updatedAt: string;
    decryptedFileUrl: string;
    encryptedFileUrl: string;
    password: string;
}

export interface setContentScheduleInterface {
    id: number,
    setId: number,
    contentId: number,
    scheduledById: number,
    availableFrom: string,
    availableTill: string
}

export interface ContentItem {
    id: number;
    name: string;
    assetType: 'VIDEO' | 'QUIZ' | 'PDF' | 'SESSION';  // Assuming assetType could have multiple values
    assetId: number;
    type: contenType;  // Enum based on your previous form
    level2Id: number;
    level3Id: number | null;
    parentAssetType: string | null;
    parentAssetId: number | null;
    createdById: number | null;
    createdAt: string;
    updatedAt: string;
    isFree: boolean;
    level2Name: string;
    level1Id: number;
    level1Name: string;
    assetData: AssetData;
    isNew?: boolean;
    order: number,
    setContentSchedule: setContentScheduleInterface[]
    newLevel2Id?: number,
    newLevel2Name?: string,
    isImported?: boolean,
}



export interface InitialStateType {
    subject: LevelInterface[] | [],
    subjectGroups: LevelInterface[] | [],
    nodeName: string,
    selectedSubjectGroupId: string,
    selectedSubjectId: string,
    isAddChapter: boolean,
    chapterData: ChapterType[],
    content: ContentItem[],
    selectedChapter: {
        level2Id?: number,
        level2Name?: number
    }
}


const initialState: InitialStateType = {
    subject: [],
    subjectGroups: [],
    nodeName: '',
    selectedSubjectGroupId: '',
    selectedSubjectId: '',
    isAddChapter: false,
    chapterData: [],
    content: [],
    selectedChapter: {}
}

const ContentReducer = (state: InitialStateType = initialState, action: any) => {

    switch (action.type) {

        case ContentConstants.SUBJECTS:
            return {
                ...state,
                subject: action.payload
            }

        case ContentConstants.SUBJECTS_GROUPS:
            return {
                ...state,
                subjectGroups: action.payload
            }

        case ContentConstants.NODE_NAME:
            return {
                ...state,
                nodeName: action.payload
            }
        case ContentConstants.SELECTED_SUBJECT_GROUP_ID:
            return {
                ...state,
                selectedSubjectGroupId: action.payload
            }

        case ContentConstants.SELECTED_SUBJECT_ID:
            return {
                ...state,
                selectedSubjectId: action.payload
            }

        case ContentConstants.IS_ADD_CHAPTER:
            return {
                ...state,
                isAddChapter: action.payload
            }

        case ContentConstants.CHAPTER_DATA:
            return {
                ...state,
                chapterData: action.payload
            }

        case ContentConstants.CONTENT:

            return {
                ...state,
                content: action.payload
            }

        case ContentConstants.ADD_CHAPTER:

            const { level2Id, level2Name } = action.payload

            const content = {
                "isNew": true,
                "id": null,
                "name": "",
                "assetType": "",
                "assetId": 0,
                "type": "",
                "level2Id": level2Id,
                "level2Name": level2Name,
                "level3Id": 0,
                "parentAssetType": "null",
                "parentAssetId": 0,
                "isFree": false,
                "createdOn": "",
                "assetData": {
                    "source": "",
                    "videoId": "",
                    "thumbnailUrlLr": "",
                    "thumbnailUrlHr": "",
                    "durInSec": 0,
                    "internalName": "",
                    "fileName": ""
                }
            }

            return {
                ...state,
                content: state.content.length ? [...state.content, content] : [content]
            }

        case ContentConstants.ADD_CONTENT:
            return {
                ...state,
                content: [...state.content, action.payload]
            }

        case ContentConstants.SELECTED_CHAPTER:
            return {
                ...state,
                selectedChapter: {
                    level2Id: action.payload.level2Id,
                    level2Name: action.payload.level2Name
                }
            }

        case ContentConstants.NEW_CONTENT:
            const new_info: {
                id: number,
                data: {
                    name: string,
                    assetType: 'VIDEO' | 'IMAGE' | 'SESSION' | 'QUIZ',
                    assetId: number,
                    type: 'LEARN' | 'PRACTISE' | 'TEST' | 'REVISE',
                    level2Id: number,
                    level3Id: number,
                    parentAssetType: null,
                    parentAssetId: null,
                    isFree: boolean,
                    order: number
                }
            } = action.payload;

            return {
                ...state,
                content: state.content.map(content => {
                    if (-1 === content.id) {
                        return {
                            ...content,  // Spread the original content object
                            id: new_info.id,
                            name: new_info.data.name,
                            assetType: new_info.data.assetType,
                            assetId: new_info.data.assetId,
                            assetData: {  // Ensure you're updating nested objects correctly
                                ...content.assetData,
                                id: new_info.data.assetId
                            },
                            type: new_info.data.type,
                            level2Id: new_info.data.level2Id,
                            level3Id: new_info.data.level3Id,
                            parentAssetType: new_info.data.parentAssetType,
                            parentAssetId: new_info.data.parentAssetId,
                            isFree: new_info.data.isFree,
                            isNew: false,
                            order: new_info.data.order
                        };
                    }
                    return content;
                })
            };

        case ContentConstants.Edit_CONTENT:
            const edit_info: {
                id: number,
                data: {
                    name: string,
                    assetType: 'VIDEO' | 'QUIZ' | 'PDF' | 'SESSION',
                    assetId: number,
                    type: 'LEARN' | 'PRACTISE' | 'TEST' | 'REVISE',
                    level2Id: number,
                    level3Id: number,
                    parentAssetType: null,
                    parentAssetId: null,
                    isFree: boolean,
                    order: number
                }
            } = action.payload;

            return {
                ...state,
                content: state.content.map(content => {
                    if (edit_info.id === content.id) {
                        return {
                            ...content,  // Spread the original content object
                            id: edit_info.id,
                            name: edit_info.data.name,
                            assetType: edit_info.data.assetType,
                            assetId: edit_info.data.assetId,
                            assetData: {  // Ensure you're updating nested objects correctly
                                ...content.assetData,
                                id: edit_info.data.assetId
                            },
                            type: edit_info.data.type,
                            level2Id: edit_info.data.level2Id,
                            level3Id: edit_info.data.level3Id,
                            parentAssetType: edit_info.data.parentAssetType,
                            parentAssetId: edit_info.data.parentAssetId,
                            isFree: edit_info.data.isFree,
                            order: edit_info.data.order
                        };
                    }
                    return content;
                })
            };

        case ContentConstants.EDIT_NEW_CONTENT_ID:
            const contentId = action.payload
            return {
                ...state,
                content: state.content.map(
                    content => {
                        if (content.id === contentId) {
                            return {
                                ...content,
                                id: contentId,
                                isNew: false
                            }
                        }
                        return content
                    }
                )
            }

        case ContentConstants.REMOVE_CONTENT:
            return {
                ...state,
                content: state.content.filter(content => content.id != action.payload.contentId)
            }

        case ContentConstants.EDIT_SESSION:
            return {
                ...state,
                content: state.content.map(
                    (con) => {
                        if (+con.id === +action.payload.id) {
                            return {
                                ...con,
                                assetData: action.payload.assetData
                            }
                        }
                        return con
                    }
                )
            }

        case ContentConstants.ADD_CONTENT_SCHEDULE:
            return {
                ...state,
                content: state.content.map(
                    (con) => {
                        if (+con.id === +action.payload.contentId) {
                            return {
                                ...con,
                                setContentSchedule: [...con.setContentSchedule, action.payload]
                            }
                        }
                        return con
                    }
                )
            }

        case ContentConstants.EDIT_CONTENT_SCHEDULE:
            return {
                ...state,
                content: state.content.map(
                    (con) => {
                        if (+con.id === +action.payload.contentId) {
                            return {
                                ...con,
                                setContentSchedule: con.setContentSchedule.map(
                                    (schedule) => {
                                        if (+schedule.id === +action.payload.id) {
                                            return action.payload
                                        }
                                        return schedule
                                    }
                                )
                            }
                        }
                        return con
                    }
                )
            }

        case ContentConstants.DELETE_CONTENT_SCHEDULE:
            return {
                ...state,
                content: state.content.map(
                    (con) => {
                        if (+con.id === +action.payload.contentId) {
                            return {
                                ...con,
                                setContentSchedule: con.setContentSchedule.filter((schedule) => +schedule.id !== +action.payload.id)
                            }
                        }
                        return con
                    }
                )
            }


        default:
            return state
    }

}



export default ContentReducer