import { BACKEND_URLS } from '../config';
import ApiService, { ApiResponse } from './ApiServices/api-service';

class mentorCallsServices extends ApiService {
    constructor() {
        super(BACKEND_URLS.GET_USERS);
    }

    getMentorCalls(studentId: number): ApiResponse {
        return this.getData(`/${studentId}/mentor-call`);
    }

    createMentorCall({
        data,
    }: {
        data: {
            userId: number;
            staffId: number;
            durationInSec: number;
            callDateTime: string;
            callDetails?: string;
            type: string;
            contactNumber: string;
            enrollmentId: number;
        };
    }): ApiResponse {
        return this.postData(`/mentor-call`, data);
    }

    updateMentorCall({
        data,
        id,
    }: {
        data: {
            userId?: number;
            staffId?: number;
            durationInSec?: number;
            callDateTime?: string;
            type?: string;
            contactNumber?: string;
            callDetails?: string;
            enrollmentId: number;
        };
        id: number;
    }): ApiResponse {
        return this.putData(`/mentor-call/${id}`, data);
    }
}

export default new mentorCallsServices();
