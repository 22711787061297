import ApiService from './api-service';

class ServicesService extends ApiService {
    constructor() {
        super('/services');
    }

    getAll() {
        return this.getData('');
    }
}

export default new ServicesService();
