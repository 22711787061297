
import React, { useState } from 'react';
import { Modal, Button, Form, FloatingLabel } from 'react-bootstrap';
import CustomUpload from '../ModulePDF/Upload';
import Select, { SingleValue } from 'react-select';
import toast from 'react-hot-toast';

import { convertISTtoUTC } from "../../utils/helpers";
interface SendNotifcationModalProps {
    show: boolean;
    onHide: () => void;
    onFileChange: (url: string) => void;
    onTitleChange: (name: string) => void;
    onMessageChange: (name: string) => void;
    oncampaignDataChange: (name: campaginType) => void;
    onConfirm: (url: string, titleName: string, studentIds: number[], message: string, campaignData: campaginType) => void;
    studentIds: number[];
    title?: string;
    bodyText?: string;
    confirmText?: string;
    cancelText?: string;
}

interface SelectType {
    value: string,
    label: string,
}



type campaginType = {
    name: string,
    title: string,
    message: string,
    iconUrl: string,
    mediaUrl: string,
    cta: string,
    ctaParam: string,
    category: string,
    sendAt: string,
}


const CallToAction: SelectType[] = [
    {
        label: 'Homepage',
        value: 'HOMEPAGE'
    },
    {
        label: 'Course page',
        value: 'COURSE'
    },
    {
        label: 'Specific course',
        value: 'SINGLECOURSE'
    },
    {
        label: 'External',
        value: 'EXTERNAL'
    }
]
const SendNotifcationModal: React.FC<SendNotifcationModalProps> = ({
    show,
    onHide,
    onConfirm,
    onFileChange,
    onTitleChange,
    onMessageChange,
    oncampaignDataChange,
    studentIds,
    title = "Send Notification",
    confirmText = "Upload",
    cancelText = "Cancel",
}) => {
    const [selectedImage, setSelectedImage] = useState<string>();
    const [titleName, setTitleName] = useState('');
    const [message, setMessage] = useState('');
    const [cta, setCTA] = useState<any>();
    const [isSchedule, setIsSchedule] = useState<boolean>();
    const [campaignData, setCampaignData] = useState<campaginType>({
        name: "",
        title: "",
        message: "",
        iconUrl: "",
        mediaUrl: "",
        cta: "",
        ctaParam: "",
        category: "",
        sendAt: "",
    });
    const [showDateTime, setShowDateTime] = useState(false);

    const handleCheckboxChange = (e: { target: { checked: boolean | ((prevState: boolean) => boolean); }; }) => {
        setShowDateTime(e.target.checked);
    };

    const handleCampSelect = (camp: SingleValue<{ label: string, value: any }>, name: string) => {
        if (name === "cta") {
            setCTA(camp?.value);
            setCampaignData(
                (prev: any) => ({
                    ...prev,
                    cta: camp?.value
                })
            )
        }
        else if (name === 'ctaParam') {
            setCampaignData(
                (prev: any) => ({
                    ...prev,
                    ctaParam: camp?.value.id
                })
            )
        }
        else if (name === 'category') {
            setCampaignData(
                (prev: any) => ({
                    ...prev,
                    category: camp?.value
                })
            )
        }
    }

    const campDataChange = (e: any) => {

        const { name, value } = e.target;

        const data = { ...campaignData };
        if (name === 'sendAt') {
            setCampaignData(
                (camp) => ({
                    ...camp,
                    'sendAt': convertISTtoUTC(value)
                })
            )
        }
        else {
            setCampaignData(
                (camp) => ({
                    ...camp,
                    [name]: value
                })
            )
        }
        oncampaignDataChange(campaignData);
    };

    const handleTitleChange = (name: string) => {
        setTitleName(name);
        onTitleChange(name);
    };
    const handleMessageChange = (message: string) => {
        setMessage(message);
        onMessageChange(message);
    };

    const handleConfirm = () => {
        if (selectedImage && titleName && message && campaignData) {
            onConfirm(`${selectedImage}`, titleName, studentIds, message, campaignData)
            resetFields(); // Reset fields on cancel
            onHide();
        } else {
            toast.error("All fields are required");
        }
    };
    const handleCancel = () => {
        resetFields(); // Reset fields on cancel
        onHide(); // Close the modal
    };

    const resetFields = () => {
        setSelectedImage('');
        setTitleName('');
        setMessage('');
        setCTA(undefined);
        setCampaignData({
            name: "",
            title: "",
            message: "",
            iconUrl: "",
            mediaUrl: "",
            cta: "",
            ctaParam: "",
            category: "",
            sendAt: "",
        });
    };
    return (
        <Modal show={show} onHide={onHide} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <text>Total Selected Student: {studentIds.length} </text>

                <div className="edit-image-btn d-flex flex-column justify-content-center align-items-center m-2">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Title"
                        value={titleName}
                        onChange={(e) => handleTitleChange(e.target.value)}
                    />
                </div>
                <div className="edit-image-btn d-flex flex-column justify-content-center align-items-center m-2">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Message"
                        value={message}
                        onChange={(e) => handleMessageChange(e.target.value)}
                    />
                </div>
                <div className="edit-image-btn d-flex flex-column   m-2">
                    <Select
                        className="react-select-container"
                        options={CallToAction}
                        placeholder='Call to action'
                        name='cta'
                        onChange={(e) => handleCampSelect(e, 'cta')}
                    />
                    {cta && cta === "EXTERNAL" && (
                        <Form.Group className="mb-3 mt-2" controlId="formBasicEmail">
                            <FloatingLabel
                                controlId="URL"
                                label="URL"
                                className="mb-3"
                            >
                                <Form.Control
                                    name="ctaParam"
                                    onChange={(e) => campDataChange(e)}
                                    type="text"
                                    placeholder=""
                                />
                            </FloatingLabel>
                        </Form.Group>
                    )}

                </div>
                <div
                    className="edit-image-btn d-flex align-items-center m-2"
                    style={{ display: 'flex' }}
                >
                    {/* <input
                        type="checkbox"
                        onChange={handleCheckboxChange}
                        style={{ marginRight: '8px' }} // Adds spacing between the checkbox and text
                    />
                    <span style={{ marginRight: '16px' }}>IsSchedule</span>
                    {showDateTime && (
                        <Form.Group className="mb-0" controlId="formBasicEmail">
                            <FloatingLabel
                                controlId="SendAt"
                                label="Send at"
                                className="mb-0"
                            >
                                <Form.Control
                                    name="sendAt"
                                    onChange={(e) => campDataChange(e)}
                                    type="datetime-local"
                                    placeholder=""
                                />
                            </FloatingLabel>
                        </Form.Group>
                    )} */}

                    <Form.Group className="mb-0" controlId="formBasicEmail">
                        <FloatingLabel
                            controlId="SendAt"
                            label="Send at"
                            className="mb-0"
                        >
                            <Form.Control
                                name="sendAt"
                                onChange={(e) => campDataChange(e)}
                                type="datetime-local"
                                placeholder=""
                                min={new Date().toISOString().slice(0, 16)} // Set min to current date and time
                            />
                        </FloatingLabel>
                    </Form.Group>
                </div>

                <div className="edit-image-btn d-flex flex-column justify-content-right align-items-start m-2">
                    <CustomUpload
                        isPdf={false}
                        onUploaded={(key) => {
                            if (key) {
                                onFileChange(key); 
                                setSelectedImage(key);
                            } else {
                                console.error('Error: File key is undefined');
                            }
                        }}
                    />
                </div>

            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={handleCancel}>
                    {cancelText}
                </Button>
                <Button variant="danger" onClick={handleConfirm}>
                    {confirmText}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default SendNotifcationModal;