import {
  FILTER_LIST,
  questionSubmit,
} from "../../components/Quiz/functions/Functions";
import { QuestionItem, QuizItem, QuizSectionItem } from "../../types/quizTypes";
import { TestReducersConstants } from "./TestReducersConstants";

export type TestReducer = {
  active_index: number;
  MessageModal: boolean;
  filter_option: string;
  student_attempt: any | null;
  quiz_id?: number;
  quiz_details: QuizItem | null;
  const_quiz_details?: QuizItem;
  scholarshipData: any;
};

const initialState: TestReducer = {
  active_index: 0,
  filter_option: "All",
  quiz_details: null,
  MessageModal: false,
  scholarshipData: {},
  student_attempt: null,
};

const TestReducers = (state = initialState, action: any) => {
  switch (action.type) {
    case "scholarship_data":
      return { ...state, scholarshipData: action.payload };

    case TestReducersConstants.RESET:
      return {
        active_index: 0,
        const_quiz_details: {},
        quiz_details: {},
        student_attempt: {},
        filter_option: "All",
        MessageModal: false,
      };

    case TestReducersConstants.TEST_DATA:
      const quiz_details = {
        ...action.payload.quiz_details,
        quiz_id: action.payload.quiz_details.quiz_id,
        questions: [...action.payload.quiz_details.questions].map(
          (question, id) => {
            return {
              ...question,
              question_no: id + 1,
            };
          }
        ),
        sections: action.payload.quiz_details.sections.map(
          (section: QuizSectionItem) => {
            const attemptQuestions =
              action.payload.quiz_details.questions.reduce(
                (count: any, question: QuestionItem) => {
                  if (
                    question.sectionId === section.id &&
                    question.qHint === "ANSWERED"
                  ) {
                    return count + 1;
                  }
                  return count;
                },
                0
              );

            return {
              ...section,
              attempt_questions: attemptQuestions,
            };
          }
        ),
      };
      return {
        ...state,
        quiz_details: quiz_details,
        student_attempt: action.payload.student_attempt,
        const_quiz_details: quiz_details,
        quiz_id: action.payload.quiz_details.quiz_id,
      };

    case TestReducersConstants.ACTIVE_INDEX:
      return {
        ...state,
        active_index: action.payload,
      };

    // case TestReducersConstants.IS_ANSWERED:
    //   // takes the option_id.
    //   const { option_id } = action.payload;
    //   if (!state.quiz_details) return state;
    //   const answeredQuestions = [...state.quiz_details?.questions];

    //   const answeredOptionsArr = [];

    //   if (
    //     answeredQuestions[state.active_index].question_type === "single-correct"
    //   ) {
    //     answeredQuestions[state.active_index].options = answeredQuestions[
    //       state.active_index
    //     ].options.map((option, index) => {
    //       return option.option_id === option_id
    //         ? { ...option, is_selected: !option.is_selected }
    //         : { ...option, is_selected: false };
    //     });


    //     const options = answeredQuestions[state.active_index].options;
    //     for (let i = 0; i < options.length; i++) {
    //       if (options[i].is_selected) {
    //         answeredOptionsArr[0] = {
    //           option_id: options[i].option_id,
    //         };
    //       }
    //     }
    //   } else if (
    //     answeredQuestions[state.active_index].question_type ===
    //     "multiple-correct"
    //   ) {

    //     answeredQuestions[state.active_index].options = answeredQuestions[
    //       state.active_index
    //     ].options.map((option, index) => {
    //       return option.option_id === option_id
    //         ? { ...option, is_selected: !option.is_selected }
    //         : option;
    //     });

    //     const options = answeredQuestions[state.active_index].options;
    //     for (let i = 0; i < options.length; i++) {
    //       if (options[i].is_selected) {
    //         answeredOptionsArr.push({
    //           option_id: options[i].option_id,
    //         });
    //       }
    //     }
    //   }

    //   answeredQuestions[state.active_index].attempt_state =
    //     answeredOptionsArr.length > 0 ? "ANSWERED" : "SKIPPED";

    //   state.quiz_details.sections.map((sec) => {
    //     if (
    //       sec.section_id == answeredQuestions[state.active_index].section_id
    //     ) {
    //       sec.attempt_questions = answeredQuestions.filter(
    //         (q) =>
    //           q.attempt_state === "ANSWERED" &&
    //           q.section_id === answeredQuestions[state.active_index].section_id
    //       ).length;
    //     }
    //     return sec;
    //   });
    //   questionSubmit({
    //     quiz_id: state.quiz_id,
    //     quiz_attempt_id: state.student_attempt?.quiz_attempt_id,
    //     question_id: answeredQuestions[state.active_index].question_id,
    //     options: answeredOptionsArr,
    //   });

    //   return {
    //     ...state,
    //     quiz_details: {
    //       ...state.quiz_details,
    //       questions: answeredQuestions,
    //     },
    //   };

    // case TestReducersConstants.IS_NUMERICAL:
    //   const { answer_numerical } = action.payload;
    //   if (!state.quiz_details) return state;
    //   const AnswerNumericalQue = [...state.quiz_details.questions];
    //   AnswerNumericalQue[state.active_index].answer_numerical =
    //     answer_numerical;

    //   AnswerNumericalQue[state.active_index].attempt_state =
    //     answer_numerical.length > 0 ? "ANSWERED" : "SKIPPED";

    //   state.quiz_details.sections.map((sec) => {
    //     if (
    //       sec.section_id == AnswerNumericalQue[state.active_index].section_id
    //     ) {
    //       sec.attempt_questions = AnswerNumericalQue.filter(
    //         (q) => q.attempt_state === "ANSWERED"
    //       ).length;
    //     }
    //     return sec;
    //   });

    //   questionSubmit({
    //     quiz_id: state.quiz_id,
    //     quiz_attempt_id: state.student_attempt?.quiz_attempt_id,
    //     question_id: AnswerNumericalQue[state.active_index].question_id,
    //     answer_numerical: answer_numerical,
    //   });

    //   return {
    //     ...state,
    //     quiz_details: {
    //       ...state.quiz_details,
    //       questions: AnswerNumericalQue,
    //     },
    //   };

    // case TestReducersConstants.OPTION_MATCHES:
    //   const { i, j } = action.payload;
    //   if (!state.quiz_details) return state;
    //   const OptionMatchs = [...state.quiz_details.questions];
    //   const answeredOptions = [];

    //   const selectedOptionIndexes =
    //     OptionMatchs[state.active_index].matches[j].selected_option_indexes;
    //   const iIndex = selectedOptionIndexes.indexOf(i);

    //   if (iIndex !== -1) {
    //     // If i is already in the array, remove it
    //     selectedOptionIndexes.splice(iIndex, 1);
    //   } else {
    //     // If i is not in the array, add it
    //     selectedOptionIndexes.push(i);
    //   }

    //   const MTC = [...state.quiz_details.questions];
    //   const options = MTC[state.active_index].options;
    //   const matches = MTC[state.active_index].matches;

    //   for (let x = 0; x < options.length; x++) {
    //     for (let y = 0; y < matches.length; y++) {
    //       if (matches[y].selected_option_indexes.includes(x)) {
    //         answeredOptions.push({
    //           match_id: matches[y].id,
    //           option_id: options[x].option_id,
    //         });
    //       }
    //     }
    //   }

    //   OptionMatchs[state.active_index].attempt_state =
    //     answeredOptions.length > 0 ? "ANSWERED" : "SKIPPED";

    //   state.quiz_details.sections.map((sec) => {
    //     if (sec.section_id == OptionMatchs[state.active_index].section_id) {
    //       sec.attempt_questions = OptionMatchs.filter(
    //         (q) => q.attempt_state === "ANSWERED"
    //       ).length;
    //     }
    //     return sec;
    //   });

    //   questionSubmit({
    //     quiz_id: state.quiz_id,
    //     quiz_attempt_id: state.student_attempt?.quiz_attempt_id,
    //     question_id: MTC[state.active_index].question_id,
    //     matches: answeredOptions,
    //   });

    //   return {
    //     ...state,
    //     quiz_details: {
    //       ...state.quiz_details,
    //       questions: OptionMatchs,
    //     },
    //   };

    // case TestReducersConstants.NEXT:
    //   return {
    //     ...state,
    //     active_index: state.active_index + 1,
    //   };

    // case TestReducersConstants.PREV:
    //   return {
    //     ...state,
    //     active_index: state.active_index - 1,
    //   };

    // case TestReducersConstants.FILTER:
    //   if (!state.const_quiz_details) return state;
    //   const questions = [...state.const_quiz_details.questions];

    //   const handleData = (filter_option: any, data: any) => {
    //     if (data.length > 0) {
    //       return {
    //         ...state,
    //         filter_option: filter_option,
    //         quiz_details: {
    //           ...state.quiz_details,
    //           questions: data,
    //         },
    //       };
    //     } else {
    //       return {
    //         ...state,
    //         MessageModal: true,
    //         filter_option: state.filter_option,
    //       };
    //     }
    //   };

    //   if (FILTER_LIST[0] === action.payload) {
    //     return handleData("All", questions);
    //   } else if (FILTER_LIST[1] === action.payload) {
    //     const ques = questions.filter((question) => {
    //       return (
    //         question.attempt_state === "ANSWERED" &&
    //         question.is_correct === false
    //       );
    //     });

    //     return handleData("Incorrect", ques);
    //   } else if (FILTER_LIST[2] === action.payload) {
    //     const ques = questions.filter((question) => {
    //       return (
    //         question.attempt_state === "ANSWERED" &&
    //         question.is_correct === true
    //       );
    //     });

    //     return handleData("Correct", ques);
    //   } else if (FILTER_LIST[3] === action.payload) {
    //     const ques = questions.filter((question) => {
    //       return question.attempt_state === "SKIPPED";
    //     });

    //     return handleData("Skipped", ques);
    //   }

    //   break;

    // case TestReducersConstants.SECTION:
    //   const sec = action.payload; // id=60
    //   if (!state.quiz_details) return state;
    //   const ques = [...state.quiz_details.questions];

    //   let id = null;

    //   for (let i = 0; i < ques.length; i++) {
    //     if (ques[i].section_id == sec) {
    //       id = i;
    //       break;
    //     }
    //   }

    //   return {
    //     ...state,
    //     active_index: Number(id),
    //   };

    case TestReducersConstants.MESSAGE_MODAL:
      return {
        ...state,
        MessageModal: !state.MessageModal,
      };

    default:
      return state;
  }
};

export default TestReducers;
