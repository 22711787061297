import React, { useEffect } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'

function PracticeQuiz() {
    const navigate = useNavigate()
    const quizId = useParams()?.quizId

    useEffect(
        () => {
            navigate("/contents/practice-quiz/" + quizId)
        }, []
    )


    return (
        <div>
            {
                quizId
                    ?
                    <Outlet />
                    :
                    <>
                        PracticeQuiz
                    </>
            }
        </div>
    )
}

export default PracticeQuiz