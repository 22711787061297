import { useDispatch, useSelector } from "react-redux";
import { AssetItem, GroupedAsset } from "../../types/ApiTypes";
import { ContentConstants } from "../../Redux/content/content-constants";
import { Accordion, AccordionContext, Button, ListGroup, useAccordionButton } from "react-bootstrap";
import { FaCirclePlay, FaCirclePlus, FaRegSquareMinus, FaRegSquarePlus } from "react-icons/fa6";
import { groupDataWithChildren } from "../../utils/helpers";
import { useContext, useEffect, useState } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import { FRONTEND_URLS } from "../../config";
import { LuFileSpreadsheet } from "react-icons/lu";
import { ModalsConstants } from "../../Redux/models/models-constants";
import { FaFilePdf, FaFileSignature, FaVrCardboard } from "react-icons/fa";


const CustomToggle = ({ children, eventKey }: any) => {
    const { activeEventKey } = useContext(AccordionContext);
    const isCurrentEventKey = activeEventKey === eventKey;

    const decoratedOnClick = useAccordionButton(eventKey, () => { });
    return (
        <div
            className="d-flex align-items-center m-2"
            onClick={decoratedOnClick}
            style={{
                cursor: 'pointer',
            }}
        >
            {isCurrentEventKey ? <FaRegSquareMinus /> : <FaRegSquarePlus />}
            <Link
                to="#"
                className="nav-link ms-2"
                data-bs-toggle="collapse"
                data-bs-target="#navDashboard"
                aria-expanded={isCurrentEventKey ? true : false}
                aria-controls="navDashboard"
            >
                {children}
            </Link>
        </div>
    );
};

const Content = ({ data }: { data: AssetItem }) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { nodeId, contentId } = useParams()

    const assetType = data.assetType

    
    const handleContentnavigation = () => {
        dispatch({
            type: ContentConstants.SELECTED_CHAPTER,
            payload: {
                level2Id: data.level2Id,
                level2Name: data.level2Name,
            },
        })
        
        navigate(FRONTEND_URLS.CONTENTS.EDIT_NODE + '/' + nodeId + '/' + FRONTEND_URLS.CONTENTS.ASSET + '/' + data.id, { replace: true });
    };
    
    useEffect(
        () => {
            if (contentId === `${data.id}`) {
                dispatch({
                    type: ContentConstants.SELECTED_CHAPTER,
                    payload: {
                        level2Id: data.level2Id,
                        level2Name: data.level2Name,
                    },
                })
            }
        }, []
    )

    return data.name ? (
        <NavLink
            to={FRONTEND_URLS.CONTENTS.EDIT_NODE + '/' + nodeId + '/' + FRONTEND_URLS.CONTENTS.ASSET + '/' + data.id}
            style={{ textDecoration: 'none' }}
            onClick={handleContentnavigation}
        >
            {({ isActive }) => (
                <div
                    className={`d-flex p-2 w-100 mb-2 my-1 nav-bar-li ${isActive ? 'active-content' : ''} mt-1`}
                >
                    <div
                        style={{
                            cursor: 'pointer',
                            width: '30px'
                        }}
                    >
                        {
                            assetType === 'PDF'
                                ?
                                <FaFilePdf className="me-3" size={20} color={isActive ? 'white' : 'black'} />
                                :
                                assetType === 'VIDEO'
                                    ?
                                    <FaCirclePlay color={isActive ? 'white' : 'black'} className="me-3" size={20} />
                                    :
                                    assetType === 'QUIZ'
                                        ?
                                        <FaFileSignature className="me-3" size={20} color={isActive ? 'white' : 'black'} />
                                        :
                                        <FaVrCardboard className="me-3" size={20} color={isActive ? 'white' : 'black'} />
                        }
                    </div>
                    <div style={{
                        color: isActive ? 'white' : 'black'
                    }}>{data.name}</div>
                </div>
            )}
        </NavLink>
    ) : (
        <></>
    );
};

const ContentPart = ({ chapter }: { chapter: GroupedAsset }) => {
    const dispatch = useDispatch();
    const handleOnShow = () => {
        dispatch({ type: ModalsConstants.IS_Modal, payload: true });
        dispatch({
            type: ContentConstants.SELECTED_CHAPTER,
            payload: {
                level2Id: chapter.id,
                level2Name: chapter.name,
            },
        });
    };

    return (
        <ListGroup.Item
            as="li"
            bsPrefix="nav-item"
            key={chapter.name}
            style={{
                listStyleType: 'none',
            }}
        >
            <Accordion defaultActiveKey="0" className="navbar-nav flex-column">
                <CustomToggle eventKey={0}>{chapter.name}</CustomToggle>

                <Accordion.Collapse eventKey={0 as any} bsPrefix="nav-item">
                    <ListGroup as="ul" bsPrefix="" className="nav flex-column ps-3 ms-2">
                        {chapter.children &&
                            chapter.children.length > 0 &&
                            chapter.children?.map((child: AssetItem, index: number) => (
                                <Content key={index} data={child} />
                            ))}
                        <div
                            className="d-flex align-items-center w-100 mb-2"
                            onClick={handleOnShow}
                            style={{ cursor: 'pointer' }}
                        >
                            <FaCirclePlus className="me-3" />
                            <div>Add Content</div>
                        </div>
                    </ListGroup>
                </Accordion.Collapse>
            </Accordion>
        </ListGroup.Item>
    );
};



function SideBar() {
    const dispatch = useDispatch();
    const data = useSelector<any>(state => state.ContentReducer.content) as AssetItem[];

    const [sidebarHeight, setSidebarHeight] = useState('80vh');
    const [navHeight, setNavHeight] = useState('74vh');

    const handleResize = () => {
        const windowHeight = window.innerHeight;
        const header = document.getElementById('node-header');
        const headerHeight = header ? header.offsetHeight : 0;

        const newSidebarHeight = `${windowHeight - headerHeight}px`;
        setSidebarHeight(newSidebarHeight);
        setNavHeight(`${windowHeight - headerHeight - 55}px`); // Adjust for padding/margin if needed
    };

    useEffect(() => {
        handleResize(); // Set initial height
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleIsAddChapter = () => dispatch({ type: ContentConstants.IS_ADD_CHAPTER, payload: true });

    return (
        <div
            className="col-3 p-2"
            id="SiderBar"
            style={{
                borderRight: '2px solid',
                height: sidebarHeight,
                position: 'relative',
            }}
        >
            <Button className="d-flex align-items-center w-100 mb-2" onClick={handleIsAddChapter} style={{
                height: '38px'
            }}>
                <FaCirclePlus className="me-3" />
                <div>Add Chapter</div>
            </Button>
            <div className="overflow-y-auto node-nav-bar" style={{ height: navHeight }}>
                {data && data.length ? (
                    groupDataWithChildren(data)?.map((chapter: any, index: number) => {
                        if (chapter.id) {
                            return <ContentPart chapter={chapter} key={index} />;
                        }
                        return null;
                    })
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
}

export default SideBar;
