import React, { useEffect, useRef, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { User, userTypesEnum } from '../../types/user-profille-types';
import { useNavigate } from 'react-router-dom';
import userService from '../../services/user-service';
import { Button, Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import { FRONTEND_URLS } from '../../config';

function AllUsersReadOnly() {
    const [users, setUsers] = useState<User[]>([]);
    const [loading, setLoading] = useState(true);
    const [searchUser, setSearchUser] = useState('');

    const pageRef = useRef<number>(1);
    const limitRef = useRef<number>(100);
    const totalPageRef = useRef<number>(1);
    const navigate = useNavigate();
    const [userType, setUserType] = useState<keyof typeof userTypesEnum>('ALL');
    const [totalRows, setTotalRows] = useState(1);

    const getAllUsers = () => {
        setLoading(true);
        let query = `limit=${limitRef.current}&page=${pageRef.current}&search=${searchUser}`;

        if (userType === 'STAFF') {
            query += `&isStaff=true`;
        } else if (userType === 'STUDENT') {
            query += `&isStaff=false`;
        }

        userService.getAllUsers(query).then((res) => {
            if (res.status === 'success') {
                setUsers(res.data.users);
                if (res.meta.total && res.meta.limit) {
                    setTotalRows(res.meta.total);
                    totalPageRef.current = Math.ceil(res.meta.total / res.meta.limit);
                }
            }
            setLoading(false);
        });
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        pageRef.current = 1; // Reset page when search is triggered
        getAllUsers();
    };

    const handlePageChange = (page: number) => {
        pageRef.current = page;
        getAllUsers();
    };

    const handleLimitChange = (limit: number) => {
        limitRef.current = limit;
        pageRef.current = 1; // Reset to the first page
        getAllUsers();
    };

    useEffect(() => {
        getAllUsers();
    }, []);

    const columns: TableColumn<User>[] = [
        {
            name: 'Name',
            selector: (row) => row.name ?? '',
            sortable: true,
            cell: (row) => (
                <div className='text-breaker' style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                    {row.name ?? ''}
                </div>
            ),
        },
        {
            name: 'Username',
            selector: (row) => row.username,
            sortable: true,
            cell: (row) => (
                <div className='text-breaker' style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                    {row.username}
                </div>
            ),
        },
        {
            name: 'Join At',
            selector: (row) => (row?.createdOn ? String(row.createdOn) : ''),
            sortable: true,
            cell: (row) => (
                <div className='text-breaker' style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                    {row?.createdOn ? String(row.createdOn) : ''}
                </div>
            ),
        },
    ];
    return (
        <>
            <h3>All Users</h3>
            <Form onSubmit={handleSubmit}>
                <Row className="g-3 align-items-center">
                    <Col xs={12} sm={6} md={4} lg={3}>
                        <FloatingLabel controlId="floatingSelect" label="Filter by Type">
                            <Form.Select
                                value={userType}
                                onChange={(e) => {
                                    setUserType(e.target.value as keyof typeof userTypesEnum);
                                }}
                            >
                                <option value={userTypesEnum.ALL}>All</option>
                                <option value={userTypesEnum.STAFF}>Staff</option>
                                <option value={userTypesEnum.STUDENT}>Student</option>
                            </Form.Select>
                        </FloatingLabel>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={4}>
                        <FloatingLabel controlId="SearchUserbyNumber" label="Search User">
                            <Form.Control
                                placeholder="Search User"
                                value={searchUser}
                                onChange={(e) => setSearchUser(e.target.value)}
                            />
                        </FloatingLabel>
                    </Col>
                    <Col xs={12} sm="auto">
                        <Button type="submit" className="w-100">
                            Search
                        </Button>
                    </Col>
                </Row>
            </Form>
            <DataTable
                columns={columns}
                data={users}
                noDataComponent={<div>No Data Available</div>}
                pagination
                paginationPerPage={limitRef.current}
                paginationRowsPerPageOptions={[100, 500, 1000]}
                onRowClicked={(e) => {
                    navigate(`${FRONTEND_URLS.STUDENT.STUDENT_ALL_USERS}/${e.id}`);
                }}
                paginationServer
                pointerOnHover
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handleLimitChange}
                paginationTotalRows={totalRows}
                progressPending={loading}
            />
        </>
    );
}

export default AllUsersReadOnly;
