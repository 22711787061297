import { ProcessItem } from '../../types/course.types';
import ApiService, { ApiResponse } from './api-service';

class ProcessService extends ApiService {
    constructor() {
        super('/processes');
    }

    getAll(): ApiResponse<{ processes: ProcessItem[] }> {
        return this.getData('');
    }
}

export default new ProcessService();
