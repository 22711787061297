import { Card, Col, FloatingLabel, Form, InputGroup, Row, Table } from "react-bootstrap";
import { Action, amountDetailsType, paymentMode, paymentStatus } from "../../pages/bd/order/order-form";
import { FaPlusCircle } from "react-icons/fa";
import { useEffect } from "react";



export default function AmountDetails({ amountDetails, setAmountDetails }: { amountDetails: amountDetailsType, setAmountDetails: React.Dispatch<React.SetStateAction<amountDetailsType>> }) {
    const handleOnChange = (event: React.ChangeEvent<HTMLSelectElement & HTMLInputElement>) => {
        const { checked, value, id } = event.target

        setAmountDetails(
            (prevDetatils) => ({
                ...prevDetatils,
                totalAmount: value
            })
        )
    }

    const handleTableChange = ({ value, id, field }: { value: string, id: number, field: string }) => {

        setAmountDetails((prevDetails) => {
            const paymentDetailExists = prevDetails.paymentDetails.some((paymentDetail, index) => index === id);

            if (paymentDetailExists) {
                return {
                    ...prevDetails,
                    paymentDetails: prevDetails.paymentDetails.map((paymentDetail, index) => {
                        if (index === id) {
                            return {
                                ...paymentDetail,
                                [field]: value,
                                action: paymentDetail.action === Action.New ? Action.New : Action.Edit
                            };
                        }
                        return paymentDetail;
                    })
                };
            }
            return prevDetails
        });
    };

    const handleAddRow = () => {
        const paymentRow = {
            paymentAmount: '',
            mode: null,
            paymentDate: '',
            status: paymentStatus.PENDING,
            comment: '',
            action: Action.New
        }

        setAmountDetails(
            (prevDetails) => ({
                ...prevDetails,
                paymentDetails: [...prevDetails.paymentDetails, paymentRow]
            })
        )
    }

    const handleRemoveRow = (id: number) => {
        setAmountDetails((prevDetails) => ({
            ...prevDetails,
            paymentDetails: prevDetails.paymentDetails.map((row, index) =>
                (index === id) ? { ...row, action: Action.Delete } : row
            )
        }));
    }

    function getCurrentDateFormatted() {
        const today = new Date();

        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const day = String(today.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    }


    useEffect(
        () => {
            const paymentRow = {
                paymentAmount: '',
                mode: null,
                paymentDate: amountDetails.paymentDetails.length === 0 ? getCurrentDateFormatted() : '',
                status: amountDetails.paymentDetails.length === 0 ? paymentStatus.COMPLETE : paymentStatus.PENDING,
                comment: '',
                action: Action.New
            }

            if (amountDetails.paymentDetails.length === 0) {
                setAmountDetails(
                    (prevDetails) => ({
                        ...prevDetails,
                        paymentDetails: [...prevDetails.paymentDetails, paymentRow]
                    })
                )
            }
        }, []
    )



    return (
        <Card className='m-2'>
            <Card.Body>
                <Row>
                    <Col md={4}>

                        <FloatingLabel
                            controlId="totalAmount"
                            label="Total Order Amount"
                            className="mb-3"
                        >
                            <Form.Control
                                type="number"
                                value={amountDetails.totalAmount}
                                onChange={handleOnChange}
                                placeholder="Total Order Amount"
                            />
                        </FloatingLabel>

                    </Col>
                    <Col md={6} />

                    <Col md={2} className='d-flex align-items-center'>

                    </Col>
                </Row>
                <Row className="p=md-1 p-sm-3">
                    {
                        amountDetails.paymentDetails.map((paymentDetail, index) => {
                            return (
                                paymentDetail.action !== Action.Delete && (
                                    <div className="row my-2 justify-content-center g-0 payment-card position-sm-relative" key={index} style={{
                                        padding: '12px',
                                    }}>
                                        <div className="col-12 col-md-2">
                                            <FloatingLabel
                                                controlId={`PaymentAmount-${index}`}
                                                label="Payment Amount"
                                                className="me-2"
                                            >
                                                <Form.Control
                                                    type="number"
                                                    value={paymentDetail.paymentAmount}
                                                    onChange={(e) => handleTableChange({ field: "paymentAmount", value: e.target.value, id: index })}
                                                    placeholder="Payment Amount"
                                                />
                                            </FloatingLabel>
                                        </div>
                                        <div className="col-12 col-md-2">
                                            <FloatingLabel controlId={`Mode-${index}`} label="Mode"
                                                className="me-2"
                                            >
                                                <Form.Select
                                                    aria-label="Mode"
                                                    onChange={(e) => handleTableChange({ field: "mode", value: e.target.value, id: index })}
                                                >
                                                    <option value={''}>Open this select menu</option>
                                                    {
                                                        Object.values(paymentMode).map((mode, idx) => (
                                                            <option key={idx} value={mode} selected={mode === paymentDetail.mode}>{mode}</option>
                                                        ))
                                                    }
                                                </Form.Select>
                                            </FloatingLabel>
                                        </div>
                                        <div className="col-12 col-md-2">
                                            <FloatingLabel controlId={`PaymentDate-${index}`} label="Payment Date"
                                                className="me-2"
                                            >
                                                <Form.Control
                                                    type="date"
                                                    value={paymentDetail.paymentDate ? paymentDetail.paymentDate : ''}
                                                    onChange={(e) => handleTableChange({ field: "paymentDate", value: e.target.value, id: index })}
                                                />
                                            </FloatingLabel>
                                        </div>
                                        <div className="col-12 col-md-2">
                                            <FloatingLabel controlId={`Status-${index}`} label="Status"
                                                className="me-2"
                                            >
                                                <Form.Select
                                                    aria-label="Status"
                                                    onChange={(e) => handleTableChange({ field: "status", value: e.target.value, id: index })}
                                                >
                                                    <option>Open this select menu</option>
                                                    {
                                                        Object.values(paymentStatus).map((status, idx) => (
                                                            <option key={idx} value={status} selected={status === paymentDetail.status}>{status}</option>
                                                        ))
                                                    }
                                                </Form.Select>
                                            </FloatingLabel>
                                        </div>
                                        <div className="col-12 col-md-3">
                                            <FloatingLabel controlId={`Comment-${index}`} label="Comment"
                                                className="me-2"
                                            >
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter comment here"
                                                    value={paymentDetail.comment}
                                                    onChange={(e) => handleTableChange({ field: "comment", value: e.target.value, id: index })}
                                                />
                                            </FloatingLabel>
                                        </div>
                                        {
                                            (index !== 0) ?
                                                <div className="col-12 col-md-1 d-flex align-items-center justify-content-end payment-cross">
                                                    <FaPlusCircle
                                                        color='red'
                                                        size={30}
                                                        style={{ cursor: 'pointer', rotate: '45deg' }}
                                                        fontWeight={40}
                                                        onClick={() => handleRemoveRow(index)}
                                                    />
                                                </div>
                                                :
                                                <div className="col-12 col-md-1 text-center"></div>
                                        }
                                    </div>
                                )
                            );
                        })
                    }
                </Row>
                <Row>
                    <FaPlusCircle color='green' size={30} onClick={handleAddRow} style={{
                        cursor: 'pointer',
                        width: "60px",
                        margin: "auto"
                    }} />
                </Row>
            </Card.Body>
        </Card >
    )
}
