import { Formik } from 'formik';
import { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import DataTable, { TableColumn } from 'react-data-table-component';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { ContentConstants } from '../../Redux/content/content-constants';
import { ModalsConstants } from '../../Redux/models/models-constants';
import { BACKEND_URLS, FRONTEND_URLS } from '../../config';
import { axiosClient } from '../../services/axiosClient';
import nodeServices from '../../services/node.services';
import { ChapterType, LevelInterface } from '../../types/ApiTypes';
import { NodeContentType } from '../../types/node.types';
import { transformAssetData } from '../../utils/helpers';


const ImportSession = () => {
    const schema = yup.object().shape({
        search: yup.string(),
        subject: yup.string(),
        chapter: yup.string(),
    });

    const columns: TableColumn<NodeContentType>[] = [
        {
            name: "Content Id",
            selector: (row) => row.contentId,
            cell: (row: any) => <div
                data-tag="allowRowEvents"
                className='text-breaker'
            > {row.contentId} </div>
        },
        {
            name: "Name",
            selector: (row) => row.internalName,
            cell: (row: any) => <div
                data-tag="allowRowEvents"
                className='text-breaker'
            > {row.internalName} </div>
        },
        {
            name: "Update On",
            selector: (row) => row.assetCreatedOn.split('T')[0],
            cell: (row) => <div
                data-tag="allowRowEvents"
                className='text-breaker'
            > {row.assetCreatedOn.split('T')[0]} </div>
        },
        {
            name: "Chapter",
            selector: (row) => row.level2InternalName,
            cell: (row) => <div
                data-tag="allowRowEvents"
                className='text-breaker'
            > {row.level2InternalName}</div>
        },
    ];

    const subjectData = useSelector<any>(
        (state) => state.ContentReducer.subject
    ) as LevelInterface[];
    const selectedChapter = useSelector<any>(
        (state) => state.ContentReducer.selectedChapter
    ) as { level2Id: number; level2Name: number };

    const [chapterData, setChapterData] = useState<ChapterType[]>([]);
    const [data, setData] = useState<NodeContentType[]>([]);
    const [selectedSubject, setSelectedSubject] = useState<string>('');
    const [selectedContent, setSelectedContent] = useState<any>()
    const [selectedRow, setSelectedRow] = useState<number | null>(null);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { nodeId } = useParams();
    const [loading, setLoading] = useState(false)


    const handleAddContent = () => {
        const data = transformAssetData(selectedContent)
        let contentId = -1
        if (selectedContent.level2Id == selectedChapter.level2Id) {
            contentId = selectedContent.contentId
            dispatch({ type: ContentConstants.ADD_CONTENT, payload: { ...data, "isNew": true, isImported: true, id: selectedContent.contentId, level2Id: selectedChapter.level2Id, level2Name: selectedChapter.level2Name, newLevel2Id: selectedContent.level2Id, newLevel2Name: selectedContent.level2Name } })
        }
        else {
            contentId = -1
            dispatch({ type: ContentConstants.ADD_CONTENT, payload: { ...data, "isNew": true, isImported: true, level2Id: selectedChapter.level2Id, level2Name: selectedChapter.level2Name, id: -1, newLevel2Id: selectedContent.level2Id, newLevel2Name: selectedContent.level2Name } })
        }
        dispatch({ type: ModalsConstants.IS_Modal, payload: false })
        navigate(FRONTEND_URLS.CONTENTS.EDIT_NODE + `/${nodeId}` + '/' + FRONTEND_URLS.CONTENTS.ASSET + '/' + contentId)
    }

    const handleSubjectChange = async (
        e: React.ChangeEvent<HTMLSelectElement>
    ) => {
        const subjectId = e.target.value;
        setSelectedSubject(subjectId);

        if (subjectId) {
            try {
                const { data } = await axiosClient.get(
                    BACKEND_URLS.LEVEL + 2 + `?level1Id=${subjectId}`
                );
                setChapterData(data.data.level);
            } catch (error: any) {
                toast.error(error.message);
            }
        } else {
            setChapterData([]);
        }
    };

    const handleFilter = async (values: {
        search?: string;
        subject?: string;
        chapter?: string;
    }) => {
        let query = "";

        if (values.search) {
            query += `&name=${values.search}`;
        }
        if (values.subject) {
            query += `&level1Id=${values.subject}`;
        }
        if (values.chapter) {
            query += `&level2Id=${values.chapter}`;
        }

        try {
            const { data } = await nodeServices.getContentType({
                assetType: 'session',
                name: values.search,
                level1Id: values.subject,
                level2Id: values.chapter
            })

            // const { data } = await axiosClient.get("/content?" + query);
            setData(data);
            setLoading(false);
        } catch (error: any) {
            toast.error(error.message)
        }
    };


    // const handleFilter = async (values: {
    //     search?: string;
    //     subject?: string;
    //     chapter?: string;
    // }) => {
    //     let query = "assetType=SESSION";

    //     if (values.search) {
    //         query += `&internalName=${values.search}`;
    //     }
    //     if (values.subject) {
    //         query += `&level1Id=${values.subject}`;
    //     }
    //     if (values.chapter) {
    //         query += `&level2Id=${values.chapter}`;
    //     }

    //     try {
    //         const data = await sessionService.getSessions('?' + query)
    //         setData(data.data);
    //     } catch (error: any) {
    //         toast.error(error.message);
    //     }
    // };


    const handleRowSelected = ({ selectedRows }: { selectedRows: NodeContentType[] }) => {
        setSelectedRow((selectedRows[0] && selectedRows[0].assetId) ? selectedRows[0].assetId : null);
        setSelectedContent(selectedRows[0])
    };

    const conditionalRowStyles = [
        {
            when: (row: any) => row.id === selectedRow,
            style: {
                backgroundColor: '#d4edda',
                color: 'black',
            },
        },
    ];


    return (
        <>
            <Formik
                validationSchema={schema}
                onSubmit={(values) => {
                    handleFilter(values);
                }}
                initialValues={{
                    search: '',
                    subject: '',
                    chapter: '',
                }}
            >
                {({ handleSubmit, handleChange, values, touched, errors }) => (
                    <Form noValidate onSubmit={handleSubmit} className='p-2'>
                        {/* Search input */}
                        <Form.Group controlId="validationFormik04" className="mb-3 row align-items-center">
                            <Form.Label className="col-sm-3 col-form-label">Search</Form.Label>
                            <div className="col-sm-9">
                                <Form.Control
                                    type="text"
                                    placeholder="search here..."
                                    name="search"
                                    value={values.search}
                                    onChange={handleChange}
                                    isInvalid={touched.search && !!errors.search}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.search}
                                </Form.Control.Feedback>
                            </div>
                        </Form.Group>

                        {/* Subject Select */}
                        <Form.Group className="mb-3 row align-items-center">
                            <Form.Label className="col-sm-3 col-form-label">Subject</Form.Label>
                            <div className="col-sm-9">
                                <Form.Select
                                    name='subject'
                                    onChange={(e) => {
                                        handleChange(e);
                                        handleSubjectChange(e);
                                    }}
                                    value={values.subject}
                                    isInvalid={touched.subject && !!errors.subject}
                                >
                                    <option value="">Select a subject</option>
                                    {subjectData.map((subject) => (
                                        <option key={subject.id} value={subject.id}>
                                            {subject.internalName}
                                        </option>
                                    ))}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    {errors.subject}
                                </Form.Control.Feedback>
                            </div>
                        </Form.Group>

                        {/* Chapter Select */}
                        {chapterData.length > 0 && (
                            <Form.Group className="mb-3 row align-items-center">
                                <Form.Label className="col-sm-3 col-form-label">Chapter</Form.Label>
                                <div className="col-sm-9">
                                    <Form.Select
                                        name='chapter'
                                        onChange={handleChange}
                                        value={values.chapter}
                                        isInvalid={touched.chapter && !!errors.chapter}
                                    >
                                        <option value="">Select a Chapter</option>
                                        {chapterData.map((chapter) => (
                                            <option key={chapter.id} value={chapter.id}>
                                                {chapter.internalName}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.chapter}
                                    </Form.Control.Feedback>
                                </div>
                            </Form.Group>
                        )}

                        <Button type='submit' variant="primary" disabled={loading}>Filter</Button>
                    </Form>
                )}
            </Formik>

            <hr />

            <DataTable
                columns={columns}
                data={data}
                pagination
                paginationPerPage={100}
                paginationRowsPerPageOptions={[100, 500, 1000]}
                selectableRowsSingle
                onSelectedRowsChange={handleRowSelected}
                selectableRows
                conditionalRowStyles={conditionalRowStyles}
            />
            <Button disabled={!selectedContent} onClick={handleAddContent}>Add</Button>
        </>
    );
};


export default ImportSession