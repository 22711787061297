import { useEffect, useState } from 'react';
import { Button, Modal, Form, FloatingLabel } from 'react-bootstrap';


type ServiesModalInterface = {
  show: boolean;
  setShow: (e: boolean) => void; // Explicitly set the type for the parameter
  handleSubmit: ({ name, minAmount }: { name: string; minAmount: number }) => void; // Explicitly typed parameters for name and id,
  hardwareName?: string,
  id?: number,
  hardwareMinAmount?: number
};



const HardwareModal = ({
  show,
  setShow,
  handleSubmit,
  hardwareName,
  id,
  hardwareMinAmount
}: ServiesModalInterface) => {

  const [name, setName] = useState('');
  const [minAmount, setMinAmount] = useState(0);

  const handleClose = () => {
    setShow(false);
  }

  const handleForm = () => {
    handleSubmit({ name, minAmount })
    setName('')
    setMinAmount(0)
  }

  useEffect(() => {
    if (hardwareName && hardwareMinAmount) {
      setName(hardwareName);
      setMinAmount(hardwareMinAmount)
    } else {
      setName('');
    }
  }, [hardwareName, hardwareMinAmount]);


  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{id ? `Edit Hardware` : `Create Hardware`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formName">
              <FloatingLabel
                controlId="HardwareName"
                label="Hardware Name"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  placeholder="Enter Hardware name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </FloatingLabel>
            </Form.Group>
          </Form>

          <Form>
            <Form.Group controlId="formName">
              <FloatingLabel
                controlId="MinAmount"
                label="Min Amount"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="Enter min Amount"
                  value={minAmount}
                  onChange={(e) => setMinAmount(parseInt(e.target.value))}
                />
              </FloatingLabel>
            </Form.Group>
          </Form>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleForm}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default HardwareModal;
