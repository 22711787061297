import { ButtonHTMLAttributes } from 'react';
import './styles.css';

type Props = {
    variant:
        | 'btn-1'
        | 'btn-2'
        | 'btn-3'
        | 'btn-4'
        | 'btn-5'
        | 'btn-6'
        | 'btn-7'
        | 'btn-8'
        | 'btn-9'
        | 'btn-10'
        | 'btn-11'
        | 'btn-12'
        | 'btn-13'
        | 'btn-14'
        | 'btn-15'
        | 'btn-16';
    text: string;
} & ButtonHTMLAttributes<any>;

const SpecialButton = ({ variant = 'btn-1', text = 'Read More', onClick = () => {},...rest }: Props) => {
    const useSpan = ['btn-3', 'btn-4', 'btn-5', 'btn-6', 'btn-7', 'btn-8', 'btn-12'].includes(
        variant
    );

    return (
        <button className={`custom-btn ${variant}`} onClick={onClick} {...rest}>
            {useSpan ? <span>{text}</span> : text}
            {variant === 'btn-11' && <div className="dot"></div>}{' '}
            {variant === 'btn-12' && <span>{text}</span>}
        </button>
    );
};

export default SpecialButton;
