import { PaymentGatewayItem, PaymentGatewayListItem } from '../../types/payment-gateway-types';
import ApiService, { ApiResponse } from './api-service';

class PaymentGatewayService extends ApiService {
    constructor() {
        super('/payment-gateways');
    }

    getGateWays(): ApiResponse<{ paymentGateways: PaymentGatewayListItem[] }> {
        return this.getData('');
    }

    getGatewayDetails(id: number): ApiResponse<{ paymentGateway: PaymentGatewayItem }> {
        return this.getData(`/${id}`);
    }

    createGateway(data: { slug: string; isActive: boolean; apiKey: string; apiSecret: string }) {
        return this.postData('', data);
    }
    updateGateway(
        id: number,
        data: { slug?: string; isActive?: boolean; apiKey?: string; apiSecret?: string }
    ) {
        return this.putData(`/${id}`, data);
    }
}

export default new PaymentGatewayService();
