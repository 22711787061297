import React, { useState, ChangeEvent, FormEvent } from 'react';
import { Form, Button, Row, Col, InputGroup, Modal } from 'react-bootstrap';
import './styles.css';
import { TestimonialPayload, TestimonialResp } from '../../types/testimonialTypes';
import { pdfUploadHelper } from '../../utils/helpers';
import toast from 'react-hot-toast';
import UploadPromotionalVideo from '../course/upload-video';

interface TestimonialFormProps {
    initialData?: TestimonialResp;
    onSubmit: (data: TestimonialPayload, e?: number) => void;
    submitLabel?: string;
}

const TestimonialForm: React.FC<TestimonialFormProps> = ({
    initialData,
    onSubmit,
    submitLabel = 'Save',
}) => {
    const [formData, setFormData] = useState<TestimonialPayload>({
        name: initialData?.name || '',
        label: initialData?.label || '',
        message: initialData?.message || '',
        videoId: initialData?.videoId || undefined,
        imageUrl: initialData?.imageUrl || '',
    });
    const [showVideoModel, setShowVideoModel] = useState(false);

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: name === 'videoId' ? Number(value) : value,
        }));
    };

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (!formData.name) return toast.error('Name is required');
        if (!formData.label) return toast.error('Label is required');
        if (!formData.message) return toast.error('Message is required');
        if (!formData.imageUrl) return toast.error('Image is required');
        if (initialData?.id) onSubmit(formData, initialData.id);
        else onSubmit(formData);
    };

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files?.length) {
            let file = files[0];
            pdfUploadHelper(file).then((res) => {
                setFormData((prev) => ({ ...prev, imageUrl: res }));
            });
        }
    };

    return (
        <Form className="testimonial-form-container" onSubmit={handleSubmit}>
            <Form.Group controlId="testimonialName" className="mb-3">
                <Form.Label>Student Name</Form.Label>
                <InputGroup>
                    <Form.Control
                        required
                        type="text"
                        placeholder="Enter Student name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                    />
                </InputGroup>
            </Form.Group>

            <Form.Group controlId="testimonialLabel" className="mb-3">
                <Form.Label>HighLight</Form.Label>
                <InputGroup>
                    <Form.Control
                        required
                        type="text"
                        name="label"
                        value={formData.label}
                        onChange={handleChange}
                    />
                </InputGroup>
            </Form.Group>

            <Form.Group controlId="testimonialMessage" className="mb-3">
                <Form.Label>Message</Form.Label>
                <Form.Control
                    required
                    as="textarea"
                    rows={4}
                    placeholder="Enter the testimonial message..."
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                />
            </Form.Group>

            <Form.Group controlId="testimonialImageUrl" className="mb-3">
                <Form.Label>Image</Form.Label>
                <InputGroup>
                    <Form.Control
                        type="file"
                        accept="image/*"
                        name="imageFile"
                        onChange={handleFileChange}
                    />
                </InputGroup>
                <small style={{ fontSize: 10 }}>{formData.imageUrl}</small>
            </Form.Group>

            <Form.Group
                controlId="testimonialVideoId"
                className="mb-3 border border-light-subtle rounded p-2"
            >
                <Form.Label className="fw-bold">Video (Optional)</Form.Label>
                <div className="d-flex align-items-center">
                    {formData.videoId && (
                        <span className="me-3">Current Video ID: {formData.videoId}</span>
                    )}
                </div>
                <UploadPromotionalVideo
                    onSuccess={(d) => setFormData((p) => ({ ...p, videoId: d.id }))}
                />
            </Form.Group>

            <Row>
                <Col className="text-end">
                    <Button variant="success" type="submit" className="px-4">
                        {submitLabel}
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

export default TestimonialForm;
