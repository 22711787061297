import { Formik } from "formik";
import { Button, Form } from "react-bootstrap";
import { ContentConstants } from "../../Redux/content/content-constants";
import { ModalsConstants } from "../../Redux/models/models-constants";
import { BACKEND_URLS, FRONTEND_URLS } from "../../config";
import { AssetType, VideoSource } from "../../types/types";
import { axiosClient } from "../../services/axiosClient";
import { getPosterUrls } from "../../utils/helpers";
import * as yup from 'yup';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { useState } from "react";

const VideoLink = () => {
    const schema = yup.object().shape({
        videoID: yup.string().required('Video ID is required'),
    });

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { nodeId } = useParams();
    const [loading, setLoading] = useState(false)

    const selectedChapter = useSelector<any>(
        (state) => state.ContentReducer.selectedChapter
    ) as { level2Id: number; level2Name: number };


    const handleVideoLink = async (values: {
        videoID: string,
    }) => {
        setLoading(true)

        let fileName = '',
            durInSec = 0,
            source = VideoSource.VDOCIPHER,
            thumbnailUrlLr = '',
            thumbnailUrlHr = '';

        const fetchVideoDetails = async (videoID: string, sources: VideoSource[]) => {
            if (videoID && /^[0-9]+$/.test(videoID)) {
                const response = await axiosClient.get(`${BACKEND_URLS.FETCH_VIDEO_DETAILS}/${videoID}/${VideoSource.VIMEO}`);
                const data = response.data.data;
                return {...data, thumbnailUrlHr: data.thumbnailHr, thumbnailUrlLr:data.thumbnailLr }
            }
            else {

                for (const source of sources) {
                    try {
                        const response = await axiosClient.get(`${BACKEND_URLS.FETCH_VIDEO_DETAILS}/${videoID}/${source}`);
                        const data = response.data.data;
                        const posters = getPosterUrls(data.posters);
                        return {
                            source,
                            fileName: data.title,
                            durInSec: data.length,
                            thumbnailUrlHr: posters.hrUrl,
                            thumbnailUrlLr: posters.lrUrl,
                        };
                    } catch (error: any) {
                        console.error(`Failed to fetch from ${source}: ${error.message}`);
                    }
                }
            }
            throw new Error('No video found for this video ID.');
        };

        const loadVideoData = async (videoID: string) => {
            try {
                const videoData = await fetchVideoDetails(videoID, [
                    VideoSource.VDOCIPHER,
                    VideoSource.VDOCIPHER_SECURE,
                ]);

                // Assign the fetched data to external variables for later use
                ({
                    fileName,
                    durInSec,
                    source,
                    thumbnailUrlHr,
                    thumbnailUrlLr
                } = videoData);

                toast.success('Video details loaded successfully: ' + fileName);
                return true

            } catch (error: any) {
                toast.error(error.message);
                return false
            }
        };
        const isVideoLoaded = await loadVideoData(values.videoID)
        if (!isVideoLoaded) {
            return
        }



        const assetData = {
            "source": source,
            "internalName": fileName ?? '',
            "fileName": fileName ?? '',
            "durInSec": durInSec,
            "videoId": `${values.videoID}`,
            "thumbnailHr": thumbnailUrlHr,
            "thumbnailLr": thumbnailUrlLr,
        }

        const assetId = await axiosClient.post(
            BACKEND_URLS.ASSET_CREATE, assetData
        )

        const content = {
            isNew: true,
            id: -1,
            name: fileName,
            assetType: AssetType.VIDEO,
            assetId: assetId.data.data.id,
            type: "",
            level2Id: selectedChapter.level2Id,
            level2Name: selectedChapter.level2Name,
            level3Id: null,
            parentAssetType: "null",
            parentAssetId: 0,
            isFree: false,
            createdOn: "",
            assetData: {
                id: assetId.data.data.id,
                source: source,
                videoId: values.videoID,
                thumbnailUrlLr: thumbnailUrlLr,
                thumbnailUrlHr: thumbnailUrlHr,
                durInSec,
                internalName: fileName,
                fileName,
            },
        };

        dispatch({ type: ContentConstants.ADD_CONTENT, payload: content });
        dispatch({ type: ModalsConstants.IS_Modal, payload: false });
        setLoading(false)
        navigate(
            FRONTEND_URLS.CONTENTS.EDIT_NODE +
            `/${nodeId}` +
            "/" +
            FRONTEND_URLS.CONTENTS.ASSET
            + '/' + content.id
        );
    };

    return (
        <Formik
            validationSchema={schema}
            onSubmit={(values) => handleVideoLink(values)}

            initialValues={{
                videoID: '',
                // platform: '',
                // videoName: ''
            }}
        >
            {({ handleSubmit, handleChange, setFieldValue, values, touched, errors }) => (
                <Form noValidate onSubmit={handleSubmit} className='p-2'>
                    <Form.Group controlId="validationFormik04" className="mb-3 row align-items-center">
                        <Form.Label className="col-sm-3 col-form-label">Video ID</Form.Label>
                        <div className="col-sm-9">
                            <Form.Control
                                type="text"
                                placeholder="Video ID"
                                name="videoID"
                                value={values.videoID}
                                onChange={handleChange}
                                isInvalid={touched.videoID && !!errors.videoID}
                            />
                            <Form.Control.Feedback type="invalid" className="text-danger">
                                {errors.videoID}
                            </Form.Control.Feedback>
                        </div>
                    </Form.Group>

                    <Button type='submit' variant="primary" disabled={loading}>Add</Button>
                </Form>
            )
            }
        </Formik>
    );
}


export default VideoLink