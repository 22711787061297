import { LevelMapResponse } from '../../types/levelTypes';
import ApiService, { ApiResponse } from './api-service';

class LevelService extends ApiService {
    constructor() {
        super('/level');
    }

    getItems<K extends keyof LevelMapResponse>(
        levelId: K,
        query?: any
    ): ApiResponse<{ level: LevelMapResponse[K][] }> {
        return this.getData(`/${levelId}${!!query ? query : ''}`);
    }
}

export default new LevelService();
