import ApiService, { ApiResponse } from './api-service';

class GeneralService extends ApiService {
    constructor() {
        super('');
    }

    getSettings<T extends Record<string, any>, K extends Array<keyof T>>(
        values: K
    ): ApiResponse<Pick<T, K[number]>> {
        return this.postData('/settings', values);
    }

    updateVideo(data: { duration: number; videoId: number }): ApiResponse {
        return this.putData(`/asset/upload-video/${data.videoId}`, { duration: data.duration });
    }

    getVideoOtp(data: { source: 'VDOCIPHER_SECURE'; videoId: string; otpDuration: number }) {
        return this.postData('/content/vdo-cipher-otp', data);
    }
}

export default new GeneralService();
