import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { PiCrownFill, PiCrownLight } from 'react-icons/pi';
import { IoMdAdd, IoMdClose } from 'react-icons/io';
import CourseService from '../../../services/ApiServices/course-service';
import SetService from '../../../services/ApiServices/set-service';
import { CourseSetItem, SetItem } from '../../../types/setTypes';
import { Button, FloatingLabel, Form, Modal } from 'react-bootstrap';
import CustomCheckbox from '../../common/custom-checkbox';
import { getMediaUrl } from '../../../utils/helpers';
import toast from 'react-hot-toast';

type Props = {
    courseSets: CourseSetItem[];
};

const SetTabs = ({ courseSets }: Props) => {
    const [sets, setSets] = useState<SetItem[]>([]);
    const [addedIds, setAddedIds] = useState<number[]>(courseSets.map((set) => set.setId));
    const [updateEnrolled, setUpdateEnrolled] = useState(false);
    const [action, setAction] = useState<'add' | 'remove'>('add');
    const [addedSets, setAddedSets] = useState<CourseSetItem[]>(courseSets);
    const [query, setQuery] = useState<string>('');
    const [addedSetQuery, setAddedSetQuery] = useState<string>('');
    const { courseId } = useParams();
    const [show, setShow] = useState(false);
    const [currData, setCurrData] = useState<SetItem | CourseSetItem | null>(null);

    const handleShow = () => setShow((prev) => !prev);

    useEffect(() => {
        SetService.getAll().then((res) => setSets(res.data.sets));
    }, []);

    const fetchCourseDetails = () => {
        if (courseId) {
            CourseService.getCourseById(+courseId).then((res) => {
                const course = res.data.course;
                setAddedSets(course.sets);
                setAddedIds(course.sets.map((set: CourseSetItem) => set.setId));
            });
        }
    };

    const filteredSets = sets
        .filter(
            (set) =>
                set.name.toLowerCase().includes(query.toLowerCase()) && !addedIds.includes(set.id)
        )
        .sort(
            (a, b) =>
                new Date(b?.updatedOn ?? '').getTime() - new Date(a?.updatedOn ?? '').getTime()
        ); // Sort by updatedOn in descending order

    const filteredAddedSets = addedSets.filter((set) =>
        set.name.toLowerCase().includes(addedSetQuery.toLowerCase())
    );

    const addSetToCourse = (set: CourseSetItem) => {
        const data = {
            setId: set.id,
            isPrimary: set?.isPrimary || false,
            order: 1,
            ...(updateEnrolled && { updateEnrolled }),
        };
        if (courseId) {
            CourseService.addSet(+courseId, data).then((res) => {
                setUpdateEnrolled(false);
                if (res.status === 'success') {
                    fetchCourseDetails();
                } else toast.error(res.message);
            });
        }
    };

    const removeSetFromCourse = (set: CourseSetItem) => {
        if (courseId) {
            CourseService.removeSet(+courseId, {
                setId: set.setId,
                ...(updateEnrolled && { updateEnrolled }),
            }).then((res) => {
                setUpdateEnrolled(false);
                if (res.status === 'success') {
                    fetchCourseDetails();
                } else toast.error(res.message);
            });
        }
    };

    const updateCourseSetStatus = (id: number, isPrimary: boolean) => {
        const data = { courseSetId: id, isPrimary };
        CourseService.updateSet(id, data).then((res) => {
            if (res.status === 'success') {
                setUpdateEnrolled(false);
                fetchCourseDetails();
            } else toast.error(res.message);
        });
    };

    const handleModelClick = () => {
        if (action === 'add' && currData) {
            addSetToCourse(currData as CourseSetItem);
        } else if (action === 'remove' && currData) {
            removeSetFromCourse(currData as CourseSetItem);
        }
        handleShow();
    };

    const handleAddClick = (set: SetItem) => {
        if (!addedSets || addedSets.length == 0) {
            setCurrData({ ...set, isPrimary: true });
        } else {
            setCurrData(set);
        }
        setAction('add');
        handleShow();
    };

    const handleRemoveClick = (set: CourseSetItem) => {
        setCurrData(set);
        setAction('remove');
        handleShow();
    };

    return (
        <div className="row sets">
            <Modal show={show} onHide={handleShow}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>Do you really want to {action} this set?</h5>
                    <CustomCheckbox
                        checked={updateEnrolled}
                        onChecked={(_, v) => setUpdateEnrolled(v)}
                        label={
                            action == 'add'
                                ? 'Assign this set to existing students also?'
                                : 'Remove this set to existing students also?'
                        }
                    />
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="danger" onClick={handleModelClick}>
                        Yes
                    </Button>
                    <Button variant="secondary" onClick={handleShow}>
                        No
                    </Button>
                </Modal.Footer>
            </Modal>

            <div className="col-md-6">
                <h2>Available Sets</h2>
                <div className="my-4">
                    <input
                        type="text"
                        placeholder="Search sets"
                        className="form-control"
                        onChange={(e) => setQuery(e.target.value)}
                    />
                </div>
                <div className="my-3 container row gap-3 mx-auto">
                    {filteredSets.map((set) => (
                        <div className="card col-md-3 px-0" key={set.id}>
                            <img
                                className="w-100"
                                src={getMediaUrl(set.thumbnail)}
                                style={{ aspectRatio: '16/9' }}
                                alt={set.name}
                            />
                            <div className="card-body">
                                <p className="mb-0" style={{ fontSize: '14px' }}>
                                    <Link to={`/contents/edit-set/${set.id}`} target="_blank">
                                        {set.name}
                                    </Link>
                                </p>
                            </div>
                            <div className="card-footer">
                                <a
                                    onClick={() => handleAddClick(set)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <IoMdAdd size={20} className="mx-auto" />
                                </a>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="col-md-6" style={{ borderLeft: '1px solid #c3c3c3' }}>
                <h2>Added Sets</h2>
                <div className="my-4">
                    <input
                        type="text"
                        placeholder="Search sets"
                        className="form-control"
                        onChange={(e) => setAddedSetQuery(e.target.value)}
                    />
                </div>
                <div className="my-3 container row gap-3 mx-auto">
                    {filteredAddedSets.map((set) => (
                        <div className="card col-md-3 px-0" key={set.setId}>
                            <img
                                className="w-100"
                                src={getMediaUrl(set.thumbnail) || ''}
                                style={{ aspectRatio: '16/9' }}
                                alt={set.name}
                            />
                            <div className="card-body">
                                <p className="card-title mb-0" style={{ fontSize: '14px' }}>
                                    <Link to={`/contents/edit-set/${set.id}`} target="_blank">
                                        {set.name}
                                    </Link>
                                </p>
                            </div>
                            <div className="card-footer d-flex">
                                <a className="col-6" onClick={() => handleRemoveClick(set)}>
                                    <IoMdClose
                                        style={{ cursor: 'pointer' }}
                                        size={20}
                                        className="mx-auto text-danger"
                                    />
                                </a>
                                <div
                                    className="col-6"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => updateCourseSetStatus(set.id, !set.isPrimary)}
                                >
                                    {set.isPrimary ? (
                                        <PiCrownFill size={20} className="mx-auto text-primary" />
                                    ) : (
                                        <PiCrownLight size={20} className="mx-auto text-primary" />
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default SetTabs;
