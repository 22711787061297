export const userPracticeConstants = {
  PRACTICE_REQUEST: "PRACTICE_REQUEST",
  GET_PRACTICE_DATA: "GET_PRACTICE_DATA",
  UPDATE_PRACTICE_DATA: "UPDATE_PRACTICE_DATA",
  UPDATE_CURRENT_INDEX: "UPDATE_CURRENT_INDEX",
  UPDATE_PRACTICE_SHOW_SOLUTION: "UPDATE_PRACTICE_SHOW_SOLUTION",
  SUBMIT_PRACTICE_ANSWER: "SUBMIT_PRACTICE_ANSWER",

  PRACTICE_REQUEST_FAILED: "PRACTICE_REQUEST_FAILED",
  BOTTOM_OFF_CANVAS: "BOTTOM_OFF_CANVAS",
};
