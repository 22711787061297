export const correct_response_variants = [
    "Bingo! You've nailed it! 😄",
    'Awesome! You got it right! 👍',
    'Ding, ding, ding! Correct answer! 🎉',
    "You're on fire! Absolutely right! 🔥",
    'Whoop, there it is! Your answer is spot-on! 🎯',
    'Bravo! You hit the bullseye with your answer! 👏',
    'Hip, hip, hooray! Your response is correct! 🙌',
    'Way to go! Your answer is a winner! 🏆',
    "Oh yeah! That's the right answer, my friend! 😊",
    "You're crushing it! Your answer is absolutely correct! 💪",
];

export const incorrect_response_variants = [
    'You made some mistake! Give it another shot. 😊',
    "Oops! Not quite, but don't give up! 😅",
    'Not quite what we were looking for, but keep trying! 🤔',
    "Hmm, not quite on target. You'll get it next time! 👍",
    "Nice try, but let's give it another go. 💪",
    "Not the right answer, but don't let that discourage you! 😃",
    'Not quite, but keep up the great effort! 🌟',
    'Close, but not quite there yet. Keep pushing! 🔥',
    "That's not it, but keep thinking and trying! 💡",
    "Not the answer we were expecting, but keep going! You're doing great! 🎉",
];

export const practiceQuestionTypeLabel = [
    'SINGLE CORRECT TYPE',
    'ONE OR MORE THAN ONE CORRECT TYPE',
    'NUMERICAL TYPE',
    'PARAGRAPH TYPE (SINGLE CORRECT)',
    'PARAGRAPH TYPE (ONE OR MORE THAN ONE CORRECT)',
    'SUBJECTIVE TYPE',
    // "MATCHING LIST TYPE",
    'MATCH THE COLUMN TYPE',
    // "ASSERTION AND REASON",
];
export const quizQuestionTypeLabel = [
    'SINGLE CORRECT TYPE',
    'ONE OR MORE THAN ONE CORRECT TYPE',
    'NUMERICAL TYPE',
    'PARAGRAPH TYPE (SINGLE CORRECT)',
    'PARAGRAPH TYPE (ONE OR MORE THAN ONE CORRECT)',
    'SUBJECTIVE TYPE',
    // "MATCHING LIST TYPE",
    'MATCH THE COLUMN TYPE',
    // "ASSERTION AND REASON",
];

export const sessionType = ['ZOOM', 'CLASSROOM', 'YOUTUBE'];
