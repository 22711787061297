import axios from 'axios';
import { useState } from 'react';
import DataTable from 'react-data-table-component';
import toast from 'react-hot-toast';
import { useLoaderData } from 'react-router-dom';
import MainBtn from '../../components/btn/main-btn';
import ExamTargetModal from '../../components/modals/create-exam-target-modal';
import { BACKEND_URLS } from '../../config';
import { axiosClient } from '../../services/axiosClient';
import { TargetInterface, TargetsApiInterface } from '../../types/ApiTypes';
import { Modal } from 'react-bootstrap';

export async function loader(): Promise<TargetsApiInterface> {
    const data = await axiosClient.get(BACKEND_URLS.TARGETS);
    return data.data;
}

function ExamTargets() {
    const { data } = useLoaderData() as TargetsApiInterface;
    const [serviceData, setServiceData] = useState<TargetInterface[]>(data.targets);
    const [show, setShow] = useState<boolean>(false);
    const [id, setId] = useState<number | undefined>(undefined);
    const [name, setName] = useState<string | undefined>(undefined);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const handleClick = (row: any) => {
        setId(row.id);
        setName(row.name);
        setShow(true);
    };

    const servicesColumns = [
        {
            name: 'Service Name',
            selector: (row: TargetInterface) => row.name,
            cell: (row: TargetInterface) => (
                <div
                    data-tag="allowRowEvents"
                    className='text-breaker'
                >
                    {' '}
                    {row.name}{' '}
                </div>
            ),
        },
        {
            name: 'Created By',
            selector: (row: TargetInterface) => row.createdBy?.name || '',
            cell: (row: TargetInterface) => (
                <div
                    data-tag="allowRowEvents"
                    className='text-breaker'
                >
                    {' '}
                    {row.createdBy?.name || ''}{' '}
                </div>
            ),
        },
        {
            name: 'Created On',
            selector: (row: TargetInterface) => row.createdOn.split('T')[0],
            cell: (row: TargetInterface) => (
                <div
                    data-tag="allowRowEvents"
                    className='text-breaker'
                >
                    {' '}
                    {row.createdOn.split('T')[0]}{' '}
                </div>
            ),
        },
        {
            name: 'Action',
            cell: (row: TargetInterface) => (
                <button className="btn btn-primary" onClick={() => handleClick(row)}>
                    Edit
                </button>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    const handleSubmit = async ({
        name,
        testimonialIds,
    }: {
        name: string;
        testimonialIds?: number[];
    }) => {
        try {
            if (id) {
                const res = await axiosClient.put(BACKEND_URLS.TARGETS + `/` + id, {
                    name: name,
                });

                if (res.status === 200) {
                    toast.success('targets Item updated successfully!');
                } else {
                    toast.error(`Failed to create targets. Status: ${res.status}`);
                }
            } else {
                const res = await axiosClient.post(BACKEND_URLS.TARGETS, {
                    name: name,
                    testimonialIds,
                });

                if (res.status === 201) {
                    toast.success('targets created successfully!');
                } else {
                    toast.error(`Failed to create targets. Status: ${res.status}`);
                }
            }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                toast.error(
                    `Error: ${error.response?.data?.message || 'Failed to create targets.'}`
                );
            } else {
                toast.error('An unexpected error occurred.');
            }
        } finally {
            setShow(false);
            setId(undefined);
            setName(undefined);
            const res = await axiosClient.get(BACKEND_URLS.TARGETS);
            setServiceData(res.data.data.targets);
        }
    };

    return (
        <div className="mx-4">
            <h2>Targets</h2>

            <DataTable
                columns={servicesColumns}
                data={serviceData} // Make sure to pass the correct data array here
                pagination
                paginationPerPage={100}
                paginationRowsPerPageOptions={[100, 500, 1000]}
                customStyles={{
                    table: {
                        style: {
                            width: '90%',
                            margin: 'auto',
                        },
                    },
                }}
            />
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{id ? `Edit Exam Target` : `Create Exam Target`}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ExamTargetModal handleSubmit={handleSubmit} id={id} serviceName={name} />
                </Modal.Body>
            </Modal>

            <MainBtn
                message="create targets"
                onClick={() => {
                    setId(undefined);
                    setName('');
                    handleShow();
                }}
            />
        </div>
    );
}

export default ExamTargets;
