import React from "react";

export default function LoadingIndicator() {
  return (
    <div className="spinner-container">
      <div className="loading-div">
        <div className="loading-spinner" />
      </div>
    </div>
  );
}
