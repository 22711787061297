import Moment from 'moment';

type TimeStampToStrReturnType<T extends boolean> = T extends true
    ? string
    : {
          date: string;
          time: string;
          date_in_yyyy_mm_dd: string;
      };

export default {
    format: function (date: any) {
        return Moment(date).format('DD MMM, YYYY hh:mm A');
    },

    timeStampToStr: function <T extends boolean>(
        timestamp: any,
        formate: T = false as T
    ): TimeStampToStrReturnType<T> {
        const date = new Date(timestamp);
        const dd = date.getDate();
        const mm = date.getMonth() + 1;
        const yyyy = date.getFullYear();
        const hh = date.getHours();
        const min = date.getMinutes();

        const _dd = dd < 10 ? `0${dd}` : dd;
        const _mm = mm < 10 ? `0${mm}` : mm;
        const _hh = hh === 0 ? 12 : hh % 12 < 10 ? '0' + (hh % 12) : hh % 12;
        const _min = min < 10 ? `0${min}` : min;

        const dateStr = _dd + '-' + _mm + '-' + yyyy;
        const dateStrInYYYYMMDD = yyyy + '-' + _mm + '-' + _dd;
        const timeStr = _hh + ':' + _min + ' ' + (hh >= 12 ? 'PM' : 'AM');

        if (formate) {
            return (yyyy +
                '-' +
                _mm +
                '-' +
                _dd +
                'T' +
                (hh < 10 ? '0' + hh : hh) +
                ':' +
                (min < 10 ? '0' + min : min)) as TimeStampToStrReturnType<T>;
        }

        return {
            date: dateStr,
            time: timeStr,
            date_in_yyyy_mm_dd: dateStrInYYYYMMDD,
        } as TimeStampToStrReturnType<T>;
    },
};

export const secToHHMMSS = (sec: any, long = true) => {
    const hh = Math.floor(sec / 3600);
    const mm = Math.floor((sec % 3600) / 60);
    const ss = Math.floor(sec % 60);
    if (!long)
        return `${hh > 9 ? hh : '0' + hh}:${mm > 9 ? mm : '0' + mm}:${ss > 9 ? ss : '0' + ss}`;

    let formatedTime = '';
    if (hh) {
        formatedTime += hh + ' hh ';
    }
    if (mm) {
        formatedTime += mm + ' min ';
    }
    formatedTime += ss + ' sec';
    return formatedTime;
};

export const secToHHMMSSObject = (sec: any) => {
    const hh = Math.floor(sec / 3600);
    const mm = Math.floor((sec % 3600) / 60);
    const ss = Math.floor(sec % 60);
    return { hh, mm, ss };
};

export const generateCSV = (headers: string[], data: any[], title?: string) => {
    console.log({ headers, data, title });
    const rows = data.map((row) => headers.map((h) => row[h]));

    // Create CSV content
    const csvContent = [
        title,
        headers.join(','), // column headers
        ...rows.map((row) => row.join(',')), // rows
    ].join('\n');

    // Trigger download of the CSV
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${title || 'report'}.csv`;
    link.click();
};
