import React from 'react';

function IframeViewer() {
    return (
        <div>
            <iframe
                src="https://player.vdocipher.com/v2/?otp=20160313versASE323zhL0NHOaCstDTPI43JNXOV06UA3iFFPRT4Zsk0knw5satd&playbackInfo=eyJ2aWRlb0lkIjoiMGVlZDE3ZjE0NzU1NDIwMmJmNjlkMjlkMTFhODJjNTUifQ=="
                style={{ border: 0, height: '360px', width: '640px', maxWidth: '100%' }}
                allowFullScreen={true}
                allow="encrypted-media"
            ></iframe>
        </div>
    );
}

export default IframeViewer;
