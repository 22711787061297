import { BACKEND_URLS } from "../config";
import { CampaignInterface } from "../types/notifiation";
import ApiService, { ApiResponse } from "./ApiServices/api-service";


class NotificationServices extends ApiService {

    constructor() {
        super(BACKEND_URLS.NOTIFICATIION)
    }

    makeNotificationTemplate(data:
        {
            name: string,
            title: string,
            body: string,
            imageUrl: string,
            cta: string,
            ctaParams: { link?: string, courseId?: number }
        }): ApiResponse {
        return this.postData("/templates", data)
    }

    createCampaign(data: {
        name: string,
        scheduledAt: string,
        pushTemplateId: number
    }): ApiResponse {
        return this.postData('/campaigns', data)
    }

    userCampaingn(
        data: {
            campaignId: number
            classes: number[],
            targets: number[]
        }
    ) {
        return this.postData(`/campaigns/${data.campaignId}/users`, {
            classes: data.classes,
            targets: data.targets
        })
    }

    getAllCampaigns(): ApiResponse<{ campaigns: CampaignInterface[] }> {
        return this.getData('/campaigns')
    }

    testNotification(data: {
        pushTemplateId: number,
        phoneNumber: string
    }): ApiResponse<{
        messageId: string,
        success: boolean
    }> {
        return this.postData('/campaigns/test-notification', data)
    }
}

export default new NotificationServices()