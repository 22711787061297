import { useEffect, useState } from 'react';
import { PermissionResponse } from '../../types/permission.types';
import ESaralLoader from '../Spinner/ESaralLoader';
import permissionService from '../../services/ApiServices/permission-service';
import toast from 'react-hot-toast';

const CreateEditPermission = ({ id, onSuccess }: { id?: number; onSuccess: () => void }) => {
    const [permission, setPermission] = useState<Partial<PermissionResponse> | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (id) {
            setIsLoading(true);
            permissionService
                .getPermissionById(id)
                .then((res) => {
                    if (res.status == 'success') setPermission(res.data.permission);
                })
                .finally(() => setIsLoading(false));
            // TODO: Fetch admin menu list
        }
    }, [id]);

    const handleInputChange = (key: keyof PermissionResponse, value: string) => {
        setPermission((prev) => ({ ...prev, [key]: value }));
    };

    const handleSubmit = () => {
        if (!permission?.slug) return toast.error('Slug is required');
        if (!permission?.description) return toast.error('Description is required');
        const payload = { description: permission.description, slug: permission.slug };
        if (id) {
            permissionService
                .updatePermission(id, payload)
                .then((res) => {
                    if (res.status == 'success') {
                        toast.success(res.message);
                        onSuccess();
                    }
                })
                .catch(toast.error);
        } else
            permissionService
                .createPermission(payload)
                .then((res) => {
                    if (res.status == 'success') {
                        toast.success(res.message);
                        onSuccess();
                    }
                })
                .catch(toast.error);
    };

    if (isLoading) return <ESaralLoader />;
    return (
        <div className="container mt-2">
            <div className="mb-3">
                <label htmlFor="permissionSlug" className="form-label">
                    Permission Slug
                </label>
                <input
                    type="text"
                    id="permissionSlug"
                    className="form-control"
                    value={permission?.slug || ''}
                    onChange={(e) => handleInputChange('slug', e.target.value)}
                    placeholder="Enter Permission slug"
                />
            </div>

            <div className="mb-3">
                <label htmlFor="permissionDescription" className="form-label">
                    Permission Description
                </label>
                <input
                    type="text"
                    id="permissionDescription"
                    className="form-control"
                    value={permission?.description || ''}
                    onChange={(e) => handleInputChange('description', e.target.value)}
                    placeholder="Enter Permission description"
                />
            </div>
            <button className="btn btn-primary" onClick={handleSubmit}>
                {id ? 'Update Permission' : 'Create Permission'}
            </button>
        </div>
    );
};

export default CreateEditPermission;
