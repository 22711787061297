import { useEffect, useRef, useState } from 'react'
import { DateTime } from "luxon";
import { BACKEND_URLS, ENDPOINT_HOST } from '../../../config';
import { useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { OrderHeader } from '../../../components/headers';
import { axiosClient } from '../../../services/axiosClient';
import toast from 'react-hot-toast';


//@ts-ignore
window.DateTime = DateTime


function Order() {
  const [customParams, setCustomParams] = useState<{ [key: string]: string | number }>({});
  const [data, setData] = useState([]);
  // const [totalRows, setTotalRows] = useState(0);
  // const [perPage, setPerPage] = useState(100);
  // const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();

  const onChangeFilterText = (value: any, key: string) => {
    setCustomParams((prev) => ({
      ...prev,
      [key]: value
    }));
  };

  const onFilterApply = () => {
    // fetchData(currentPage);
    fetchData();
  };

  const columns = [
    {
      name: "Enroll Date",
      selector: (row: any) => row.orderDate?.split('T')[0],
      sortable: true,
      cell: (row: any) => <div
        data-tag="allowRowEvents"
        className='text-breaker'
      > {row.orderDate?.split('T')[0]} </div>,
    },
    {
      name: "UserName",
      selector: (row: any) => row.user.username,
      sortable: true,
      cell: (row: any) => <div
        data-tag="allowRowEvents"
        className='text-breaker'
      > {row.user.username} </div>,
    },
    {
      name: "Name",
      selector: (row: any) => row.user.name,
      sortable: true,
      cell: (row: any) => <div
        data-tag="allowRowEvents"
        className='text-breaker'
      > {row.user.name} </div>,
    },
    {
      name: "Course",
      cell: (row: any) => <div
        data-tag="allowRowEvents"
        className='text-breaker'
      >
        {row.course.name}
      </div>,
      sortable: true,
    },
    {
      name: "Total Amount",
      selector: (row: any) => row.totalAmount,
      sortable: true,
      cell: (row: any) => <div
        data-tag="allowRowEvents"
        className='text-breaker'
      > {row.totalAmount} </div>,
    },
    {
      name: "Paid Amount",
      cell: (row: any) => {
        const totalAmount = row?.enrollmentPayment?.reduce((total: any, payment: any) => total + payment.amount, 0);
        return <span>{totalAmount}</span>;
      },
      sortable: true,
    },
  ];

  const fetchData = async (
    // page: number, 
    // size = perPage
  ) => {
    const params = {
      // page,
      // limit: size,
      ...customParams,
    };

    try {
      const response = await axiosClient.get(`${ENDPOINT_HOST + BACKEND_URLS.ORDERS}`,
        {
          params,
        }
      );

      const ajaxResponse = handleAjaxResponse(response.data);
      setData(ajaxResponse.data);
      // setTotalRows(ajaxResponse.last_page * size);
    } catch (error: any) {
      toast.error("Error fetching data:", error);
    }
  };

  const handleAjaxResponse = (response: any) => {
    if (response && response.data) {
      return {
        data: response.data,
        last_page: response.total_pages || 1,
      };
    } else {
      toast.error("Invalid response format:", response);
      return { data: [] };
    }
  };

  // const handlePageChange = (page: number) => {
  //   setCurrentPage(page);
  //   fetchData(page);
  // };

  const handleRowClick = (row: any) => {
    navigate(`/order/${row.id}`);
  };

  // useEffect(() => {
  // fetchData(currentPage);
  // }, [currentPage]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className='mx-3'>
      <h1>All Orders</h1>
      <OrderHeader onChangeFilterText={onChangeFilterText}
        onFilterApply={onFilterApply}
      // onFilterApply={onFilterApply}
      />

      <DataTable
        columns={columns}
        data={data}
        pagination
        paginationPerPage={100}
        paginationRowsPerPageOptions={[100, 500, 1000]}
        // paginationServer
        // paginationTotalRows={totalRows}
        // onChangePage={handlePageChange}
        onRowClicked={handleRowClick}
        highlightOnHover
        pointerOnHover
        noDataComponent="No Data Set"
        customStyles={{
          table: {
            style: {
              width: '90%',
              margin: 'auto'
            }
          }
        }}
      />
    </div>
  );
}

export default Order;
