import { useEffect, useState } from 'react';
import { FaCalendarAlt, FaPencilAlt } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { ModalsConstants } from '../../Redux/models/models-constants';
import ContentSchedule from '../modals/content-schedule';
import nodeService from '../../services/ApiServices/node-service';
import { CreateNode } from '../../pages/content/node';

type NodeType = {
    id: number;
    branding?: string;
    category?: string;
    class?: string;
    target?: string;
    targetYear?: string;
    group?: string;
    itemType?: string;
    level0Id?: number;
    level1Id?: number;
    phase?: string;
};

const EditNodeHeader = () => {
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [nodeData, setNodeData] = useState<NodeType>();

    const nodeName = useSelector<any>((state) => state.ContentReducer.nodeName) as string;

    const dispatch = useDispatch();
    const { nodeId } = useParams();

    const handleIconClick = () => {
        setIsEditing(true);
        dispatch({ type: ModalsConstants.IS_NODE_MODAL, payload: true });
    };

    useEffect(() => {
        if (nodeId)
            nodeService.getById(+nodeId).then((nodeRes) => {
                if (nodeRes.status == 'success') setNodeData(nodeRes.data.node as any);
            });
    }, []);

    return (
        <div
            className="p-3"
            style={{
                borderBottom: '2px solid',
            }}
            id="node-header"
        >
            <ContentSchedule />
            <Row>
                <Col sm="10">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <h2 style={{ margin: 0 }}>{nodeName}</h2>
                        <FaPencilAlt
                            style={{ marginLeft: '8px', cursor: 'pointer' }}
                            onClick={handleIconClick}
                        />
                    </div>
                </Col>
                <Col>
                    <Link
                        to="#"
                        onClick={() =>
                            dispatch({ type: ModalsConstants.IS_SCHEDULE_MODAL, payload: true })
                        }
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            color: '#007bff', // Customize color
                            textDecoration: 'none',
                            padding: '0.5rem 1rem',
                            borderRadius: '4px',
                            transition: 'color 0.3s',
                        }}
                        className="nav-link"
                        // onMouseEnter={(e) => e.currentTarget.style.color = '#0056b3'}  // Hover effect
                        // onMouseLeave={(e) => e.currentTarget.style.color = '#007bff'}
                    >
                        <FaCalendarAlt style={{ marginRight: '0.5rem' }} /> {/* Icon with margin */}
                        Set Content Schedule
                    </Link>
                </Col>
            </Row>

            {isEditing && <CreateNode node={nodeData} />}
        </div>
    );
};

export default EditNodeHeader;
