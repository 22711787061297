import { FC } from 'react';
import './styles/styles.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import {
    ErrorPage,
    Root,
    RootLoader,
    Login,
    Quiz,
    PracticeQuiz,
    PracticeQuizEdit,
    User,
    PracticeSheetPage,
    ProofRead,
    ModulePdfPage,
    SolveCast,
    Practice,
    Admin,
    Content,
    Engagment,
    StudentParentReport,
    Setting,
    settingLoader,
} from './pages';
import { FRONTEND_URLS } from './config';
import Course, { loader } from './pages/content/course';
import Sets, { setLoader } from './pages/content/set';
import CreateSet, { editSetLoader } from './components/sets/create-set';
import CreateCourse, { createCourseLoader } from './components/course/create-course';
import { CreateOrderForm, Order, Payments } from './pages/bd';
import Students from './pages/students';
import QuizEdit from './components/Quiz/QuizEdit/QuizEdit';
import AllQuizzes from './components/Quiz/AllQuizzes';
import AllUsers from './pages/students/all-users';
import { Toaster } from 'react-hot-toast';
import UploadQuizPage from './components/upload-quiz/page';
import CollectionPage, { collectionLoader } from './pages/content/collection';
import CreateCollection from './components/collection/create-collection';
import RolesPage from './pages/user/roles';
import PermissionsPage from './pages/user/permissions';
import Testimonials from './pages/admin/testimonials';

const router = createBrowserRouter([
    {
        path: FRONTEND_URLS.ROOT,
        element: <Root />,
        errorElement: <ErrorPage errorMessage="All errors of the app come here." />,
        loader: RootLoader,
        children: [
            {
                path: FRONTEND_URLS.ENGAGMENT.DOUBT_TOOL,
                element: <Engagment.Doubts />,
                errorElement: <ErrorPage errorMessage="order page error" />,
                // loader: Engagment.DoubtLoader,
                children: [],
            },
            {
                path: FRONTEND_URLS.SETTING,
                element: <Setting />,
                errorElement: <ErrorPage errorMessage="setting page error" />,
                loader: settingLoader,
                children: [],
            },
            {
                path: FRONTEND_URLS.ENGAGMENT.NOTIFICATION,
                element: <Engagment.Notification />,
                errorElement: <ErrorPage errorMessage="notifcation page error" />,
                loader: Engagment.NotificationLoader,
                children: [],
            },
            {
                path: FRONTEND_URLS.STUDENT.ENROLLED_STUDENTS,
                element: <Students.EnrolledStudents />,
                errorElement: <ErrorPage errorMessage="order page error" />,
                children: [],
            },
            {
                path: FRONTEND_URLS.STUDENT.ENROLLED_STUDENTS + '/:enrollmentId',
                element: <Students.StudentInfo />,
                errorElement: <ErrorPage errorMessage="order page error" />,
                children: [],
            },
            {
                path: FRONTEND_URLS.STUDENT.ENROLLED_STUDENTS + '/:enrollmentId/:studentId',
                element: <Students.StudentInfo />,
                errorElement: <ErrorPage errorMessage="order page error" />,
                children: [],
            },
            {
                path: FRONTEND_URLS.STUDENT.STUDENT_ALL_USERS,
                element: <Students.AllUsersReadOnly />,
                errorElement: <ErrorPage errorMessage="order page error" />,
                children: [],
            },
            {
                path: FRONTEND_URLS.STUDENT.STUDENT_ALL_USERS + `/:userId`,
                element: <Students.UserAllInfoReadOnly />,
                errorElement: <ErrorPage errorMessage="order page error" />,
                children: [],
            },
            {
                path: FRONTEND_URLS.BD.ORDER,
                element: <Order />,
                errorElement: <ErrorPage errorMessage="order page error" />,
                children: [],
            },
            {
                path: FRONTEND_URLS.BD.PAYMENTS,
                element: <Payments />,
                errorElement: <ErrorPage errorMessage="order page error" />,
                children: [],
            },
            {
                path: FRONTEND_URLS.ORDER + '/:orderId',
                element: <CreateOrderForm />,
                errorElement: <ErrorPage errorMessage="order page error" />,
                children: [],
            },
            {
                path: FRONTEND_URLS.ADMIN.LEVEL + '/:levelId',
                element: <Admin.SubjectGroups />,
                errorElement: <ErrorPage errorMessage="Error in loading content group" />,
                loader: Admin.SubjectGroupLoader,
                children: [],
            },

            {
                path: FRONTEND_URLS.ADMIN.SERVICES,
                element: <Admin.Services />,
                errorElement: <ErrorPage errorMessage="Error in loading content group" />,
                loader: Admin.serviceLoader,
                children: [],
            },
            {
                path: FRONTEND_URLS.ADMIN.PROCESSES,
                element: <Admin.Processes />,
                errorElement: <ErrorPage errorMessage="Error in loading content group" />,
                loader: Admin.processLoader,
                children: [],
            },
            {
                path: FRONTEND_URLS.ADMIN.HARDWARES,
                element: <Admin.Hardware />,
                errorElement: <ErrorPage errorMessage="Error in loading content group" />,
                loader: Admin.haedwareLoader,
                children: [],
            },
            {
                path: FRONTEND_URLS.ADMIN.EXAM_TARGETS,
                element: <Admin.ExamTargets />,
                errorElement: <ErrorPage errorMessage="Error in loading content group" />,
                loader: Admin.examTargetLoader,
                children: [],
            },
            {
                path: FRONTEND_URLS.ADMIN.STUDENT_CLASSES,
                element: <Admin.StudentClasses />,
                errorElement: <ErrorPage errorMessage="Error in loading content group" />,
                loader: Admin.studentClassLoader,
                children: [],
            },
            {
                path: FRONTEND_URLS.ADMIN.DEMO_COURSES,
                element: <Admin.DemoCourses />,
                errorElement: <ErrorPage errorMessage="Error in loading content group" />,
                // loader: Admin.studentClassLoader,
                children: [],
            },

            {
                path: FRONTEND_URLS.CONTENTS.NODE,
                element: <Content.Node.Node />,
                errorElement: <ErrorPage errorMessage="Error in loading content group" />,
                loader: Content.Node.NodeLoader,
                children: [],
            },
            {
                path: FRONTEND_URLS.CONTENTS.COURSES,
                element: <Course />,
                loader: loader,
                errorElement: <ErrorPage errorMessage="Error in loading content group" />,
                children: [],
            },
            {
                path: FRONTEND_URLS.CONTENTS.CREATE_COURSE,
                element: <CreateCourse />,
                errorElement: <ErrorPage errorMessage="Error in loading content group" />,
                children: [],
            },
            {
                path: FRONTEND_URLS.CONTENTS.EDIT_COURSE + '/:courseId',
                element: <CreateCourse />,
                loader: createCourseLoader,
                errorElement: <ErrorPage errorMessage="Error in loading content group" />,
                children: [],
            },
            {
                path: FRONTEND_URLS.CONTENTS.SETS,
                element: <Sets />,
                loader: setLoader,
                errorElement: <ErrorPage errorMessage="Error in loading content group" />,
                children: [],
            },
            {
                path: FRONTEND_URLS.CONTENTS.CREATE_SET,
                element: <CreateSet />,
                errorElement: <ErrorPage errorMessage="Error in loading content group" />,
                children: [],
            },
            {
                path: FRONTEND_URLS.CONTENTS.EDIT_SET + '/:setId',
                element: <CreateSet />,
                loader: editSetLoader,
                errorElement: <ErrorPage errorMessage="Error in loading content group" />,
                children: [],
            },
            {
                path: FRONTEND_URLS.CONTENTS.QUIZ,
                element: <AllQuizzes />,
                errorElement: <ErrorPage errorMessage="Error in loading content group" />,
                children: [],
            },
            {
                path: FRONTEND_URLS.CONTENTS.UPLOAD_QUIZ,
                element: <UploadQuizPage />,
                errorElement: <ErrorPage errorMessage="Error in loading content group" />,
                children: [],
            },
            {
                path: FRONTEND_URLS.CONTENTS.CREATE_QUIZ,
                element: <QuizEdit />,
                errorElement: <ErrorPage errorMessage="Error in loading content group" />,
                children: [],
            },
            {
                path: FRONTEND_URLS.CONTENTS.CREATE_PRACTISE_QUIZ,
                element: <QuizEdit />,
                errorElement: <ErrorPage errorMessage="Error in loading content group" />,
                children: [],
            },
            {
                path: FRONTEND_URLS.CONTENTS.PRACTICE_QUIZ_EDIT + '/:quizId',
                element: <QuizEdit />,
                errorElement: <ErrorPage errorMessage="Error in loading content group" />,
                children: [],
            },
            {
                path: FRONTEND_URLS.CONTENTS.EDIT_QUIZ + '/:quizId',
                element: <QuizEdit />,
                errorElement: <ErrorPage errorMessage="Error in loading content group" />,
                children: [],
            },
            {
                path: FRONTEND_URLS.CONTENTS.LIVE_MANAGMENT + '/:tab',
                element: <Content.LiveManagment />,
                errorElement: <ErrorPage errorMessage="Error in loading content group" />,
                children: [],
            },
            {
                path: FRONTEND_URLS.CONTENTS.COLLECTION,
                element: <CollectionPage />,
                errorElement: <ErrorPage errorMessage="Error in loading colletions" />,
                loader: collectionLoader,
                children: [],
            },
            {
                path: FRONTEND_URLS.CONTENTS.CREATE_COLLECTION,
                element: <CreateCollection />,
                errorElement: <ErrorPage errorMessage="Error in loading add colletion" />,
                children: [],
            },
            {
                path: FRONTEND_URLS.CONTENTS.EDIT_COLLECTION + '/:collectionId',
                element: <CreateCollection />,
                errorElement: <ErrorPage errorMessage="Error in loading update colletions" />,
                children: [],
            },
            {
                path: FRONTEND_URLS.ADMIN.TESTIMONIAL,
                element: <Testimonials />,
                errorElement: <ErrorPage errorMessage="Error in loading update colletions" />,
                children: [],
            },
            // ------------------------ Depreated -------------------- //

            {
                path: FRONTEND_URLS.CONTENT,
                element: <Content.Content />,
                errorElement: <ErrorPage errorMessage="Login error" />,
                children: [
                    {
                        path: FRONTEND_URLS.QUIZ,
                        element: <Quiz />,
                        errorElement: <ErrorPage errorMessage="Login error" />,
                        children: [
                            {
                                path: FRONTEND_URLS.QUIZ,
                                element: <Quiz />,
                                errorElement: <ErrorPage errorMessage="Login error" />,
                                children: [],
                            },
                        ],
                    },
                ],
            },
            {
                path: FRONTEND_URLS.PRACTICE_QUIZ,
                element: <PracticeQuiz />,
                errorElement: <ErrorPage errorMessage="Login error" />,
                children: [
                    {
                        path: ':quizId',
                        element: <PracticeQuizEdit />,
                        errorElement: <ErrorPage errorMessage="Login error" />,
                        children: [],
                    },
                ],
            },
            {
                path: FRONTEND_URLS.USER,
                element: <User />,
                errorElement: <ErrorPage errorMessage="Login error" />,
                children: [
                    {
                        path: FRONTEND_URLS.PRACTISE + '/:quizId',
                        element: <Practice />,
                        errorElement: <ErrorPage errorMessage="Login error" />,
                        children: [],
                    },
                    {
                        path: FRONTEND_URLS.PRACTISE_SHEET + '/:quizId',
                        element: <PracticeSheetPage />,
                        errorElement: <ErrorPage errorMessage="Login error" />,
                        children: [],
                    },
                ],
            },
            {
                path: FRONTEND_URLS.PROOF_READ + '/:quizId',
                element: <ProofRead />,
                errorElement: <ErrorPage errorMessage="Login error" />,
                children: [],
            },
            {
                path: FRONTEND_URLS.MODULE_PDF + '/:quizId',
                element: <ModulePdfPage />,
                errorElement: <ErrorPage errorMessage="Login error" />,
                children: [],
            },
            {
                path: FRONTEND_URLS.ALL_USERS,
                element: <AllUsers />,
                children: [],
            },
            {
                path: FRONTEND_URLS.ALL_USERS + '/:id',
                element: <Students.UserDetails />,
                errorElement: <ErrorPage errorMessage="User detail page error" />,
                loader: Students.UserDetailsLoader,
                children: [],
            },
            {
                path: FRONTEND_URLS.ROLES,
                element: <RolesPage />,
                children: [],
            },
            {
                path: FRONTEND_URLS.PERMISSIONS,
                element: <PermissionsPage />,
                children: [],
            },
        ],
    },

    {
        path: FRONTEND_URLS.EXTERNAL.IFRAME_VIEWER,
        element: <Content.IframeViewer />,
        errorElement: <ErrorPage errorMessage="order page error" />,
        // loader: Engagment.DoubtLoader,
        children: [],
    },

    {
        path: FRONTEND_URLS.CONTENTS.EDIT_NODE + '/:nodeId',
        element: <Content.Node.EditNode />,
        errorElement: <ErrorPage errorMessage="Error in loading content group" />,
        loader: Content.Node.EditNodeLoader,
        children: [
            {
                path: 'content/:contentId',
                element: <Content.Node.Content />,
                errorElement: <ErrorPage errorMessage="Error in loading content group" />,
                // loader: Content.Node.EditNodeLoader,
                children: [],
            },
        ],
    },
    {
        path: FRONTEND_URLS.STUDENT.STUDENT_PARENT_REPORT + '/:studentId',
        element: <StudentParentReport />,
        errorElement: <ErrorPage errorMessage="order page error" />,
        children: [],
    },
    {
        path: FRONTEND_URLS.STUDENT.STUDENT_PARENT_REPORT,
        element: <StudentParentReport />,
        errorElement: <ErrorPage errorMessage="order page error" />,
        children: [],
    },
    {
        path: FRONTEND_URLS.LOGIN,
        element: <Login />,
        errorElement: <ErrorPage errorMessage="Login error" />,
        children: [],
    },
    {
        path: FRONTEND_URLS.USER_SOLVE_CAST,
        element: <SolveCast />,
        errorElement: <ErrorPage errorMessage="Solve cast error" />,
        children: [],
    },
]);

const App: FC = () => {
    return (
        <>
            <div
                style={{
                    position: 'absolute',
                }}
            >
                <Toaster
                    position="top-center"
                    reverseOrder={false}
                    gutter={8}
                    containerClassName="toaster-container"
                    containerStyle={{
                        zIndex: '11000000000', // Ensure it's higher than Bootstrap's modal
                    }}
                    toastOptions={{
                        position: 'top-right',
                        className: '',
                        duration: 1000 * 5,
                        style: {
                            background: '#363636',
                            color: '#fff',
                        },
                        success: {
                            // Additional success settings if needed
                        },
                    }}
                />
            </div>
            <RouterProvider router={router} />
        </>
    );
};

export default App;
