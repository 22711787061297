import logo from './logo.png';
import esaral_logo from './esaral_logo.png';
import girl from './girl.png';
import boy from './boy.png';
import book from './book_default.png';

const images = {
    book,
    boy,
    girl,
    esaral_logo,
    logo,
}

export default images