import { useEffect } from 'react';
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { FRONTEND_URLS } from '../../config';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Level1Item, Level2Item } from '../../types/levelTypes';
import LevelService from '../../services/ApiServices/level-service';

type Props = {
    setQuizType: (e: any) => void;
    setSubject: (e: any) => void;
    setChapter: (e: any) => void;
    setSearch: (e: any) => void;
};

const AllQuizzesHeader = (props: Props) => {
    const navigate = useNavigate();

    const [subjectOpts, setSubjectOpts] = useState<Level1Item[]>([]);
    const [chapterOpts, setChapterOpts] = useState<Level2Item[]>([]);


    useEffect(() => {
        LevelService.getItems(1).then((res) => setSubjectOpts(res.data?.level));
    }, []);

    return (
        <div style={{ width: '100%' }}>
            <Button
                size="sm"
                style={{ marginRight: 15 }}
                onClick={() => navigate(FRONTEND_URLS.CONTENTS.CREATE_QUIZ)}
            >
                Add Quiz
            </Button>
            <Button
                style={{ marginRight: 15 }}
                size="sm"
                onClick={() => navigate(FRONTEND_URLS.CONTENTS.CREATE_PRACTISE_QUIZ)}
            >
                Add Practice Quiz
            </Button>
            <Button
                style={{ marginRight: 15 }}
                size="sm"
                onClick={() => navigate(FRONTEND_URLS.CONTENTS.UPLOAD_QUIZ)}
            >
                Upload Quiz
            </Button>
            <Row>
                <Col md={3}>
                    <InputGroup size="sm" className="mt-3">
                        <InputGroup.Text id="queSections">Quiz Type</InputGroup.Text>
                        <Form.Select
                            name="quizType"
                            onChange={(e) => props.setQuizType(e.target.value)}
                            aria-label="Quiz Type"
                            aria-describedby="queSections"
                        >
                            <option value="">All</option>
                            <option value={'QUIZ'}>Quiz</option>
                            <option value={'PRACTICE_QUIZ'}>Practice Quiz</option>
                        </Form.Select>
                    </InputGroup>
                </Col>
                <Col md={3}>
                    <InputGroup size="sm" className="mt-3">
                        <InputGroup.Text id="subject">Subject</InputGroup.Text>
                        <Form.Select
                            name="subject"
                            onChange={(e) => {
                                props.setChapter('');
                                setChapterOpts([]);
                                props.setSubject(e.target.value);
                                LevelService.getItems(2, `?level1Id=${e.target.value}`).then(
                                    (res) => setChapterOpts(res.data.level)
                                );
                            }}
                        >
                            <option value="">---------</option>
                            {subjectOpts?.map((opt) => (
                                <option key={opt.id} value={opt.id}>
                                    {opt.internalName}
                                </option>
                            ))}
                        </Form.Select>
                    </InputGroup>
                </Col>
                <Col md={3}>
                    <InputGroup size="sm" className="mt-3">
                        <InputGroup.Text id="cahpter">Chapter</InputGroup.Text>
                        <Form.Select
                            name="chapter"
                            onChange={(e) => {
                                props.setChapter(e.target.value);
                            }}
                        >
                            <option value="">----------</option>
                            {chapterOpts?.map((opt) => (
                                <option key={opt.id} value={opt.id}>
                                    {opt.internalName}
                                </option>
                            ))}
                        </Form.Select>
                    </InputGroup>
                </Col>
                <Col md={3}>
                    <Form
                        onSubmit={(e: any) => {
                            e.preventDefault();
                            props.setSearch(e.target[0].value);
                        }}
                    >
                        <InputGroup size="sm" className="mt-3">
                            <InputGroup.Text id="search">Search</InputGroup.Text>
                            <Form.Control
                                placeholder="Search"
                                aria-label="Search"
                                aria-describedby="search"
                                name="search"
                                onBlur={e => { props.setSearch(e.target.value) }}
                            />
                        </InputGroup>
                    </Form>
                </Col>
            </Row>
        </div>
    );
};
export default AllQuizzesHeader;
