import React from 'react';
import { Card, Table } from 'react-bootstrap';
import { istToReadable } from '../../utils/helpers';
import { CallRecord } from '../../types/mentor-calls-types';
import { Timetable } from '../../types/enrollments';
import { MEDIA_BASE_URL } from '../../config';
import { enrollmentTimetableInterface, User } from '../../types/user-profille-types';

function CallLogs({
    tableData,
    allTimeTables,
    user,
}: {
    tableData: CallRecord[];
    allTimeTables: enrollmentTimetableInterface[];
    user: User[] | null;
}) {
    console.log('allTimeTables ======>', allTimeTables);
    return tableData.length > 0 ? (
        <Card style={{ overflow: 'hidden' }}>
            <Card.Body>
                <h3>Call logs</h3>
                <div>
                    <Table striped bordered hover responsive>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Relation</th>
                                <th>Contact Type</th>
                                <th>Contact</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableData?.map((row, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{istToReadable(row?.callDateTime, true)}</td>
                                        <td>{row.staff.label}</td>
                                        <td>{row.durationInSec > 0 ? `Yes` : `No`}</td>
                                        <td>{row.type}</td>
                                        <td>{row.callDetails}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </div>
                <h3>TimeTable</h3>
                <div>
                    <Table striped hover>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Assigned By</th>
                                <th>Assign date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {allTimeTables ? (
                                allTimeTables?.map((tt, index) => {
                                    console.log('tt =====>', tt);
                                    const timetable = tt.content;
                                    const createdOn = new Date(timetable.createdOn);
                                    const dd = createdOn.getDate();
                                    const mm = createdOn.getMonth() + 1;
                                    const yyyy = createdOn.getFullYear();
                                    const hh = createdOn.getHours();
                                    const min = createdOn.getMinutes();
                                    const assginedByName = user?.find(
                                        (use: User) => use?.id === timetable.createdById
                                    )?.name;
                                    return (
                                        <tr key={index}>
                                            <td>{timetable.name}</td>
                                            <td>{assginedByName}</td>
                                            <td>
                                                {dd < 10 ? `0${dd}` : dd}-{mm < 10 ? `0${mm}` : mm}-
                                                {yyyy}
                                                {hh % 12 < 10 ? `0${hh % 12}` : hh % 12}:
                                                {min < 10 ? `0${min}` : min} {hh > 12 ? 'PM' : 'AM'}
                                            </td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <></>
                            )}
                        </tbody>
                    </Table>
                </div>
            </Card.Body>
        </Card>
    ) : (
        <></>
    );
}

export default CallLogs;
