"use client";
import React, { lazy, useEffect } from "react";
import { useState } from "react";
import { useLocation, useParams } from "react-router-dom";



const Page = lazy(() => import("./Page"));

const inst: any = {
  "SINGLE CORRECT TYPE": {
    lable: `<span style="
                    font-size: 18px;
                    font-weight: bold;
                    text-decoration: underline;
                ">SINGLE CORRECT TYPE</span>`,
    description:
      "<em>Each question has FOUR options for correct answer. <strong>ONLY ONE</strong> of these four options is correct option. For each question, choose the correct option to answer the question.</em>",
  },
  "ONE OR MORE THAN ONE CORRECT TYPE": {
    lable: `<span style="
                    font-size: 18px;
                    font-weight: bold;
                    text-decoration: underline;
                ">ONE OR MORE THAN ONE CORRECT TYPE </span>`,
    description:
      "<em>Each question has FOUR options for correct answer(s). <strong>ONE OR MORE THAN ONE</strong> of these four option(s) is (are) correct option(s). For each question, choose the correct option(s) to answer the question.</em>",
  },
  "NUMERICAL TYPE": {
    lable: `<span style="
                    font-size: 18px;
                    font-weight: bold;
                    text-decoration: underline;
                ">NUMERICAL TYPE </span>`,
    description:
      "<em>The answer to each question is a <strong>NUMERICAL VALUE</strong>. For each question, find the correct NUMERICAL value (in decimal notation, truncated/rounded-off to the secound decimal place; e.g. 6.25, 7.00, -0.33, -.30, 30.27, -127.30)</em>",
  },
  "MATCH THE COLUMN TYPE": {
    lable: `<span style="
                    font-size: 18px;
                    font-weight: bold;
                    text-decoration: underline;
                ">MATCH THE COLUMN TYPE</span>`,
    description:
      "<em>Following questions contain statements given in two columns, Which have to be matched the statements in <strong>column-I</strong> are labelled as A, B, C, and D while the statements in <strong>columns-II</strong> are labeled as (P), (Q), (R) and (S). Any given statements in <strong>column-I</strong> can have correct matching with <strong>ONE OR MORE</strong> statement(s) in <strong>column-II</strong>.</em>",
  },
  "PARAGRAPH TYPE": {
    lable: `<span style="
                    font-size: 18px;
                    font-weight: bold;
                    text-decoration: underline;
                ">PARAGRAPH TYPE</span>`,
    description:
      "<em>This section contains PARAGRAPHS. Based on each paragraph, there are questions. Each question has FOUR options. ONLY ONE of these four options corresponds to the correct answer. For each question, choose the option corresponding to the correct answer</em>",
  },
  "PARAGRAPH TYPE (SINGLE CORRECT)": {
    lable: `<span style="
                    font-size: 18px;
                    font-weight: bold;
                    text-decoration: underline;
                ">PARAGRAPH TYPE (SINGLE CORRECT)</span>`,
    description:
      "<em>This section contains PARAGRAPHS. Based on each paragraph, there are questions. Each question has FOUR options. ONLY ONE of these four options corresponds to the correct answer. For each question, choose the option corresponding to the correct answer</em>",
  },
  "PARAGRAPH TYPE (ONE OR MORE THAN ONE CORRECT)": {
    lable: `<span style="
                    font-size: 18px;
                    font-weight: bold;
                    text-decoration: underline;
                ">PARAGRAPH TYPE (ONE OR MORE THAN ONE CORRECT)</span>`,
    description:
      "<em>This section contains PARAGRAPHS. Based on each paragraph, there are questions. Each question has FOUR options. <strong>ONE OR MORE THAN ONE</strong> of these four option(s) is (are) correct option(s). For each question, choose the correct option(s) to answer the question.</em>",
  },
  "MATCHING LIST TYPE": {
    lable: `<span style="
                    font-size: 18px;
                    font-weight: bold;
                    text-decoration: underline;
                ">MATCHING LIST TYPE</span>`,
    description:
      "<em>Each question has TWO (02) matching lists: LIST I and LIST II. FOUR options are given representing matching of elements from LISTI and LIST II.ONLY ONE of these four options corresponds to a correct matching For each question, choose the option corresponding to the correct matching.</em>",
  },
  "ASSERTION AND REASON": {
    lable: `<span style="
                    font-size: 18px;
                    font-weight: bold;
                    text-decoration: underline;
                ">ASSERTION AND REASON</span>`,
    description: "",
  },
  "SUBJECTIVE TYPE": {
    lable: `<span style="
                    font-size: 18px;
                    font-weight: bold;
                    text-decoration: underline;
                ">SUBJECTIVE TYPE</span>`,
    description: "",
  },
};

const ModuleQueHtml = (): JSX.Element => {

  const quizId = useParams<{ quizId: string }>().quizId

  const pathname = useLocation().pathname;
  const isQueOnly = pathname.includes("question-only");

  const [questions, setQuestions] = useState<any>([]);
  const [uniqueQueArr, setUniqueQueArr] = useState<any>([]);
  const [quiz, setQuiz] = useState<any>({});

  const regex = /jee |neet /gi;
  const quizName = quiz?.name?.replace(regex, "").split("Practice Sheet");

  return (
    questions.length > 0 ? (
      <table className="report-container">
        <thead className="report-header">
          <tr style={{ borderBottom: "1px solid #000" }}>
            <th className="report-header-cell">
              <div className="header-info">
                <img
                  alt=""
                  src={
                    require("../../assets/images/esaral_logo.png").default
                      .src
                  }
                  height={36}
                  width={70}
                  style={{ height: 36, width: "auto", backgroundColor: "#fff" }}
                />
                <div
                  style={{ flex: 1, textAlign: "end", fontWeight: "normal" }}
                >
                  {quizName[0]}
                </div>
              </div>
            </th>
          </tr>
          <tr>
            <td className="dummyHeader">
              <div className="headerDevider" />
            </td>
          </tr>
        </thead>
        <tfoot className="report-footer">
          <tr>
            <td className="report-footer-cell">
              <div className="footer-info" id="pageFooter">
                &nbsp;
              </div>
            </td>
          </tr>
        </tfoot>
        <tbody className="report-content">
          <tr>
            <td className="report-content-cell">
              <div className="tbody-header">
                <span>
                  {quizName[1]?.replace(
                    /ex |exercise -|exercise /gi,
                    "Exercise - "
                  )}
                </span>
                <span>
                  {" "}
                  {quizName[1]?.includes("1")
                    ? "Concept Builder"
                    : "Brain Booster"}
                </span>
              </div>

              {uniqueQueArr.map((questionsArr: any, i: number) =>
                questionsArr[0].question_type !== "match-the-column" ? (
                  <div className="print-col-container" key={i}>
                    {questionsArr.map((question: any, l: number) => {

                      const related_quiz = question.question_quizs.filter(
                        (q: any) => q.id !== quiz.quiz_id
                      );
                      const instruction = question.question_type_label &&
                        (question.realInd === 0 ||
                          question.question_type_label !==
                          questions[question.realInd - 1]
                            .question_type_label) && (
                          <div
                            key={l}
                            dangerouslySetInnerHTML={{
                              __html:
                                inst[question.question_type_label].lable +
                                "<br/>" +
                                inst[question.question_type_label].description +
                                "<br/><br/>",
                            }}
                          />
                        );
                      const topic_id =
                        (question.realInd === 0 ||
                          question.topic !==
                          questions[question.realInd - 1].topic) &&
                        question.topic;
                      const paragraph =
                        question.question_type_label.includes(
                          "PARAGRAPH TYPE"
                        ) &&
                          (question.realInd === 0 ||
                            questions[question.realInd].paragraph_id !==
                            questions[question.realInd - 1].paragraph_id)
                          ? question.paragraph
                          : "";
                      return (
                        <Page
                          key={question.realInd}
                          question={question}
                          instruction={instruction}
                          related_quiz={related_quiz}
                          index={question.realInd}
                          isQueOnly={isQueOnly}
                          topic_id={topic_id}
                          paragraph={paragraph}
                        />
                      );
                    })}
                  </div>
                ) : (
                  <div className="print-fw-container" key={i}>
                    {questionsArr.map((question: any, l: number) => {
                      const related_quiz = question.question_quizs.filter(
                        (q: any) => q.id !== quiz.quiz_id
                      );
                      const instruction = question.question_type_label &&
                        (question.realInd === 0 ||
                          question.question_type_label !==
                          questions[question.realInd - 1]
                            .question_type_label) && (
                          <div
                            key={l}
                            dangerouslySetInnerHTML={{
                              __html:
                                inst[question.question_type_label].lable +
                                "<br/>" +
                                inst[question.question_type_label].description +
                                "<br/><br/>",
                            }}
                          />
                        );
                      const topic_id =
                        (question.realInd === 0 ||
                          question.topic !==
                          questions[question.realInd - 1].topic) &&
                        question.topic;
                      return (
                        <Page
                          key={question.realInd}
                          question={question}
                          instruction={instruction}
                          related_quiz={related_quiz}
                          index={question.realInd}
                          isQueOnly={isQueOnly}
                          topic_id={topic_id}
                        />
                      );
                    })}
                  </div>
                )
              )}
            </td>
          </tr>
        </tbody>
      </table>
    )
      :
      <>
        ModuleQueHtml
      </>
  );
};
export default ModuleQueHtml;
