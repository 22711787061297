import React, { useEffect, useState } from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import paymentGatewayService from '../../services/ApiServices/payment-gateway-service';
import { PaymentGatewayItem } from '../../types/payment-gateway-types';
import toast from 'react-hot-toast';

type Props = {
    id?: number;
    onClose: () => void;
};

const CreateEditGateway = ({ id, onClose }: Props) => {
    const [formData, setFormData] = useState<PaymentGatewayItem>({
        id: 0,
        slug: '',
        isActive: false,
        apiKey: '',
        apiSecret: '',
    });
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (id) {
            setIsLoading(true);
            paymentGatewayService
                .getGatewayDetails(id)
                .then((res) => {
                    if (res.status === 'success') {
                        setFormData(res.data.paymentGateway);
                    }
                })
                .finally(() => setIsLoading(false));
        }
    }, [id]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, type, checked } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsLoading(true);
        if (!formData.slug) return toast.error('Slug is required');
        if (!formData.apiKey) return toast.error('API Key is required');
        if (!formData.apiSecret) return toast.error('API Secret is required');
        const { id: _id = null, ...body } = formData;
        try {
            const response = id
                ? await paymentGatewayService.updateGateway(id, body)
                : await paymentGatewayService.createGateway(body);

            if (response.status === 'success') {
                toast.success(response.message);
                onClose();
            }
        } catch (error) {
            console.error('Error saving gateway:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Modal show onHide={onClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>{id ? 'Edit Gateway' : 'Create Gateway'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {isLoading ? (
                    <div className="text-center">
                        <Spinner animation="border" variant="primary" />
                    </div>
                ) : (
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3">
                            <Form.Label>Slug</Form.Label>
                            <Form.Control
                                type="text"
                                name="slug"
                                value={formData.slug}
                                onChange={handleChange}
                                placeholder="Enter gateway slug"
                                required
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>ApiKey</Form.Label>
                            <Form.Control
                                type="text"
                                name="apiKey"
                                value={formData.apiKey}
                                onChange={handleChange}
                                placeholder="Enter Api Key"
                                required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Api Secret</Form.Label>
                            <Form.Control
                                type="text"
                                name="apiSecret"
                                value={formData.apiSecret}
                                onChange={handleChange}
                                placeholder="Enter Api Secret"
                                required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Check
                                type="checkbox"
                                name="isActive"
                                checked={formData.isActive}
                                onChange={handleChange}
                                label="Active"
                            />
                        </Form.Group>
                        <div className="d-flex justify-content-end">
                            <Button variant="secondary" onClick={onClose} className="me-2">
                                Cancel
                            </Button>
                            <Button variant="primary" type="submit" disabled={isLoading}>
                                {id ? 'Update' : 'Create'}
                            </Button>
                        </div>
                    </Form>
                )}
            </Modal.Body>
        </Modal>
    );
};

export default CreateEditGateway;
