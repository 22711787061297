import { useState } from 'react'
import { Button, FloatingLabel, Form, Modal } from 'react-bootstrap'
import toast from 'react-hot-toast'
import { CiCircleRemove } from 'react-icons/ci'
import { FaEdit, FaPlusCircle } from 'react-icons/fa'
import { useAppSelector } from '../../Redux/hooks'
import settingServices from '../../services/setting.services'
import { settingInterface } from '../../types/types'



const UpdateArrayValues = ({
    array,
    setValue,
}: {
    array: any[];
    setValue: React.Dispatch<React.SetStateAction<any[]>>;
}) => {
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [editId, setEditId] = useState<number | null>(null);

    const handleNewItem = () => {
        setValue((prev) => [...prev, ""]); // Add a blank item to the array
        setEditId(array.length); // Set new item in edit mode
        setIsEdit(true);
    };

    const handleRemove = (id: number) => {
        setValue((prev) => prev.filter((_, index) => index !== id)); // Remove item by index
        if (editId === id) {
            setIsEdit(false); // Cancel edit if the deleted item was being edited
            setEditId(null);
        }
    };

    const handleEdit = (index: number) => {
        setEditId(index);
        setIsEdit(true);
    };

    const handleBlur = () => {
        setIsEdit(false);
        setEditId(null);
    };

    const handleChange = (index: number, newValue: string) => {
        const updatedArray = [...array];
        updatedArray[index] = newValue;
        setValue(updatedArray);
    };

    return (
        <>
            {array.map((item, index) => {
                return (
                    <div key={index} className="d-flex align-items-center mb-2">
                        {isEdit && editId === index ? (
                            <Form.Control
                                value={item}
                                onChange={(e) => handleChange(index, e.target.value)}
                                onBlur={handleBlur}
                                autoFocus
                            />
                        ) : (
                            <>
                                <Form.Control value={item} className="me-2" disabled />
                                <FaEdit
                                    className="text-primary me-2"
                                    size={20}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handleEdit(index)}
                                />
                                <CiCircleRemove
                                    className="text-danger"
                                    size={20}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handleRemove(index)}
                                />
                            </>
                        )}
                    </div>
                )
            }
            )}
            <div
                className="p-3 text-success"
                style={{ cursor: "pointer", display: "flex", alignItems: "center", width: '162px'  }}
                onClick={handleNewItem}
            >
                <FaPlusCircle size={20} />
                <span className="ms-2">Add New Item</span>
            </div>
        </>
    );
};



const UpdateObjectValues = ({
    array,
    setValue,
}: {
    array: Record<string, any>;
    setValue: React.Dispatch<React.SetStateAction<Record<string, any>>>;
}) => {
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [editId, setEditId] = useState<string | null>(null);

    const handleRemove = (key: string) => {
        setValue((prev) => {
            const updatedArray = { ...prev };
            delete updatedArray[key];
            return updatedArray;
        });
    };

    const handleNewItem = () => {
        setValue((prev) => ({
            ...prev,
            [`newKey${Object.keys(prev).length}`]: '',
        }));
        setEditId(`newKey${Object.keys(array).length}`);
        setIsEdit(true);
    };

    const handleSave = () => {
        setIsEdit(false);
        setEditId(null);
    };

    const handleKeyChange = (oldKey: string, newKey: string) => {
        setValue((prev) => {
            const updatedArray = { ...prev };
            updatedArray[newKey] = updatedArray[oldKey];
            delete updatedArray[oldKey];
            return updatedArray;
        });
        setEditId(newKey);
    };

    const handleValueChange = (key: string, newValue: string) => {
        setValue((prev) => ({
            ...prev,
            [key]: newValue,
        }));
    };

    return (
        <>
            {Object.keys(array).map((key, index) => (
                <div key={index} className="d-flex mb-2 align-items-center">
                    {isEdit && editId === key ? (
                        <>
                            {/* Editable Key */}
                            <Form.Control
                                value={key}
                                onChange={(e) => handleKeyChange(key, e.target.value)}
                                className="me-2 w-25"
                            />
                            {/* Editable Value */}
                            <Form.Control
                                value={array[key]}
                                onChange={(e) => handleValueChange(key, e.target.value)}
                                className="me-2 w-25"
                            />
                            <Button onClick={handleSave}>Save</Button>
                        </>
                    ) : (
                        <>
                            {/* Non-editable Key */}
                            <Form.Control value={key} className="me-2 w-25" disabled />
                            {/* Non-editable Value */}
                            <Form.Control value={array[key]} className="me-2 w-25" disabled />
                            {/* Edit Icon */}
                            <FaEdit
                                className="me-2 text-primary"
                                size={20}
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    setEditId(key);
                                    setIsEdit(true);
                                }}
                            />
                            {/* Remove Icon */}
                            <CiCircleRemove
                                className="text-danger"
                                size={20}
                                style={{ cursor: 'pointer' }}
                                onClick={() => handleRemove(key)}
                            />
                        </>
                    )}
                </div>
            ))}

            {/* Add New Item */}
            <div
                className="p-3 text-success"
                style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', width: '162px' }}
                onClick={handleNewItem}
            >
                <FaPlusCircle size={20} />
                <span className="ms-2">Add New Item</span>
            </div>
        </>
    );
};



function SettingModal({ data, handleClose, setSettings }: { data: { key: string, value: string | (number | string)[] | settingInterface } | null, handleClose: () => void, setSettings: React.Dispatch<React.SetStateAction<settingInterface[]>> }) {

    const [key, setkey] = useState<string>(data?.key ?? '')
    const [value, setValue] = useState<string | [] | {}>(data?.value ?? '')
    const isSettingModal = useAppSelector((state) => state.ModalReducer.isSettingModal)
    const isEdit = data ? true : false
    const isEditKey = isEdit ? data?.key : ''

    const handleSubmit = async () => {
        if (!key) {
            return toast.error("key is required.")
        }
        if (!value) {
            return toast.error("key is required.")
        }
        if (data) {
            if (!isEditKey) {
                return toast.error("Edit Key is required.")
            }
            const res = await settingServices.updateSettings(isEditKey, key, value)
            if (res.status === 'success') {
                setSettings((prev) => prev.map(
                    (setting) => {
                        const settingKey = Object.keys(setting)[0]
                        if (settingKey === isEditKey) {
                            return { [key]: value}
                            }
                            return setting
                    }
                )
            )
                toast.success("Setting updated successfully.")
            }
            else {
                toast.error(res.message)
            }
        }
        else {
            const res = await settingServices.addSettings(key, value)
            if (res.status === 'success') {
                setSettings((prev) => [
                    ...(prev || []),
                    {
                        [key]: `${value}`,
                    },
                ]);
                toast.success("Setting added successfully.")
            }
            else {
                toast.error(res.message)
            }
        }
        handleClose()
    }

    return (
        <Modal
            // {...props}
            size="xl"
            backdrop="static"
            keyboard={false}
            show={isSettingModal}
            onHide={handleClose}
            dialogClassName="modal-90"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    {isEdit ? 'Edit' : 'Add'} Setting
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FloatingLabel
                    controlId="Key"
                    label="Key"
                    className="mb-3"
                >
                    <Form.Control
                        value={key}
                        placeholder="Write key here."
                        onChange={(e) => setkey(e.target.value)}
                    />
                </FloatingLabel>
                {
                    typeof value === 'object'
                        ?
                        (

                            Array.isArray(value)
                                ?
                                <UpdateArrayValues array={value} setValue={setValue} />
                                :
                                <UpdateObjectValues array={value} setValue={setValue} />
                        )
                        :
                        <FloatingLabel controlId="Value" label="Value">
                            <Form.Control
                                value={`${value}`}
                                onChange={(e) => setValue(e.target.value)}
                                as="textarea"
                                placeholder="write a values here."
                                style={{ height: '100px' }}
                            />
                        </FloatingLabel>
                }
                <div className='w-100 text-end'>
                    <Button className='mt-3' onClick={handleSubmit}>{isEdit ? 'Edit' : 'Save'}</Button>
                </div>
            </Modal.Body>
        </Modal >
    )
}

export default SettingModal