import { useState } from 'react';
import { Form, Modal, Table } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { FaEdit, FaPlus, FaRegClock } from 'react-icons/fa';
import { IoTrashBin } from 'react-icons/io5';
import 'rsuite/DateRangePicker/styles/index.css';
import { DateRange } from 'rsuite/esm/DateRangePicker';
import { ContentConstants } from '../../Redux/content/content-constants';
import { setContentScheduleInterface } from '../../Redux/content/content-reducer';
import { useAppDispatch } from '../../Redux/hooks';
import contentServices from '../../services/content.services';
import { convertUTCtoIST } from '../../utils/helpers';

function ContentScheduleTiming({ contentName, set, scheduleData, contentId }: { contentName: string, set: { id: number, name: string }, scheduleData?: setContentScheduleInterface[], contentId: number }) {

    const [isModal, setIsModal] = useState(false)
    const handleClose = () => setIsModal(false)
    const handleModalOpen = () => setIsModal(true)
    const dispatch = useAppDispatch()
    const value = scheduleData?.find((sd) => sd.setId === set.id)
    const data = value ? [value.availableFrom, value.availableTill].map((dt) => new Date(dt)) as DateRange : null
    const sceduleId = value ? value.id : null
    const isNew = !!!data
    const [dataRange, setDateRange]= useState<{availableFrom: string | null, availableTill: string| null}>({availableFrom: value?.availableFrom ?? '', availableTill: value?.availableTill ?? ''})

    const handleContentUsage = async () => {
        const data = {
            setId: set.id,
            contentId,
            availableFrom: dataRange.availableFrom ? new Date(dataRange.availableFrom)  : null,
            availableTill: dataRange.availableTill ? new Date(dataRange.availableTill) : null
        }
        if (!sceduleId) {
            const res = await contentServices.sheduleContent(data)
            if (res.status === 'success') {
                toast.success("content schedule successfully")
                dispatch({ type: ContentConstants.ADD_CONTENT_SCHEDULE, payload: res.data })
            }
            else {
                // toast.error("content schedule something wrong.")
            }
        }
        else {
            if (!sceduleId) {
                toast.error("id is not provided.")
                return
            }
            const res = await contentServices.updateSheduleContent(data, sceduleId)
            if (res.status === 'success') {
                toast.success("content schedule successfully")
                dispatch({ type: ContentConstants.EDIT_CONTENT_SCHEDULE, payload: res.data })
            }
            else {
                // toast.error("content schedule something wrong.")
            }
        }
    }

    const handleRemove = async (contentId: number, setId: number, id: number | null) => {
        if (!id) {
            toast.error("id is not provided.")
            return
        }
        const data = {
            setId,
            contentId,
            availableFrom: '',
            availableTill: '',
            id
        }
        const res = await contentServices.delteSheduleContent(id)
        if (res.status === 'success') {
            toast.success("content deleted successfully")
            dispatch({ type: ContentConstants.DELETE_CONTENT_SCHEDULE, payload: data })
        }
    }

    return (
        <>
            <Modal show={isModal} onHide={handleClose} size='lg' animation backdrop='static'>
                <Modal.Header closeButton>
                    <Modal.Title
                        className='d-flex align-items-center'
                    >
                        <span>
                            <FaRegClock />
                        </span>
                        &nbsp;
                        Schedule Content {contentName}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table striped bordered hover responsive>
                        <thead>
                            <tr>
                                <th>Set</th>
                                <th>Schedule</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                <tr key={set.id}>
                                    <td>{set.name}</td>
                                    <td className='d-flex align-items-center'>
                                        <Form.Control 
                                        type="datetime-local"
                                        id="availableFrom"
                                        value={dataRange.availableFrom ? convertUTCtoIST(dataRange.availableFrom) : ''}
                                        onChange={(e) => setDateRange((prev) => ({
                                            ...prev,
                                            availableFrom: e.target.value
                                        }))}
                                        />
                                        <Form.Control 
                                        type="datetime-local"
                                        id="availableTill"
                                        value={dataRange.availableTill ? convertUTCtoIST(dataRange.availableTill) : ''}
                                        onChange={(e) => setDateRange((prev) => ({
                                            ...prev,
                                            availableTill: e.target.value
                                        }))}
                                        />
                                        <div className='d-flex flex-column ms-3 align-items-center' style={{
                                            width:'30px'
                                        }}>
                                        {
                                          isNew ? (
                                            <div style={{
                                                width:'25px'
                                            }}>
                                            <FaPlus size={20} onClick={handleContentUsage} style={{ cursor: 'pointer' }} />
                                            </div>
                                          ) : (
                                            <div style={{
                                                width:'25px'
                                            }}>
                                            <FaEdit size={20} onClick={handleContentUsage} style={{ cursor: 'pointer' }} />
                                          </div>
                                          )                                        
                                        }
                                        {!isNew && <div style={{
                                            width:'30px'
                                        }} className='mt-2'> <IoTrashBin color='red' size={20} style={{ cursor: 'pointer' }} onClick={(e) => handleRemove(contentId, set.id, sceduleId)} /> </div>}
                                        </div>
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </Table>
                </Modal.Body>
            </Modal>
            <div style={{
                width: '50px'
            }}>
                {
                    isNew
                        ?
                        <FaRegClock onClick={handleModalOpen} style={{ cursor: 'pointer' }} />
                        :
                        <FaEdit onClick={handleModalOpen} style={{ cursor: 'pointer' }} />
                }
            </div>
        </>
    )
}

export default ContentScheduleTiming