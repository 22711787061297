import { mediaUploadConstants } from "../../ReducerConstants/mediaUploadConstants";


interface mediaDataInterface {
  mediaData: {
    editRequired: boolean
    percent: number
    qOrder: number
    questionId: number
    status: 'COMPLETED' | 'NOT_STARTED' | 'IN_PROGRESS' | "FAILED"
    assetId: number | string,
    name?: string
  }[]
}


const initState: mediaDataInterface = { mediaData: [] };

export default function mediaUploadReducer(state = initState, action: any) {
  switch (action.type) {
    case mediaUploadConstants.MEDIA_UPLOAD_START:
      return {
        ...state,
        mediaData: [...state.mediaData, action.data],
      };
    case mediaUploadConstants.MEDIA_UPLOAD_INPROGRESS:
      return {
        ...state,
        mediaData: state.mediaData.map((m) => {
          if (m.name === action.name) {
            return { ...m, percent: action.percent };
          }
          return m;
        }),
      };
    case mediaUploadConstants.MEDIA_UPLOAD_COMPLETED:
      return {
        ...state,
        mediaData: state.mediaData.map((m) => {
          if (m.name === action.name) {
            return { ...m, status: "COMPLETED", url: action.url };
          }
          return m;
        }),
      };
    case mediaUploadConstants.MEDIA_UPLOAD_FAILED:
      return {
        ...state,
        mediaData: state.mediaData.map((m) => {
          if (m.name === action.name) {
            return { ...m, status: "FAILED" };
          }
          return m;
        }),
      };
    default:
      return state;
  }
}
