export const TestReducersConstants = {
  TEST_DATA: "TEST_DATA",
  ACTIVE_INDEX: "ACTIVE_INDEX",
  IS_ANSWERED: "IS_ANSWERED",
  OPTION_MATCHES: "OPTION_MATCHES",
  IS_NUMERICAL: "IS_NUMERICAL",
  NEXT: "NEXT",
  PREV: "PREV",
  FILTER: "FILTER",
  SECTION: "SECTION",
  RESET: "RESET",
  MESSAGE_MODAL: "MESSAGE_MODAL",
};
