import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { ContentConstants } from '../../../Redux/content/content-constants';
import { ContentItem } from '../../../Redux/content/content-reducer';
import { useAppDispatch, useAppSelector } from '../../../Redux/hooks';
import { LiveManagementConstants } from '../../../Redux/live-mangment/live-mangment-constant';
import { ModalsConstants } from '../../../Redux/models/models-constants';
import PDFVIewer from '../../../components/PDFVIewer';
import VideoPlayer from '../../../components/media/videoplayer.component';
import ConfirmModal from '../../../components/modals/remove-item-model';
import { BACKEND_URLS, FRONTEND_URLS } from '../../../config';
import { axiosClient } from '../../../services/axiosClient';
import { AssetType, contenType, VideoSource } from '../../../types/types';

type level3Type = {
    id: number;
    internalName: string;
    imageUrl: string;
    name: string;
};

const Content = () => {
    const schema = yup.object().shape({
        name: yup.string(),
        contentGroup: yup.string().nullable(),
        Type: yup.string().nonNullable(),
        isFree: yup.boolean(),
        order: yup.number(),
    });

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { nodeId, contentId } = useParams();
    const contentIdNumber = Number(contentId);
    const [contentGroup, setContentGroup] = useState<level3Type[]>([]);

    const selectedChapter = useAppSelector<any>(
        (state) => state.ContentReducer.selectedChapter
    ) as {
        level2Id: number;
        level2Name: number;
    };
    const content = useAppSelector(
        (state) =>
            state.ContentReducer.content.find(
                (content: ContentItem) => content.id === contentIdNumber
            ) as ContentItem
    ) as ContentItem;
    const isContentImortedDifferent = !!content?.isImported
        ? content?.newLevel2Id
            ? content?.newLevel2Id !== content?.level2Id
            : false
        : true;

    const handleContentCreate = async (values: {
        name: string;
        contentGroup: string | null | number;
        type: string;
        isFree: boolean;
        order: number;
    }) => {
        try {
            var response, contentId;

            if (!selectedChapter.level2Id) {
                toast.error('chapter is not their.');
                return;
            }

            if (!values.type) {
                toast.error('Type not be empty.');
                return;
            }

            if (content?.isNew === true) {
                const data = {
                    name: values.name,
                    assetType: content?.assetType,
                    assetId: content?.assetData.id,
                    type: values.type,
                    level2Id: selectedChapter.level2Id ?? null,
                    level3Id: values.contentGroup
                        ? typeof values.contentGroup === 'string'
                            ? parseInt(values.contentGroup)
                            : values.contentGroup
                        : null,
                    parentAssetType: content.parentAssetType ?? null,
                    parentAssetId: content.parentAssetId ?? null,
                    isFree: values.isFree,
                };

                if (isContentImortedDifferent || contentIdNumber < 0) {
                    response = await axiosClient.post(BACKEND_URLS.CONTENT, data);
                    contentId = response.data.data.id;
                } else {
                    contentId = content?.id;
                }

                dispatch({
                    type: ContentConstants.NEW_CONTENT,
                    payload: { id: contentId, data: { ...data, order: values.order } },
                });
                dispatch({ type: ContentConstants.EDIT_NEW_CONTENT_ID, payload: contentId });

                await axiosClient.post(`/nodes/${nodeId}/content`, {
                    contentId,
                    order: values.order,
                });
                toast.success('Content created successfully.');
                navigate(
                    FRONTEND_URLS.CONTENTS.EDIT_NODE +
                        `/${nodeId}` +
                        '/' +
                        FRONTEND_URLS.CONTENTS.ASSET +
                        '/' +
                        contentId,
                    { replace: true }
                );
            } else {
                const data = {
                    name: values.name,
                    assetType: content?.assetType,
                    assetId: content?.assetData.id,
                    type: values.type,
                    level2Id: selectedChapter.level2Id ?? null,
                    level3Id: values.contentGroup
                        ? typeof values.contentGroup === 'string'
                            ? parseInt(values.contentGroup)
                            : values.contentGroup
                        : null,
                    parentAssetType: null,
                    parentAssetId: null,
                    isFree: values.isFree,
                };

                if (
                    content?.name !== values.name ||
                    content.level3Id !== values.contentGroup ||
                    content.type !== values.type ||
                    content.isFree !== values.isFree
                ) {
                    response = await axiosClient.put(
                        BACKEND_URLS.CONTENT + '/' + content?.id,
                        data
                    );
                }

                if (content?.order != values.order) {
                    // adding to node
                    const nodeAdd = await axiosClient.put(
                        `/nodes/${nodeId}/content/${content?.id}`,
                        {
                            order: values.order,
                        }
                    );
                }

                dispatch({
                    type: ContentConstants.Edit_CONTENT,
                    payload: { id: content?.id, data: { ...data, order: values.order } },
                });
                navigate(
                    FRONTEND_URLS.CONTENTS.EDIT_NODE +
                        `/${nodeId}` +
                        '/' +
                        FRONTEND_URLS.CONTENTS.ASSET +
                        '/' +
                        content?.id,
                    { replace: true }
                );
                toast.success('Content edited successfully.');
            }
        } catch (error: any) {
            toast.error('Error creating content:', error);
            toast.error(
                'An error occurred while creating content. Please try again later. ' +
                    error.response.data.message
            );
        }
    };

    const handleCancle = async () => {
        if (content?.id && content.id < 0) {
            dispatch({ type: ContentConstants.REMOVE_CONTENT, payload: { contentId: content.id } });
            navigate(FRONTEND_URLS.CONTENTS.EDIT_NODE + '/' + nodeId, { replace: true });
        } else {
            try {
                const data = await axiosClient.delete(
                    `/nodes/${nodeId}/content` + '/' + content?.id
                );
                dispatch({
                    type: ContentConstants.REMOVE_CONTENT,
                    payload: { contentId: content?.id },
                });
                toast.success(data.data.message);
                navigate(FRONTEND_URLS.CONTENTS.EDIT_NODE + '/' + nodeId, { replace: true });
            } catch (error: any) {
                toast.error(error?.message);
            }
        }
    };

    const handleSession = () => {
        dispatch({ type: LiveManagementConstants.SESSIONS, payload: [content?.assetData] });
        dispatch({
            type: LiveManagementConstants.CURRENT_SESSION_ID,
            payload: content?.assetData.id,
        });
        navigate(`${location.pathname}?content=3`);
        dispatch({ type: ModalsConstants.IS_Modal, payload: true });
    };

    useEffect(() => {
        if (contentGroup) {
            axiosClient(BACKEND_URLS.LEVEL + 3)
                .then((res) => {
                    setContentGroup(res.data.data.level);
                })
                .catch((err: any) => {
                    toast.error(err.message);
                });
        }
    }, [selectedChapter, content, contentId]);

    const [showModal, setShowModal] = useState(false); // Modal visibility state

    const handleRemove = () => {
        setShowModal(true); // Open the modal on 'Remove' click
    };

    const confirmRemove = () => {
        setShowModal(false); // Close the modal
        handleCancle(); // Execute the remove logic
    };

    const cancelRemove = () => setShowModal(false); // Close modal without removing

    return (
        <div
            className="d-flex flex-column w-100"
            style={{
                height: '80vh',
                overflow: 'auto',
            }}
        >
            <Formik
                initialValues={{
                    name: content?.name ?? '',
                    contentGroup: content?.level3Id ?? '',
                    type: content?.type ?? '',
                    isFree: content?.isFree || false,
                    order: content?.order || 1,
                }}
                validationSchema={schema}
                onSubmit={(values) => handleContentCreate(values)}
                enableReinitialize
            >
                {({ handleSubmit, handleChange, setFieldValue, values, touched, errors }) => (
                    <Form
                        className="mx-4 d-flex justify-content-center flex-column w-75 mt-3 border p-4 rounded shadow"
                        noValidate
                        onSubmit={handleSubmit}
                    >
                        <Form.Group as={Row} controlId="validationFormikName">
                            <Form.Label column sm={3}>
                                Name:
                            </Form.Label>
                            <Col sm={9}>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Internal Name"
                                    name="name"
                                    value={values.name}
                                    onChange={handleChange}
                                    isInvalid={touched.name && !!errors.name}
                                    className="mb-2"
                                />
                                <Form.Control.Feedback type="invalid">
                                    {typeof errors.name === 'string' ? errors.name : ''}
                                </Form.Control.Feedback>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="validationFormikOrder">
                            <Form.Label column sm={3}>
                                Order:
                            </Form.Label>
                            <Col sm={9}>
                                <Form.Control
                                    required
                                    type="number"
                                    placeholder="Order"
                                    name="order"
                                    value={values.order}
                                    onChange={handleChange}
                                    isInvalid={touched.order && !!errors.order}
                                    className="mb-2"
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.order}
                                </Form.Control.Feedback>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="validationFormikContentGroup">
                            <Form.Label column sm={3}>
                                Content Group:
                            </Form.Label>
                            <Col sm={9}>
                                <Form.Select
                                    name="contentGroup"
                                    onChange={handleChange}
                                    value={values.contentGroup ?? ''}
                                    isInvalid={touched.contentGroup && !!errors.contentGroup}
                                    className="mb-2"
                                >
                                    <option value="">
                                        --------Select Content Group-----------
                                    </option>
                                    {contentGroup.map((group) => (
                                        <option key={group.internalName} value={group.id}>
                                            {group.name}
                                        </option>
                                    ))}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    {typeof errors.contentGroup === 'string'
                                        ? errors.contentGroup
                                        : ''}
                                </Form.Control.Feedback>
                            </Col>
                            <Form.Control.Feedback type="invalid">
                                {typeof errors.contentGroup === 'string' ? errors.contentGroup : ''}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Row} controlId="validationFormikType">
                            <Form.Label column sm={3}>
                                Type:
                            </Form.Label>
                            <Col sm={9}>
                                <Form.Select
                                    name="type"
                                    onChange={handleChange}
                                    value={values.type}
                                    isInvalid={touched.type && !!errors.type}
                                    className="mb-2"
                                >
                                    <option value="">--------Select type-----------</option>
                                    {Object.values(contenType).map((type) => (
                                        <option key={type} value={type}>
                                            {type}
                                        </option>
                                    ))}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    {typeof errors.type === 'string' ? errors.type : ''}
                                </Form.Control.Feedback>
                            </Col>
                        </Form.Group>

                        {content?.assetType === AssetType.VIDEO ? (
                            <Form.Group as={Row}>
                                <Form.Label column sm={3}>
                                    <strong>videoId</strong>
                                </Form.Label>
                                <Col sm={9}>
                                    <strong>{content.assetData.videoId}</strong>
                                </Col>
                            </Form.Group>
                        ) : content?.assetType === AssetType.QUIZ ? (
                            <Form.Group as={Row}>
                                <Form.Label column sm={3}>
                                    <strong>Quiz Id</strong>
                                </Form.Label>
                                <Col sm={9}>
                                    <strong>{content.assetId}</strong>
                                </Col>
                            </Form.Group>
                        ) : (
                            <></>
                        )}
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={3}></Form.Label>
                            <Col sm={3}>
                                <Form.Check
                                    label="Is Free"
                                    onChange={(e) => setFieldValue('isFree', e.target.checked)}
                                    checked={values.isFree}
                                    isInvalid={touched.isFree && !!errors.isFree}
                                />
                            </Col>
                        </Form.Group>

                        {/* Buttons */}
                        <div className="d-flex justify-content-between">
                            <div>
                                <Button variant="danger" onClick={handleRemove}>
                                    Remove
                                </Button>
                            </div>
                            <div>
                                {content?.assetType === AssetType.SESSION ? (
                                    <Button
                                        type="button"
                                        variant="primary"
                                        className="mx-3"
                                        onClick={handleSession}
                                    >
                                        Edit Session
                                    </Button>
                                ) : null}
                                <Button type="submit" variant="primary">
                                    {content?.isNew === true ? 'Submit' : 'Edit'}
                                </Button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>

            <div className="mx-4 d-flex justify-content-center flex-column w-75 mt-3 p-4 align-items-center">
                {content?.assetType === AssetType.PDF ? (
                    <PDFVIewer
                        encrypted_file_url={content.assetData.encryptedFileUrl}
                        pdf_password={content.assetData.password}
                    />
                ) : content?.assetType === AssetType.VIDEO ? (
                    <VideoPlayer
                        videoUrl={content?.assetData.videoId}
                        source={content?.assetData.source as VideoSource} // Casting the string to VideoSource
                    />
                ) : content?.assetType === AssetType.QUIZ ? (
                    <>Quiz</>
                ) : (
                    <>Session</>
                )}
            </div>

            {/* Confirmation Modal */}
            <ConfirmModal
                show={showModal}
                onHide={cancelRemove}
                onConfirm={confirmRemove}
                title="Confirm Removal"
                bodyText="Are you sure you want to remove this item? This action cannot be undone."
                confirmText="Remove"
                cancelText="Cancel"
            />
        </div>
    );
};

export default Content;
