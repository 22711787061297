import { useEffect, useState } from 'react';
import { Table, Spinner, Button } from 'react-bootstrap';
import { PaymentGatewayListItem } from '../../types/payment-gateway-types';
import paymentGatewayService from '../../services/ApiServices/payment-gateway-service';
import CreateEditGateway from './create-edit-gateway';

const PaymentGateways = () => {
    const [gateways, setGateways] = useState<PaymentGatewayListItem[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [editId, setEditId] = useState<number | undefined>(undefined);

    const fetchGateways = () => {
        setIsLoading(true);
        paymentGatewayService
            .getGateWays()
            .then((res) => {
                if (res.status === 'success') {
                    setGateways(res.data.paymentGateways);
                }
            })
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        fetchGateways();
    }, []);

    return (
        <div style={{ margin: '20px' }}>
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h3>Payment Gateways</h3>
                <Button
                    onClick={() => {
                        setEditId(undefined);
                        setShowModal(true);
                    }}
                >
                    Create Gateway
                </Button>
            </div>

            {showModal && (
                <CreateEditGateway
                    id={editId}
                    onClose={() => {
                        setShowModal(false);
                        fetchGateways();
                    }}
                />
            )}

            {isLoading ? (
                <div style={{ textAlign: 'center', padding: '20px' }}>
                    <Spinner animation="border" variant="primary" />
                </div>
            ) : gateways.length > 0 ? (
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Slug</th>
                            <th>Status</th>
                            <th>Created On</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {gateways.map((gateway) => (
                            <tr key={gateway.id}>
                                <td>{gateway.id}</td>
                                <td>{gateway.slug}</td>
                                <td>
                                    <span
                                        className={`badge ${
                                            gateway.isActive ? 'bg-success' : 'bg-danger'
                                        }`}
                                    >
                                        {gateway.isActive ? 'Active' : 'Inactive'}
                                    </span>
                                </td>
                                <td>{new Date(gateway.createdOn).toLocaleDateString('en-GB')}</td>
                                <td>
                                    <Button
                                        variant="outline-primary"
                                        size="sm"
                                        onClick={() => {
                                            setEditId(gateway.id);
                                            setShowModal(true);
                                        }}
                                        style={{ marginRight: '8px' }}
                                    >
                                        Edit
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            ) : (
                <p>No payment gateways found.</p>
            )}
        </div>
    );
};

export default PaymentGateways;
