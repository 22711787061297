import { NodeItem } from '../../types/node.types';
import ApiService, { ApiResponse } from './api-service';

class NodeService extends ApiService {
    constructor() {
        super('/nodes');
    }
    getAll(): ApiResponse<{ nodes: NodeItem[] }> {
        return this.getData('/');
    }
    getNodeSets(nodeId: number): ApiResponse<
        {
            id: number;
            name: string;
            endDate: string;
            isStatic: boolean;
            type: string;
            thumbnail: string;
            createdById: null | number;
            createdOn: string;
            updatedOn: string;
        }[]
    > {
        return this.getData(`/${nodeId}/sets`);
    }
    getById(id: number): ApiResponse<{ node: NodeItem }> {
        return this.getData(`/${id}`);
    }
    updateNode(id: number, data: any) {
        return this.putData(`/${id}`, data);
    }

    createNode(data: any): ApiResponse<{ id: number }> {
        return this.postData(``, data);
    }
}

export default new NodeService();
