export const ContentConstants = {
    SUBJECTS: 'SUBJECTS',
    SUBJECTS_GROUPS: 'SUBJECTS_GROUPS',
    NODE_NAME: 'NODE_NAME',
    SELECTED_SUBJECT_GROUP_ID: 'SELECTED_SUBJECT_GROUP_ID',
    SELECTED_SUBJECT_ID: 'SELECTED_SUBJECT_ID',
    IS_ADD_CHAPTER: 'IS_ADD_CHAPTER',
    CHAPTER_DATA: 'CHAPTER_DATA',
    ADD_CHAPTER: 'ADD_CHAPTER',
    CONTENT: 'CONTENT',
    ADD_CONTENT: 'ADD_CONTENT',
    SELECTED_CHAPTER: 'SELECTED_CHAPTER',
    Edit_CONTENT: 'Edit_CONTENT',
    EDIT_NEW_CONTENT_ID: 'EDIT_NEW_CONTENT_ID',
    REMOVE_CONTENT: 'REMOVE_CONTENT',
    NEW_CONTENT: 'NEW_CONTENT',
    EDIT_SESSION: 'EDIT_SESSION',
    ADD_CONTENT_SCHEDULE: 'ADD_CONTENT_SCHEDULE',
    EDIT_CONTENT_SCHEDULE: 'EDIT_CONTENT_SCHEDULE',
    DELETE_CONTENT_SCHEDULE: 'DELETE_CONTENT_SCHEDULE'
}