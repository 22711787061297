import { NodeItem } from '../../types/node.types';
import { CreateSetItem, SetItem, SetNodeItem } from '../../types/setTypes';
import ApiService, { ApiResponse } from './api-service';

class SetService extends ApiService {
    constructor() {
        super('/sets');
    }

    getAll(): ApiResponse<{ sets: SetItem[] }> {
        return this.getData('?limit=200');
    }
    getById(id: number): ApiResponse<{ set: SetItem }> {
        return this.getData(`/${id}`);
    }
    createSet(data: CreateSetItem) {
        return this.postData('/', data);
    }
    updateSet(id: number, data: CreateSetItem) {
        return this.putData(`/${id}`, data);
    }
    getNodes(id: number): ApiResponse<{ nodes: SetNodeItem[] }> {
        return this.getData(`/${id}/nodes`);
    }
    addNode(id: number, data: { nodeId: number }) {
        return this.postData(`/${id}/nodes`, data);
    }
    removeNode(id: number, data: { nodeId: number }) {
        return this.deleteData(`/${id}/nodes`, data);
    }
}

export default new SetService();
