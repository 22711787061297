import React, { useState, useRef } from 'react';
import { FaPaperclip, FaSmile, FaMicrophone, FaPaperPlane, FaTimes } from 'react-icons/fa';
import './chat-box.css';
import { pdfUploadHelper } from '../../../utils/helpers';

type Props = {
    onSendMessage: () => void;
    value: string;
    onChange: (e: any) => void;
    onFileUpload: (url: string) => void;
};

const ChatboxFooter = ({ onSendMessage, value, onChange, onFileUpload }: Props) => {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [preview, setPreview] = useState<string | null>(null);

    const handleFileClick = () => {
        fileInputRef.current?.click();
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            pdfUploadHelper(file).then((url) => {
                onFileUpload(url);
            });
            setPreview(URL.createObjectURL(file));
        }
        e.target.value = '';
    };

    const handleSend = () => {
        onSendMessage();
        setPreview(null);
    };

    const handleCancelPreview = () => {
        setPreview(null);
    };

    return (
        <div className="chatbox-footer">
            {preview && (
                <div className="image-preview">
                    <img src={preview} alt="Preview" />
                    <button className="cancel-preview" onClick={handleCancelPreview}>
                        <FaTimes />
                    </button>
                </div>
            )}
            <button className="icon-button">
                <FaSmile />
            </button>
            <button className="icon-button" onClick={handleFileClick}>
                <FaPaperclip />
            </button>
            <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
                style={{ display: 'none' }}
                accept="image/*"
            />
            <input
                type="text"
                className="message-input"
                placeholder="Type a message"
                value={value}
                onChange={onChange}
                onKeyUp={(e) => e.key === 'Enter' && handleSend()}
            />
            {value.trim() || preview ? (
                <button className="send-button" onClick={handleSend}>
                    <FaPaperPlane />
                </button>
            ) : (
                <button className="icon-button">
                    <FaMicrophone />
                </button>
            )}
        </div>
    );
};

export default ChatboxFooter;
