import React, { useEffect } from 'react'
import { LoginComponent } from '../components'
import { useNavigate } from 'react-router-dom'

function Login() {

    const navigate = useNavigate()

    
  useEffect(
    () => {
      const tkn = localStorage.getItem('@user')
      if (tkn){
        navigate('/')
      }
    }, []
  )

    return (
        <>
            <LoginComponent />
        </>
    )
}

export default Login