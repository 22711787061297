import { Accordion, useAccordionButton, Table } from 'react-bootstrap';
import { GroupedByDateContent } from '../../types/contentUsageTypes';
import { icons } from '../../assets/icons';
import formatDuration from '../helpers/timeFormat';

// Custom Toggle for Accordion

function ContentItem({ data }: { data: GroupedByDateContent }) {
    const { date, time } = data;

    const getTotalRows = (level1Content: any[]) => {
        return level1Content.reduce((acc: number, level1Item: any) => {
            return (
                acc +
                level1Item?.content?.reduce((innerAcc: number, level2Content: any) => {
                    return innerAcc + (level2Content?.content?.length || 0);
                }, 0)
            );
        }, 0);
    };

    const totalRowsForDate = getTotalRows(data.level1Content);

    return (
        <tbody>
            {data.level1Content.map((level1Item, level1Idx: number) => {
                const rowsForSubject = level1Item?.content?.reduce((acc: number, level2Content) => {
                    if ('content' in level2Content) {
                        return acc + level2Content?.content?.length;
                    }
                    return acc;
                }, 0);

                return level1Item?.content?.map((level2Content, level2Idx: number) => {
                    return (
                        'content' in level2Content &&
                        level2Content?.content?.map((contentItem, contentItemIdx: number) => {
                            return (
                                <tr key={`${level1Idx}-${level2Idx}-${contentItemIdx}`}>
                                    {/* Render date only in the first row of the entire date's data */}

                                    {level1Idx === 0 && level2Idx === 0 && contentItemIdx === 0 && (
                                        <td rowSpan={totalRowsForDate}>
                                            {new Date(date).toLocaleDateString('en-GB', {
                                                day: '2-digit',
                                                month: '2-digit',
                                            })}
                                        </td>
                                    )}
                                    {level1Idx === 0 && level2Idx === 0 && contentItemIdx === 0 && (
                                        <td rowSpan={totalRowsForDate}>{formatDuration(time)}</td>
                                    )}
                                    <td>
                                        <div className="d-flex align-items-center">
                                            {/* Conditional rendering for the icon */}
                                            {contentItem.assetType == 'SESSION' ? (
                                                contentItem.contentType == 'VIDEO' ? (
                                                    <div title="Session Video">
                                                        {icons.live_session}
                                                    </div>
                                                ) : contentItem.contentType == 'PDF' ? (
                                                    <div title="Session PDF">{icons.PDF}</div>
                                                ) : (
                                                    <div title="Session">{icons.LIVE}</div>
                                                )
                                            ) : contentItem.assetType === 'PDF' ? (
                                                <div title="PDF">{icons.PDF}</div>
                                            ) : contentItem.assetType === 'VIDEO' ? (
                                                <div title="Video">{icons.VIDEO}</div>
                                            ) : contentItem.assetType === 'QUIZ' ? (
                                                <div title="Quiz">{icons.QUIZ}</div>
                                            ) : null}

                                            {/* Content name next to the icon */}
                                            <span className="ms-2">
                                                {contentItem.contentName} (
                                                {formatDuration(contentItem.durInSec)})
                                            </span>
                                        </div>
                                    </td>
                                    {/* Render chapter name only for the first row of the chapter's data */}
                                    {contentItemIdx === 0 && (
                                        <td rowSpan={level2Content?.content?.length}>
                                            {level2Content?.level2Name}
                                        </td>
                                    )}
                                    {/* Render subject name only for the first row of the subject's data */}
                                    {level2Idx === 0 && contentItemIdx === 0 && (
                                        <td rowSpan={rowsForSubject}>{level1Item?.level1Name}</td>
                                    )}
                                </tr>
                            );
                        })
                    );
                });
            })}
        </tbody>
    );
}

// Main Component to display Level1Content[]
const Level1Accordion = ({ data }: { data: GroupedByDateContent[] }) => {
    return (
        <Accordion>
            {data?.length > 0 ? (
                <Table bordered>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Duration</th>
                            <th>Content</th>
                            <th>Chapter</th>
                            <th>Subject</th>
                        </tr>
                    </thead>
                    {data?.map((item, idx) => (
                        // JSON.stringify(item)
                        // <Level1AccordionItem key={idx} data={item} />
                        <ContentItem key={idx} data={item} />
                    ))}
                </Table>
            ) : (
                'No Data found'
            )}
        </Accordion>
    );
};

export default Level1Accordion;
