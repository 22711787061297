import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import Practice from '../components/Practice/Practice';
import { CurrentUser } from '../types/types';

function ProofRead() {
    const quizId = useParams().quizId ?? 0;

    const [user, setUser] = useState<CurrentUser>()

    // useEffect(
    //     () => {
    //         getServerAuthSession()
    //     }, []
    // )
    
    
    // return <Practice quizId={+quizId} currentUser={user} />;
    return <>prorf-read quizId {quizId}</>;
}

export default ProofRead