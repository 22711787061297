export enum TYPES {
    GK = 'Gurukul',
    MAA = 'Maa Mentorship',
    OP = 'OP',
    PI = 'Padho India',
    SM = 'Study Material',
    TS = 'Test Series',
}

export enum TERMS {
    YL = 'year long',
    CC = 'crash course',
    ER = 'early',
    IN = 'inteigrated',
}
