import { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { icons } from "../../../assets/icons";
import { QuizSectionItem } from "../../../types/quizTypes";
import QuizService from "../../../services/ApiServices/QuizService";
import toast from "react-hot-toast";

type Props = {
  quizId?: number;
  section: QuizSectionItem & { id?: number };
  setSection: (e: any) => void;
  index: number;
  isDisabled: boolean;
};

const QuizSection = ({
  quizId,
  section,
  setSection,
  index,
  isDisabled,
}: Props) => {
  const [disabled, setDisabled] = useState<boolean>(isDisabled);
  const sectionNameRef = useRef<HTMLInputElement>(null);
  const maxQuestionsRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (sectionNameRef.current && section.sectionName) {
      sectionNameRef.current.value = section.sectionName;
    }
    if (maxQuestionsRef.current && section.maxQuestions) {
      maxQuestionsRef.current.value = String(section.maxQuestions);
    }
  }, [section]);
  useEffect(() => {
    // return () => {
    //   if (!disabled) {
    //     toast.error("Warning: Section changes will not be saved.");
    //   }
    // };
  }, []);
  const saveSection = (isActive = true) => {
    if (isActive) {
      if (quizId) {
        if (section.id)
          QuizService.updateSection(quizId, section.id, {
            sectionName: sectionNameRef.current!.value,
            maxQuestions: Number(maxQuestionsRef.current!.value),
          });
        else {
          QuizService.addSection(quizId, {
            sectionName: sectionNameRef.current!.value,
            maxQuestions: Number(maxQuestionsRef.current!.value),
          });
        }
      }
      setSection((sections: any) => {
        sections[index].sectionName = sectionNameRef.current!.value;
        sections[index].maxQuestions = Number(maxQuestionsRef.current!.value);
        return [...sections];
      });

      setDisabled(true);
    } else {
      if (quizId && section.id) {
        QuizService.removeSection(quizId, { sectionId: section.id }).then(
          (res) => {
            if (res.status === 'error') {
              toast.error(res.message)
            }
            else {
              setSection((sections: any) =>
                sections.filter((_: any, i: number) => i !== index)
              );
            }
          }
        )
      }
    }
    // window.location.reload()
  };

  return (
    <tr>
      <td>
        <Form.Control
          ref={sectionNameRef}
          name="sectionName"
          size="sm"
          type="text"
          defaultValue={section.sectionName}
          disabled={disabled}
        />
      </td>
      <td>
        <Form.Control
          ref={maxQuestionsRef}
          name="maxQuestions"
          size="sm"
          type="number"
          defaultValue={section.maxQuestions}
          disabled={disabled}
        />
      </td>

      {disabled ? (
        <td>
          <a onClick={() => setDisabled(false)} style={{ cursor: 'pointer' }} > {icons.edit} </a>
        </td>
      ) : (
        <td>
          <a onClick={() => saveSection()} style={{ cursor: 'pointer' }} > {icons.correct} </a>
        </td>
      )}
      <td>
        <a onClick={() => saveSection(false)} style={{ cursor: 'pointer' }} > {icons.cancel} </a>
      </td>
    </tr>
  );
};

export default QuizSection;
