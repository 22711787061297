import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row, Stack } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { axiosClient } from '../../../services/axiosClient';
import { BACKEND_URLS, ENDPOINT_HOST } from '../../../config';
import PaymentsHeader from '../../../components/headers/payement.header';
import toast from 'react-hot-toast';

function Payments() {
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(100)

  // Filter states
  const [filters, setFilters] = useState({
    collectedBy: "",
    status: "Completed",
    paymentType: "",
    phase: "",
    search: "",
    from: "",
    to: "",
  });

  const fetchAllEnrollments = async (page: number) => {
    setLoading(true);
    try {
      const response = await axiosClient.get(`${ENDPOINT_HOST + BACKEND_URLS.ENROLLMENT}`, {
        params: {
          page,
          limit: perPage,
          ...filters,
        },
      });
      setData(response.data.data);
    } catch (error: any) {
      toast.error("Error fetching enrollments:", error);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (page: number) => {
    fetchAllEnrollments(page);
  };

  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    setPerPage(newPerPage);
    fetchAllEnrollments(page);
  };

  const onFilterApply = () => {
    setResetPaginationToggle(true);
    fetchAllEnrollments(1);
  };

  const onChangeFilterText = (value: string, key: string) => {
    setFilters((prev) => ({ ...prev, [key]: value }));
  };

  const columns = [
    {
      name: "Payment Date",
      selector: (row: any) => row.paymentDate, sortable: true,
      cell: (row: any) => <div
        data-tag="allowRowEvents"
        style={{ overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipses' }}
      > {row.paymentDate} </div>,
    },
    {
      name: "User Name",
      selector: (row: any) => row.user.username, sortable: true,
      cell: (row: any) => <div
        data-tag="allowRowEvents"
        style={{ overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipses' }}
      > {row.user.username} </div>,
    },
    {
      name: "Name",
      selector: (row: any) => row.user.name, sortable: true, cell: (row: any) => <div
        data-tag="allowRowEvents"
        style={{ overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipses' }}
      > {row.user.name} </div>,
    },
    {
      name: "Course",
      selector: (row: any) => row.course.name, sortable: true,
      cell: (row: any) => <div
        data-tag="allowRowEvents"
        style={{ overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipses' }}
      > {row.course.name} </div>,
    },
    {
      name: "Current End Date",
      selector: (row: any) => row.validTill?.split('T')[0], sortable: true,
      cell: (row: any) => <div
        data-tag="allowRowEvents"
        style={{ overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipses' }}
      > {row.validTill?.split('T')[0]} </div>,
    },
    {
      name: "Total Amount",
      selector: (row: any) => row.totalAmount, sortable: true,
      cell: (row: any) => <div
        data-tag="allowRowEvents"
        style={{ overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipses' }}
      > {row.totalAmount} </div>,
    },
    {
      name: "Payment Type",
      selector: (row: any) => row.payment_type, sortable: true,
      cell: (row: any) => <div
        data-tag="allowRowEvents"
        style={{ overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipses' }}
      > {row.payment_type} </div>,
    },
    {
      name: "Status",
      cell: (row: any) => (
        <span>
          {row.enrollmentPayment.some((payment: any) => payment.status === "PENDING") ? 'Pending' : 'Completed'}
        </span>
      ),
      sortable: false,
    },
    { name: "Collected by", selector: (row: any) => row.collectedBy, sortable: true },
  ];

  useEffect(() => {
    fetchAllEnrollments(1); // Fetch data on mount
  }, []);

  return (
    <div className='mx-3'>
      <h1>All Payments</h1>
      <PaymentsHeader
        onChangeFilterText={onChangeFilterText}
        onFilterApply={onFilterApply}
        userPermissions={['ad']} // Example permission, modify as needed
      />

      {/* <DataTable
        columns={columns}
        data={data}
        progressPending={loading}
        pagination
        paginationPerPage={100}
        paginationRowsPerPageOptions={[100, 500, 1000]}
        noDataComponent="No Data Set"
        paginationServer
        highlightOnHover
        pointerOnHover
        onRowClicked={(row) => console.log("Row clicked:", row)}
        paginationResetDefaultPage={resetPaginationToggle}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        paginationTotalRows={totalRows}
        customStyles={{
          table: {
            style: {
              width: '90%',
              margin: 'auto'
            }
          }
        }}
      /> */}
    </div>
  );
}

export default Payments;