import { Link } from "react-router-dom";

const UserHeader = () => {
    return (
        <div className="my-3 mx-2">
            <div className="d-flex gap-2">
                <Link to="/roles" className="btn btn-primary">
                    Create Role
                </Link>
                <Link to="/permission" className="btn btn-primary">
                    Create Permission
                </Link>
                <Link to="/users/user?id=0" className="btn btn-primary" target="_blank">
                    Create User
                </Link>
            </div>
        </div>
    );
};

export default UserHeader;
