import { combineReducers } from "redux";
import userPracticeReducer from "./Reducers/User/userPracticeReducer";
import mediaUploadReducer from "./Reducers/User/mediaUploadReducer";
import userReducer from "./Reducers/Admin/userReducer";
import quizReducer from "./Reducers/Admin/quizReducer";
import TestReducers from "./Test/TestReducers";
import NavBarReducer from "./nav-bar/nav-bar";
import ModalReducer from "./models/models";
import ContentReducer from "./content/content-reducer";
import LiveMangmentReducer from "./live-mangment/live-mangment-reducer";

const rootReducer = combineReducers({
  userReducer,
  quizReducer,
  test: TestReducers,

  //user side
  userPracticeReducer,
  mediaUploadReducer,
  NavBarReducer,
  ModalReducer,
  ContentReducer,
  liveMangment: LiveMangmentReducer

});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
