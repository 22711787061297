import { useState } from 'react';
import formatDuration from '../helpers/timeFormat';
import { QuizAttemptItem } from '../../types/quizTypes';
import { Button, Modal, Table } from 'react-bootstrap';
import QuizAttempts from '../StudentStatistics/QuizAttempts';
import { FaCheckCircle, FaQuestionCircle, FaTimesCircle } from 'react-icons/fa';

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

function formatTimestamp(timestamp: number | string) {
    const date = new Date(timestamp);
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const formattedMonth = months[monthIndex];
    const formattedDate = `${day < 10 ? '0' : ''}${day} ${formattedMonth} ${year} ${
        hours < 10 ? '0' : ''
    }${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    return formattedDate;
}
function calculateTimeTaken(startTime: string, endTime: string) {
    const start = new Date(startTime);
    const end = new Date(endTime);
    const diffInSeconds = Math.floor((end.getTime() - start.getTime()) / 1000); // Convert difference to seconds
    return diffInSeconds;
}

function TestInsightTable({ quizData }: { quizData: QuizAttemptItem[] }) {
    const [selectedQuiz, setSelectedQuiz] = useState<QuizAttemptItem | null>(null);
    const [showModal, setShowModal] = useState(false);
    const handleRowClick = (quiz: QuizAttemptItem) => {
        setSelectedQuiz(quiz); // Set the selected quiz
        setShowModal(true); // Show the modal
    };

    const handleCloseModal = () => {
        setShowModal(false); // Close the modal
        setSelectedQuiz(null); // Clear the selected quiz
    };

    return (
        <div>
            {quizData.length > 0 ? (
                <div className="row justify-content-center mb-0 ">
                    <div className="col-12 col-lg-12 col-xl-12 mb-8 mb-lg-0">
                        <h4 className="my-3">Test Taken </h4>
                        <Table striped bordered hover>
                            <thead>
                                <tr className="accordion">
                                    <th scope="col">
                                        <strong>Quiz Name</strong>
                                    </th>

                                    <th scope="col">
                                        <strong>Start Time</strong>
                                    </th>

                                    <th scope="col">
                                        <strong>Status</strong>
                                    </th>

                                    <th scope="col">
                                        <strong>Total Marks</strong>
                                    </th>
                                    <th scope="col">
                                        <strong>Time Taken</strong>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {quizData?.map((quiz, i: number) => (
                                    <tr
                                        key={i}
                                        className="clickable-row"
                                        onClick={() => handleRowClick(quiz)}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <td>{quiz.quiz.internalName}</td>

                                        <td>{formatTimestamp(quiz.startTime)}</td>

                                        <td>
                                            {quiz.status == 'COMPLETED' ? (
                                                <div
                                                    className="d-flex"
                                                    style={{
                                                        gap: '8px',
                                                    }}
                                                >
                                                    <div
                                                        className="d-flex align-items-center"
                                                        style={{
                                                            color: 'black',
                                                        }}
                                                    >
                                                        <FaCheckCircle
                                                            style={{
                                                                marginRight: '8px',
                                                                color: 'green',
                                                            }}
                                                            title="Correct Answers"
                                                        />
                                                        {quiz.correctAnswers}
                                                    </div>
                                                    <div
                                                        className="d-flex align-items-center"
                                                        style={{
                                                            color: 'black',
                                                        }}
                                                    >
                                                        <FaTimesCircle
                                                            style={{
                                                                marginRight: '8px',
                                                                color: 'red',
                                                            }}
                                                            title="Incorrect Answers"
                                                        />
                                                        {quiz.incorrectAnswers}
                                                    </div>
                                                    <div
                                                        className="d-flex align-items-center"
                                                        style={{
                                                            color: 'black',
                                                        }}
                                                    >
                                                        <FaQuestionCircle
                                                            style={{
                                                                marginRight: '8px',
                                                                color: 'orange',
                                                            }}
                                                            title="Skipped Answers"
                                                        />
                                                        {quiz.skippedAnswers}
                                                    </div>
                                                </div>
                                            ) : (
                                                quiz.status
                                            )}
                                        </td>

                                        <td>
                                            {quiz.marksScored}/{quiz.quizTotalMarks}
                                        </td>
                                        <td>
                                            {formatDuration(
                                                calculateTimeTaken(quiz.startTime, quiz.endTime)
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </div>
            ) : (
                'No Quiz Attemped'
            )}
            <Modal show={showModal} onHide={handleCloseModal} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Quiz Attempt Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedQuiz && (
                        <QuizAttempts quizId={selectedQuiz.quizId} attemptId={selectedQuiz.id} />
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default TestInsightTable;
