import { quizConstants } from "../../ReducerConstants";
import { QuizItem } from "../../../types/quizTypes";

export type QuizState = {
  loading: boolean;
  quiz?: QuizItem;
  errors?: string;
  quizActiveIndex?: number;
};

const initState: QuizState = {
  quizActiveIndex: 0,
  loading: false,
};

export default function quizReducer(state = initState, action: any) {
  switch (action.type) {
    case quizConstants.QUIZ_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case quizConstants.GET_QUIZ_SUCCESS:
      return {
        loading: false,
        quiz: action.data,
      };

    case quizConstants.SET_QUIZ_ACTIVE_INDEX:
      return {
        ...state,
        quizActiveIndex: action.index,
      };
    case quizConstants.GET_QUIZ_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.errors,
      };
    default:
      return state;
  }
}
