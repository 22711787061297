import { AnimatePresence, motion } from "framer-motion";
import { FaCircleCheck, FaRegCircleCheck } from "react-icons/fa6";

type Props = {
  checked: boolean;
  className?: string;
  style?: React.CSSProperties;
  onChecked: (name: string, value: boolean) => void;
  name?: string;
  size?: number;
  label?: string;
};

const variants = {
  initial: { scale: 0.5, opacity: 1 },
  animate: {
    scale: 1,
    opacity: 1,
    transition: { type: "spring", stiffness: 900, damping: 40, duration: 0.2 },
  },
};

const CustomCheckbox = ({ className = "gap-2", ...props }: Props) => {
  const { name, onChecked, checked, label = "", size = 18 } = props;

  return (
    <div
      className={`d-flex align-items-center ${className || ""}`}
      style={{ cursor: "pointer", ...(props.style && props.style) }}
    >
      <AnimatePresence mode="wait" initial={false}>
        {checked ? (
          <motion.div
            style={{ cursor: "pointer" }}
            key="checked"
            variants={variants}
            initial="initial"
            animate="animate"
            onClick={() => onChecked(name || "", !checked)}
          >
            <FaCircleCheck color="#50C878" size={size} />
          </motion.div>
        ) : (
          <motion.div
            style={{ cursor: "pointer" }}
            key="unchecked"
            variants={variants}
            initial="initial"
            animate="animate"
            onClick={() => onChecked(name || "", !checked)}
          >
            <FaRegCircleCheck size={size} />
          </motion.div>
        )}
      </AnimatePresence>
      {label && (
        <span
          onClick={() => onChecked(name || "", !checked)}
          style={{ userSelect: "none" }}
        >
          {label}
        </span>
      )}
    </div>
  );
};

export default CustomCheckbox;
