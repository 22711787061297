import { SessionInterface } from "../../types/LiveTypes";
import { LiveManagementConstant } from "./live-mangment-constant";


interface InitStateInterface {
    sessions: SessionInterface[];
    currentSessionId: string | null;
}


interface ActionInterface {
    type: LiveManagementConstant;
    payload: any;
}

const initState: InitStateInterface = {
    sessions: [],
    currentSessionId: null
};


const LiveManagementReducer = (state: InitStateInterface = initState, action: ActionInterface) => {
    switch (action.type) {
        case "SESSIONS":
            return {
                ...state,
                sessions: action.payload
            };

        case "UPDATE_SESSION":
            return {
                ...state,
                sessions: state.sessions.map(
                    (session) => {
                        if (session.id === action.payload.id) {
                            return action.payload
                        }
                        return session
                    }
                )
            }
        case "CURRENT_SESSION_ID":
            return {
                ...state,
                currentSessionId: action.payload
            }

        default:
            return state;
    }
};

export default LiveManagementReducer;
