import React, { useState, useEffect } from 'react';
import { Modal, Button, Table } from 'react-bootstrap';
import { ENDPOINT_HOST, FRONTEND_URLS } from '../../config';
import { axiosClient } from '../../services/axiosClient';
import DataTable, { TableColumn } from 'react-data-table-component';
import { FiCheckCircle, FiXCircle } from 'react-icons/fi';

interface LiveAttendenceProps {
    show: boolean;
    onHide: () => void;
    onConfirm: () => void;
    title?: string;
    confirmText?: string;
    cancelText?: string;
    sessionId?: any;
}

const LiveAttendenceModal: React.FC<LiveAttendenceProps> = ({
    show,
    onHide,
    title = 'Confirm Action',
    sessionId,
    cancelText = 'Close',
}) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [apiData, setApiData] = useState<any[]>([]);

    // Function to call the API and handle response
    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axiosClient.get(
                `${ENDPOINT_HOST}/sessions/live-attendees/${sessionId}`,
                {
                    method: 'GET',
                }
            );

            if (!response || !response.data) {
                throw new Error('Failed to fetch data');
            }
            const data = response.data.data;
            setApiData(data);
        } catch (error: any) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (show) {
            fetchData();
        }
    }, [show]);

    const calculateSummary = () => {
        const presentCount = apiData.filter((item) => item.attended).length;
        const absentCount = apiData.length - presentCount;
        return { presentCount, absentCount };
    };

    const { presentCount, absentCount } = calculateSummary();

    const divStyles = {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        padding: '1px',
    };

    const columns = [
        {
            name: 'Name',
            selector: (row: any) => row.name,
            cell: (row: any) => (
                <span className='text-breaker'>
                    {row.name}
                </span>
            ),
            sortable: true,
        },
        {
            name: 'Email',
            selector: (row: any) => row.primary_email,
            cell: (row: any) => (
                <span
                    className='text-breaker'
                >
                    {row.primary_email}
                </span>
            ),
            sortable: true,
        },
        {
            name: 'Attend',
            selector: (row: any) => row.attended, // Use the "attended" boolean for sorting
            cell: (row: any) => (
                <span style={{ color: row.attended ? 'green' : 'red', cursor: 'auto' }}>
                    {row.attended ? 'P' : 'A'}
                </span>
            ),
            sortable: true,
        },
    ];

    return (
        <Modal show={show} onHide={onHide} backdrop="static" size="lg">
            <Modal.Header closeButton>
                <Modal.Title>
                    {title}
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '15px',
                            fontSize: '14px',
                            marginBottom: '20px',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '5px',
                                color: 'green',
                            }}
                        >
                            <FiCheckCircle size={18} title="Present Students" />
                            <span>P: {presentCount}</span>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '5px',
                                color: 'red',
                            }}
                        >
                            <FiXCircle size={18} title="Abesnt Students" />
                            <span>A: {absentCount}</span>
                        </div>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {error && <div style={{ color: 'red' }}>{error}</div>}

                <DataTable
                    columns={columns}
                    data={apiData}
                    paginationPerPage={100}
                    paginationRowsPerPageOptions={[100, 500, 1000]}
                    progressPending={loading}
                    pagination
                    highlightOnHover
                    striped
                    pointerOnHover
                    onRowClicked={(row) => {
                        const url = `${FRONTEND_URLS.STUDENT.ENROLLED_STUDENTS}/${row.enrollment_id}`;
                        window.open(url, '_blank');
                    }}
                    noDataComponent="No data available"
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide} disabled={loading}>
                    {cancelText}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default LiveAttendenceModal;
