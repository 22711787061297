import { ClassItem } from '../../types/course.types';
import ApiService, { ApiResponse } from './api-service';

class StudentClassService extends ApiService {
    constructor() {
        super('/student-classes');
    }

    getAll(): ApiResponse<{ classes: ClassItem[] }> {
        return this.getData('');
    }
}

export default new StudentClassService();
